import React, { useLayoutEffect, useRef } from 'react';
import { AbstractProps, FRAME_SIZE } from 'types/common/PropsTypes';

import ModalBodyLayout from './ModalBodyLayout';
import ModalFooterLayout, { footerButtonDefProps } from './ModalFooterLayout';
import ModalHeaderLayout from './ModalHeaderLayout';

export interface ModalComponentProps extends AbstractProps {
  size?: FRAME_SIZE;
  modalId: string;
  titleName?: string;
  footerButtonDef?: footerButtonDefProps[];
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalComponent = ({ size, modalId, titleName, footerButtonDef, isOpen, setIsOpen, children }: ModalComponentProps) => {
  const closeRef = useRef<HTMLButtonElement>(null);
  const outSectionRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isOpen === undefined || isOpen) return;
    closeRef.current?.click();
  }, [isOpen]);

  return (
    <div
      id={modalId}
      className={`modal fade`}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="largeModalLabel"
      aria-hidden="true"
      ref={outSectionRef}
      onClick={(e) => {
        if (outSectionRef.current === e.target) {
          setIsOpen?.(false);
        }
      }}
    >
      <div className={`modal-dialog modal-${size ?? 'lg'}`}>
        <div className="modal-content">
          <ModalHeaderLayout closeRef={closeRef} setIsOpen={setIsOpen} titleName={titleName} />
          <ModalBodyLayout>{children}</ModalBodyLayout>
          <ModalFooterLayout setIsOpen={setIsOpen} footerButtonDef={footerButtonDef} />
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
