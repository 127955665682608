import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const GlobalNetworksComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-20">
        <h3 className="fColor-yellow">{t('about.global1')}</h3>
        <h2>
          {t('about.global2')}
          <br />
          {t('about.global3')}
        </h2>
        <p className="section-sub-title">
          {t('about.global4')}
          <br />
          {t('about.global5')}
        </p>
      </FrameCol>
      <FrameCol>
        <div className="img-full px-5 mobile-hide exp-animation" data-animation="express_b_show" data-delay="400">
          <CustomImgTag src="assets/img/custom/about_map.png?v=20240612" alt="PC용" />
        </div>
        <div className="img-full pc-hide exp-animation" data-animation="express_b_show" data-delay="400">
          <CustomImgTag src="assets/img/custom/about_map2.png?v=20240612" alt="모바일용" />
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default GlobalNetworksComponent;
