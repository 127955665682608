import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import FlowBannerLayout from 'common/view/layouts/slider/FlowBannerLayout';
import { useTranslation } from 'react-i18next';

const PartnerComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol>
        <FlowBannerLayout>
          <section
            className="partnerWrap dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall"
            style={{ backgroundImage: `url(assets/img/custom/lang_partner_bg.png)`, backgroundPosition: `center center`, backgroundSize: `cover` }}
          >
            <FrameContainer>
              <FrameRow addClassName="mt-70">
                <div className="col-md-8 mob-text-center">
                  <h1>{t('langMain.partner1')}</h1>
                </div>
                <div className="col-md-4 z-index-1">
                  <div className="flow_ctrl_box">
                    <a href="#none" id="banner_left" className="back">
                      <span className="blind">{t('langMain.partner2')}</span>
                    </a>
                    <a href="#" onClick={(e) => e.preventDefault()} id="banner_play" className="play">
                      <span className="">{t('langMain.partner3')}</span>
                    </a>
                    <a href="#" onClick={(e) => e.preventDefault()} id="banner_pause" className="stop">
                      <span className="">{t('langMain.partner4')}</span>
                    </a>
                    <a href="#none" id="banner_right" className="next">
                      <span className="blind">{t('langMain.partner5')}</span>
                    </a>
                  </div>
                </div>
              </FrameRow>
            </FrameContainer>
            <div className="footer_banner_zone z-index-1 mb-70">
              <div className="flow_banner_box">
                <div id="banner" className="flow_bann_area">
                  <ul className="a1">
                    {/* 3COM */}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_01.png" />
                    </li>
                    {/*Adobe*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_24.png" />
                    </li>
                    {/*AHC*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_57.png" />
                    </li>
                    {/*Airbnb*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_52.png" />
                    </li>
                    {/*AMD*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_31.png" />
                    </li>
                    {/*AUTODESK*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_11.png" />
                    </li>
                    {/*CISCO*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_04.png" />
                    </li>
                    {/*coupang*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_70.png" />
                    </li>
                    {/*ESTsoft*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_47.png" />
                    </li>
                    {/*ETRI*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_54.png" />
                    </li>
                    {/*HP - HEWLETTPACKARD*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_02.png" />
                    </li>
                    {/*HYUNDAI MOBIS*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_44.png" />
                    </li>
                    {/*IBM*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_01.png" />
                    </li>
                    {/*INFOR*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_26.png" />
                    </li>
                    {/*IRIVER*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_65.png" />
                    </li>
                    {/*iTPLUS*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_48.png" />
                    </li>
                    {/*LG전자*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_43.png" />
                    </li>
                    {/*MathWorks*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_67.png" />
                    </li>
                    {/*McAfee*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_36.png" />
                    </li>
                    {/*medidata*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_59.png" />
                    </li>
                    {/*Microsoft*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_14.png" />
                    </li>
                    {/*namo INTERACTIVE*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_45.png" />
                    </li>
                    {/*NETFLIX*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_64.png" />
                    </li>
                    {/*netmarble*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_71.png" />
                    </li>
                    {/*ORACLE*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_07.png" />
                    </li>
                    {/*Panasonic*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_23.png" />
                    </li>
                    {/*PayPal*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_58.png" />
                    </li>
                    {/*PRADA*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_66.png" />
                    </li>
                    {/*QUINTILES*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_60.png" />
                    </li>
                    {/*SAAB*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_15.png" />
                    </li>
                    {/*SABA*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_32.png" />
                    </li>
                    {/*SAP*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_06.png" />
                    </li>
                    {/*SEAGATE*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_25.png" />
                    </li>
                    {/*SK chemicals*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_72.png" />
                    </li>
                    {/*SMA*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_42.png" />
                    </li>
                    {/*SONY*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_18.png" />
                    </li>
                    {/*Spotify*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_61.png" />
                    </li>
                    {/*Symantec*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_37.png" />
                    </li>
                    {/*Uber*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_63.png" />
                    </li>
                    {/*YAMAGATA*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_22.png" />
                    </li>
                    {/*두나무*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_69.png" />
                    </li>
                    {/*창업진흥원*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_73.png" />
                    </li>
                    {/*한국특허정보원*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_53.png" />
                    </li>
                    {/*가천대학교*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_75.png" />
                    </li>
                    {/*세종학당재단*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_76.png" />
                    </li>
                    {/*오스템임플란트 주식회사*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_77.png" />
                    </li>
                    {/*외교부*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/it_logo_27.png" />
                    </li>
                    {/*인천상공회의소*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_78.png" />
                    </li>
                    {/*재단법인 경기창조경제혁신센터*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_79.png" />
                    </li>
                    {/*중소기업벤처진흥공단*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_80.png" />
                    </li>
                    {/*(주)그레이프시티*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_81.png" />
                    </li>
                    {/*(주)삼양사*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_82.png" />
                    </li>
                    {/*(주)지란지교소프트*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_83.png" />
                    </li>
                    {/*한국출판문화산업진흥원*/}
                    <li>
                      <CustomImgTag src="assets/img/clients/ln_logo_51.png" />
                    </li>
                    {/*한국전자통신연구원 -> ETRI와 중복됨*/}
                  </ul>
                </div>
              </div>
              <div className="col-md-12 text-center">
                <CustomATag href="/lang/portfolio" className="btn btn-primary fs-14 btn-rounded">
                  {t('langMain.partner6')} <i className="glyphicon icon icon-arrow-32 ml-5"></i>
                </CustomATag>
              </div>
            </div>
          </section>
        </FlowBannerLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default PartnerComponent;
