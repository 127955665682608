import { TabLayoutCommonProps } from 'types/common/PropsTypes';

import { FrameCol } from '../../frames';
import TabLayout from '../../tab/TabLayout';
import FooterMoreEmailModalEmailCollectionRefusalLayout from './FooterMoreEmailModalEmailCollectionRefusalLayout';

const FooterMoreEmailModalTabLayout = ({ currentTabIdx, setCurrentTabIdx }: TabLayoutCommonProps) => {
  return (
    <FrameCol size={'lg'} width={12}>
      <TabLayout isFromModal tabLabelDef={['이메일 무단수집 거부']} currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} tabPanelDef={[<FooterMoreEmailModalEmailCollectionRefusalLayout key={0} />]} />
    </FrameCol>
  );
};

export default FooterMoreEmailModalTabLayout;
