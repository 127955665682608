import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import SectionLayout from 'common/view/layouts/section/SectionLayout';

import AboutHeaderComponent from './AboutHeaderComponent';
import CIComponent from './CIComponent';
import GlobalNetworksComponent from './GlobalNetworksComponent';
import HistoryComponent from './HistoryComponent';
import PatentComponent from './PatentComponent';
import VisionComponent from './VisionComponent';

const AboutPage = () => {
  return (
    <>
      <ExpAnimationLayout>
        <SectionLayout addSectionClassName="main-color" addContianerClassName="fluid">
          <AboutHeaderComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="b-bordered">
          <VisionComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="b-bordered bg-gray">
          <GlobalNetworksComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="bg-gray">
          <CIComponent />
        </SectionLayout>
        <SectionLayout>
          <HistoryComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="bg-gray t-bordered" addContianerClassName="fluid">
          <PatentComponent />
        </SectionLayout>
      </ExpAnimationLayout>
    </>
  );
};

export default AboutPage;
