import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'usehooks-ts';

const ServiceTabComponent = () => {
  const { t } = useTranslation();

  useEffectOnce(() => {
    const $ = window.$;

    const scrollOffset = $('#ServiceHeader').offset();
    const scrollHandler = () => {
      if ($(document).scrollTop() > scrollOffset.top + 70) {
        $('#ServiceHeader').addClass('scroll-fixed');
        $('#tm1').addClass('active');
        $('#tm2').removeClass('active');
        $('#tm3').removeClass('active');
        $('#tm4').removeClass('active');
      } else {
        $('#ServiceHeader').removeClass('scroll-fixed');
      }
      if ($(document).scrollTop() > $('#service1').offset().top - 80) {
        $('#tm1').addClass('active');
        $('#tm2').removeClass('active');
        $('#tm3').removeClass('active');
        $('#tm4').removeClass('active');
      }
      if ($(document).scrollTop() > $('#service2').offset().top - 100) {
        $('#tm2').addClass('active');
        $('#tm1').removeClass('active');
        $('#tm3').removeClass('active');
        $('#tm4').removeClass('active');
      }
      if ($(document).scrollTop() > $('#service3').offset().top - 80) {
        $('#tm3').addClass('active');
        $('#tm1').removeClass('active');
        $('#tm2').removeClass('active');
        $('#tm4').removeClass('active');
      }
      if ($(document).scrollTop() > $('#service4').offset().top - 80) {
        $('#tm4').addClass('active');
        $('#tm1').removeClass('active');
        $('#tm2').removeClass('active');
        $('#tm3').removeClass('active');
      }
    };
    $(window).scroll(scrollHandler);
    return () => {
      $(window).off('scroll', scrollHandler);
    };
  });

  return (
    <>
      <FrameRow>
        <FrameCol addClassName="text-center mb-0">
          <h1 className="mb-20 mt-50">
            <span>SERVICE</span>
          </h1>
        </FrameCol>
      </FrameRow>
      <div className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall hi300 row mt-40">
        <div className="divimage dzsparallaxer--target w-100" style={{ height: '130%', backgroundImage: 'url(assets/img/custom/lang_visual_img.png)', backgroundPosition: 'center center' }}></div>
      </div>
      <div className="tab-wrap" id="ServiceHeader">
        <ul className="nav nav-tabs justify-content-center" role="tablist">
          <li className="nav-item">
            <a id="tm1" className="nav-link active" href="#service1" data-hash data-hash-offset="70" data-toggle="tab" role="tab">
              <span>{t('langService.tab1')}</span>
            </a>
          </li>
          <li className="nav-item">
            <a id="tm2" className="nav-link" href="#service2" data-hash data-hash-offset="70" data-toggle="tab" role="tab">
              <span>{t('langService.tab2')}</span>
            </a>
          </li>
          <li className="nav-item">
            <a id="tm3" className="nav-link" href="#service3" data-hash data-hash-offset="70" data-toggle="tab" role="tab">
              <span>{t('langService.tab3')}</span>
            </a>
          </li>
          <li className="nav-item">
            <a id="tm4" className="nav-link" href="#service4" data-hash data-hash-offset="70" data-toggle="tab" role="tab">
              <span>{t('langService.tab4')}</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ServiceTabComponent;
