import { selectCommunityList } from 'api/board/BoardAPI';
import { selectCommunityCount } from 'api/board/BoardAPI';
import FrameContainer from 'common/view/layouts/frames/FrameContainer';
import FrameSection from 'common/view/layouts/frames/FrameSection';
import { useLayoutEffect, useState } from 'react';
import { BoardRVO } from 'types/board';
import { ListAndPageProps } from 'types/common';
import { isSuccess } from 'util/ApiResponseUtil';
import { defaultPagingListObject } from 'util/DataUtil';

import { CommunityListComponent, CommunityListPaginationComponent } from './communityListComponents';

type CommunityCountProps = {
  all: number;
  notice: number;
  story: number;
};

// 카테고리별 게시물 카운트
export const communityCountData: CommunityCountProps = {
  all: 0,
  notice: 0,
  story: 0,
};

export type selectedTypeEnum = 0 | 7 | 15; // 0: all, 7: notice, 15: story
const CommunityListPageComponent = () => {
  const [communityList, setCommunityList] = useState<ListAndPageProps<BoardRVO>>(defaultPagingListObject);
  const [selectedType, setSelectedType] = useState<selectedTypeEnum>(0);
  const [isLoading, setIsLoading] = useState(false); // 임시 로딩처리
  const [e4Count, setE4Count] = useState<number>(0);
  const [noticeCount, setNoticeCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  // 뒤로가기 시 state 값 필요
  // 새로고침시 세션 값 필요
  // const { state } = useLocation();
  // const sessionPageNoKey = 'CategoryPageNo';

  const getCommunityList = async (pageNo?: number) => {
    const itemCountPerPage = 9;
    await selectCommunityCount()
      .then((res) => {
        const resData = res.response as CommunityCountProps;
        communityCountData.all = resData.all;
        communityCountData.notice = resData.notice;
        communityCountData.story = resData.story;
      })
      .catch((error) => {
        alert(error);
      });

    setIsLoading(true);
    await selectCommunityList({ pageNo: pageNo, rowsPage: itemCountPerPage, brdNo: selectedType })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setE4Count(communityCountData.story);
            setNoticeCount(communityCountData.notice);
            setTotalCount(communityCountData.story + communityCountData.notice);
            setSelectedType(selectedType ?? '');
            setCommunityList({
              list: resData,
              pagination: {
                totalCount: resData[0].totalCount,
                currentPageNo: pageNo ?? 1,
                itemCountPerPage,
              },
            });
            // 세션 셋팅
            // sessionStorage.setItem(sessionPageNoKey, String(pageNo ?? 1));
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  useLayoutEffect(() => {
    getCommunityList();
  }, [selectedType]);

  // useEffect(() => {
  //   return () => {
  //     sessionStorage.removeItem(sessionPageNoKey);
  //   };
  // }, []);

  // useEffect(() => {
  //   const sessionPageNo = sessionStorage.getItem(sessionPageNoKey);
  //   if (sessionPageNo !== null) getCommunityList(parseInt(sessionPageNo));
  //   else if (state !== null) getCommunityList(parseInt(state.pageNo), parseInt(state.cate));
  //   else if (state === null) getCommunityList();
  // }, [state]);

  return (
    <FrameSection addClassName="b-bordered">
      <FrameContainer>
        <CommunityListComponent e4Count={e4Count} noticeCount={noticeCount} totalCount={totalCount} selectedType={selectedType} setSelectedType={setSelectedType} isLoading={isLoading} communityList={communityList.list} />
      </FrameContainer>
      <FrameContainer>
        <CommunityListPaginationComponent pagination={communityList.pagination} pagingOnClick={getCommunityList} />
      </FrameContainer>
    </FrameSection>
  );
};

export default CommunityListPageComponent;
