import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import PaginationLayout, { PaginationLayoutProps } from 'common/view/layouts/pagination/PaginationLayout';

const CommunityListPaginationComponent = ({ pagination, pagingOnClick }: PaginationLayoutProps) => {
  return (
    <FrameRow>
      <FrameCol>
        <PaginationLayout pagination={pagination} pagingOnClick={pagingOnClick} />
      </FrameCol>
    </FrameRow>
  );
};

export default CommunityListPaginationComponent;
