import { sanitize } from 'dompurify';

const ContactMoreInfoModalPersonalInfoPolicyLayout = () => {
  return (
    <>
      <h3 className="mb-10">본인은 (주)이포넷(이하 &apos;회사&apos;라 함)이 본인의 개인정보를 수집·이용·제공 및 취급·위탁하는데 동의합니다.</h3>
      <pre
        dangerouslySetInnerHTML={{
          __html: sanitize(`<b>■ 개인정보의 수집·이용 목적</b>
    ο 입력한 본인확인정보의 정확성 여부 확인
    ο 휴대폰 SMS(또는 LMS) 답변(푸시메시지) 전송
    ο 이메일 답변 발송
    ο 기타 법률에서 정한 목적 및 서비스 관련 상담 처리 등
          
<b>■ 수집하려는 개인정보의 항목</b>
    ο 개인의 성명, 휴대폰 번호, 이메일
          
<b>■ 개인정보의 제공 및 취급위탁</b>
    ο 제공항목: 수집한 개인정보 항목
    ο 제공받는 자(수탁자): (주)이포넷
    ο 제공받는 자의 이용 목적(취급위탁 업무): 휴대폰 회신 및 이메일 상담처리 서비스 제공
          
<b>■ 개인정보의 보유 및 이용 기간</b>
    제공하신 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체없이 폐기됩니다. 단, 다음의 정보에 대해서는 아래의 이유로 명기한 기간 동안 보유합니다.
        ο 회사 내부 방침에 의한 정보보유 사유
            1.- 보유 이유: 부정 이용 방지 및 민원 처리
            2.- 보유 기간: 5년
        ο 관계법령에 의한 정보보유 사유
            1.- 이용자 불만 또는 분쟁 처리에 관한 기록
            2.- 보유 이유: 전자상거래 등에서의 소비자보호에 관한 법률
            3.- 보유 기간: 3년
          
<b>■ 귀하는 동의를 거부할 권리가 있으며, 동의하지 않을 경우 서비스를 이용하실 수 없습니다.</b>`),
        }}
      />
    </>
  );
};

export default ContactMoreInfoModalPersonalInfoPolicyLayout;
