import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import GoogleMapLayout from 'common/view/layouts/googleMap/GoogleMapLayout';
import { useTranslation } from 'react-i18next';

const ContactGoogleMapComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol textAlign="text-center">
        <h2>{t('contact.googleMap1')}</h2>
        <GoogleMapLayout
          address={t('contact.googleMap2')}
          postcode={'06169'}
          title={t('contact.googleMap3')}
          googleMapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d791.2364817589537!2d127.05800445049802!3d37.50919346723147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca115fd77124d%3A0x1f573709740e225c!2z7J207Y-s64S3!5e0!3m2!1sko!2skr!4v1621486320261!5m2!1sko!2skr"
          srcToCopy="https://www.google.com/maps/place/E4NET/@37.5090759,127.0561365,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x1f573709740e225c!8m2!3d37.5090759!4d127.0583252"
        />
      </FrameCol>
    </FrameRow>
  );
};

export default ContactGoogleMapComponent;
