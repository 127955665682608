import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';
import { stylePre } from 'util/StyleUtil';

const DepartmentsComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <FrameRow>
        <FrameCol addClassName="mob-text-center">
          <h1 className="mb-30">
            <span>
              {t('homeMain.departments1')}
              <br />
              {t('homeMain.departments2')}
            </span>
          </h1>
          <p className="section-sub-title mb-30" style={stylePre}>
            {t('homeMain.departments3')}
            <br />
            {t('homeMain.departments4')}
          </p>
        </FrameCol>
      </FrameRow>
      <ul className="post-wrap row">
        <ExpAnimationLayout>
          <li className="col-md-6">
            <article className="exp-animation" data-animation="bottom-to-top" data-delay="400">
              <div className="aboutusWrap it">
                <CustomATag href="/it">
                  <h2 itemProp="name">{t('homeMain.departments5')}</h2>
                  <div itemProp="description">
                    <p>
                      {t('homeMain.departments6')}
                      <br />
                      {t('homeMain.departments7')}
                    </p>
                  </div>
                  <div className="imgWrap">
                    <CustomImgTag alt="" src="assets/img/custom/img_it.png" />
                  </div>
                  <span>
                    {t('homeMain.departments8')} <i className="fa fa-long-arrow-right"></i>
                  </span>
                </CustomATag>
              </div>
            </article>
          </li>

          <li className="col-md-6">
            <article className="exp-animation" data-animation="bottom-to-top" data-delay="800">
              <div className="aboutusWrap lang">
                <CustomATag href="/lang">
                  <h2 itemProp="name">{t('homeMain.departments9')}</h2>
                  <div itemProp="description">
                    <p>
                      {t('homeMain.departments10')}
                      <br />
                      {t('homeMain.departments11')}
                      <br />
                      {t('homeMain.departments111')}
                    </p>
                  </div>
                  <div className="imgWrap">
                    <CustomImgTag alt="" src="assets/img/custom/img_lang.png" />
                  </div>
                  <span>
                    {t('homeMain.departments12')} <i className="fa fa-long-arrow-right"></i>
                  </span>
                </CustomATag>
              </div>
            </article>
          </li>
        </ExpAnimationLayout>
      </ul>
    </>
  );
};

export default DepartmentsComponent;
