import SectionLayout from 'common/view/layouts/section/SectionLayout';

import NewsBannerComponent from './NewsBannerComponent';
import NewsListPageComponent from './NewsListPageComponent';

const NewsPage = () => {
  return (
    <>
      <SectionLayout addSectionClassName="main-color pb-0" addContianerClassName="fluid">
        <NewsBannerComponent />
      </SectionLayout>
      <NewsListPageComponent />
    </>
  );
};

export default NewsPage;
