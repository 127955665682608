import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import TgGridLayout from 'common/view/layouts/grid/TgGridLayout';
import React from 'react';
import { NewListRVO } from 'types/sample/sample2';

interface Sample2NewsListListComponentProps {
  searchInputRef: React.RefObject<HTMLInputElement>;
  onSubmit: (pageNo: number) => void;
  newsList: NewListRVO[];
  totalCount: number;
  isLoading: boolean;
}
const Sample2NewsListListComponent = ({ searchInputRef, onSubmit, newsList, totalCount, isLoading }: Sample2NewsListListComponentProps) => {
  return (
    <>
      <FrameRow>
        <FrameCol textAlign="text-center">
          <div className="searchWrap">
            <form id="newsSearch">
              <div className="input-group">
                <input ref={searchInputRef} className="form-control" placeholder="검색어를 입력하세요" name="newsSearch" id="Search" type="text" />
                <span className="input-group-btn">
                  <button className="btn btn-outline-white mr-20" type="submit">
                    <i className="glyphicon glyphicon-search"></i>
                  </button>
                  <button className="btn btn-outline-white" type="button" onClick={() => onSubmit(1)}>
                    전체보기
                  </button>
                </span>
              </div>
            </form>
          </div>
        </FrameCol>
      </FrameRow>
      <FrameRow>
        <FrameCol>
          <p className="total-num">
            <span>{totalCount}</span> 건
          </p>

          <TgGridLayout id="grid-pracia">
            {isLoading ? (
              <h1>...Loading 임시로</h1>
            ) : (
              <div
                className="tg-grid-holder tg-layout-grid"
                data-name="Pracia"
                data-style="grid"
                data-row="1"
                data-layout="vertical"
                data-rtl=""
                data-fitrows=""
                data-filtercomb=""
                data-filterlogic="AND"
                data-filterload=""
                data-sortbyload="none"
                data-orderload="false"
                data-fullwidth=""
                data-fullheight="null"
                data-gutters="[[320,20],[480,20],[690,20],[1000,20],[1300,20],[9999,20]]"
                data-slider="{'itemNav':'null','swingSpeed':0.1,'cycleBy':'null','cycle':5000,'startAt':1}"
                data-ratio="1.25"
                data-cols="[[320,1],[480,1],[690,2],[1000,3],[1300,3],[9999,3]]"
                data-rows="[[320,200],[480,200],[690,220],[1000,220],[1300,240],[9999,240]]"
                data-animation='{"name":"From Top","visible":"translateY(0)","hidden":"translateY(-100px)"}'
                data-transition="500ms"
                data-ajaxmethod="load_more"
                data-ajaxdelay="100"
                data-preloader="true"
                data-itemdelay="100"
                data-gallery=""
                data-ajax=""
              >
                {newsList.map((ele, idx) => {
                  return (
                    <article key={`Sample2NewsListListComponentTgGridLayout${idx}`} className="tg-item pracia" data-row="1" data-col="1">
                      <div className="tg-item-inner">
                        <div className="tg-item-media-holder tg-light">
                          <div className="tg-item-media-inner">
                            <div className="tg-item-image" style={{ background: `url(${ele.imageSrc})` }}></div>
                          </div>
                          <div className={`tg-item-overlay random-bg0${idx + 1}`}></div>
                          <div className="tg-center-holder">
                            <div className="tg-center-inner">
                              <CustomATag className="tg-link-button" href={`${idx}`}>
                                <i className="tg-icon-link"></i>
                              </CustomATag>
                            </div>
                          </div>
                        </div>
                        <div className="tg-item-content-holder image-format">
                          <h2 className="newsTitle">
                            <CustomATag href={`${idx}`}>{ele.title}</CustomATag>
                          </h2>
                          <span className="newsDate">{ele.subTitle}</span>
                        </div>
                      </div>
                    </article>
                  );
                })}
              </div>
            )}
          </TgGridLayout>
        </FrameCol>
      </FrameRow>
    </>
  );
};

export default Sample2NewsListListComponent;
