import { useScript } from 'hooks/common/ScriptHook';
import { useLayoutEffect } from 'react';
import { AbstractProps } from 'types/common/PropsTypes';

const FlowBannerLayout = ({ children }: AbstractProps) => {
  const { importScript } = useScript();

  useLayoutEffect(() => {
    importScript(['js/jquery.carouFredSel-5.5.0-packed', 'js/flow_banner', 'vendor/dzsparallaxer/dzsparallaxer', 'vendor/dzsparallaxer/dzsscroller/scroller', 'vendor/dzsparallaxer/advancedscroller/plugin']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return children;
};

export default FlowBannerLayout;
