import { styleCursorPointer } from 'util/StyleUtil';

type PaginationComponentProps = {
  onClick?: () => void;
  labelName: string;
  isActive: boolean;
};
const PaginationComponent = ({ onClick, labelName, isActive }: PaginationComponentProps) => {
  return (
    <li className={`page-item${isActive ? ' active' : ''}`}>
      <a className="page-link" onClick={() => onClick?.()} style={isActive ? {} : styleCursorPointer}>
        {labelName}
      </a>
    </li>
  );
};

export default PaginationComponent;
