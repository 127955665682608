import { CH, EN, KO } from 'common/constants/Constants';
import i18n from 'i18n';
import { MouseEvent, useState } from 'react';

const langList = [
  { langVl: KO, langNm: 'KOREAN' },
  { langVl: EN, langNm: 'ENGLISH' },
  { langVl: CH, langNm: 'CHINESE' },
];

const HeaderSelectLangComponent = () => {
  const [lang, setLang] = useState<string>(sessionStorage.getItem('langCd') ?? KO);
  const currentLangInfo = langList.find((langInfo) => langInfo.langVl === lang);
  const handleSelectLanguage = (e: MouseEvent<HTMLAnchorElement>, lang: string) => {
    e.preventDefault();
    setLang(lang);
    i18n.changeLanguage(lang);
    sessionStorage.setItem('langCd', lang);
  };

  return (
    <div className="selectlang">
      <div className="nav-item dropdown">
        <a className="nav-link" href="#" role="button" id="dropdownLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span>
            <i className="glyphicon icon icon-globe-2"></i> {currentLangInfo?.langNm}
          </span>
          <i className="fa fa-angle-down"></i>
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownLanguage">
          {langList
            .filter((langInfo) => langInfo.langVl !== lang)
            .map((langInfo) => (
              <a key={`${langInfo.langVl}${langInfo.langNm}`} className="dropdown-item" href="#" onClick={(e) => handleSelectLanguage(e, langInfo.langVl)}>
                <i className="glyphicon icon icon-globe-2"></i> {langInfo.langNm}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HeaderSelectLangComponent;
