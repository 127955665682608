import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const ContactHeadComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol textAlign="text-center" addClassName="mb-0">
        <h1 className="mb-20 mt-50">
          <span>CONTACT</span>
        </h1>
        <p className="section-sub-title mb-80">{t('contact.head1')}</p>
        <h2>
          {t('contact.head2')}
          <br />
          {t('contact.head3')}
        </h2>
      </FrameCol>
    </FrameRow>
  );
};

export default ContactHeadComponent;
