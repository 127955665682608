import DetailViewLayout from 'common/view/layouts/detailView/DetailViewLayout';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useParams } from 'react-router-dom';
import { dateFormat } from 'util/FormatUtil';

const SampleNewsDetailContentsComponent = () => {
  const params = useParams();
  const newsId = params.id;
  return (
    <FrameRow>
      <FrameCol textAlign="text-center" addClassName="mb-0">
        <h1 className="mb-20 mt-50">
          <span>NEWS</span>
        </h1>
        <p className="section-sub-title mb-40">언론보도</p>
      </FrameCol>

      <DetailViewLayout
        date={dateFormat('20231003', 'yyyy.MM.dd')}
        category="NEWS"
        titleClassName="newsTitle"
        title={`뉴스타이틀${newsId}`}
        contentClassName="newsDetail"
        prevPostNo={parseInt(newsId ?? '1') - 1}
        nextPostNo={parseInt(newsId ?? '1') + 1}
        content={`
<div class="photo">
<img src="assets/img/news/news_img1.png" alt="사진" />
</div>
<div class="newsTxt">
<pre>
IT 전문기업 ‘이포넷’이 의료 AI 플랫폼 기업 ‘메디아크(대표 이찬형)’와 의료 AI 서비스 및 기술 개발을 위한 양해각서(MOU)를 체결했습니다. 지난 9일 체결된 MOU는 이포넷의 주요 기술인 소프트웨어 시스템 구축, 다국어 번역, 블록체인
등을 메디아크의 AI 진단 기술 및 증상 분석 프로그램과 접목하여 상호 전문 분야에 대한 발전을 도모하는 것을 목표로 합니다. ‘이포넷’은 금융 결제시스템 구축과 다국어 번역 서비스를 중심으로 메타버스, 블록체인 등 신기술 발굴에
주력하는 IT 전문기업으로, 지난 2019년 12월 출시한 블록체인 기반 기부 플랫폼 체리는 최근 약 3년만에 누적 후원 금액 80억 원을 기록하는 성과를 거뒀습니다.
</pre>
</div>
<div class="moreLink">
<ul>
<li>
• 딜라이트닷넷 관련기사
<span>
<a href="#">바로가기</a>
</span>
</li>
<li>
• 매일경제 관련기사
<span>
<a href="#">바로가기</a>
</span>
</li>
</ul>
</div>`}
      />
    </FrameRow>
  );
};

export default SampleNewsDetailContentsComponent;
