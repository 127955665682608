import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';
import { stylePre } from 'util/StyleUtil';

const VisionComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-40">
        <h3 className="fColor-yellow">{t('about.vision1')}</h3>
        <h2>{t('about.vision2')}</h2>
      </FrameCol>
      <FrameCol>
        <FrameRow>
          <FrameCol width={4} addClassName="col-sm-6">
            <div className="anim-perspective">
              <div className="feature-box-core v2 alternate-color exp-animation" data-animation="express_b_show" data-delay="400">
                <CustomImgTag className="feature-box-core-icon" src="assets/img/custom/vs-icon01.png" />

                <div className="feature-box-core-detail">
                  <h3 className="feature-box-core-title">
                    {/* 국문, 영문, 중문 모두 같은 텍스트라서 이렇게 i18n이 아닌 하나의 텍스트로 */}
                    Elevate with
                    <br />
                    <span className="fw-9">IT Services</span>
                    <br />
                    Leadership
                    {/* {t('about.vision3')}
                    <br />
                    {t('about.vision4')} */}
                  </h3>
                  <p className="feature-box-core-text">
                    {t('about.vision5')}
                    <br />
                    {t('about.vision6')}
                  </p>
                </div>
              </div>
            </div>
          </FrameCol>
          <FrameCol width={4} addClassName="col-sm-6">
            <div className="anim-perspective">
              <div className="feature-box-core v2 alternate-color exp-animation" data-animation="express_b_show" data-delay="800">
                <CustomImgTag className="feature-box-core-icon" src="assets/img/custom/vs-icon02.png" />

                <div className="feature-box-core-detail">
                  <h3 className="feature-box-core-title">
                    {/* 국문, 영문, 중문 모두 같은 텍스트라서 이렇게 i18n이 아닌 하나의 텍스트로 */}
                    Connect with
                    <br />
                    <span className="fw-9">Localization & Globalization</span>
                    <br />
                    Leadership
                    {/* {t('about.vision7')}
                    <br />
                    {t('about.vision8')} */}
                  </h3>
                  <p className="feature-box-core-text">
                    {t('about.vision9')}
                    <br />
                    {t('about.vision10')}
                  </p>
                </div>
              </div>
            </div>
          </FrameCol>
          <FrameCol width={4} addClassName="col-sm-6">
            <div className="anim-perspective">
              <div className="feature-box-core v2 alternate-color exp-animation" data-animation="express_b_show" data-delay="1200">
                <CustomImgTag className="feature-box-core-icon" src="assets/img/custom/vs-icon03.png" />

                <div className="feature-box-core-detail">
                  <h3 className="feature-box-core-title" style={stylePre}>
                    {/* 국문, 영문, 중문 모두 같은 텍스트라서 이렇게 i18n이 아닌 하나의 텍스트로 */}
                    Excel with
                    <br />
                    <span className="fw-9">IT Governance & Compliance</span>
                    <br />
                    Leadership
                    {/* {t('about.vision11')}
                    <br />
                    {t('about.vision12')} */}
                  </h3>
                  <p className="feature-box-core-text">
                    {t('about.vision13')}
                    <br />
                    {t('about.vision14')}
                  </p>
                </div>
              </div>
            </div>
          </FrameCol>
        </FrameRow>
      </FrameCol>
    </FrameRow>
  );
};

export default VisionComponent;
