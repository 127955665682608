import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

const SampleLayout = () => {
  // 만약 두 페이지 하나의 레이아웃을 쓴다면 layout, 각페이지명/page/ 이런 구조로?
  return (
    <>
      <Helmet>
        <title>샘플</title>
        <meta property="og:title" content="이포넷" />
        <meta property="og:description" content="샘플" />
      </Helmet>
      <Outlet />
    </>
  );
};

export default SampleLayout;
