import { sanitize } from 'dompurify';

const FooterMoreInfoModalImagePolicyLayout = () => {
  return (
    <>
      <h3 className="mb-10">영상정보처리기기 운영관리방침</h3>

      <pre
        dangerouslySetInnerHTML={{
          __html: sanitize(`㈜이포넷(이하 &apos;회사&apos;라 함)는 영상정보처리기기 운영ㆍ관리 방침을 통해 회사에서 처리하는 영상정보가 어떠한 용도와 방식으로 이용ㆍ관리되고 있는지 알려드리고 있습니다.

<i>1. 영상정보처리기기의 설치 근거 및 설치 목적
2. 영상정보처리기기 운영현황 및 처리방법
3. 영상정보처리기기 관리책임자 및 관리담당자
4. 개인영상정보의 확인방법 및 장소에 관한 사항
5. 정보주체의 영상정보 열람 등 요구에 대한 조치
6. 영상정보의 안전성 확보 조치
7. 영상정보처리기기 운영ㆍ관리 방침의 변경에 관한 사항</i>

<b>1. 영상정보처리기기의 설치 근거 및 설치 목적</b>
    회사에서는 개인정보보호법 제25조 제1항에 따라 시설안전 및 화재예방을 목적으로 영상정보처리기기를 설치ㆍ운영 하고 있습니다.

<b>2. 영상정보처리기기 운영현황 및 처리방법</b>
    회사에서 운영 중인 영상정보처리기기의 운영현황은 아래와 같습니다.
    설치 대수 : 1대
    설치 위치 및 촬영 범위 : 회사 서버실
    촬영시간 : 24시간
    보관기간 : 촬영일로부터 60일 이내
    처리방법 : 네트워크실

<b>3. 영상정보처리기기 관리책임자 및 관리담당자</b>
    영상정보처리기기 및 영상정보를 안전하게 운영ㆍ관리하기 위하여 아래와 같이 관리책임자 및 담당자를 두고 있습니다.
        - 영상정보처리기기 관리담당자 : 경영전략실 이현명 대리(02-3465-8595)

<b>4. 개인영상정보의 확인방법 및 장소에 관한 사항</b>
    가. 귀하는 개인영상정보에 관하여 열람 또는 존재확인ㆍ삭제를 원하는 경우 언제든지 영상정보처리기기 관리책임자에게 요구하실 수 있습니다. 단, 귀하가 촬영된 개인영상정보 및 명백히 정보주체의 급박한 생명, 신체, 재산의 이익을 위하여 필요한 개인영상정보에 한정됩니다.
    나. 영상정보처리기기 관리책임자 또는 관리담당자에게 미리 연락하고, 담당 부서를 방문하시면 확인 가능합니다.

<b>5. 정보주체의 영상정보 열람 등 요구에 대한 조치</b>
    가. 회사는 개인영상정보에 관하여 열람 또는 존재확인ㆍ삭제를 요구 받은 경우 지체 없이 필요한 조치를 하겠습니다.
    나. 다음의 경우에는 정보주체의 개인영상정보 열람 등 요구를 거부할 수 있습니다. 이 경우 영상정보처리기기 관리책임자는 서면 등으로 거부 사유를 정보주체에게 통지합니다.
        - 범죄수사ㆍ공소유지ㆍ재판수행에 중대한 지장을 초래하는 경우
        - 열람 등 요구에 대하여 필요한 조치를 취함으로써 타인의 사생활권이 침해될 우려가 큰 경우
        - 기타 정보주체의 열람 등 요구를 거부할 만한 정당한 사유가 존재하는 경우

<b>6. 영상정보의 안전성 확보 조치</b>
    가. 네트워크실에서 처리하는 영상정보는 안전한 물리적 보관을 위하여 비인가자의 접근이 차단되는 제한구역 잠금장치를 통하여 보관ㆍ관리되고 있습니다.
    나. 개인영상정보보호를 위한 관리적 대책으로서 개인정보에 대한 접근 권한을 차등부여하고 있습니다.
    다. 개인영상정보의 위ㆍ변조 방지를 위하여 개인영상정보의 생성 일시, 열람시 열람 목적ㆍ열람자ㆍ열람 일시 등을 기록하여 관리하고 있습니다.

<b>7. 영상정보처리기기 운영ㆍ관리 방침의 변경에 관한 사항</b>
    이 영상정보처리기기 운영ㆍ관리 방침은 법령ㆍ정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 지체없이 회사 홈페이지를 통해 변경사유 및 내용 등을 공지하도록 하겠습니다.`),
        }}
      ></pre>
    </>
  );
};

export default FooterMoreInfoModalImagePolicyLayout;
