import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const NonePage = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol>
        <div className="min-h-300 text-center pt-100 round-20">
          <p>
            <i className="glyphicon icon icon-alert-1 fa-3x"></i>
          </p>
          <p>{t('views.nonePage1')}</p>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default NonePage;
