import FooterCopyrightLayout from './FooterCopyrightLayout';
import FooterMainLayout from './FooterMainLayout';
import FooterMoreInfoLayout from './FooterMoreInfoLayout';

const FooterLayout = () => {
  return (
    <footer className="footer">
      <FooterMainLayout />
      <FooterMoreInfoLayout />
      <FooterCopyrightLayout />
    </footer>
  );
};

export default FooterLayout;
