import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CareerModalLayout from './modal/CareerModalLayout';

const CareerItTabComponent = ({ rootUrl }: { rootUrl: string }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div className={`tab-pane ${rootUrl === 'it' ? 'active' : ''}`} id="it-Service" role="tabpanel">
      <h3>{t('career.itTab1')}</h3>
      <div className="car-process">
        <ul>
          <li>{t('career.itTab2')}</li>
          <li>{t('career.itTab3')}</li>
          <li>{t('career.itTab4')}</li>
          <li>{t('career.itTab5')}</li>
          <li>
            {t('career.itTab6')}
            <br />
            {t('career.itTab7')}
          </li>
          <li>{t('career.itTab8')}</li>
        </ul>
      </div>
      <FrameRow addClassName="car-formWrap">
        <FrameCol width={4}>
          <div className="title">
            <span></span>
            {t('career.itTab9')}
          </div>
          <ul className="item">
            <li>
              <i className="fa fa-mortar-board"></i>
              {t('career.itTab10')}
            </li>
            <li>
              <i className="fa fa-user-circle-o"></i>
              {t('career.itTab11')}
            </li>
            <li>
              <i className="fa fa-desktop"></i>
              {t('career.itTab12')}
            </li>
            <li>
              <i className="fa fa-drivers-license-o"></i>
              {t('career.itTab13')}
            </li>
            <li>
              <i className="fa fa-database"></i>
              {t('career.itTab14')}
            </li>
            <li>
              <i className="fa fa-code"></i>
              {t('career.itTab15')}
            </li>
          </ul>
        </FrameCol>
        <FrameCol width={4}>
          <div className="title">
            <span></span>
            {t('career.itTab16')}
          </div>
          <ul className="item">
            <li>
              <i className="fa fa-file-text-o"></i>
              {t('career.itTab17')}
              <br />
              <small>{t('career.itTab18')}</small>
            </li>
            <li>
              <i className="fa fa-list-alt"></i>
              {t('career.itTab19')}
            </li>
            <li>
              <i className="fa fa-vcard-o"></i>
              {t('career.itTab20')}
            </li>
            <li>
              <i className="fa fa-trophy"></i>
              {t('career.itTab21')}
            </li>
          </ul>
        </FrameCol>
        <FrameCol width={4}>
          <div className="title">
            <span></span>
            {t('career.itTab22')}
          </div>
          <ul className="item2">
            <li>
              {t('career.itTab23')}
              <a href="./assets/download/e4net_resume_it.doc" download>
                <button type="button" className="btn btn-outline-primary btn-rounded pull-right mgt-10">
                  {t('career.itTab24')}
                  <i className="fa fa-download5 ml-2"></i>
                </button>
              </a>
            </li>
          </ul>
        </FrameCol>
      </FrameRow>
      <FrameCol addClassName="mt-40" textAlign="text-center">
        <button type="button" className="btn btn-md btn-primary btn-rounded fs-16 px-5" data-toggle="modal" data-target={`#it-Service-Modal`} onClick={() => setIsModalOpen(true)}>
          {t('career.itTab25')}
        </button>
      </FrameCol>
      <CareerModalLayout isOpen={isModalOpen} setIsOpen={setIsModalOpen} modalId={'it-Service-Modal'} modalTitleName={t('career.itTab26')} stat={'it'} />
    </div>
  );
};

export default CareerItTabComponent;
