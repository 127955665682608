export const onImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  e.currentTarget.src = 'assets/vendor/layerslider/skins/v5/nothumb.png';
};

export const handleElementScrollToTop = (element: HTMLElement) => {
  element.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
};
