import { selectNewsList } from 'api/board/BoardAPI';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import SlidesJSLayout from 'common/view/layouts/slider/SlidesJSLayout';
import { sanitize } from 'dompurify';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BoardRVO } from 'types/board';
import { useEffectOnce } from 'usehooks-ts';
import { isSuccess } from 'util/ApiResponseUtil';
import { dateFormat } from 'util/FormatUtil';
import { styleCursorPointer } from 'util/StyleUtil';

// news 배너 리스트
const newsBannerListData: BoardRVO[] = [
  {
    brdNm: '',
    postNo: '',
    subject: '',
    imgFile: '',
    regDt: '',
    pstgYmd: '',
    content: '',
    nextPostNo: 0,
    prevPostNo: 0,
    totalCount: 0,
    e4Count: 0,
    noticeCount: 0,
    pageNo: 1,
    home: '',
  },
];

const NewsBannerComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const customSliderProgressRef = useRef<HTMLElement>(null);
  const customSliderSlidesLogRefRef = useRef<HTMLDivElement>(null);

  const [newsBannerList, setNewsBannerList] = useState<BoardRVO[]>(newsBannerListData);
  const [bannerIndex, setBannerIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const sliderOption = {
    width: 555,
    height: 366,
    pagination: false,
    play: {
      active: true,
      auto: true,
      interval: 5000,
      swap: true,
    },
    callback: {
      complete: (pageNo: number) => {
        setBannerIndex(pageNo - 1);
      },
    },
  };

  const getNewsBannerList = async () => {
    const itemCountPerPage = 10;
    await selectNewsList({ pageNo: 1, rowsPage: itemCountPerPage, mostYn: 'Y' })
      .then((res) => {
        const resData = res.response;
        if (isSuccess(res.code)) {
          if (resData) {
            if (resData.length > 0) {
              setNewsBannerList(resData);
              setIsLoading(true);
            } else {
              setNewsBannerList(newsBannerListData);
            }
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffectOnce(() => {
    getNewsBannerList();
  });

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-0">
        <h1 className="mb-20 mt-50">
          <span>NEWS</span>
        </h1>
        <p className="section-sub-title mb-40">{t('news.listHead1')}</p>
      </FrameCol>
      <FrameCol addClassName="bg-gray py-5">
        <div className="container">
          <FrameRow>
            <FrameCol width={6}>
              {isLoading && (
                <SlidesJSLayout sliderId="Nslides" wrapperClassname="main-slides" sliderOption={sliderOption} noSlideController customProgressRef={customSliderProgressRef} customSlidesLogRef={customSliderSlidesLogRefRef}>
                  {newsBannerList &&
                    newsBannerList.map((ele, idx) => {
                      return (
                        <div
                          key={`${idx}`}
                          className="newsWrap"
                          style={{ background: `url(${ele.imgFile ? ele.imgFile : 'assets/img/news/news.jpg'})`, cursor: 'pointer' }}
                          onClick={() => {
                            navigate('/news/' + `${ele.postNo}`, { state: { data: ele } });
                          }}
                        ></div>
                      );
                    })}
                </SlidesJSLayout>
              )}
            </FrameCol>
            <FrameCol width={6}>
              <div className="disWrap">
                <div
                  className="discrption"
                  style={styleCursorPointer}
                  onClick={() => {
                    navigate('/news/' + newsBannerList[bannerIndex].postNo, { state: { data: newsBannerList[bannerIndex] } });
                  }}
                >
                  <h2>{newsBannerList[bannerIndex].subject}</h2>
                  <p className="news-detail" dangerouslySetInnerHTML={{ __html: sanitize(newsBannerList[bannerIndex].content) }}></p>
                  <p className="news-date">{dateFormat(newsBannerList[bannerIndex].pstgYmd)}</p>
                </div>
              </div>
              <div className="contWarp">
                <div className="pg-bar">
                  <span ref={customSliderProgressRef} className="progress"></span>
                </div>
                <div ref={customSliderSlidesLogRefRef} id="slidesjs-log">
                  <span className="slidesjs-slide-number"></span> / <div className="totalCnt" style={{ display: 'inline' }}></div>
                </div>
                <div className="slides-navigation" id="slides-navigation"></div>
              </div>
            </FrameCol>
          </FrameRow>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default NewsBannerComponent;
