import { ApiClient } from 'api/RestClient';
import { BoardPVO, PortfolioPVO, PortfolioRVO } from 'types/board';
import { BoardRVO } from 'types/board';
import { HistoryPVO } from 'types/history';
import { HistoryRVO } from 'types/history';
import { doFetch, doPagingFetch } from 'util/AxiosUtil';

export const selectCommunityCount = () => {
  return doFetch<
    | {
        all: number;
        notice: number;
        story: number;
      }
    | string
  >(ApiClient, '/api/board/community/count').then((res) => res.data);
};

export const selectCommunityList = async (data: BoardPVO) => {
  return doPagingFetch<BoardRVO>(ApiClient, '/api/board/community/list', data).then((res) => res.data);
};

export const selectCommunity = async (data: BoardPVO) => {
  return doFetch<BoardRVO>(ApiClient, '/api/board/community/view', data).then((res) => res.data);
};

export const selectNewsList = async (data: BoardPVO) => {
  return doPagingFetch<BoardRVO>(ApiClient, '/api/board/news/list', data).then((res) => res.data);
};

export const selectNews = async (data: BoardPVO) => {
  return doFetch<BoardRVO>(ApiClient, '/api/board/news/view', data).then((res) => res.data);
};

export const selectHistoryList = async (data: HistoryPVO) => {
  return doFetch<HistoryRVO[]>(ApiClient, '/api/board/about/history', data).then((res) => res.data);
};

export const selectITPortfolioList = async (data: PortfolioPVO) => {
  return doPagingFetch<PortfolioRVO>(ApiClient, '/api/board/it/portfolio/list', data).then((res) => res.data);
};

export const selectITPortfolioCount = (data: PortfolioPVO) => {
  return doFetch<string[]>(ApiClient, '/api/board/it/portfolio/count', data).then((res) => res.data);
};

export const selectLangPortfolioList = async (data: PortfolioPVO) => {
  return doPagingFetch<PortfolioRVO>(ApiClient, '/api/board/lang/portfolio/list', data).then((res) => res.data);
};

export const selectLangPortfolioCount = (data: PortfolioPVO) => {
  return doFetch<string[]>(ApiClient, '/api/board/lang/portfolio/count', data).then((res) => res.data);
};
