import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import SlidesJSLayout from 'common/view/layouts/slider/SlidesJSLayout';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const sliderOption = {
  width: 555,
  height: 366,
  pagination: false,
  play: {
    active: true,
    auto: true,
    interval: 5000,
    swap: true,
  },
};

const CommunityBannerComponent = () => {
  const { t } = useTranslation();

  const customSliderProgressRef = useRef<HTMLElement>(null);
  const customSliderSlidesLogRefRef = useRef<HTMLDivElement>(null);

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-0">
        <h1 className="mb-20 mt-50">
          <span>COMMUNITY</span>
        </h1>
        <p className="section-sub-title mb-40">
          {t('community.listHead1')}
          <br />
          {t('community.listHead2')}
        </p>
      </FrameCol>
      <FrameCol addClassName="bg-gray py-5">
        <div className="container">
          <FrameRow>
            <FrameCol width={6}>
              <div className="disWrap">
                <div className="discrption">
                  <h2>{t('community.banner11')}</h2>
                  <p className="news-detail">
                    {t('community.banner12')}
                    <br />
                    <br />
                    {t('community.banner13')}
                  </p>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={6} addClassName="mb-70">
              <SlidesJSLayout sliderId="Nslides" wrapperClassname="main-slides" sliderOption={sliderOption} noSlideController customProgressRef={customSliderProgressRef} customSlidesLogRef={customSliderSlidesLogRefRef}>
                <div className="newsWrap" style={{ background: 'url(assets/img/community/share_img01.png)' }}>
                  <div className="infoTxt">
                    <h3>{t('community.banner1')}</h3>
                    <p>{t('community.banner2')}</p>
                  </div>
                </div>
                <div className="newsWrap" style={{ background: 'url(assets/img/community/share_img02.png)' }}>
                  <div className="infoTxt">
                    <h3>{t('community.banner3')}</h3>
                    <p>{t('community.banner4')}</p>
                  </div>
                </div>
                <div className="newsWrap" style={{ background: 'url(assets/img/community/share_img03.png)' }}>
                  <div className="infoTxt">
                    <h3>{t('community.banner5')}</h3>
                    <p>{t('community.banner6')}</p>
                  </div>
                </div>
                <div className="newsWrap" style={{ background: 'url(assets/img/community/share_img04.png)' }}>
                  <div className="infoTxt">
                    <h3>{t('community.banner7')}</h3>
                    <p>{t('community.banner8')}</p>
                  </div>
                </div>
                <div className="newsWrap" style={{ background: 'url(assets/img/community/share_img05.png)' }}>
                  <div className="infoTxt">
                    <h3>{t('community.banner9')}</h3>
                    <p>{t('community.banner10')}</p>
                  </div>
                </div>
              </SlidesJSLayout>
              <div className="contWarp mgb-70" style={{ top: 'auto' }}>
                <div className="pg-bar">
                  <span ref={customSliderProgressRef} className="progress"></span>
                </div>
                <div ref={customSliderSlidesLogRefRef} id="slidesjs-log">
                  <span className="slidesjs-slide-number"></span> / <div className="totalCnt" style={{ display: 'inline' }}></div>
                </div>
                <div className="slides-navigation" id="slides-navigation"></div>
              </div>
            </FrameCol>
          </FrameRow>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default CommunityBannerComponent;
