import './i18n';

import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import DefaultBrowserRouter from './router/DefaultBrowserRouter';

if (process.env.REACT_APP_ACTIVE === 'STG' || process.env.REACT_APP_ACTIVE === 'PROD') {
  console.log = function no_console() {};
  console.warn = function no_console() {};
}

const App = () => {
  return (
    <HelmetProvider>
      <RouterProvider router={DefaultBrowserRouter} fallbackElement={<div>...Loading</div>} />
    </HelmetProvider>
  );
};

export default App;
