import { sanitize } from 'dompurify';

const FooterMoreInfoModalPersonalProtectionLayout = () => {
  return (
    <>
      <h3 className="mb-10">개인정보보호 내부관리계획</h3>
      <pre
        dangerouslySetInnerHTML={{
          __html: sanitize(`<i>1. 총칙
2. 내부관리계획의 수립 및 시행
3. 개인정보 보호책임자의 의무와 책임
4. 개인정보의 기술적ㆍ관리적 보호조치
5. 개인정보보호 교육
6. 개인정보 침해대응 및 피해구제</i>

<b>1. 총칙</b>
  <b>제1조(목적)</b>
      개인정보보호 내부관리계획은 정보통신망 이용촉진 및 정보보호 등에 관한 법률 28조(개인정보의 보호조치) 및 개인정보보호법 제29조(안전조치의무)의 내부관리계획 수립 및 시행 의무에 따라 제정된 것으로 주식회사 이포넷(이하 &apos;회사&apos;라 한다)가 취급하는 개인정보를 체계적으로 관리하여 개인정보가 분실, 도난, 누출, 변조, 훼손, 오ㆍ남용 등이 되지 아니하도록 함을 목적으로 한다.

  <b>제2조(적용범위)</b>
      본 계획은 회사의 홈페이지 등의 온라인을 통하여 수집, 이용, 제공 또는 관리되는 개인정보뿐만 아니라 오프라인(서면, 전화, 팩스 등)을 통해 수집, 이용, 제공 또는 관리되는 개인정보에 대해서도 적용되며, 이러한 개인정보를 취급하는 내부 임직원 및 외부업체 직원에 대해 적용된다.

  <b>제3조(용어 정의)</b>
      1. 개인정보 - 살아 있는 개인에 관한 정보로서 성명, 주민등록번호 및 영상 등을 통하여 개인을 알아볼 수 있는 정보(해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을 포함한다)를 말한다.
      2. 처리 - 개인정보의 수집, 생성, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기, 그 밖에 이와 유사한 행위를 말한다.
      3. 정보주체 - 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을 말한다.
      4. 개인정보처리자 - 업무를 목적으로 개인정보데이터의 기술적인 운용을 위하여 위하여 스스로 또는 다른 사람을 통하여 개인정보를 처리하는 기관, 법인, 단체 및 개인 등을 말한다.
      5. 개인정보 보호책임자 - 개인정보처리자의 개인정보 처리에 관한 업무를 총괄해서 책임지거나 업무처리를 최종적으로 결정하는 자로서, 개인정보보호법 제31조에 따른 지위에 해당하는 자를 말한다.
      6. 개인정보 관리자 및 개인정보 담당자 - 개인정보 보호책임자가 업무를 수행함에 있어 보조적인 역할을 하는 자를 말하며 개인정보보호 책임자가 일정 요건의 자격을 갖춘 이를 지정한다.
      7. 개인정보취급자 - 개인정보를 처리하는 업무를 담당하는 자로서 직접 개인정보에 관한 업무를 담당하는 자와 그 밖에 업무상 필요에 의해 개인정보에 접근하여 처리하는 모든 자를 말한다.
      8. 개인정보처리시스템 - 개인정보를 처리할 수 있도록 체계적으로 구성한 데이터베이스시스템을 말한다.
      9. 영상정보처리기기 - 일정한 공간에 지속적으로 설치되어 사람 또는 사물의 영상 등을 촬영하거나 이를 유ㆍ무선망을 통하여 전송하는 일체의 장치로써 폐쇄회로텔레비전(CCTV) 및 네트워크카메라를 말한다.
      10. &quot;개인영상정보&quot;라 함은 영상정보처리기기에 의하여 촬영 처리되는 영상정보 중 개인의 초상, 행동 등 사생활과 관련된 영상으로서 해당 개인의 동일성 여부를 식별할 수 있는 정보를 말한다.
      11. &quot;영상정보처리기기 운영자&quot;라 함은 개인정보 보호법 제25조 제1항 각호에 따라 영상정보처리기기를 설치ㆍ운영하는 자를 말한다.

<b>2. 내부관리계획의 수립 및 시행</b>

  <b>제4조(내부관리계획의 수립 및 승인)</b>
      1. 개인정보 보호책임자는 회사의 개인정보보호를 위한 전반적인 사항을 포함하여 내부관리계획을 수립한다.
      2. 개인정보 보호책임자는 개인정보보호를 위한 내부관리계획의 수립 시 개인정보보호와 관련한 법령 및 관련 규정을 준수하도록 내부관리계획을 수립한다.
      3. 개인정보 보호책임자는 개인정보 보호담당자가 수립한 내부관리계획의 타당성을 검토하여 개인정보보호를 위한 내부관리계획을 승인한다.
      4. 개인정보 담당자는 개인정보보호 관련 법령의 제ㆍ개정 사항 등을 반영하기 위하여 매년 5월 말 까지 내부관리계획의 타당성과 개정 필요성을 검토한다.
      5. 개인정보 담당자는 모든 항목의 타당성을 검토한 후 개정할 필요가 있다고 판단되는 경우 6월 말 까지 내부관리계획의 개정안을 작성하여 개인정보 보호책임자에게 보고하고 개인정보 보호책임자의 승인을 받아야 한다.

  <b>제5조(내부관리계획의 공표)</b>
      1. 개인정보 보호책임자는 전조에 따라 승인한 내부관리계획을 매년 7월말까지 회사의 전 임직원에게 공표한다.
      2. 내부관리계획은 임직원이 언제든지 열람할 수 있는 방법으로 비치하고, 변경사항이 있는 경우에는 이를 공지한다.

<b>3. 개인정보 보호책임자의 의무와 책임</b>

  <b>제6조(개인정보 보호책임자의 지정)</b>
      1. 회사는 개인정보보호법 시행령 제31조제2항1호에 따라 해당하는 지위에 있는 자를 개인정보 보호책임자로 임명한다.
          [개인정보 관리 책임자]
          - 이름: 이득경
          - 소속 및 직책: CTO 부사장
          - e-Mail: dklee@e4net.net

  <b>제7조(개인정보 보호책임자의 의무와 책임)</b>
      1. 개인정보 보호책임자는 정보주체의 개인정보 보호를 위하여 다음 각 호의 업무를 수행한다.
          (1) 개인정보 보호 관련 규칙 제/개정
          (2) 개인정보 보호 계획 수립 및 시행
          (3) 개인정보 처리 실태 점검 및 개선 권고
          (4) 개인정보 처리와 관련한 불만 해소 및 피해 구제
          (5) 개인정보 유출 및 오/남용 방지를 위한 내부통제시스템 구축
          (6) 개인정보 보호 교육 계획 수립 및 시행
          (7) 개인정보 파일 및 대장 등록, 파기에 대한 승인, 관리 감독
          (8) 개인정보 처리방침 수립 및 시행
          (9) 개인정보 보호 관련 자료 관리
          (10) 개인정보의 안전한 처리를 위한 내부관리계획 수립ㆍ시행
      2. 개인정보 보호책임자는 업무를 수행함에 있어서 필요한 경우 개인정보 처리 현황, 처리 체계 등에 대하여 수시로 조사하거나 관계 당사자로부터 보고를 받을 수 있다. 
      3. 개인정보 보호책임자는 개인정보 보호와 관련하여 이법 및 다른 관계 법령의 위반 사실을 알게 된 경우에는 즉시 개선조치하고, 필요하면 소속 기관 또는 단체의 장에게 개선조치를 보고한다.

  <b>제8조(개인정보취급자의 범위 및 의무와 책임)</b>
      1. 개인정보 취급자는 업무상 개인정보를 취급하는 자로서 직접 개인정보에 관한 업무를 담당하는 자와 그 밖에 업무상 필요에 의해 개인정보에 접근하여 처리하는 모든 인원을 포함한다.
      2. 개인정보취급자의 의무와 책임
          (1) 업무상 개인정보를 취급하는 자로 처리하는 개인정보가 훼손 및 누설되지 않도록 안전하게 취급한다.
          (2) 개인정보 취급자는 내부관리계획 및 개인정보의 기술적ㆍ관리적 보호조치 기준을 이행한다.
              ① 개인정보 처리업무를 수행함에 있어서 처리되는 개인정보(개인정보의 수집ㆍ보유ㆍ이용 및 제공ㆍ파기단계)에 대한 보호관리
              ② 웹사이트 및 문서에 게재된 개인정보에 대한 안전한 관리
              ③ 개인정보의 열람, 정정, 삭제 시 보호관리
          (3) 직무상 알게 된 개인정보를 누설 또는 권한 없이 처리하거나 타인의 이용에 제공하는 등 부당한 목적을 위하여 사용되지 않아야 한다.
          (4) 개인정보 취급자가 개인정보를 무단 조회하거나 오/남용 하는 경우 관계 법령에 따라 처벌될 수 있다.
          (5) 개인정보에 대해 업무 목적 외 불필요한 접근을 금지하고 개인정보 접근 권한을 임의로 양도 및 대여할 수 없다.

<b>4. 개인정보의 기술적ㆍ관리적 보호조치</b>

  <b>제9조(개인정보취급자 접근권한 관리 및 인증)</b>
      1. 개인정보처리자는 개인정보처리시스템에 대한 접근권한을 업무수행에 필요한 최소한의 범위로 업무 담당자에 따라 차등 부여한다.
      2. 개인정보처리자는 전보 또는 퇴직 등 인사이동이 발생하여 개인정보취급자가 변경되었을 경우 지체 없이 개인정보처리시스템의 접근권한을 변경 또는 말소하고, 비밀유지의무 등에 대한 서약서를 받는다.
      3. 개인정보처리자는 제1항, 제2항에 의한 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 3년간 보관한다.
      4. 개인정보처리자는 개인정보처리시스템에 접속할 수 있는 사용자 계정을 발급하는 경우, 개인정보취급자 별로 한 개의 사용자 계정을 발급하며, 다른 개인정보취급자와 공유되지 않도록 한다.
      5. 개인정보처리자는 개인정보취급자가 정보통신망을 통해 외부에서 개인정보처리시스템에 접속하려는 경우 가상사설망(VPN) 또는 SSL 등의 암호화를 통하여 안전한 접속수단을 적용한다.

  <b>제10조(비밀번호 관리)</b>
      1. 개인정보처리자는 자신의 계정과 비밀번호를 어떠한 경우라도 노출되지 않도록 관리해야 하며, 개인정보취급자 또는 정보주체가 추측하기 쉬운 숫자나 개인관련 정보를 패스워드로 이용하지 않도록 비밀번호 작성 규칙을 수립하고, 이를 적용 및 운용한다.
      2. 개인정보처리자는 비밀번호에 적정한 기간의 유효기간을 설정한다.

  <b>제11조(접근통제)</b>
      1. 개인정보처리자는 정보통신망을 통한 불법적인 접근 및 침해사고 방지를 위해 다음 각 호의 기능을 포함한 시스템을 설치ㆍ운영한다.
          (1) 외부와의 통신은 사전에 허가된 통신망을 사용함을 원칙으로 하며, 개인정보처리시스템에 대한 접속 권한을 IP(Internet Protocol)주소 등으로 제한하여 인가받지 않은 접근을 제한한다.
          (2) 무선랜의 접근통제는 암호화 방식은 WPA2, 인증프로토콜은 PEAP을 사용하거나, 이에 상응하는 보안 수준의 방식을 구현한다.
          (3) 개인정보보호 책임자는 보안시스템 운영의 권한과 책임을 가진다. 보안시스템의 정상 서비스 수행 여부를 모니터링한다. 사고의 징후/발생을 인지하거나 시스템이나 네트워크의 보안 취약점 및 장애에 대하여 신속히 보고, 대응조치한다.
      2. 개인정보처리자는 취급중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 열람권한이 없는 자에게 공개되거나 외부에 유출되지 않도록 개인정보처리시스템 및 업무용 컴퓨터에 보안조치를 취한다.

  <b>제12조(개인정보의 암호화)</b>
      1. 개인정보처리자는 비밀번호, 바이오정보에 대해서는 안전한 암호 알고리즘으로 암호화하여 저장한다. 단, 비밀번호를 저장하는 경우에는 복호화되지 않도록 일방향 암호화하여 저장한다.
      2. 개인정보처리자는 정보주체의 개인정보를 정보통신망을 통하여 송ㆍ수신하거나 보조저장매체 등을 통하여 전달하는 경우에는 이를 암호화한다.
      3. 개인정보처리자는 인터넷 구간 및 인터넷 구간과 내부망의 중간 지점(DMZ)에 고유식별정보를 저장하는 경우에는 이를 암호화한다.
      4. 개인정보처리자 또는 개인정보취급자는 정보주체의 개인정보를 업무용 컴퓨터(PC)에 저장할 때에는 상용 암호화 소프트웨어 또는 안전한 암호화 알고리즘을 사용하여 암호화 저장한다.

  <b>제13조(접속기록의 위ㆍ변조 방지)</b>
      1. 개인정보처리자는 개인정보취급자가 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관한다.
      2. 개인정보처리자는 개인정보취급자의 접속기록이 위ㆍ변조 및 도난, 분실되지 않도록 해당 접속기록을 안전하게 보관한다.

  <b>제14조(보안프로그램 설치 및 운영)</b>
      1. 개인정보처리자는 개인정보처리시스템 또는 업무용 컴퓨터에 악성 프로그램 등을 방지ㆍ치료할 수 있는 백신 소프트웨어 등의 보안 프로그램을 설치ㆍ운영한다.
      2. 보안 프로그램의 자동 업데이트 기능을 사용하거나, 또는 일 1회 이상 업데이트를 적용한다.
      3. 악성 프로그램관련 경보가 발령된 경우 또는 사용 중인 응용프로그램이나 운영체제 소프트웨어의 제작업체에서 보안 업데이트 공지가 있는 경우, 즉시 이에 따른 업데이트를 적용한다.

  <b>제15조(물리적 접근제한)</b>
      1. 개인정보처리자는 서버실, 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 있는 경우에는 이에 대한 출입통제 절차를 수립ㆍ운영한다.
      2. 개인정보처리자는 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관한다.
      3. 개인정보처리자는 물리적 접근방지를 위한 별도의 보호시설에 출입하거나 개인정보를 열람하는 경우, 그 출입자에 대한 출입사실 및 열람 내용에 관한 관리대장을 작성하도록 한다.

<b>5. 개인정보보호 교육</b>

  <b>제16조(개인정보보호 교육 계획의 수립)</b>
      1. 개인정보 보호책임자는 다음 각 호의 사항을 포함하는 연간 개인정보보호 교육계획을 매년 6월말까지 수립한다.
          (1) 교육목적 및 대상
          (2) 교육내용
          (3) 교육 일정 및 방법
      2. 개인정보 보호책임자는 수립한 개인정보보호 교육 계획을 실시한 이후에 교육의 성과와 개선 필요성을 검토하여 차년도 교육계획 수립에 반영한다.

  <b>제17조(개인정보보호 교육의 실시)</b>
      1. 개인정보 보호책임자는 정보주체정보보호에 대한 직원들의 인식제고를 위해 노력해야 하며, 개인정보의 오/남용 또는 유출 등을 적극 예방하기 위해 임ㆍ직원을 대상으로 매년 정기적으로 연1회 이상의 개인정보보호 교육을 실시한다.
      2. 교육 방법은 집체 교육뿐만 아니라, 인터넷 교육, 그룹웨어 교육 등 다양한 방법을 활용하여 실시하고, 필요한 경우 외부 전문기관이나 전문요원에 위탁하여 교육을 실시한다.
      3. 개인정보보호에 대한 중요한 전파 사례가 있거나 개인정보보호 업무와 관련하여 변경된 사항이 있는 경우, 개인정 보보호책임자는 부서 회의 등을 통해 수시 교육을 실시할 수 있다.

<b>6. 개인정보 침해대응 및 피해구제</b>

  <b>제18조(권익침해 구제방법)</b>
      개인정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청한다. 이 밖에 기타 개인정보침해의 신고 및 상담에 대하여는 아래의 기관에 문의한다.
          (1) 개인정보침해신고센터 (https://privacy.kisa.or.kr / 국번없이 118)
          (2) 대검찰청 사이버수사과 (https://www.spo.go.kr / 국번없이 1301)
          (3) 경찰청 사이버안전국 (https://www.ctrc.go.kr / 국번없이 182)`),
        }}
      />
    </>
  );
};

export default FooterMoreInfoModalPersonalProtectionLayout;
