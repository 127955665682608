import { findStatsIt } from 'api/it/main/StatsItAPI';
import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsItRVO } from 'types/it/main/StatsItRVO';

const defaultStatsItInfo = {
  totalPj: '',
  siPj: '',
  smPj: '',
  servicePj: '',
};

const CounterComponent = () => {
  const { t } = useTranslation();

  const [pj, setPj] = useState<StatsItRVO>(defaultStatsItInfo);

  const fetchCounter = async () => {
    await findStatsIt()
      .then((res) => {
        if (!res) return;
        setPj(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCounter();
  }, []);

  return (
    <FrameRow>
      <FrameCol>
        <div className="row counterWrap">
          <div className="col-md-6 counters total-counter">
            <div className="counter">
              <h2>{t('itMain.counter1')}</h2>
              <strong className="counter-number" data-to={pj.totalPj}>
                {pj.totalPj}
              </strong>
            </div>
            <div className="base">
              <p>{t('itMain.counter6')}</p>
            </div>
          </div>
          <div className="col-md-6 counters it-counter">
            <div className="row">
              <div className="col-md-10">
                <div className="counter">
                  <span className="counter-icon">
                    <CustomImgTag src="assets/img/custom/it_counter01.png" alt={t('itMain.counter2')} />
                  </span>
                  <span className="text">{t('itMain.counter2')}</span>
                  <strong className="counter-number" data-to={pj.siPj}>
                    {pj.siPj}
                  </strong>
                </div>
              </div>

              <div className="col-md-5">
                <div className="counter">
                  <span className="counter-icon">
                    <CustomImgTag src="assets/img/custom/it_counter02.png" alt={t('itMain.counter3')} />
                  </span>
                  <span className="text">{t('itMain.counter3')}</span>
                  <strong className="counter-number" data-to={pj.smPj}>
                    {pj.smPj}
                  </strong>
                </div>
              </div>

              <div className="col-md-5">
                <div className="counter">
                  <span className="counter-icon">
                    <CustomImgTag src="assets/img/custom/it_counter03.png" alt={t('itMain.counter4')} />
                  </span>
                  <span className="text">{t('itMain.counter4')}</span>
                  <strong className="counter-number" data-to={pj.servicePj}>
                    {pj.servicePj}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default CounterComponent;
