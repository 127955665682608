import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';

const SampleDepartmentsComponent = () => {
  return (
    <>
      <FrameRow>
        <FrameCol addClassName="mob-text-center">
          <h1 className="mb-30">
            <span>
              OUR
              <br />
              BUSINESS
            </span>
          </h1>
          <p className="section-sub-title mb-30">이포넷의 성공에 탄탄한 사업본부가 있어요</p>
        </FrameCol>
      </FrameRow>
      <ul className="post-wrap row">
        <ExpAnimationLayout>
          <li className="col-md-6">
            <article className="exp-animation" data-animation="bottom-to-top" data-delay="400">
              <div className="aboutusWrap it">
                <CustomATag href="#">
                  <h2 itemProp="name">IT서비스</h2>
                  <div itemProp="description">
                    <p>
                      #IT서비스 #컨설팅 #솔루션 #블록체인 #NFT <br />
                      #지불결제 #카드 #멤버십·포인트
                    </p>
                  </div>
                  <div className="imgWrap">
                    <CustomImgTag alt="" src="assets/img/custom/img_it.png" />
                  </div>
                  <span>
                    MORE <i className="fa fa-long-arrow-right"></i>
                  </span>
                </CustomATag>
              </div>
            </article>
          </li>

          <li className="col-md-6">
            <article className="exp-animation" data-animation="bottom-to-top" data-delay="800">
              <div className="aboutusWrap lang">
                <CustomATag href="#">
                  <h2 itemProp="name">언어서비스</h2>
                  <div itemProp="description">
                    <p>
                      #전문 번역 #현지화 #다국어 번역
                      <br />
                      #AI 데이터 생성 서비스
                    </p>
                  </div>
                  <div className="imgWrap">
                    <CustomImgTag alt="" src="assets/img/custom/img_lang.png" />
                  </div>
                  <span>
                    MORE <i className="fa fa-long-arrow-right"></i>
                  </span>
                </CustomATag>
              </div>
            </article>
          </li>
        </ExpAnimationLayout>
      </ul>
    </>
  );
};

export default SampleDepartmentsComponent;
