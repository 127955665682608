import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import FramePageInner from 'common/view/layouts/frames/FramePageInner';
import SectionLayout from 'common/view/layouts/section/SectionLayout';
import { useTranslation } from 'react-i18next';
import { stylePre } from 'util/StyleUtil';

const ServiceTabDetailComponent = () => {
  const { t, i18n } = useTranslation();

  return (
    <FramePageInner>
      <div id="service1">
        <SectionLayout>
          <FrameRow>
            <FrameCol>
              <p className="service-sub-title">
                <span>{t('itService.detailSystem1')}</span>
              </p>
              <h2 className="mb-30">
                <span className="fColor-blue">{t('itService.detailSystem2')}</span>
                <br />
                {t('itService.detailSystem3')}
              </h2>
              <p className="section-sub-title text-center mb-30">
                {t('itService.detailSystem4')}
                <br />
                {t('itService.detailSystem5')}
              </p>
            </FrameCol>
          </FrameRow>
          <FrameRow addClassName="mt-50">
            <FrameCol width={4} addClassName="col-sm-6">
              <ExpAnimationLayout>
                <div className="anim-perspective">
                  <div className="service-box exp-animation" data-animation="express_b_show" data-delay="200">
                    <p className="title">{t('itService.detailSystem6')}</p>
                    <ul>
                      <li>{t('itService.detailSystem7')}</li>
                      <li>{t('itService.detailSystem8')}</li>
                      <li>{t('itService.detailSystem9')}</li>
                    </ul>
                  </div>
                </div>
              </ExpAnimationLayout>
            </FrameCol>
            <FrameCol width={4} addClassName="col-sm-6">
              <div className="anim-perspective">
                <div className="service-box exp-animation" data-animation="express_b_show" data-delay="400">
                  <p className="title">{t('itService.detailSystem10')}</p>
                  <ul>
                    <li>{t('itService.detailSystem11')}</li>
                    <li>{t('itService.detailSystem12')}</li>
                    <li>{t('itService.detailSystem13')}</li>
                  </ul>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={4} addClassName="col-sm-6">
              <div className="anim-perspective">
                <div className="service-box exp-animation" data-animation="express_b_show" data-delay="600">
                  <p className="title">{t('itService.detailSystem14')}</p>
                  <ul>
                    <li>{t('itService.detailSystem15')}</li>
                    <li>{t('itService.detailSystem16')}</li>
                  </ul>
                </div>
              </div>
            </FrameCol>
          </FrameRow>
        </SectionLayout>
        <SectionLayout addSectionClassName="bg-lite-blue">
          <FrameRow>
            <FrameCol width={6}>
              <FrameRow>
                <FrameCol addClassName="pb-20">
                  <div className="service-box2">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/svc_ico_01.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p className="title">{t('itService.detailSystem17')}</p>
                    <p>{t('itService.detailSystem18')}</p>
                  </div>
                </FrameCol>
                <FrameCol addClassName="pb-20">
                  <div className="service-box2">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/svc_ico_02.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p className="title">{t('itService.detailSystem19')}</p>
                    <p>{t('itService.detailSystem20')}</p>
                  </div>
                </FrameCol>
              </FrameRow>
            </FrameCol>
            <FrameCol width={6} addClassName="mobile-hide">
              <FrameRow addClassName="full-height pb-20">
                <FrameCol addClassName="full-height">
                  <div className="img-box" style={{ background: 'url(assets/img/custom/it_service_img02.jpg)' }}></div>
                </FrameCol>
              </FrameRow>
            </FrameCol>
            <FrameCol width={6} addClassName="pb-20">
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/svc_ico_03.png" alt={t('itService.detailAlt1')} />
                </div>
                <p className="title">{t('itService.detailSystem21')}</p>
                <p>
                  {t('itService.detailSystem22')}
                  <br />
                  {t('itService.detailSystem23')}
                </p>
              </div>
            </FrameCol>
            <FrameCol width={6} addClassName="pb-20">
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/svc_ico_04.png" alt={t('itService.detailAlt1')} />
                </div>
                <p className="title">{t('itService.detailSystem24')}</p>
                <p>
                  {t('itService.detailSystem25')}
                  <br />
                  {t('itService.detailSystem26')}
                </p>
              </div>
            </FrameCol>
            <FrameCol addClassName="pb-20">
              <div className="service-box2">
                <div className="icon2">
                  <CustomImgTag src="assets/img/icons/svc_ico_05.png" alt={t('itService.detailAlt1')} />
                </div>
                <p className="title">{t('itService.detailSystem27')}</p>
                <p>
                  {t('itService.detailSystem28')}
                  <br />
                  {t('itService.detailSystem29')}
                </p>
              </div>
            </FrameCol>
            <FrameCol addClassName="pb-20">
              <div className="service-box3 mt-20">
                {i18n.language == 'ko' ? (
                  <CustomImgTag src="assets/img/custom/it_service_img03.png" alt={t('itService.detailAlt2')} />
                ) : i18n.language == 'ch' ? (
                  <CustomImgTag src="assets/img/custom/it_service_img03_cn.png" alt={t('itService.detailAlt2')} />
                ) : (
                  <CustomImgTag src="assets/img/custom/it_service_img03_en.png" alt={t('itService.detailAlt2')} />
                )}
              </div>
            </FrameCol>
            <FrameCol addClassName="text-center mt-40">
              <CustomATag href="/it/portfolio" state={['PC02', 'PC03']} className="btn btn-primary fs-14 btn-rounded px-5">
                {/* TO-DO 금융, 공공 카테고리 값 넘기기 */}
                {t('itService.detailSystem30')}
              </CustomATag>
            </FrameCol>
          </FrameRow>
        </SectionLayout>
      </div>
      <div id="service2">
        <section className="section-primary pt-140 pb-0" id="tab01">
          <FrameContainer addClassName="mb-80">
            <FrameRow>
              <FrameCol addClassName="text-center">
                <div className="tab-wrap2">
                  <ul className="nav nav-tabs justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link active" href="#tab01" data-hash data-hash-offset="70">
                        <span>{t('itService.detailPay1')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#tab02" data-hash data-hash-offset="70">
                        <span>{t('itService.detailPay2')}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </FrameCol>
            </FrameRow>
          </FrameContainer>
          <FrameCol addClassName="mt-50">
            <h2 className="mb-30">
              <span className="fColor-blue">{t('itService.detailPay3')}</span>
              <br />
              {t('itService.detailPay4')}
              <br />
              {t('itService.detailPay5')}
            </h2>
            <p className="section-sub-title text-center mb-30">
              {t('itService.detailPay6')}
              <br />
              {t('itService.detailPay7')}
              <br />
              {t('itService.detailPay8')}
            </p>
          </FrameCol>
          <FrameContainer>
            <FrameRow addClassName="mt-50 pb-80">
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="200">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/svc_ico_06.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailPay9')}
                      <span>{t('itService.detailPay10')}</span>
                    </p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="400">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/svc_ico_07.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailPay11')}
                      <span>{t('itService.detailPay12')}</span>
                    </p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="600">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/svc_ico_08.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailPay13')}
                      <span>{t('itService.detailPay14')}</span>
                    </p>
                  </div>
                </div>
              </FrameCol>
            </FrameRow>
          </FrameContainer>
          <SectionLayout addSectionClassName="bg-lite-blue" addContianerClassName="fluid">
            <div className="container px-0">
              <FrameRow>
                <FrameCol addClassName="pb-20">
                  <div className="mt-20">
                    {i18n.language == 'ko' ? (
                      <CustomImgTag src="assets/img/custom/it_service_img04.png" alt={t('itService.detailAlt2')} />
                    ) : i18n.language == 'ch' ? (
                      <CustomImgTag src="assets/img/custom/it_service_img04_cn.png" alt={t('itService.detailAlt2')} />
                    ) : (
                      <CustomImgTag src="assets/img/custom/it_service_img04_en.png" alt={t('itService.detailAlt2')} />
                    )}
                  </div>
                </FrameCol>
                <FrameCol addClassName="pb-20">
                  <div className="mt-20">
                    {i18n.language == 'ko' ? (
                      <CustomImgTag src="assets/img/custom/it_service_img05.png" alt={t('itService.detailAlt2')} />
                    ) : i18n.language == 'ch' ? (
                      <CustomImgTag src="assets/img/custom/it_service_img05_cn.png" alt={t('itService.detailAlt2')} />
                    ) : (
                      <CustomImgTag src="assets/img/custom/it_service_img05_en.png" alt={t('itService.detailAlt2')} />
                    )}
                  </div>
                </FrameCol>
                <FrameCol addClassName="text-center mt-40">
                  <p className="fColor-blue fw-7 mb-10">{t('itService.detailPay15')}</p>
                  <a href="./assets/download/ppms.pdf" className="btn btn-outline-primary btn-rounded mb-20" download>
                    {t('itService.detailPay16')}
                    <i className="ml-5 fa fa-download5"></i>
                  </a>
                  <br />
                  <CustomATag href="/it/portfolio/" state={['PC05']} className="btn btn-primary fs-14 btn-rounded px-5">
                    {t('itService.detailPay17')}
                  </CustomATag>
                </FrameCol>
              </FrameRow>
            </div>
          </SectionLayout>
        </section>
        <section className="section-primary pt-140 pb-0" id="tab02">
          <FrameContainer addClassName="mb-80">
            <FrameRow>
              <FrameCol addClassName="text-center">
                <div className="tab-wrap2">
                  <ul className="nav nav-tabs justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link" href="#tab01" data-hash data-hash-offset="70">
                        <span>{t('itService.detailMembership1')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" href="#tab02" data-hash data-hash-offset="70">
                        <span>{t('itService.detailMembership2')}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </FrameCol>
            </FrameRow>
          </FrameContainer>
          <FrameCol addClassName="mt-50">
            <h2 className="mb-30">
              <span className="fColor-blue">{t('itService.detailMembership3')}</span>
              <br />
              {t('itService.detailMembership4')}
              <br />
              {t('itService.detailMembership5')}
            </h2>
            <p className="section-sub-title text-center mb-30">
              {t('itService.detailMembership6')}
              <br />
              {t('itService.detailMembership7')}
            </p>
          </FrameCol>
          <FrameContainer>
            <FrameRow addClassName="mt-50 pb-80">
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="200">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/svc_ico_09.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailMembership8')}
                      <span>{t('itService.detailMembership9')}</span>
                    </p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="400">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/svc_ico_10.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailMembership10')}
                      <span>{t('itService.detailMembership11')}</span>
                    </p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="600">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/svc_ico_11.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailMembership12')}
                      <span>{t('itService.detailMembership13')}</span>
                    </p>
                  </div>
                </div>
              </FrameCol>
            </FrameRow>
          </FrameContainer>
          <SectionLayout addSectionClassName="bg-lite-blue" addContianerClassName="fluid">
            <div className="container px-0">
              <FrameRow>
                <FrameCol addClassName="pb-20">
                  <div className="mt-20">
                    {i18n.language == 'ko' ? (
                      <CustomImgTag src="assets/img/custom/it_service_img06.png" alt={t('itService.detailAlt2')} />
                    ) : i18n.language == 'ch' ? (
                      <CustomImgTag src="assets/img/custom/it_service_img06_cn.png" alt={t('itService.detailAlt2')} />
                    ) : (
                      <CustomImgTag src="assets/img/custom/it_service_img06_en.png" alt={t('itService.detailAlt2')} />
                    )}
                  </div>
                </FrameCol>
                <FrameCol addClassName="pb-20">
                  <div className="mt-20">
                    {i18n.language == 'ko' ? (
                      <CustomImgTag src="assets/img/custom/it_service_img07.png" alt={t('itService.detailAlt2')} />
                    ) : i18n.language == 'ch' ? (
                      <CustomImgTag src="assets/img/custom/it_service_img07_cn.png" alt={t('itService.detailAlt2')} />
                    ) : (
                      <CustomImgTag src="assets/img/custom/it_service_img07_en.png" alt={t('itService.detailAlt2')} />
                    )}
                  </div>
                </FrameCol>
                <FrameCol addClassName="text-center mt-40">
                  <p className="fColor-blue fw-7 mb-10">{t('itService.detailMembership14')}</p>
                  <a href="./assets/download/lmps.pdf" className="btn btn-outline-primary btn-rounded mb-20" download>
                    {t('itService.detailMembership15')}
                    <i className="ml-5 fa fa-download5"></i>
                  </a>
                  <br />
                  <CustomATag href="/it/portfolio" state={['PC06']} className="btn btn-primary fs-14 btn-rounded px-5">
                    {t('itService.detailMembership16')}
                  </CustomATag>
                </FrameCol>
              </FrameRow>
            </div>
          </SectionLayout>
        </section>
      </div>
      <div id="service3">
        <section className="section-primary pt-140 pb-80" id="tab03">
          <FrameContainer addClassName="mb-80">
            <FrameRow>
              <FrameCol addClassName="text-center">
                <div className="tab-wrap2">
                  <ul className="nav nav-tabs justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link active" href="#tab03" data-hash data-hash-offset="70">
                        <span>{t('itService.detailLinkages1')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#tab04" data-hash data-hash-offset="70">
                        <span>{t('itService.detailLinkages2')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#tab05" data-hash data-hash-offset="70">
                        <span>{t('itService.detailLinkages3')}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </FrameCol>
            </FrameRow>
          </FrameContainer>
          <FrameCol addClassName="mt-50">
            <h2 className="mb-50">
              <span className="fColor-blue">{t('itService.detailLinkages4')}</span>
            </h2>
            <FrameContainer>
              <FrameRow>
                <FrameCol width={4}>
                  <CustomImgTag src="assets/img/custom/it_service_img08.png" alt={t('itService.detailAlt1')} />
                </FrameCol>
                <FrameCol width={8} addClassName="info-box">
                  <p>
                    {t('itService.detailLinkages5')}
                    <br />
                    {t('itService.detailLinkages6')}
                    <br />
                    {t('itService.detailLinkages7')}
                    <br />
                    {t('itService.detailLinkages8')}
                  </p>
                </FrameCol>
              </FrameRow>
            </FrameContainer>
          </FrameCol>
        </section>
        <section className="section-primary bg-lite-blue">
          <FrameContainer>
            <FrameRow>
              <FrameCol addClassName="pb-20">
                <h3 className="mb-50">{t('itService.detailLinkages9')}</h3>
                <div className="mt-20">
                  {i18n.language == 'ko' ? (
                    <CustomImgTag src="assets/img/custom/it_service_img09.png" alt={t('itService.detailAlt2')} />
                  ) : i18n.language == 'ch' ? (
                    <CustomImgTag src="assets/img/custom/it_service_img09_cn.png" alt={t('itService.detailAlt2')} />
                  ) : (
                    <CustomImgTag src="assets/img/custom/it_service_img09_en.png" alt={t('itService.detailAlt2')} />
                  )}
                </div>
              </FrameCol>
            </FrameRow>
            <FrameCol addClassName="mt-80">
              <h3 className="mb-50">{t('itService.detailLinkages10')}</h3>
              <div className="service-box5">
                <div className="box-area">
                  <p className="title">
                    {t('itService.detailLinkages11')}
                    <br />
                    {t('itService.detailLinkages12')}
                  </p>
                  <ul>
                    <li>{t('itService.detailLinkages13')}</li>
                    <li>{t('itService.detailLinkages14')}</li>
                  </ul>
                </div>
                <div className="box-area">
                  <p className="title">
                    {t('itService.detailLinkages15')}
                    <br />
                    {t('itService.detailLinkages16')}
                  </p>
                  <ul>
                    <li>{t('itService.detailLinkages17')}</li>
                    <li>{t('itService.detailLinkages18')}</li>
                  </ul>
                </div>
                <div className="box-area">
                  <p className="title">
                    {t('itService.detailLinkages19')}
                    <br />
                    {t('itService.detailLinkages20')}
                  </p>
                  <ul>
                    <li>{t('itService.detailLinkages21')}</li>
                    <li>{t('itService.detailLinkages22')}</li>
                    <li>{t('itService.detailLinkages23')}</li>
                  </ul>
                </div>
                <div className="box-area">
                  <p className="title">
                    {t('itService.detailLinkages24')}
                    <br />
                    {t('itService.detailLinkages25')}
                  </p>
                  <ul>
                    <li>{t('itService.detailLinkages26')}</li>
                    <li>{t('itService.detailLinkages27')}</li>
                  </ul>
                </div>
              </div>
            </FrameCol>
            <FrameCol addClassName="text-center mt-40">
              <CustomATag href="/it/portfolio" state={['PC08']} className="btn btn-primary fs-14 btn-rounded px-5">
                {t('itService.detailLinkages28')}
              </CustomATag>
            </FrameCol>
          </FrameContainer>
        </section>
        <section className="section-primary pt-140 pb-80" id="tab04">
          <FrameContainer addClassName="mb-80">
            <FrameRow>
              <FrameCol addClassName="text-center">
                <div className="tab-wrap2">
                  <ul className="nav nav-tabs justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link" href="#tab03" data-hash data-hash-offset="70">
                        <span>{t('itService.detailSimilarity1')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" href="#tab04" data-hash data-hash-offset="70">
                        <span>{t('itService.detailSimilarity2')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#tab05" data-hash data-hash-offset="70">
                        <span>{t('itService.detailSimilarity3')}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </FrameCol>
            </FrameRow>
          </FrameContainer>
          <FrameCol addClassName="mt-50">
            <h2 className="mb-50">
              <span className="fColor-blue">{t('itService.detailSimilarity4')}</span>
              <br />
              {t('itService.detailSimilarity5')}
            </h2>
            <FrameContainer>
              <FrameRow>
                <FrameCol width={4}>
                  <CustomImgTag src="assets/img/custom/it_service_img10.png" alt={t('itService.detailAlt1')} />
                </FrameCol>
                <FrameCol width={8} addClassName="info-box">
                  <p>
                    {t('itService.detailSimilarity6')}
                    <br />
                    {t('itService.detailSimilarity7')}
                    <br />
                    {t('itService.detailSimilarity8')}
                  </p>
                </FrameCol>
              </FrameRow>
            </FrameContainer>
          </FrameCol>
        </section>
        <section className="section-primary bg-lite-blue">
          <FrameRow>
            <FrameCol addClassName="pb-20">
              <FrameContainer>
                <h3 className="mb-50">{t('itService.detailSimilarity9')}</h3>
                <div className="mt-20">
                  {i18n.language == 'ko' ? (
                    <CustomImgTag src="assets/img/custom/it_service_img11.png" alt={t('itService.detailAlt2')} />
                  ) : i18n.language == 'ch' ? (
                    <CustomImgTag src="assets/img/custom/it_service_img11_cn.png" alt={t('itService.detailAlt2')} />
                  ) : (
                    <CustomImgTag src="assets/img/custom/it_service_img11_en.png" alt={t('itService.detailAlt2')} />
                  )}
                </div>
              </FrameContainer>
            </FrameCol>
          </FrameRow>
          <FrameRow>
            <FrameCol addClassName="mt-80">
              <FrameContainer>
                <h3 className="mb-50">{t('itService.detailSimilarity10')}</h3>
                <div className="service-box5">
                  <div className="box-area text-center">
                    <i className="fa fa-check-circle fColor-blue fa-3x mb-20"></i>
                    <p className="dis-tit" style={stylePre}>
                      {t('itService.detailSimilarity11')}
                      <br />
                      {t('itService.detailSimilarity12')}
                      <br />
                      {t('itService.detailSimilarity13')}
                    </p>
                  </div>
                  <div className="box-area text-center">
                    <i className="fa fa-check-circle fColor-blue fa-3x mb-20"></i>
                    <p>
                      {t('itService.detailSimilarity14')}
                      <br />
                      {t('itService.detailSimilarity15')}
                      <br />
                      {t('itService.detailSimilarity16')}
                    </p>
                  </div>
                  <div className="box-area text-center">
                    <i className="fa fa-check-circle fColor-blue fa-3x mb-20"></i>
                    <p>
                      {t('itService.detailSimilarity17')}
                      <br />
                      {t('itService.detailSimilarity18')}
                      <br />
                      {t('itService.detailSimilarity19')}
                    </p>
                  </div>
                  <div className="box-area text-center">
                    <i className="fa fa-check-circle fColor-blue fa-3x mb-20"></i>
                    <p>
                      {t('itService.detailSimilarity20')}
                      <br />
                      {t('itService.detailSimilarity21')}
                      <br />
                      {t('itService.detailSimilarity22')}
                    </p>
                  </div>
                </div>
              </FrameContainer>
            </FrameCol>
            <FrameCol addClassName="mt-80">
              <FrameContainer>
                <h3 className="mb-50">{t('itService.detailSimilarity23')}</h3>
                <div className="service-box6">
                  <div className="box-area">
                    <div className="img-area">
                      <CustomImgTag src="assets/img/custom/it_service_img12.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailSimilarity24')}
                      <br />
                      {t('itService.detailSimilarity25')}
                      <br />
                      {t('itService.detailSimilarity26')}
                    </p>
                  </div>
                  <div className="box-area">
                    <div className="img-area">
                      <CustomImgTag src="assets/img/custom/it_service_img13.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailSimilarity27')}
                      <br />
                      {t('itService.detailSimilarity28')}
                      <br />
                      {t('itService.detailSimilarity29')}
                    </p>
                  </div>
                  <div className="box-area">
                    <div className="img-area">
                      <CustomImgTag src="assets/img/custom/it_service_img14.png" alt={t('itService.detailAlt1')} />
                    </div>
                    <p>
                      {t('itService.detailSimilarity30')}
                      <br />
                      {t('itService.detailSimilarity31')}
                      <br />
                      {t('itService.detailSimilarity32')}
                    </p>
                  </div>
                </div>
              </FrameContainer>
            </FrameCol>
            <FrameCol addClassName="text-center mt-40">
              <CustomATag href="/it/portfolio" state={['PC09']} className="btn btn-primary fs-14 btn-rounded px-5">
                {t('itService.detailSimilarity33')}
              </CustomATag>
            </FrameCol>
          </FrameRow>
        </section>
        <section className="section-primary pt-140 pb-0" id="tab05">
          <FrameContainer addClassName="mb-80">
            <FrameRow>
              <FrameCol addClassName="text-center">
                <div className="tab-wrap2">
                  <ul className="nav nav-tabs justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link" href="#tab03" data-hash data-hash-offset="70">
                        <span>{t('itService.detailBlockchain1')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#tab04" data-hash data-hash-offset="70">
                        <span>{t('itService.detailBlockchain2')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" href="#tab05" data-hash data-hash-offset="70">
                        <span>{t('itService.detailBlockchain3')}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </FrameCol>
            </FrameRow>
          </FrameContainer>
          <FrameCol addClassName="text-center mt-50">
            <h2 className="mb-80">
              <span className="fColor-blue">{t('itService.detailBlockchain4')}</span>
              <br />
              {t('itService.detailBlockchain5')}
            </h2>
          </FrameCol>
        </section>
        <section className="section-primary bg-lite-blue">
          <FrameRow>
            <FrameCol addClassName="text-center">
              <p className="fw-7 fs-20">{t('itService.detailBlockchain6')}</p>
              <p className="mb-50">
                <CustomImgTag src="assets/img/icons/image_bclab.png" alt={t('itService.detailAlt3')} className="w350" />
              </p>
              <h1 className="mb-30">{t('itService.detailBlockchain7')}</h1>
              <p className="section-sub-title text-center mb-30">
                {t('itService.detailBlockchain8')}
                <br />
                {t('itService.detailBlockchain9')}
              </p>
            </FrameCol>
            <FrameCol>
              <FrameContainer>
                <div className="mt-20 bg-white round-30 p-50 text-center">
                  {i18n.language == 'ko' ? (
                    <CustomImgTag src="assets/img/custom/it_service_img15.png" alt={t('itService.detailAlt2')} className="w750" />
                  ) : i18n.language == 'ch' ? (
                    <CustomImgTag src="assets/img/custom/it_service_img15_cn.png" alt={t('itService.detailAlt2')} className="w750" />
                  ) : (
                    <CustomImgTag src="assets/img/custom/it_service_img15_en.png" alt={t('itService.detailAlt2')} className="w750" />
                  )}
                </div>
              </FrameContainer>
            </FrameCol>
            <FrameCol addClassName="text-center mt-50">
              <h2 className="mb-20">{t('itService.detailBlockchain10')}</h2>
              <p className="section-sub-title text-center mb-50">
                {t('itService.detailBlockchain11')}
                <br />
                {t('itService.detailBlockchain12')}
                <br />
                {t('itService.detailBlockchain13')}
              </p>
            </FrameCol>
            <FrameContainer>
              <FrameRow>
                <FrameCol width={6} addClassName="pb-20">
                  <div className="bg-white round-30 p-40 text-center">
                    <div className="p-10 round-25 fw-7 fs-18 w250 float-c bg-gray2 fColor-black">{t('itService.detailBlockchain14')}</div>
                    <CustomImgTag src="assets/img/custom/it_service_img16.png" alt={t('itService.detailAlt2')} className="w600" />
                  </div>
                </FrameCol>
                <FrameCol width={6} addClassName="pb-20">
                  <div className="bg-white round-30 p-40 text-center">
                    <div className="p-10 round-25 fw-7 fs-18 w250 float-c bg-gray2 fColor-black">{t('itService.detailBlockchain15')}</div>
                    <CustomImgTag src="assets/img/custom/it_service_img17.png" alt={t('itService.detailAlt2')} className="w600" />
                  </div>
                </FrameCol>
                <FrameCol addClassName="pb-20">
                  <div className="bg-white round-30 p-40 text-center">
                    <div className="p-10 round-25 fw-7 fs-18 w250 float-c bg-gray2 fColor-black">{t('itService.detailBlockchain16')}</div>
                    <CustomImgTag src="assets/img/custom/it_service_img18.png" alt={t('itService.detailAlt2')} className="w130" />
                  </div>
                </FrameCol>
              </FrameRow>
            </FrameContainer>
          </FrameRow>
        </section>
        <section className="section-primary">
          <FrameRow>
            <FrameCol addClassName="text-center">
              <h2 className="mb-20">{t('itService.detailBlockchain17')}</h2>
              <p className="section-sub-title text-center mb-50">
                {t('itService.detailBlockchain18')}
                <br />
                {t('itService.detailBlockchain19')}
                <br />
                {t('itService.detailBlockchain20')}
              </p>
            </FrameCol>
            <FrameContainer>
              <FrameCol addClassName="pb-20">
                <div className="cherry-box">
                  <FrameRow>
                    <FrameCol width={6}>
                      <p className="fw-7">{t('itService.detailBlockchain21')}</p>
                      <h1>
                        {t('itService.detailBlockchain22')}
                        <br />
                        {t('itService.detailBlockchain23')}
                      </h1>
                      <p className="subtitle">{t('itService.detailBlockchain24')}</p>
                      <p className="subtitle">{t('itService.detailBlockchain25')}</p>
                      <CustomATag href="https://givecherry.org/" target="_blank" className="btn btn-outline-primary btn-rounded fs-14">
                        {t('itService.detailBlockchain26')} <i className="glyphicon icon icon-arrow-32"></i>
                      </CustomATag>
                    </FrameCol>
                    <FrameCol width={6}>
                      <CustomImgTag src="assets/img/custom/it_service_img19.png" alt={t('itService.detailAlt4')} />
                    </FrameCol>
                  </FrameRow>
                </div>
              </FrameCol>
            </FrameContainer>
          </FrameRow>
        </section>
      </div>
    </FramePageInner>
  );
};

export default ServiceTabDetailComponent;
