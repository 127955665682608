import { sanitize } from 'dompurify';

const FooterMoreInfoModalPersonalPolicyLayout = () => {
  return (
    <>
      <h3 className="mb-10">개인정보 처리방침</h3>
      <pre
        dangerouslySetInnerHTML={{
          __html:
            sanitize(`㈜이포넷(이하 &apos;회사&apos;)은 이용자의 개인정보를 중요시하며, &quot;정보통신망 이용촉진 및 정보보호&quot;에 관한 법률과 개인정보보호법 등을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 이용자님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.
회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
회사의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.

<i>1. 수집하는 개인정보 및 수집방법
2. 개인정보의 수집 및 이용목적
3. 수집한 개인정보의 공유 및 제공
4. 개인정보처리 위탁
5. 수집한 개인정보의 보유 및 이용기간
6. 개인정보의 파기절차 및 방법
7. 개인정보의 안전성 확보 조치
8. 이용자 및 법정대리인의 권리와 그 행사방법
9. 개인정보 자동 수집 장치의 설치ㆍ운영 및 그 거부에 관한 사항
10.개인정보 관리책임자 및 개인정보담당 부서
11. 고지의 의무</i>

ο 본 방침은 2016년 7월 1일부터 시행됩니다.

<b>1. 수집하는 개인정보 및 수집방법</b>
    <b>1) 수집항목</b>
        회사는 회사관련 문의, 서비스/상품 문의, 채용 문의, 홈페이지 문의, 부정제보, 보안제보 등에 대한 관리를 위하여 아래와 같은 개인정보를 수집하고 있습니다.

        가.문의하기
            ▶ 필수정보
                - 이름, 회사명, 이메일, 연락처
            ▶ 동의를 거부할 권리 및 동의 거부에 따른 불이익
                - 필수 정보 수집에 대한 동의를 거부할 권리가 있습니다.
                - 필수 정보 외, 선택 정보 수집에 대한 동의를 거부할 권리가 있습니다.
                  단, 동의를 거부할 경우 문의에 대한 응대가 불가능할 수 있습니다.

        나.부정제보
            ▶ 필수 사항 : 없음
            ▶ 선택 사항 : 이름, 연락처, 이메일
            ▶ 동의를 거부할 권리 및 동의 거부에 따른 불이익
                - 선택 정보 수집에 대한 동의를 거부할 권리가 있습니다.
                - 정보 수집에 대한 동의를 거부할 권리가 있으며, 동의하지 않더라고 보안제보를 입력하시는데 제약은 없습니다. (무기명 제보 가능)
                  단, 동의를 거부하실 경우 제보 민원을 적기에 처리하지 못할 수 있습니다.

        다.보안신고센터(제보)
            ▶ 필수 사항 : 없음
            ▶ 선택 사항 : 이름, 연락처, 이메일
            ▶ 동의를 거부할 권리 및 동의 거부에 따른 불이익
                - 선택 정보 수집에 대한 동의를 거부할 권리가 있습니다.
                - 정보 수집에 대한 동의를 거부할 권리가 있으며, 동의하지 않더라고 보안제보를 입력하시는데 제약은 없습니다. (무기명 제보 가능)
                  단, 동의를 거부하실 경우 제보 민원을 적기에 처리하지 못할 수 있습니다.

    <b>2) 수집방법</b>
        회사는 다음과 같은 방법으로 개인정보를 수집합니다.
            - 홈페이지 &apos;문의하기&apos; 메뉴

<b>2. 개인정보의 수집 및 이용목적</b>
    회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
        - 고객 문의/부정제보/보안제보에 대한 응대 및 관리
        - 회사관련 문의, 서비스/상품 문의, 채용 문의, 홈페이지 문의 등의 고객 문의에 대한 피드백 제공 및 관리

<b>3. 수집한 개인정보의 공유 및 제공</b>
    회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
        - 이용자들이 사전에 동의한 경우
        - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

<b>4. 개인정보처리 위탁</b>
    회사는 이용자의 개인정보를 원칙적으로 외부에 처리 위탁하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
        - 이용자들이 사전에 동의한 경우
        - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사 기관의 요구가 있는 경우

<b>5. 수집한 개인정보의 보유 및 이용기간</b>
    고객 문의/부정제보/보안제보에 대한 피드백이 제공되는 시점에 해당 정보를 지체 없이 파기합니다.

<b>6. 개인정보의 파기절차 및 방법</b>
    회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
    파기절차 및 방법은 다음과 같습니다.
        <b>1) 파기절차</b>
            고객이 입력하신 정보는 목적이 달성된 후 내부방침에 의한 정보보호사유에 따라(보유 및 이용기간 참조) 일정기간 저장된 후 파기되어 집니다.
            동 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용 되지 않습니다.
        <b>2) 파기방법</b>
            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

<b>7. 개인정보의 안전성 확보 조치</b>
    회사는 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
        <b>1) 내부관리계획의 수립 및 시행</b>
            회사의 내부관리계획 수립하여 개인정보 보호를 위해 실행합니다.
        <b>2) 개인정보 처리 담당자의 최소화 및 교육</b>
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
        <b>3) 접속기록의 보관 및 위변조 방지</b>
            개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
        <b>4) 해킹 등에 대비한 기술적 대책 및 비인가자에 대한 출입 통제</b>
            회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신ㆍ점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는 물론 불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.

<b>8. 이용자 및 법정대리인의 권리와 그 행사방법</b>
    이용자 및 법정대리인은 언제든지 등록되어 있는 이용자의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보의 열람, 정정 및 삭제 등의 요청은 개인정보관리책임자에게 전화 혹은 e-mail로 요청하시면 본인 확인 절차를 거친 후 지체없이 조치하겠습니다.
    이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
    회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

<b>9. 개인정보 자동 수집 장치의 설치ㆍ운영 및 그 거부에 관한 사항</b>
    쿠키 등 인터넷 서비스 이용 시 자동 생성되는 개인정보를 수집하는 장치를 운영하지 않습니다.

<b>10. 개인정보관리책임자 및 개인정보담당 부서</b>
    회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
        <b>1) 개인정보관리 최고책임자</b>
            성명 : 이득경 부사장
            전화번호 : 02-3465-8500
            이메일 : dklee@e4net.net
        <b>2) 개인정보관리 책임자</b>
            성명 : 김신형 부장
            전화번호 : 02-3465-8591
            이메일 : shkim1@e4net.net

이용자께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

개인정보침해신고센터 (https://privacy.kisa.or.kr/118)
정보보호마크인증위원회 (https://www.eprivacy.or.kr/02-580-0533~4)
대검찰청 첨단범죄수사과 (https://www.spo.go.kr/02-3480-2000)
경찰청 사이버테러대응센터 (https://www.netan.go.kr/1566-0112)

<b>11. 고지의 의무</b>
    현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 &apos;공지사항&apos;을 통해 고지할 것입니다.`),
        }}
      />
    </>
  );
};

export default FooterMoreInfoModalPersonalPolicyLayout;
