import { FrameCol } from 'common/view/layouts/frames';
import TabLayout from 'common/view/layouts/tab/TabLayout';
import { TabLayoutCommonProps } from 'types/common/PropsTypes';

import ContactMoreInfoModalPersonalInfoPolicyLayout from './ContactMoreInfoModalPersonalInfoPolicyLayout';

const ContactMoreInfoModalTabLayout = ({ currentTabIdx, setCurrentTabIdx }: TabLayoutCommonProps) => {
  return (
    <FrameCol size={'lg'} width={12}>
      <TabLayout isFromModal tabLabelDef={['개인정보 수집/이용동의']} currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} tabPanelDef={[<ContactMoreInfoModalPersonalInfoPolicyLayout key={0} />]} />
    </FrameCol>
  );
};

export default ContactMoreInfoModalTabLayout;
