import { useCallback, useEffect, useRef, useState } from 'react';
import { CommonProps } from 'types/common/PropsTypes';
import { useEffectOnce } from 'usehooks-ts';

/********************************************************************************
 * useMasterSlider
 ********************************************************************************/
export interface useMasterSliderProps extends CommonProps {
  sliderOption: object;
  arrowOption?: object;
  bulltesOption?: object;
  timebarOption?: object;
  prevBtnRef?: React.RefObject<HTMLElement>;
  nextBtnRef?: React.RefObject<HTMLElement>;
  customProgressRef?: React.RefObject<HTMLDivElement>;
  customSlidesLogRef?: React.RefObject<HTMLDivElement>;
}
export const useMasterSlider = ({ id, sliderOption, arrowOption, bulltesOption, timebarOption, prevBtnRef, nextBtnRef, customProgressRef, customSlidesLogRef, children }: useMasterSliderProps) => {
  const slideRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  const [masterslider] = useState(() => new window.MasterSlider());

  const handleSlidePrev = useCallback(() => {
    masterslider.api?.previous(!0);
  }, [masterslider]);

  const handleSlideNext = useCallback(() => {
    masterslider.api?.next(!0);
  }, [masterslider]);

  const addOnClickEvent = useCallback(() => {
    if (prevBtnRef) prevBtnRef.current?.addEventListener('click', handleSlidePrev);
    if (nextBtnRef) nextBtnRef.current?.addEventListener('click', handleSlideNext);
  }, [prevBtnRef, nextBtnRef, handleSlidePrev, handleSlideNext]);

  const removeOnClickEvent = useCallback(() => {
    if (prevBtnRef) prevBtnRef.current?.removeEventListener('click', handleSlidePrev);
    if (nextBtnRef) nextBtnRef.current?.removeEventListener('click', handleSlideNext);
  }, [prevBtnRef, nextBtnRef, handleSlidePrev, handleSlideNext]);

  const handleProgressBarWidth = useCallback((progressBarWidth: string, progressRef?: React.RefObject<HTMLDivElement>) => {
    if (!progressRef?.current) return;
    progressRef.current.style.width = progressBarWidth;
    progressRef.current.setAttribute('data-appear-progress-animation', progressBarWidth);
  }, []);

  const handleInnerText = useCallback((ref: React.RefObject<HTMLDivElement> | undefined, selector: string, value: string | number) => {
    const element = ref?.current?.querySelector(selector);
    if (element) element.textContent = value.toString();
  }, []);

  const handleLogStatus = useCallback(
    (number: number) => {
      if (customSlidesLogRef?.current) handleInnerText(customSlidesLogRef, '.slidesjs-slide-number', number);
    },
    [customSlidesLogRef, handleInnerText],
  );

  const handleProcessBar = useCallback(
    (number: number) => {
      const totalCount = slideRef.current?.querySelectorAll(`.ms-slide`)?.length;
      if (!totalCount) return;
      const progressBarWidth = `${((number ?? 1) / totalCount) * 100}%`;
      handleProgressBarWidth(progressBarWidth, progressRef);
      handleProgressBarWidth(progressBarWidth, customProgressRef);
    },
    [slideRef, handleProgressBarWidth, progressRef, customProgressRef],
  );

  const handleSliderChangeEnd = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: { target: any }) => {
      const currentIndex = event.target?.currentSlide?.index + 1;
      handleLogStatus(currentIndex);
      handleProcessBar(currentIndex);
    },
    [handleLogStatus, handleProcessBar],
  );

  const addCustomEventListener = useCallback(() => {
    addOnClickEvent();
    masterslider.api?.addEventListener(window.MSSliderEvent?.CHANGE_END, handleSliderChangeEnd);
  }, [addOnClickEvent, masterslider, handleSliderChangeEnd]);

  const removeCustomEventListener = useCallback(() => {
    removeOnClickEvent();
    masterslider.api?.removeEventListener(window.MSSliderEvent?.CHANGE_END, handleSliderChangeEnd);
  }, [removeOnClickEvent, masterslider, handleSliderChangeEnd]);

  useEffect(() => {
    const totalCount = slideRef.current?.querySelectorAll('.ms-slide').length;
    const isSlideValid = masterslider && (totalCount ?? 0) > 0 && !slideRef.current?.className.includes('ms-wk');
    if (isSlideValid) {
      if (arrowOption) masterslider.control('arrows', arrowOption);
      if (bulltesOption) masterslider.control('bullets', bulltesOption);
      if (timebarOption) masterslider.control('timebar', timebarOption);
      masterslider.setup(id, sliderOption);
      if (customSlidesLogRef?.current) handleInnerText(customSlidesLogRef, '.totalCnt', String(totalCount));
    }
    addCustomEventListener();

    return () => removeCustomEventListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return { slideRef, progressRef };
};

/********************************************************************************
 * useSlidesJs
 ********************************************************************************/
export type SliderOption = {
  callback?: {
    loaded?: (number: number) => void;
    start?: (number: number) => void;
    complete?: (number: number) => void;
  };
};
export interface useSlidesJsProps extends CommonProps {
  sliderOption?: SliderOption;
  customProgressRef?: React.RefObject<HTMLElement>;
  customSlidesLogRef?: React.RefObject<HTMLDivElement>;
}
export const useSlidesJs = ({ id, sliderOption, customProgressRef, customSlidesLogRef }: useSlidesJsProps) => {
  const progressRef = useRef<HTMLElement>(null);
  const slidesLogRef = useRef<HTMLDivElement>(null);

  const { loaded, start, complete } = sliderOption?.callback ?? {};

  const handleInnerText = useCallback((ref: React.RefObject<HTMLDivElement> | undefined, selector: string, value: string | number) => {
    const element = ref?.current?.querySelector(selector);
    if (element) element.textContent = value.toString();
  }, []);

  const handleLogStatus = useCallback(
    (number: number) => {
      if (slidesLogRef.current) handleInnerText(slidesLogRef, '.slidesjs-slide-number', number);
      if (customSlidesLogRef?.current) handleInnerText(customSlidesLogRef, '.slidesjs-slide-number', number);
    },
    [slidesLogRef, handleInnerText, customSlidesLogRef],
  );

  const handleProcessBar = useCallback(
    (number: number) => {
      const totalCount = document.querySelectorAll(`#${id} .slidesjs-slide`)?.length;
      if (!totalCount) return;
      const progressBarWidth = `${((number ?? 1) / totalCount) * 100}%`;
      if (progressRef.current) progressRef.current.style.width = progressBarWidth;
      if (customProgressRef?.current) customProgressRef.current.style.width = progressBarWidth;
    },
    [id, progressRef, customProgressRef],
  );

  const onSlideCompleted = useCallback(
    (number: number) => {
      handleLogStatus(number);
      handleProcessBar(number);
    },
    [handleLogStatus, handleProcessBar],
  );

  useEffectOnce(() => {
    const initializeSlider = () => {
      const $ = window.$;
      if (!id || !$) return;
      $(`#${id}`).slidesjs({
        ...sliderOption,
        callback: {
          loaded: (number: number) => {
            const totalCount = document.querySelectorAll(`#${id} .slidesjs-slide`)?.length;
            if (!totalCount) return;
            if (slidesLogRef.current) handleInnerText(slidesLogRef, '.totalCnt', totalCount);
            if (customSlidesLogRef?.current) handleInnerText(customSlidesLogRef, '.totalCnt', totalCount);
            onSlideCompleted(1);
            loaded?.(number);
          },
          start: (number: number) => {
            start?.(number);
          },
          complete: (number: number) => {
            onSlideCompleted(number);
            // if (sliderOption?.callback?.complete) sliderOption?.callback?.complete(number);
            complete?.(number);
          },
        },
      });
    };
    initializeSlider();

    return () => {};
  });

  return { progressRef, slidesLogRef };
};
