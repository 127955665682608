import React from 'react';

export interface GoogleMapComponentProps {
  googleMapSrc: string;
}
const GoogleMapComponent = ({ googleMapSrc }: GoogleMapComponentProps) => {
  return <iframe src={googleMapSrc} width="100%" height="450" style={{ border: 0 }} allowFullScreen loading="lazy"></iframe>;
};

export default React.memo(GoogleMapComponent);
