import { CustomImgTag } from 'common/view/components/custom';
import { sanitize } from 'dompurify';
import React from 'react';
import { FRAME_SIZE, TEXT_ALIGN } from 'types/common/PropsTypes';
import { styleCursorPointer } from 'util/StyleUtil';

import { FrameCol } from '../frames';
import DetailViewNavBar from './DetailViewNavBar';

type imgDefProps = {
  src: string;
  alt: string;
};

type linkDefElementProps = { text: string; linkText: string; link?: string; target?: string; onClick?: () => void };

type linkDefProps = {
  title?: string;
  element: linkDefElementProps[];
};

type DetailViewLayoutProps = {
  colClassName?: string;
  colSize?: FRAME_SIZE;
  colWidth?: number;
  textAlign?: TEXT_ALIGN;
  wrapperClassName?: string;
  date?: string;
  category?: string;
  title?: string;
  titleClassName?: string;
  imgClassName?: string;
  imgDef?: imgDefProps | imgDefProps[];
  content: string;
  txtClassName?: string;
  contentClassName?: string;
  linkDef?: linkDefProps;
  linkClassName?: string;
  isNoNavButtons?: boolean;
  brdNo?: string;
  prevPostNo: number;
  nextPostNo: number;
};

const DetailViewLayout = ({
  colSize,
  colWidth,
  colClassName,
  textAlign,
  wrapperClassName,
  date,
  category,
  title,
  titleClassName,
  imgClassName,
  imgDef,
  content,
  txtClassName,
  contentClassName,
  linkDef,
  linkClassName,
  isNoNavButtons,
  brdNo,
  prevPostNo,
  nextPostNo,
}: DetailViewLayoutProps) => {
  return (
    <>
      <FrameCol size={colSize} width={colWidth} addClassName={colClassName} textAlign={textAlign}>
        <div className={wrapperClassName ?? 'viewWrap'}>
          <div className="dateWrap">
            <p>
              {date && <span>{date ?? ''}</span>}
              {date && category && <span>ㅣ</span>}
              {category ?? ''}
            </p>
          </div>
          <div className={titleClassName ?? ''}>
            <p className="mb-0">{title ?? ''}</p>
          </div>
          {/* <div className={contentClassName ?? ''} dangerouslySetInnerHTML={{ __html: sanitize(content) }}></div> */}
          <div className={contentClassName ?? ''}>
            {imgDef &&
              (Array.isArray(imgDef)
                ? imgDef.map(
                    (e, idx) =>
                      e.src && (
                        <div key={`${e.src} ${e.alt} ${idx}`} className={imgClassName ? imgClassName : 'photo_thum'}>
                          <CustomImgTag src={e.src} alt={e.alt} />
                        </div>
                      ),
                  )
                : imgDef.src && (
                    // TODO: imgDef.src 없으면 <div> 안 보이게
                    <div className={imgClassName ? imgClassName : 'photo_thum'}>
                      <CustomImgTag src={imgDef.src} alt={imgDef.alt} />
                    </div>
                  ))}

            <div className={txtClassName ? txtClassName : 'newsTxt'} dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
            {linkDef && (
              <div className={linkClassName ? linkClassName : ''}>
                {linkDef.title && <div className="title">{linkDef.title}</div>}
                <ul>
                  {linkDef.element.map((e, idx) => (
                    <li key={`${e.link} ${e.linkText} ${idx}`} style={e.link ? {} : { display: 'none' }}>
                      <span>
                        {e.text}
                        <a
                          type="button"
                          href={e.link ? e.link : '#'}
                          style={styleCursorPointer}
                          target={e.target ? e.target : '_self'}
                          onClick={(event) => {
                            if (e.onClick) {
                              event.preventDefault();
                              e.onClick();
                            }
                          }}
                        >
                          {e.linkText}
                        </a>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </FrameCol>
      {isNoNavButtons !== true && <DetailViewNavBar size={colSize} width={colWidth} brdNo={brdNo != null ? brdNo : ''} prevPostNo={prevPostNo} nextPostNo={nextPostNo} />}
    </>
  );
};

export default React.memo(DetailViewLayout);
