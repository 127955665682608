import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useRootUrl, useSecondUrl } from 'hooks/common/CommonHook';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'usehooks-ts';

const IrTabComponent = () => {
  const { t } = useTranslation();

  const { currentSecondUrl: secondUrl } = useSecondUrl();
  const { currentRootUrl: rootUrl } = useRootUrl();

  useEffectOnce(() => {
    const $ = window.$;

    const scrollOffset = $('#ServiceHeader').offset();
    const scrollHandler = () => {
      if ($(document).scrollTop() > scrollOffset.top + 70) {
        $('#ServiceHeader').addClass('ir-scroll-fixed');
      } else {
        $('#ServiceHeader').removeClass('ir-scroll-fixed');
      }
    };
    $(window).scroll(scrollHandler);
    return () => {
      $(window).off('scroll', scrollHandler);
    };
  });

  return (
    <FrameRow>
      <FrameCol>
        <FrameRow>
          <FrameCol addClassName="col-md-12 text-center mb-50">
            <h1 className="mb-20 mt-50">
              <span>IR</span>
            </h1>
            <p className="section-sub-title mb-0">{t('irCommon.head1')}</p>
          </FrameCol>
        </FrameRow>
        <FrameRow>
          <FrameCol addClassName="container-fluid b-bordered t-bordered">
            <div className="ir-tabWrap" id="ServiceHeader">
              <ul>
                <li>
                  <CustomATag className={(rootUrl === 'ir' && secondUrl == null) || secondUrl === 'corporate' ? 'active' : ''} href="/ir/corporate">
                    <span>{t('irCommon.tab1')}</span>
                  </CustomATag>
                </li>
                <li>
                  <CustomATag className={secondUrl === 'financial' ? 'active' : ''} href="/ir/financial">
                    <span>{t('irCommon.tab2')}</span>
                  </CustomATag>
                </li>
                <li>
                  <CustomATag className={secondUrl === 'policy' ? 'active' : ''} href="/ir/policy">
                    <span>{t('irCommon.tab3')}</span>
                  </CustomATag>
                </li>
                <li>
                  <CustomATag className={secondUrl === 'news' ? 'active' : ''} href="/ir/news">
                    <span>{t('irCommon.tab4')}</span>
                  </CustomATag>
                </li>
                <li>
                  <CustomATag className={secondUrl === 'contact' ? 'active' : ''} href="/ir/contact">
                    <span>{t('irCommon.tab5')}</span>
                  </CustomATag>
                </li>
              </ul>
            </div>
          </FrameCol>
        </FrameRow>
      </FrameCol>
    </FrameRow>
  );
};

export default IrTabComponent;
