import { selectNews } from 'api/board/BoardAPI';
import DetailViewLayout from 'common/view/layouts/detailView/DetailViewLayout';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BoardRVO } from 'types/board';
import { isSuccess } from 'util/ApiResponseUtil';
import { dateFormat } from 'util/FormatUtil';

const defaultNewsData = {
  brdNm: '',
  postNo: '',
  subject: '',
  imgFile: '',
  regDt: '',
  pstgYmd: '',
  content: '',
  nextPostNo: 0,
  prevPostNo: 0,
  totalCount: 0,
  e4Count: 0,
  noticeCount: 0,
  pageNo: 1,
  home: '',
};

const NewsViewComponent = () => {
  const { t } = useTranslation();

  const [newsData, setNewsData] = useState<BoardRVO>(defaultNewsData);
  const { pathname } = useLocation();
  const getNews = async () => {
    const postNo: number = parseInt(pathname.split('/').pop() as string); // news/123
    await selectNews({ postNo: postNo })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setNewsData(resData);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getNews();
  }, [pathname]);

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-0">
        <h1 className="mb-20 mt-50">
          <span>NEWS</span>
        </h1>
        <p className="section-sub-title mb-40">{t('news.viewHead1')}</p>
      </FrameCol>
      <DetailViewLayout
        date={dateFormat(newsData.pstgYmd)}
        category="NEWS"
        titleClassName="newsTitle"
        title={newsData.subject}
        contentClassName="newsDetail"
        content={newsData.content as string}
        prevPostNo={newsData.prevPostNo}
        nextPostNo={newsData.nextPostNo}
        imgDef={{ src: newsData.imgFile, alt: '사진' }}
        linkClassName={newsData.home ? 'moreLink' : ''}
        linkDef={{
          element: [{ text: newsData.home ? t('news.viewLink1') + ' \n\n' : '', linkText: newsData.home ? t('news.viewLink2') : '', link: newsData.home ?? '', target: '_blank' }],
        }}
      />
    </FrameRow>
  );
};
export default NewsViewComponent;
