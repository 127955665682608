import SectionLayout from 'common/view/layouts/section/SectionLayout';

import NewsViewComponent from './NewsViewComponent';

const NewsViewPage = () => {
  return (
    <SectionLayout addSectionClassName="b-bordered">
      <NewsViewComponent />
    </SectionLayout>
  );
};

export default NewsViewPage;
