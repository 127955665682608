import { AbstractProps } from 'types/common/PropsTypes';

import FooterLayout from '../footer/FooterLayout';

const PageLayout = ({ children }: AbstractProps) => {
  return (
    <div className="page">
      {children}
      <FooterLayout />
    </div>
  );
};

export default PageLayout;
