import ModalLayout from 'common/view/layouts/modal/ModalLayout';
import { TabLayoutCommonProps } from 'types/common/PropsTypes';

import ContactMoreInfoModalTabLayout from './ContactMoreInfoModalTabLayout';

export interface ContactMoreInfoModalLayoutProps extends TabLayoutCommonProps {
  modalId: string;
  modalTitleName?: string;
}

const ContactMoreInfoModalLayout = ({ modalId, modalTitleName, currentTabIdx, setCurrentTabIdx }: ContactMoreInfoModalLayoutProps) => {
  return (
    <ModalLayout modalId={modalId} titleName={modalTitleName}>
      <ContactMoreInfoModalTabLayout currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} />
    </ModalLayout>
  );
};

export default ContactMoreInfoModalLayout;
