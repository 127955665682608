import { VideoPlayer } from 'common/view/components';
import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';

const SampleVideoComponent = () => {
  return (
    <FrameRow>
      <FrameCol width={4}>
        <h1>영상으로 이포넷을 만나보세요</h1>
        <h2>YTN줌인토크쇼 [석세스토리]5회</h2>
        <p className="subtitle">
          1995년, 컴퓨터 한 대를 두고 시작해
          <br />
          30년에 가까운 시간 동안 대한민국 IT 벤처 성장의 중심에 선 기업!
        </p>
        <p>
          기술력만으로 연 매출 200억 원대의 강소기업으로 성장한 이포넷! 받아온 도움의 손길을 아로새기고 이를 다시 사회에 환원하겠다는 마음으로 기업을 이끄는 가슴 따뜻한 IT 전문가 이수정 대표! 석세스토리의 다섯 번째 주인공 이수정 대표의
          성공스토리를 만나본다.
        </p>
        <CustomATag href="https://www.ytn.co.kr/replay/view.php?idx=239&amp;key=202303071139519819" target="_blank" className="btn btn-primary fs-14 btn-rounded">
          YTN 사이언스 출처 기사 보기 <i className="glyphicon icon icon-arrow-32"></i>
        </CustomATag>
      </FrameCol>
      <FrameCol width={8}>
        <VideoPlayer thumbNailSrc={'assets/img/custom/ytn_news.png'} thumbNailAlt={'Photo Title'} videoSrc={'https://www.youtube.com/embed/VIDEO_ID'} videoTitle={'Embedded Video'} />
      </FrameCol>
    </FrameRow>
  );
};

export default SampleVideoComponent;
