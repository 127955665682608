import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useRootUrl } from 'hooks/common/CommonHook';

import CareerHomeTabComponent from './CareerHomeTabComponent';
import CareerItTabComponent from './CareerItTabComponent';
import CareerLangTabComponent from './CareerLangTabComponent';

const CareerTabDetailComponent = () => {
  const { currentRootUrl: rootUrl } = useRootUrl();

  return (
    <FrameRow>
      <FrameCol>
        <div className="tab-content">
          <CareerItTabComponent rootUrl={rootUrl} />
          <CareerLangTabComponent rootUrl={rootUrl} />
          <CareerHomeTabComponent rootUrl={rootUrl} />
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default CareerTabDetailComponent;
