import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import FlowBannerLayout from 'common/view/layouts/slider/FlowBannerLayout';
import { useTranslation } from 'react-i18next';

const AdvantagesComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="mob-text-center">
        <FlowBannerLayout>
          <section className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall">
            <div className="divimage dzsparallaxer--target w-100" style={{ height: `120%`, backgroundImage: `url(assets/img/custom/lang_main_img.png)`, backgroundPosition: `bottom center` }}></div>
            <div className="container lang_infoWrap">
              <FrameContainer>
                <h1 className="mb-30">
                  <span>{t('langMain.advantages1')}</span>
                </h1>
                <p className="section-sub-title mb-40">
                  {t('langMain.advantages2')}
                  <br />
                  {t('langMain.advantages3')}
                </p>
              </FrameContainer>
              <ExpAnimationLayout>
                <FrameContainer>
                  <div className="service-box">
                    <div className="box-area text-center exp-animation" data-animation="express_b_show" data-delay="200">
                      <i className="fa fa-check-circle fColor-yellow fa-3x mb-20"></i>
                      <p className="title">
                        {t('langMain.advantages4')}
                        <br />
                        {t('langMain.advantages5')}
                      </p>
                      <p>{t('langMain.advantages15')}</p>
                    </div>
                    <div className="box-area text-center exp-animation" data-animation="express_b_show" data-delay="400">
                      <i className="fa fa-check-circle fColor-yellow fa-3x mb-20"></i>
                      <p className="title">
                        {t('langMain.advantages6')}
                        <br />
                        {t('langMain.advantages7')}
                      </p>
                      <p>{t('langMain.advantages8')}</p>
                    </div>
                    <div className="box-area text-center exp-animation" data-animation="express_b_show" data-delay="600">
                      <i className="fa fa-check-circle fColor-yellow fa-3x mb-20"></i>
                      <p className="title">
                        {t('langMain.advantages9')}
                        <br />
                        {t('langMain.advantages10')}
                      </p>
                      <p>{t('langMain.advantages11')}</p>
                    </div>
                    <div className="box-area text-center exp-animation" data-animation="express_b_show" data-delay="800">
                      <i className="fa fa-check-circle fColor-yellow fa-3x mb-20"></i>
                      <p className="title">
                        {t('langMain.advantages12')}
                        <br />
                        {t('langMain.advantages13')}
                      </p>
                      <p>{t('langMain.advantages14')}</p>
                    </div>
                  </div>
                </FrameContainer>
              </ExpAnimationLayout>
            </div>
          </section>
        </FlowBannerLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default AdvantagesComponent;
