import { onImgError } from 'util/UiUtil';

interface CustomImgTagProps {
  src: string;
  alt?: string;
  className?: string;
  width?: number | string;
  height?: number | string;
}
const CustomImgTag = ({ src, alt, className, width, height }: CustomImgTagProps) => {
  return <img className={className ?? ''} src={src} width={width ?? ''} height={height ?? ''} alt={alt ?? ''} onError={onImgError} />;
};

export default CustomImgTag;
