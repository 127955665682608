import { insertContact } from 'api/contact/ContactAPI';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactFormPVO } from 'types/contact/ContactFormPVO';
import { isSuccess } from 'util/ApiResponseUtil';
import { isEmailValid, isNameValid, isTelValid } from 'util/RegexUtil';
import { styleCursorPointer } from 'util/StyleUtil';

import ContactMoreInfoModalLayout from './modal/ContactMoreInfoModalLayout';

const defaultFormInfo = {
  brdNo: 8,
  ctgr1Cd: '',
  usrNm: '',
  email: '',
  phone: '',
  comment: '',
  info: false,
};

const ContactFormComponent = (props: { categoryValue: string }) => {
  const { t } = useTranslation();

  const modalId = 'contactModal';
  const modalTitleName = t('contact.form1');
  const [currentTabIdx, setCurrentTabIdx] = useState(0);
  const [formInfo, setFormInfo] = useState<ContactFormPVO>(defaultFormInfo);

  let ctgrCd = '';
  if (props.categoryValue === 'home') ctgrCd = '60';
  else if (props.categoryValue === 'it') ctgrCd = '70';
  else if (props.categoryValue === 'lang') ctgrCd = '80';
  else if (props.categoryValue === 'ir') ctgrCd = '100';

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name } = e.target;
    if (name in formInfo) {
      onInputChange(e);
    }

    setFormInfo((prev) => ({ ...prev, ['ctgr1Cd']: ctgrCd }));
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    let newValue: string | boolean = value;
    if (type === 'checkbox') newValue = (e.target as HTMLInputElement).checked;
    setFormInfo((prev) => ({ ...prev, [name]: newValue }));
  };

  const onSubmit = async () => {
    // 폼 유효성 검사
    if (formInfo.usrNm == '') return alert(t('contact.form2'));
    if (!isNameValid(formInfo.usrNm).res) return alert(t('contact.form3'));
    if (formInfo.email == '') return alert(t('contact.form4'));
    if (!isEmailValid(formInfo.email).res) return alert(t('contact.form5'));
    if (formInfo.phone == '') return alert(t('contact.form6'));
    if (!isTelValid(formInfo.phone).res) return alert(t('contact.form7'));
    if (formInfo.comment == '') return alert(t('contact.form8'));
    if (formInfo.info == false) return alert(t('contact.form9'));

    // 폼 전송
    await insertContact(formInfo)
      .then((res) => {
        if (isSuccess(res.code)) {
          alert(t('contact.form10'));
          setFormInfo(defaultFormInfo);
          gtag('event', 'conversion', { send_to: 'AW-789757267/VIKfCJuHmoEZENP6yvgC' });
        } else if (!res.response) {
          alert(t('contact.form11'));
        } else {
          alert(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(t('contact.form11'));
      });
  };

  return (
    <div className="smart-wrap">
      <div className="smart-forms smart-container">
        <form method="post" id="contactForm">
          <div className="form-body">
            <div className="frm-row">
              <label htmlFor="names" className="field-label colm colm2">
                {t('contact.form12')}
              </label>
              <div className="section colm colm10">
                <label className="field prepend-icon">
                  <input type="text" name="usrNm" id="usrNm" className="gui-input" placeholder={t('contact.form2')} value={formInfo.usrNm} onChange={onChange} maxLength={20} />
                </label>
              </div>
            </div>

            <div className="frm-row">
              <label htmlFor="email" className="field-label colm colm2">
                {t('contact.form13')}
              </label>
              <div className="section colm colm10">
                <label className="field prepend-icon">
                  <input type="email" name="email" id="email" className="gui-input" placeholder={t('contact.form14')} value={formInfo.email} onChange={onChange} maxLength={30} />
                </label>
              </div>
            </div>

            <div className="frm-row">
              <label htmlFor="phone" className="field-label colm colm2">
                {t('contact.form15')}
              </label>
              <div className="section colm colm10">
                <label className="field prepend-icon">
                  <input type="tel" name="phone" id="phone" className="gui-input" placeholder={t('contact.form6')} value={formInfo.phone} onChange={onChange} maxLength={20} />
                </label>
                <div className="section mt-10">
                  <span>
                    <i className="fa fa-exclamation-circle"></i> {t('contact.form16')}
                  </span>
                </div>
              </div>
            </div>

            <div className="frm-row">
              <label htmlFor="comment" className="field-label colm colm2">
                {t('contact.form17')}
              </label>
              <div className="section colm colm10">
                <label className="field prepend-icon">
                  <textarea className="gui-textarea" id="comment" name="comment" placeholder={t('contact.form18')} value={formInfo.comment} onChange={onChange} maxLength={5000}></textarea>
                </label>
              </div>
            </div>

            <div className="section text-center">
              <label className="field option option-yellow">
                <input type="checkbox" name="info" checked={formInfo.info} onChange={onChange} />
                <span className="checkbox"></span>
                {t('contact.form19')}
                <em className="small-text fine-grey">
                  <a data-toggle="modal" data-target="#contactModal" style={styleCursorPointer}>
                    {t('contact.form20')}
                  </a>
                </em>
              </label>
            </div>
          </div>
          <div className="form-footer pb-40">
            <button type="button" className="btn btn-primary w40p" onClick={onSubmit}>
              {t('contact.form21')}
            </button>
          </div>
        </form>
      </div>
      <ContactMoreInfoModalLayout modalId={modalId} modalTitleName={modalTitleName} currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} />
    </div>
  );
};

export default ContactFormComponent;
