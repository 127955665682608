import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import SlidesJSLayout from 'common/view/layouts/slider/SlidesJSLayout';
import { useRef } from 'react';

const sliderOption = {
  width: 550,
  height: 390,
  pagination: false,
  //navigation: true,
  play: {
    active: true,
    auto: true,
    interval: 5000,
    swap: true,
  },
};

const Sample2HeadlineComponent = () => {
  const customSliderProgressRef = useRef<HTMLElement>(null);
  const customSliderSlidesLogRefRef = useRef<HTMLDivElement>(null);

  return (
    <FrameRow>
      <FrameCol addClassName="bg-gray py-5">
        <FrameContainer>
          <FrameRow>
            <FrameCol width={6}>
              <SlidesJSLayout wrapperClassname="main-slides" sliderId="Nslides" sliderOption={sliderOption} noSlideController customProgressRef={customSliderProgressRef} customSlidesLogRef={customSliderSlidesLogRefRef}>
                <div className="newsWrap" style={{ background: 'url(assets/img/news/news_img1.png)' }}></div>
                <div className="newsWrap" style={{ background: 'url(assets/img/news/news_img2.png)' }}></div>
                <div className="newsWrap" style={{ background: 'url(assets/img/news/news_img3.png)' }}></div>
                <div className="newsWrap" style={{ background: 'url(assets/img/news/news_img4.png)' }}></div>
                <div className="newsWrap" style={{ background: 'url(assets/img/news/news_img5.png)' }}></div>
              </SlidesJSLayout>
            </FrameCol>
            <FrameCol width={6}>
              <div className="disWrap">
                <div className="discrption">
                  <h2>이포넷, ‘메디아크’와 의료 AI 서비스 및 기술 개발 위한 MOU 체결</h2>
                  <p className="news-detail">
                    IT 전문기업 ‘이포넷’이 의료 AI 플랫폼 기업 ‘메디아크(대표 이찬형)’와 의료 AI 서비스 및 기술 개발을 위한 양해각서(MOU)를 체결했습니다. 지난 9일 체결된 MOU는 이포넷의 주요 기술인 소프트웨어 시스템 구축.IT 전문기업
                    ‘이포넷’이 의료 AI 플랫폼 기업 ‘메디아크(대표 이찬형)’와 의료 AI 서비스 및 기술 개발을 위한 양해각서(MOU)를 체결했습니다. 지난 9일 체결된 MOU는 이포넷의 주요 기술인 소프트웨어 시스템 구축...
                  </p>
                  <p className="news-date">2023.04.02</p>
                </div>
              </div>
              <div className="contWarp">
                <div className="pg-bar">
                  <span ref={customSliderProgressRef} className="progress"></span>
                </div>
                <div ref={customSliderSlidesLogRefRef} id="slidesjs-log">
                  <span className="slidesjs-slide-number"></span> / <div className="totalCnt" style={{ display: 'inline' }}></div>
                </div>
                <div className="slides-navigation" id="slides-navigation"></div>
              </div>
            </FrameCol>
          </FrameRow>
        </FrameContainer>
      </FrameCol>
    </FrameRow>
  );
};

export default Sample2HeadlineComponent;
