import { useBodyId } from 'hooks/common/CommonHook';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const NewsLayout = () => {
  const { t } = useTranslation();

  useBodyId('news');

  return (
    <>
      <Helmet>
        <title>{t('views.layout1')}</title>
        <meta property="og:title" content="이포넷" />
        <meta property="og:description" content="기술로 세계를 섬기는 기업" />
      </Helmet>
      <Outlet />
    </>
  );
};

export default NewsLayout;
