import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const VisualAreaComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="col-xs-12">
        <ExpAnimationLayout>
          <section className="section-primar visual-area">
            <FrameContainer>
              <FrameRow>
                <FrameCol width={12}>
                  <h1>
                    <span>
                      {t('langMain.visual1')}
                      <br />
                      {t('langMain.visual11')}
                    </span>
                    <span className="pull-right">
                      <CustomATag href="/lang/service" className="btn btn-primary fs-14 btn-rounded">
                        {t('langMain.visual2')}
                        <i className="glyphicon icon icon-arrow-32 ml-5"></i>
                      </CustomATag>
                    </span>
                  </h1>
                </FrameCol>
                <FrameCol width={3}>
                  <CustomATag href="/lang/service#service1" state={{ offset: 70 }}>
                    <div className="visual-box-core v2 alternate-color exp-animation" data-animation="express_image_appear" data-delay="200">
                      <h2>{t('langMain.visual3')}</h2>
                      <div className="visual-box-core-detail">
                        <p className="visual-box-core-text">{t('langMain.visual4')}</p>
                      </div>
                      <div className="visual-icon-box p-5">
                        <CustomImgTag src="assets/img/custom/lang_icon_01.png" alt={t('langMain.visualAlt1')} />
                      </div>
                    </div>
                  </CustomATag>
                </FrameCol>
                <FrameCol width={3}>
                  <CustomATag href="/lang/service#service2" state={{ offset: 70 }}>
                    <div className="visual-box-core v2 alternate-color exp-animation" data-animation="express_image_appear" data-delay="400" style={{ padding: '35px' }}>
                      <h2>{t('langMain.visual5')}</h2>
                      <div className="visual-box-core-detail">
                        <p className="visual-box-core-text">{t('langMain.visual6')}</p>
                      </div>
                      <div className="visual-icon-box p-5">
                        <CustomImgTag src="assets/img/custom/lang_icon_02.png" alt={t('langMain.visualAlt1')} />
                      </div>
                    </div>
                  </CustomATag>
                </FrameCol>
                <FrameCol width={3}>
                  <CustomATag href="/lang/service#service3" state={{ offset: 70 }}>
                    <div className="visual-box-core v2 alternate-color exp-animation" data-animation="express_image_appear" data-delay="600">
                      <h2>{t('langMain.visual7')}</h2>
                      <div className="visual-box-core-detail">
                        <p className="visual-box-core-text">{t('langMain.visual8')}</p>
                      </div>
                      <div className="visual-icon-box p-5">
                        <CustomImgTag src="assets/img/custom/lang_icon_03.png" alt={t('langMain.visualAlt1')} />
                      </div>
                    </div>
                  </CustomATag>
                </FrameCol>
                <FrameCol width={3}>
                  <CustomATag href="/lang/service#service4" state={{ offset: 70 }}>
                    <div className="visual-box-core v2 alternate-color exp-animation" data-animation="express_image_appear" data-delay="800">
                      <h2>{t('langMain.visual9')}</h2>
                      <div className="visual-box-core-detail">
                        <p className="visual-box-core-text">{t('langMain.visual10')}</p>
                      </div>
                      <div className="visual-icon-box p-5">
                        <CustomImgTag src="assets/img/custom/lang_icon_04.png" alt={t('langMain.visualAlt1')} />
                      </div>
                    </div>
                  </CustomATag>
                </FrameCol>
              </FrameRow>
            </FrameContainer>
            <div className="visual-bg">
              <video className="bg-video__content" autoPlay muted loop>
                <source src="assets/img/custom/tng_main.mp4" type="video/mp4" />
              </video>
            </div>
          </section>
        </ExpAnimationLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default VisualAreaComponent;
