import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const LangMainLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('views.layout1')}</title>
        <meta property="og:title" content="이포넷" />
        <meta property="og:description" content="기술로 세계를 섬기는 기업" />
        <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-789757267/Q2MpCJaeoesYENP6yvgC'});
          `}
        </script>
      </Helmet>
      <Outlet />
    </>
  );
};

export default LangMainLayout;
