import SectionLayout from 'common/view/layouts/section/SectionLayout';
import { useRootUrl } from 'hooks/common/CommonHook';
import CareerHeadComponent from 'views/common/children/career/page/CareerHeadComponent';
import CareerTabComponent from 'views/common/children/career/page/CareerTabComponent';
import CareerTabDetailComponent from 'views/common/children/career/page/CareerTabDetailComponent';
import CareerWelfareBenefitInfoComponent from 'views/common/children/career/page/CareerWelfareBenefitInfoComponent';

const CareerPage = () => {
  const { currentRootUrl: rootUrl } = useRootUrl();

  return (
    <>
      <SectionLayout addSectionClassName="main-color pb-0" addContianerClassName="fluid">
        <CareerHeadComponent />
      </SectionLayout>
      <SectionLayout>
        <CareerWelfareBenefitInfoComponent />
      </SectionLayout>
      <SectionLayout addSectionClassName="bg-gray pb-0 b-bordered" addContianerClassName="fluid">
        <CareerTabComponent rootUrl={rootUrl} />
      </SectionLayout>
      <SectionLayout>
        <CareerTabDetailComponent />
      </SectionLayout>
    </>
  );
};

export default CareerPage;
