import { useScript } from 'hooks/common/ScriptHook';
import { useLayoutEffect } from 'react';
import { CommonProps } from 'types/common/PropsTypes';

interface ExpAnimationLayoutProps extends CommonProps {
  dataAnimation?: number;
  dataDelay?: number;
}

const ExpAnimationLayout = ({ children }: ExpAnimationLayoutProps) => {
  const { importScript } = useScript();

  useLayoutEffect(() => {
    importScript(['js/theme']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return children;
};

export default ExpAnimationLayout;
