import SectionLayout from 'common/view/layouts/section/SectionLayout';
import { useBodyId } from 'hooks/common/CommonHook';

import AdvantagesComponent from './AdvantagesComponent';
import BlogComponent from './BlogComponent';
import CounterComponent from './CounterComponent';
import PartnerComponent from './PartnerComponent';
import VisualAreaComponent from './VisualAreaComponent';

const LangMainPage = () => {
  useBodyId('lang_main');

  return (
    <>
      <section>
        <VisualAreaComponent />
      </section>
      <SectionLayout addSectionClassName="counter-primary" addContianerClassName="fluid">
        <CounterComponent />
      </SectionLayout>
      <SectionLayout addSectionClassName="counter-primary" addContianerClassName="container-fluid">
        <PartnerComponent />
      </SectionLayout>
      <SectionLayout addSectionClassName="counter-primary" addContianerClassName="container-fluid">
        <AdvantagesComponent />
      </SectionLayout>
      <SectionLayout addSectionClassName="b-bordered lang_blogWrap">
        <BlogComponent />
        {/* <BlogTest /> */}
      </SectionLayout>
    </>
  );
};

export default LangMainPage;
