import { useSlidesJs, useSlidesJsProps } from 'hooks/common/SliderHook';

interface SlidesJSLayoutProps extends useSlidesJsProps {
  sliderId: string;
  sliderClassName?: string;
  wrapperClassname?: string;
  controllerLabelName?: string;
  sliderOption: object;
  noSlidesNavInside?: boolean;
  noSlideController?: boolean;
}

const SlidesJSLayout = ({ sliderId: id, sliderClassName, wrapperClassname, controllerLabelName, sliderOption, noSlidesNavInside, noSlideController, customProgressRef, customSlidesLogRef, children }: SlidesJSLayoutProps) => {
  const { progressRef, slidesLogRef } = useSlidesJs({ id, sliderOption, customProgressRef, customSlidesLogRef });

  return (
    <div className={wrapperClassname ?? ''}>
      <div id={id} className={sliderClassName ?? id}>
        {children}
      </div>
      {/* 슬라이드 컨트롤 */}
      {noSlideController || (
        <div className="contWarp">
          {controllerLabelName && <span className="tit">{controllerLabelName}</span>}
          <div className="pg-bar">
            <span ref={progressRef} className="progress"></span>
          </div>
          <div ref={slidesLogRef} id="slidesjs-log">
            <span className="slidesjs-slide-number">1</span> / <div className="totalCnt" style={{ display: 'inline' }}></div>
          </div>
          {noSlidesNavInside || <div className="slides-navigation" id="slides-navigation"></div>}
        </div>
      )}
    </div>
  );
};

export default SlidesJSLayout;
