import SectionLayout from 'common/view/layouts/section/SectionLayout';

import Sample2DetailContentsComponent from './SampleNewsDetailContentsComponent';

const SampleNewsDetailPage = () => {
  return (
    <SectionLayout addSectionClassName="b-bordered">
      <Sample2DetailContentsComponent />
    </SectionLayout>
  );
};

export default SampleNewsDetailPage;
