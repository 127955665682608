import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_THEME, FRAME_SIZE } from 'types/common/PropsTypes';

export interface footerButtonDefProps {
  colorTheme?: COLOR_THEME;
  size?: FRAME_SIZE;
  dontDismiss?: boolean;
  icon?: string;
  labelName?: string;
}

interface ModalFooterLayoutProps {
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  footerButtonDef?: footerButtonDefProps[];
}

const ModalFooterLayout = ({ footerButtonDef, setIsOpen }: ModalFooterLayoutProps) => {
  const { t } = useTranslation();

  const buttonDef = footerButtonDef ?? [{}];

  return (
    <div className="modal-footer">
      {buttonDef.map((ele, idx) => {
        return (
          <button key={`ModalFooterLayout${idx}`} type="button" className={`btn btn-${ele.colorTheme ?? 'primary'} btn-${ele.size ?? 'md'}`} data-dismiss={ele.dontDismiss ? '' : 'modal'} onClick={() => setIsOpen?.(false)}>
            {ele.icon && <i className={`fa fa-${ele.icon ?? 'check'}`}></i>}
            {ele.labelName ?? t('modal.footer1')}
          </button>
        );
      })}
    </div>
  );
};

export default React.memo(ModalFooterLayout);
