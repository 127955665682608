import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import SlidesJSLayout from 'common/view/layouts/slider/SlidesJSLayout';

const sliderOption = {
  width: 940,
  height: 528,
  pagination: false,
  play: {
    active: true,
    auto: true,
    interval: 8000,
    swap: true,
    pauseOnHover: true,
  },
};
const SampleMainComponent = () => {
  return (
    <FrameRow>
      <FrameCol addClassName="col-xs-12">
        <SlidesJSLayout wrapperClassname="main-slides" sliderId="Mslides" controllerLabelName="E4NET" sliderOption={sliderOption}>
          <div>
            <div className="slideWrap">
              <div className="tngsvc">
                <a href="#">
                  {/* 언어서비스 배너 바로가기 링크 */}
                  <div className="slide-01">
                    <div className="discrption">
                      <p className="dis-tit">언어서비스</p>
                      <p className="dis-subtit">
                        전문 번·통역
                        <br />
                        다국어 번역
                        <br />
                        AI번역
                      </p>
                      <p className="golink">
                        <i className="fa fa-arrow-circle-right fa-3x"></i>
                      </p>
                    </div>
                    <CustomImgTag src="assets/img/slider/slide_tng01.png" alt="언어서비스" />
                  </div>
                  <div className="bg-video">
                    <div className="discrption">
                      <p className="dis-tit">
                        세계가 인정한
                        <br />
                        전문 번역 기업
                      </p>
                      <p className="dis-subtit">언어서비스</p>
                      <p className="golink">
                        <i className="fa fa-arrow-circle-right fa-3x"></i>
                      </p>
                    </div>
                    <video className="bg-video__content" autoPlay muted loop>
                      <source src="assets/img/main/e4net_tng_vod.mp4" type="video/mp4" />
                    </video>
                  </div>
                </a>
              </div>

              <div className="itsvc">
                {/* IT서비스 배너 바로가기 링크 */}
                <a href="#">
                  <div className="slide-02">
                    <div className="discrption">
                      <p className="dis-tit">IT서비스</p>
                      <p className="dis-subtit">
                        금융·공공 시스템 통합
                        <br />
                        지불결제 서비스
                        <br />
                        대내외연계 솔루션
                      </p>
                      <p className="golink">
                        <i className="fa fa-arrow-circle-right fa-3x"></i>
                      </p>
                    </div>
                    <CustomImgTag src="assets/img/slider/slide_snc01.png" alt="IT서비스" />
                  </div>
                  <div className="bg-video">
                    <div className="discrption">
                      <p className="dis-tit">
                        고객의 서비스 가치를 높이는
                        <br />
                        시스템 통합(SI) 전문 기업
                      </p>
                      <p className="dis-subtit">IT서비스</p>
                      <p className="golink">
                        <i className="fa fa-arrow-circle-right fa-3x"></i>
                      </p>
                    </div>
                    <video className="bg-video__content" autoPlay muted loop>
                      <source src="assets/img/main/e4net_it_vod.mp4" type="video/mp4" />
                    </video>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* 언어서비스 */}
          <div className="slide-bg1">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>언어서비스 사업본부</label>
                  <p className="dis-tit">
                    분야·언어별 전문 번역가와
                    <br />
                    엄격한 품질 관리로
                    <br />
                    최고의 번역 서비스를 제공합니다.
                  </p>
                  <p className="dis-subtit">전문 번역</p>
                  <CustomATag href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img01.png" className="dis-img" alt="언어사업부" />
              </div>
            </div>
          </div>
          <div className="slide-bg2">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>언어서비스 사업본부</label>
                  <p className="dis-tit">
                    글로벌 네트워크를 통해
                    <br />
                    전 세계 77개국어로
                    <br />
                    다국어 번역을 제공합니다
                  </p>
                  <p className="dis-subtit">다국어 번역</p>
                  <a href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img02.png" className="dis-img" alt="언어사업부" />
              </div>
            </div>
          </div>
          <div className="slide-bg3">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>언어서비스 사업본부</label>
                  <p className="dis-tit">
                    최신 AI 번역 기술을 활용하여
                    <br />
                    경제적인 비용으로 신속하게
                    <br />
                    번역 서비스를 제공합니다
                  </p>
                  <p className="dis-subtit">AI 번역</p>
                  <a href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img03.png" className="dis-img" alt="언어사업부" />
              </div>
            </div>
          </div>
          <div className="slide-bg4">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>언어서비스 사업본부</label>
                  <p className="dis-tit">해외에서 인정한 전문 번역 기업</p>
                  <p className="dis-detail">
                    국제 번역 표준 ISO 17100 · ISO 18587 인증
                    <br />
                    2020-2023 4년 연속 아시아 태평양 지역 Top 30위
                    <br />
                    2022 Slator 선정 글로벌 Top 132위
                    <br />
                    2022 3백만불 수출의 탑 달성
                  </p>
                  <p className="dis-subtit">인증 &amp; 멤버십</p>
                  <a href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img04.png" className="dis-img" alt="언어사업부" />
              </div>
            </div>
          </div>

          {/* IT 서비스 */}
          <div className="slide-bg5">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>IT서비스 사업본부</label>
                  <p className="dis-tit">
                    디지털플랫폼 전환과
                    <br />
                    고도화를 위한 최적의 시스템
                    <br />
                    통합 서비스를 제공합니다
                  </p>
                  <p className="dis-subtit">금융 시스템 통합</p>
                  <a href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img05.png" className="dis-img" alt="IT서비스" />
              </div>
            </div>
          </div>
          <div className="slide-bg6">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>IT서비스 사업본부</label>
                  <p className="dis-tit">
                    27년간 다양한 성공사례를 통해
                    <br />
                    입증된 최고 수준의 전문 시스템
                    <br />
                    통합 서비스를 제공합니다
                  </p>
                  <p className="dis-subtit">공공 시스템 통합</p>
                  <a href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img06.png" className="dis-img" alt="IT서비스" />
              </div>
            </div>
          </div>
          <div className="slide-bg7">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>IT서비스 사업본부</label>
                  <p className="dis-tit">
                    디지털 금융에 가치를 더하고
                    <br />
                    고객 금융생활을
                    <br />더 편리하게 지원합니다
                  </p>
                  <p className="dis-subtit">지불결제 서비스</p>
                  <a href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img07.png" className="dis-img" alt="IT서비스" />
              </div>
            </div>
          </div>
          <div className="slide-bg8">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>IT서비스 사업본부</label>
                  <p className="dis-tit">
                    맞춤형 멤버십 서비스로
                    <br />
                    충성고객 확대와 고객 이탈
                    <br />
                    최소화를 지원합니다
                  </p>
                  <p className="dis-subtit">멤버십/포인트</p>
                  <a href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img08.png" className="dis-img" alt="IT서비스" />
              </div>
            </div>
          </div>
          <div className="slide-bg9">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>IT서비스 사업본부</label>
                  <p className="dis-tit">
                    고객 데이터 목적지까지
                    <br />
                    안정적인 송·수신 서비스를
                    <br />
                    지원합니다
                  </p>
                  <p className="dis-subtit">대외 연계 솔루션</p>
                  <a href="#" target="_blank" className="btn btn-primary fs-12 btn-rounded">
                    자세히 보기 <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img09.png" className="dis-img" alt="IT서비스" />
              </div>
            </div>
          </div>
        </SlidesJSLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default SampleMainComponent;
