import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow, FrameSection } from 'common/view/layouts/frames';
import FramePageInner from 'common/view/layouts/frames/FramePageInner';
import SectionLayout from 'common/view/layouts/section/SectionLayout';
import SlidesJSLayout from 'common/view/layouts/slider/SlidesJSLayout';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { stylePre } from 'util/StyleUtil';

const ServiceTabDetailComponent = () => {
  const { t } = useTranslation();

  const customSliderProgressRef = useRef<HTMLElement>(null);
  const customSliderSlidesLogRefRef = useRef<HTMLDivElement>(null);
  const sliderOption = {
    width: 590,
    height: 390,
    pagination: false,
    play: {
      active: true,
      auto: true,
      interval: 5000,
      swap: true,
    },
  };

  return (
    <FramePageInner>
      <div id="service1">
        <SectionLayout>
          <FrameRow>
            <FrameCol addClassName="text-center mb-0">
              <h2 className="mb-20">
                <span>{t('langService.detailPro1')}</span>
              </h2>
              <p className="section-sub-title mb-50">
                {t('langService.detailPro2')}
                <br />
                {t('langService.detailPro3')}
              </p>
            </FrameCol>
            <FrameCol>
              <FrameRow addClassName="langitem-box-wrap">
                <FrameCol width={6} addClassName="langitem-box-col">
                  <div className="langitem-box-inner">
                    <div className="langitem-box exp-animation" data-animation="express_pop" data-delay="0">
                      <p className="number">01</p>
                      <div className="langitem-box-text" style={stylePre}>
                        <p className="title">{t('langService.detailPro4')}</p>
                        <ul>
                          <li>{t('langService.detailPro5')}</li>
                          <li>{t('langService.detailPro6')}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </FrameCol>
                <FrameCol width={6} addClassName="langitem-box-col">
                  <div className="langitem-box-inner">
                    <div className="langitem-box exp-animation" data-animation="express_pop" data-delay="200">
                      <p className="number">02</p>
                      <div className="langitem-box-text">
                        <p className="title">{t('langService.detailPro7')}</p>
                        <ul>
                          <li>{t('langService.detailPro8')}</li>
                          <li>{t('langService.detailPro9')}</li>
                          <li>{t('langService.detailPro10')}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </FrameCol>
                <FrameCol width={6} addClassName="langitem-box-col">
                  <div className="langitem-box-inner">
                    <div className="langitem-box exp-animation" data-animation="express_pop" data-delay="400">
                      <p className="number">03</p>
                      <div className="langitem-box-text">
                        <p className="title">{t('langService.detailPro11')}</p>
                        <ul>
                          <li style={stylePre}>{t('langService.detailPro12')}</li>
                          <li style={stylePre}>{t('langService.detailPro13')}</li>
                          <li>
                            {t('langService.detailPro14')}
                            <br />
                            {t('langService.detailPro15')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </FrameCol>
                <FrameCol width={6} addClassName="langitem-box-col">
                  <div className="langitem-box-inner">
                    <div className="langitem-box exp-animation" data-animation="express_pop" data-delay="600">
                      <p className="number">04</p>
                      <div className="langitem-box-text">
                        <p className="title">{t('langService.detailPro16')}</p>
                        <ul>
                          <li>{t('langService.detailPro17')}</li>
                          <li>{t('langService.detailPro18')}</li>
                          <li style={stylePre}>{t('langService.detailPro19')}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </FrameCol>
              </FrameRow>
            </FrameCol>
          </FrameRow>
        </SectionLayout>
        <SectionLayout addSectionClassName="bg-lite-orange">
          <FrameRow>
            <FrameCol width={6}>
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/lang_svc_ico_01.png" alt={t('langService.detailAlt1')} />
                  <span>
                    {t('langService.detailCategory1')}
                    <br />
                    {t('langService.detailCategory2')}
                  </span>
                </div>
                <div className="textWrap">
                  <p className="title">{t('langService.detailCategory3')}</p>
                  <p className="discrption">{t('langService.detailCategory4')}</p>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={6}>
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/lang_svc_ico_02.png" alt={t('langService.detailAlt1')} />
                  <span>
                    {t('langService.detailCategory5')}
                    <br />
                    {t('langService.detailCategory6')}
                  </span>
                </div>
                <div className="textWrap" style={stylePre}>
                  <p className="title">{t('langService.detailCategory7')}</p>
                  <p className="discrption">{t('langService.detailCategory8')}</p>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={6}>
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/lang_svc_ico_03.png" alt={t('langService.detailAlt1')} />
                  <span>{t('langService.detailCategory9')}</span>
                </div>
                <div className="textWrap">
                  <p className="title">{t('langService.detailCategory10')}</p>
                  <p className="discrption">{t('langService.detailCategory11')}</p>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={6}>
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/lang_svc_ico_04.png" alt={t('langService.detailAlt1')} />
                  <span>{t('langService.detailCategory12')}</span>
                </div>
                <div className="textWrap">
                  <p className="title">{t('langService.detailCategory13')}</p>
                  <p className="discrption">{t('langService.detailCategory14')}</p>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={6}>
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/lang_svc_ico_05.png" alt={t('langService.detailAlt1')} />
                  <span>{t('langService.detailCategory15')}</span>
                </div>
                <div className="textWrap">
                  <p className="title">{t('langService.detailCategory16')}</p>
                  <p className="discrption">{t('langService.detailCategory17')}</p>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={6}>
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/lang_svc_ico_06.png" alt={t('langService.detailAlt1')} />
                  <span>
                    {t('langService.detailCategory18')}
                    <br />
                    {t('langService.detailCategory19')}
                  </span>
                </div>
                <div className="textWrap">
                  <p className="title">{t('langService.detailCategory20')}</p>
                  <p className="discrption">{t('langService.detailCategory21')}</p>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={6}>
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/lang_svc_ico_07.png" alt={t('langService.detailAlt1')} />
                  <span>{t('langService.detailCategory22')}</span>
                </div>
                <div className="textWrap">
                  <p className="title">{t('langService.detailCategory23')}</p>
                  <p className="discrption">{t('langService.detailCategory24')}</p>
                </div>
              </div>
            </FrameCol>
            <FrameCol width={6}>
              <div className="service-box2">
                <div className="icon">
                  <CustomImgTag src="assets/img/icons/lang_svc_ico_08.png" alt={t('langService.detailAlt1')} />
                  <span>
                    {t('langService.detailCategory25')}
                    <br />
                    {t('langService.detailCategory26')}
                  </span>
                </div>
                <div className="textWrap">
                  <p className="title">{t('langService.detailCategory27')}</p>
                  <p className="discrption">{t('langService.detailCategory28')}</p>
                </div>
              </div>
            </FrameCol>
          </FrameRow>
        </SectionLayout>
      </div>
      <div id="service2">
        <FrameSection>
          <div className="container mb-50">
            <FrameCol addClassName="mt-50">
              <h2 className="mb-30">
                <span>{t('langService.detailMulti1')}</span>
              </h2>
              <p className="section-sub-title text-center mb-30">
                {t('langService.detailMulti2')}
                <br />
                {t('langService.detailMulti3')}
              </p>
            </FrameCol>
            <FrameRow addClassName="mt-50">
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="200">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_09.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p>
                      <span>
                        {t('langService.detailMulti4')}
                        <br />
                        {t('langService.detailMulti5')}
                      </span>
                      {t('langService.detailMulti51') != '' ? <br /> : ''}
                      {t('langService.detailMulti51')}
                    </p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="400">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_10.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p>
                      <span>{t('langService.detailMulti6')}</span>
                      <br />
                      {t('langService.detailMulti7')}
                    </p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="600">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_11.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p>
                      <span>{t('langService.detailMulti8')}</span>
                      <br />
                      {t('langService.detailMulti9')}
                      <br />
                      {t('langService.detailMulti10')}
                    </p>
                  </div>
                </div>
              </FrameCol>
            </FrameRow>
          </div>
        </FrameSection>
        <section className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall">
          <div className="divimage dzsparallaxer--target w-100" style={{ height: '120%', backgroundImage: 'url(assets/img/custom/lang_service_img01.png)', backgroundPosition: 'bottom center' }}></div>
          <div className="container pt-80 pb-80">
            <FrameRow>
              <FrameCol>
                <h2 className="mb-30">
                  <span>{t('langService.detailLang1')}</span>
                </h2>
                <div className="lang_sp_Wrap">
                  <div>
                    <p className="title">Europe</p>
                    <ul>
                      <li>Danish</li>
                      <li>Bosnian</li>
                      <li>Dutch</li>
                      <li>Finnish</li>
                      <li>French</li>
                      <li>Spanish</li>
                    </ul>
                    <ul>
                      <li>German</li>
                      <li>Greek</li>
                      <li>Irish</li>
                      <li>Scottish Gaelic</li>
                      <li>Italian</li>
                      <li>Norwegian</li>
                    </ul>
                    <ul>
                      <li>Bokmal</li>
                      <li>Portuguese</li>
                      <li>Swedish</li>
                    </ul>
                  </div>
                  <div>
                    <p className="title">Eastern Europe</p>
                    <ul>
                      <li>Albanian</li>
                      <li>Bulgarian</li>
                      <li>Croatian</li>
                      <li>Czech</li>
                      <li>Estonian</li>
                    </ul>
                    <ul>
                      <li>Latvian</li>
                      <li>Lithuanian</li>
                      <li>Macedonian</li>
                      <li>Polish</li>
                      <li>Romanian</li>
                    </ul>
                    <ul>
                      <li>Russian</li>
                      <li>Serbian</li>
                      <li>Slovak</li>
                      <li>Slovenian</li>
                      <li>Ukrainian</li>
                    </ul>
                  </div>
                  <div>
                    <p className="title">North America</p>
                    <ul>
                      <li>English</li>
                      <li>Canadian French</li>
                    </ul>
                  </div>
                </div>
                <div className="lang_sp_Wrap2">
                  <div style={{ flex: '.5' }}>
                    <p className="title">Africa</p>
                    <ul>
                      <li>Afrikaans</li>
                      <li>Amharic</li>
                      <li>Hausa</li>
                      <li>Swahili</li>
                    </ul>
                  </div>
                  <div style={{ flex: '.8' }}>
                    <p className="title">Middle East</p>
                    <ul>
                      <li>Arabic</li>
                      <li>Farsi</li>
                      <li>Hebrew</li>
                      <li>Kurdish</li>
                      <li>Turkish</li>
                    </ul>
                  </div>
                  <div style={{ flex: '2.5' }}>
                    <p className="title">Asia</p>
                    <ul>
                      <li>Assamese</li>
                      <li>Bengali</li>
                      <li>Burmese</li>
                      <li>Traditional Chinese</li>
                      <li>Simplified Chinese</li>
                      <li>Gujarati</li>
                      <li>Hindi</li>
                      <li>Indonesian</li>
                    </ul>
                    <ul>
                      <li>Cantonese</li>
                      <li>Japanese</li>
                      <li>Khmer</li>
                      <li>Kazakh</li>
                      <li>Lao</li>
                      <li>Malay</li>
                      <li>Malayalam</li>
                      <li>Marathi</li>
                      <li>Mongolian</li>
                      <li>Oriya</li>
                    </ul>
                    <ul>
                      <li>Sinhala</li>
                      <li>Tamil</li>
                      <li>Telugu</li>
                      <li>Thai</li>
                      <li>Urdu</li>
                      <li>Uzbek</li>
                      <li>Vietnamese</li>
                    </ul>
                  </div>
                  <div>
                    <p className="title">Latin America</p>
                    <ul>
                      <li>Brazilian Portuguese</li>
                      <li>Latin American Spanish</li>
                    </ul>
                  </div>
                </div>
              </FrameCol>
            </FrameRow>
          </div>
        </section>
      </div>
      <div id="service3">
        <FrameSection>
          <div className="container mb-40">
            <FrameCol addClassName="mt-40">
              <h2 className="mb-30">
                <span>{t('langService.detailInterpretation1')}</span>
              </h2>
              <p className="section-sub-title text-center mb-30">
                {t('langService.detailInterpretation2')}
                <br />
                {t('langService.detailInterpretation3')}
                <br />
                {t('langService.detailInterpretation4')}
                <br />
                {t('langService.detailInterpretation5')}
              </p>
            </FrameCol>
            <FrameRow addClassName="mt-50">
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation " data-animation="express_b_show" data-delay="200">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_12.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p className="title">{t('langService.detailInterpretation6')}</p>
                    <p>
                      {t('langService.detailInterpretation7')}
                      {t('langService.detailInterpretation8') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation8')}
                      {t('langService.detailInterpretation9') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation9')}
                      {t('langService.detailInterpretation10') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation10')}
                      {t('langService.detailInterpretation11') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation11')}
                      {t('langService.detailInterpretation12') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation12')}
                    </p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="400">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_13.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p className="title">{t('langService.detailInterpretation13')}</p>
                    <p>
                      {t('langService.detailInterpretation14')}
                      {t('langService.detailInterpretation15') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation15')}
                      {t('langService.detailInterpretation16') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation16')}
                      {t('langService.detailInterpretation17') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation17')}
                    </p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="600">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_14.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p className="title">{t('langService.detailInterpretation18')}</p>
                    <p>
                      {t('langService.detailInterpretation19')}
                      {t('langService.detailInterpretation20') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation20')}
                      {t('langService.detailInterpretation21') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation21')}
                      {t('langService.detailInterpretation22') != '' ? <br /> : ''}
                      {t('langService.detailInterpretation22')}
                    </p>
                  </div>
                </div>
              </FrameCol>
            </FrameRow>
          </div>
        </FrameSection>
        <FrameSection addClassName="bg-lite-orange">
          <div className="container mb-40">
            <FrameRow addClassName="mt-40">
              <FrameCol width={6}>
                <div className="image-box">
                  <CustomImgTag src="assets/img/custom/lang_svc_img01.png" alt={t('langService.detailAlt2')} />
                </div>
              </FrameCol>
              <FrameCol width={6}>
                <div className="listWrap">
                  <ul>
                    <li>{t('langService.detailBanner1')}</li>
                    <li>{t('langService.detailBanner2')}</li>
                    <li>{t('langService.detailBanner3')}</li>
                    <li>{t('langService.detailBanner4')}</li>
                    <li>{t('langService.detailBanner5')}</li>
                    <li>{t('langService.detailBanner6')}</li>
                  </ul>
                </div>
              </FrameCol>
              <FrameCol width={6}>
                <div className="svctxtWrap mt-40">
                  <p className="title">{t('langService.detailBanner7')}</p>
                  <p className="discrption">{t('langService.detailBanner8')}</p>
                  <p className="title mt-40">{t('langService.detailBanner9')}</p>
                  <p className="discrption">{t('langService.detailBanner10')}</p>
                </div>
              </FrameCol>
              <FrameCol width={6}>
                {/* BEST 포트폴리오 */}
                <div className="main-slides mt-40">
                  <SlidesJSLayout sliderId="Nslides" wrapperClassname="main-slides" sliderOption={sliderOption} noSlideController customProgressRef={customSliderProgressRef} customSlidesLogRef={customSliderSlidesLogRefRef}>
                    <div className="newsWrap" style={{ background: 'url(assets/img/custom/lang_svc_img05.png)' }}></div>
                    <div className="newsWrap" style={{ background: 'url(assets/img/custom/lang_svc_img06.png)' }}></div>
                    <div className="newsWrap" style={{ background: 'url(assets/img/custom/lang_svc_img07.png)' }}></div>
                    <div className="newsWrap" style={{ background: 'url(assets/img/custom/lang_svc_img08.png)' }}></div>
                    <div className="newsWrap" style={{ background: 'url(assets/img/custom/lang_svc_img09.png)' }}></div>
                  </SlidesJSLayout>
                </div>
                <div className="contWarp mgb-30">
                  <div className="pg-bar">
                    <span ref={customSliderProgressRef} className="progress" style={{ width: '10%' }}></span>
                  </div>
                  <div ref={customSliderSlidesLogRefRef} id="slidesjs-log">
                    <span className="slidesjs-slide-number">1</span> / 5
                  </div>
                  <div className="slides-navigation" id="slides-navigation"></div>
                </div>
              </FrameCol>
            </FrameRow>
          </div>
        </FrameSection>
      </div>
      <div id="service4">
        <FrameSection>
          <div className="container mb-40">
            <div className="row mt-40 exp-animation " data-animation="express_b_show" data-delay="200">
              <FrameCol>
                <h2 className="mb-30">
                  <span>{t('langService.detailAi1')}</span>
                </h2>
                <p className="section-sub-title text-center mb-50">
                  {t('langService.detailAi2')}
                  <br />
                  {t('langService.detailAi3')}
                </p>
              </FrameCol>
              <FrameCol width={6}>
                <div className="image-box">
                  <CustomImgTag src="assets/img/custom/lang_svc_img10.png" alt={t('langService.detailAlt2')} />
                </div>
              </FrameCol>
              <FrameCol width={6}>
                <div className="service-box3">
                  <div className="icon">
                    <CustomImgTag src="assets/img/icons/lang_svc_ico_15.png" alt={t('langService.detailAlt1')} />
                  </div>
                  <div className="textWrap">
                    <p className="title">{t('langService.detailAi4')}</p>
                    <p>{t('langService.detailAi5')}</p>
                  </div>
                </div>
                <div className="service-box3">
                  <div className="icon">
                    <CustomImgTag src="assets/img/icons/lang_svc_ico_16.png" alt={t('langService.detailAlt1')} />
                  </div>
                  <div className="textWrap">
                    <p className="title">{t('langService.detailAi6')}</p>
                    <p>{t('langService.detailAi7')}</p>
                  </div>
                </div>
              </FrameCol>
            </div>
          </div>
          <div className="container mt-80">
            <FrameCol>
              <h2 className="mb-30">
                <div className="mb-2">
                  <Link to="https://aitrans.e4net.net" target="_blank" title={t('langService.detailFuture0')}>
                    <CustomImgTag src="assets/img/icons/image_aitrans.png" alt="AITrans" className="w170"></CustomImgTag>
                  </Link>
                </div>
                <span>{t('langService.detailFuture1')}</span>
              </h2>
            </FrameCol>
            <FrameRow addClassName="mt-50">
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation " data-animation="express_b_show" data-delay="200">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_17.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p className="title">{t('langService.detailFuture2')}</p>
                    <p className="min-h-220">
                      {t('langService.detailFuture3')}
                      {t('langService.detailFuture4') != '' ? <br /> : ''}
                      {t('langService.detailFuture4')}
                      {t('langService.detailFuture5') != '' ? <br /> : ''}
                      {t('langService.detailFuture5')}
                    </p>
                    <ul className="detail">
                      <li>{t('langService.detailFuture6')}</li>
                      <li>{t('langService.detailFuture7')}</li>
                      <li>{t('langService.detailFuture8')}</li>
                    </ul>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="400">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_18.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p className="title">{t('langService.detailFuture10')}</p>
                    <p className="min-h-220">
                      {t('langService.detailFuture11')}
                      <br />
                      {t('langService.detailFuture12')}
                    </p>
                    <ul className="detail">
                      <li>{t('langService.detailFuture14')}</li>
                      <li>{t('langService.detailFuture15')}</li>
                      <li>{t('langService.detailFuture17')}</li>
                    </ul>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={4} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="service-box4 exp-animation" data-animation="express_b_show" data-delay="600">
                    <div className="icon">
                      <CustomImgTag src="assets/img/icons/lang_svc_ico_19.png" alt={t('langService.detailAlt1')} />
                    </div>
                    <p className="title">{t('langService.detailFuture18')}</p>
                    <p className="min-h-220">
                      {t('langService.detailFuture19')}
                      {t('langService.detailFuture20') != '' ? <br /> : ''}
                      {t('langService.detailFuture20')}
                    </p>
                    <ul className="detail">
                      <li>{t('langService.detailFuture21')}</li>
                      <li>
                        {t('langService.detailFuture22')}
                        {t('langService.detailFuture23') != '' ? <br /> : ''}
                        {t('langService.detailFuture23')}
                      </li>
                    </ul>
                  </div>
                </div>
              </FrameCol>
            </FrameRow>
            <div className="col-md-12 round-30 bg-middle-orange p-40 mt-50 exp-animation" data-animation="express_b_show" data-delay="800">
              <FrameRow addClassName="p-0">
                <FrameCol width={2}>
                  <CustomImgTag src="assets/img/icons/iso.png" alt={t('langService.detailAlt3')} />
                </FrameCol>
                <FrameCol width={10} addClassName="mob-text-center">
                  <p className="fw-7 fs-20 fColor-black mt-15">{t('langService.detailFuture24')}</p>
                  <p className="mb-0">{t('langService.detailFuture25')}</p>
                </FrameCol>
              </FrameRow>
            </div>
            {/*20240229 이정원이사님 삭제요청*/}
            {/* <div className="col-md-12 text-center round-30 bg-orange p-40 mt-50 exp-animation" data-animation="express_b_show" data-delay="1000">
              <p className="fw-7 fs-18 fColor-white mb-0">
                <i className="fa fa-exclamation-circle fa-4x"></i>
                <br />
                {t('langService.detailFuture26')}
                <br />
                {t('langService.detailFuture28')}
              </p>
            </div> */}
          </div>
        </FrameSection>
        <FrameSection addClassName="bg-lite-orange">
          <div className="container mb-40">
            <FrameCol addClassName="mt-40">
              <h2 className="mb-30">
                <span>{t('langService.detailReference1')}</span>
              </h2>
              <p className="section-sub-title text-center mb-30">{t('langService.detailReference2')}</p>
            </FrameCol>
            <FrameRow>
              <div className="ai-table mobile-hide exp-animation" data-animation="express_b_show" data-delay="200">
                <table>
                  <tbody>
                    <tr>
                      <th>{t('langService.detailReference3')}</th>
                      <td>
                        <ul>
                          <li>{t('langService.detailReference4')}</li>
                          <li>{t('langService.detailReference5')}</li>
                          <li>{t('langService.detailReference6')}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('langService.detailReference7')}</th>
                      <td>
                        <ul>
                          <li>{t('langService.detailReference8')}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('langService.detailReference9')}</th>
                      <td>
                        <ul>
                          <li>{t('langService.detailReference10')}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('langService.detailReference11')}</th>
                      <td>
                        <ul>
                          <li>{t('langService.detailReference12')}</li>
                          <li>{t('langService.detailReference13')}</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <FrameCol addClassName="pc-hide">
                <div className="ai-table2 exp-animation" data-animation="express_b_show" data-delay="400">
                  <table>
                    <tbody>
                      <tr>
                        <th>{t('langService.detailReference3')}</th>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li>{t('langService.detailReference4')}</li>
                            <li>{t('langService.detailReference5')}</li>
                            <li>{t('langService.detailReference6')}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('langService.detailReference7')}</th>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li>{t('langService.detailReference8')}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('langService.detailReference9')}</th>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li>{t('langService.detailReference10')}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('langService.detailReference11')}</th>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li>{t('langService.detailReference12')}</li>
                            <li>{t('langService.detailReference13')}</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </FrameCol>
            </FrameRow>
          </div>
        </FrameSection>
      </div>
    </FramePageInner>
  );
};

export default ServiceTabDetailComponent;
