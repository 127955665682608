import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameContainer, FrameRow, FrameSection } from 'common/view/layouts/frames';
import FramePageInner from 'common/view/layouts/frames/FramePageInner';
import SectionLayout from 'common/view/layouts/section/SectionLayout';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const sliderOption = {
  width: 420,
  height: 400,
  top: 0,
  minHeight: 0,
  space: 50,
  start: 1,
  grabCursor: true,
  swipe: true,
  mouse: true,
  keyboard: false,
  layout: 'partialview',
  wheel: false,
  autoplay: true,
  instantStartLayers: false,
  loop: true,
  shuffle: false,
  preload: 0,
  heightLimit: true,
  autoHeight: false,
  smoothHeight: true,
  endPause: false,
  overPause: true,
  fillMode: 'fill',
  centerControls: true,
  startOnAppear: false,
  layersMode: 'center',
  autofillTarget: '',
  hideLayers: false,
  fullscreenMargin: 0,
  speed: 30,
  dir: 'h',
  parallaxMode: 'swipe',
  view: 'basic',
  filters: {
    grayscale: 1,
    contrast: 1.5,
  },
};

const arrowOption = { autohide: false, soverVideo: true };
const bulletsOption = { autohide: false, align: 'bottom', margin: 10, space: 4 };

const ProfileComponent = () => {
  const { t, i18n } = useTranslation();

  const customSliderProgressRef = useRef<HTMLDivElement>(null);
  const customSliderSlidesLogRefRef = useRef<HTMLDivElement>(null);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  useEffect(() => {
    const scrollToRight = () => {
      const elements = document.getElementsByClassName('lang-history');
      if (elements.length > 0) {
        elements[0].scrollTo(300, 0);
      }
    };
    scrollToRight();
    window.screenTop;
  });
  return (
    <>
      <SectionLayout addSectionClassName="main-color pb-0" addContianerClassName="fluid">
        <FrameRow>
          <FrameCol addClassName="text-center mb-0">
            <h1 className="mb-20 mt-50">
              <span>PROFILE</span>
            </h1>
            <p className="section-sub-title mb-50">{t('langProfile.head1')}</p>
          </FrameCol>
        </FrameRow>
        <div className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall hi300 row">
          <div className="divimage dzsparallaxer--target w-100" style={{ height: '120%', backgroundImage: 'url(assets/img/custom/lang_visual_img02.png)', backgroundPosition: 'top center' }}></div>
          <div className="profile-txtWrap exp-animation" data-animation="express_b_show" data-delay="200">
            <p>
              {t('langProfile.visual11')}
              <br />
              {t('langProfile.visual12')}
              <br />
              {t('langProfile.visual13')}
              <br />
              {t('langProfile.visual14')}
              <br />
              {t('langProfile.visual15')}
              <br />
              {t('langProfile.visual16')}
            </p>
          </div>
        </div>
      </SectionLayout>
      <SectionLayout addSectionClassName="bg-gray">
        <FrameRow>
          <FrameCol>
            <div className="profile-box01">
              <div>
                <span>
                  <CustomImgTag src="assets/img/icons/profile_ico_01.png" />
                </span>
                <p>{t('langProfile.performance1')}</p>
                <p className="value">{t('langProfile.performance2')}</p>
              </div>
              <div>
                <span>
                  <CustomImgTag src="assets/img/icons/profile_ico_02.png" />
                </span>
                <p>{t('langProfile.performance3')}</p>
                <p className="value">{t('langProfile.performance4')}</p>
              </div>
              {/** 20240223 매출부분 삭제 */}
              {/* <div>
                <span>
                  <CustomImgTag src="assets/img/icons/profile_ico_03.png" />
                </span>
                <p>{t('langProfile.performance5')}</p>
                <p className="value">{t('langProfile.performance6')}</p>
              </div> */}
              <div>
                <span>
                  <CustomImgTag src="assets/img/icons/profile_ico_04.png" />
                </span>
                <p>{t('langProfile.performance7')}</p>
                <p className="value">{t('langProfile.performance8')}</p>
              </div>
              <div>
                <span>
                  <CustomImgTag src="assets/img/icons/profile_ico_05.png" />
                </span>
                <p>{t('langProfile.performance9')}</p>
                <p className="value">{t('langProfile.performance10')}</p>
              </div>
            </div>
          </FrameCol>
        </FrameRow>
      </SectionLayout>
      <FramePageInner>
        <FrameSection>
          <div className="container mb-80">
            <FrameRow>
              <FrameCol addClassName="text-center">
                <h2 className="mb-40">
                  <span>{t('langProfile.global1')}</span>
                </h2>
              </FrameCol>
              <FrameCol>
                <FrameRow>
                  <FrameCol width={6}>
                    <div className="profile-box02">
                      <div className="profile-box-img">
                        <p>
                          <CustomImgTag src="assets/img/custom/gala.png" alt={t('langProfile.global2')} />
                        </p>
                        <p>{t('langProfile.global2')}</p>
                        <p className="fColor-orange">{t('langProfile.global3')}</p>
                      </div>
                      <div className="profile-box-text">
                        <p className="mb-0">{t('langProfile.global4')}</p>
                      </div>
                    </div>
                  </FrameCol>
                  <FrameCol width={6}>
                    <div className="profile-box02">
                      <div className="profile-box-img">
                        <p>
                          <CustomImgTag src="assets/img/custom/top3001.png" alt={t('langProfile.alt1')} />
                          <CustomImgTag src="assets/img/custom/top3002.png" alt={t('langProfile.alt1')} />
                        </p>
                        <p>{t('langProfile.global5')}</p>
                        <p className="fColor-blue">{t('langProfile.global6')}</p>
                      </div>
                      <div className="profile-box-text">
                        <p className="mb-0">{t('langProfile.global7')}</p>
                        <p className="mb-0">{t('langProfile.global8')}</p>
                      </div>
                    </div>
                  </FrameCol>
                </FrameRow>
              </FrameCol>
            </FrameRow>
          </div>
          <FrameContainer>
            <FrameCol addClassName="mb-40">
              <h2>
                <span>{t('langProfile.iso1')}</span>
              </h2>
            </FrameCol>
            <FrameRow>
              <FrameCol width={3} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="profile-box03 exp-animation" data-animation="express_b_show" data-delay="400">
                    <div className="icon">
                      <span>
                        <CustomImgTag src="assets/img/icons/icon_world.png" alt={t('langProfile.alt1')} />
                      </span>
                      <p className="title">{t('langProfile.iso2')}</p>
                      <p>{t('langProfile.iso3')}</p>
                    </div>
                    <p className="discrption">{t('langProfile.iso4')}</p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={3} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="profile-box03 exp-animation" data-animation="express_b_show" data-delay="800">
                    <div className="icon">
                      <span>
                        <CustomImgTag src="assets/img/icons/icon_world.png" alt={t('langProfile.alt1')} />
                      </span>
                      <p className="title">{t('langProfile.iso5')}</p>
                      <p>{t('langProfile.iso6')}</p>
                    </div>
                    <p className="discrption">{t('langProfile.iso7')}</p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={3} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="profile-box03 exp-animation" data-animation="express_b_show" data-delay="1200">
                    <div className="icon">
                      <span>
                        <CustomImgTag src="assets/img/icons/icon_world.png" alt={t('langProfile.alt1')} />
                      </span>
                      <p className="title">{t('langProfile.iso8')}</p>
                      <p>{t('langProfile.iso9')}</p>
                    </div>
                    <p className="discrption">{t('langProfile.iso10')}</p>
                  </div>
                </div>
              </FrameCol>
              <FrameCol width={3} addClassName="col-sm-6">
                <div className="anim-perspective full-height">
                  <div className="profile-box03 exp-animation" data-animation="express_b_show" data-delay="1600">
                    <div className="icon">
                      <span>
                        <CustomImgTag src="assets/img/icons/icon_world.png" alt={t('langProfile.alt1')} />
                      </span>
                      <p className="title">{t('langProfile.iso11')}</p>
                      <p>{t('langProfile.iso12')}</p>
                    </div>
                    <p className="discrption">{t('langProfile.iso13')}</p>
                  </div>
                </div>
              </FrameCol>
            </FrameRow>
          </FrameContainer>
        </FrameSection>
        <SectionLayout addSectionClassName="bg-gray" addContianerClassName="fluid">
          <FrameRow>
            <FrameCol addClassName="p-0">
              <MasterSliderLayout
                id="profileslider"
                className="master-slider ms-skin-default"
                sliderOption={sliderOption}
                arrowOption={arrowOption}
                bulltesOption={bulletsOption}
                noProgressBar
                prevBtnRef={prevBtnRef}
                nextBtnRef={nextBtnRef}
                customProgressRef={customSliderProgressRef}
                customSlidesLogRef={customSliderSlidesLogRefRef}
              >
                <div className="ms-slide">
                  <div className="bgimgWrap" style={{ background: 'url(assets/img/profile/lang_profile_01.png?v=20240325)' }}></div>
                </div>
                <div className="ms-slide">
                  <div className="bgimgWrap" style={{ background: 'url(assets/img/profile/lang_profile_02.png?v=20240325)' }}></div>
                </div>
                <div className="ms-slide">
                  <div className="bgimgWrap" style={{ background: 'url(assets/img/profile/lang_profile_03.png?v=20240325)' }}></div>
                </div>
                <div className="ms-slide">
                  <div className="bgimgWrap" style={{ background: 'url(assets/img/profile/lang_profile_04.png?v=20240325)' }}></div>
                </div>
                <div className="ms-slide">
                  <div className="bgimgWrap" style={{ background: 'url(assets/img/profile/lang_profile_05.jpg?v=20240325)' }}></div>
                </div>
                <div className="ms-slide">
                  <div className="bgimgWrap" style={{ background: 'url(assets/img/profile/lang_profile_06.png?v=20240325)' }}></div>
                </div>
                <div className="ms-slide">
                  <div className="bgimgWrap" style={{ background: 'url(assets/img/profile/lang_profile_07.png?v=20240325)' }}></div>
                </div>
                <div className="ms-slide">
                  <div className="bgimgWrap" style={{ background: 'url(assets/img/profile/lang_profile_08.png?v=20240325)' }}></div>
                </div>
                <div className="control mgb-30">
                  <div ref={prevBtnRef} id="newsPrevButton" title={t('langProfile.slide1')} className="ms-nav-prev ms-ctrl-hide"></div>
                  <div ref={nextBtnRef} id="newsNextButton" title={t('langProfile.slide2')} className="ms-nav-next ms-ctrl-hide"></div>
                  <div className="progress">
                    <div ref={customSliderProgressRef} className="progress-bar progress-bar-primary">
                      <span></span>
                    </div>
                  </div>
                  <div ref={customSliderSlidesLogRefRef} id="slidesjs-log">
                    <span className="slidesjs-slide-number">1</span> / <div className="totalCnt" style={{ display: 'inline' }}></div>
                  </div>
                </div>
              </MasterSliderLayout>
            </FrameCol>
          </FrameRow>
        </SectionLayout>
        <SectionLayout>
          <div className="row exp-animation" data-animation="express_b_show" data-delay="200">
            <FrameCol addClassName="mb-50">
              <h2 className="mb-80">
                <span>{t('langProfile.historyHead1')}</span>
              </h2>
              <div className="lang-history">
                <ul>
                  <li>
                    <span>{t('langProfile.historyLine1')}</span>
                    {t('langProfile.historyLine2')}
                    <br />
                    {t('langProfile.historyLine3')}
                    <br />
                    {t('langProfile.historyLine4')}
                  </li>
                  <li>
                    <span>{t('langProfile.historyLine5')}</span>
                    {t('langProfile.historyLine6')}
                    <br />
                    {t('langProfile.historyLine7')}
                    <br />
                    {t('langProfile.historyLine8')}
                    <br />
                    {t('langProfile.historyLine9')}
                  </li>
                  <li>
                    <span>{t('langProfile.historyLine10')}</span>
                    {t('langProfile.historyLine11')}
                    <br />
                    {t('langProfile.historyLine12')}
                    <br />
                    {t('langProfile.historyLine13')}
                  </li>
                  <li>
                    <span>{t('langProfile.historyLine14')}</span>
                    {t('langProfile.historyLine15')}
                    <br />
                    {t('langProfile.historyLine16')}
                    <br />
                    {t('langProfile.historyLine17')}
                    <br />
                    {t('langProfile.historyLine18')}
                  </li>
                  <li>
                    <span>{t('langProfile.historyLine19')}</span>
                    {t('langProfile.historyLine20')}
                  </li>
                  <li>
                    <span>{t('langProfile.historyLine21')}</span>
                    {t('langProfile.historyLine22')}
                    <br />
                    {t('langProfile.historyLine23')}
                    <br />
                    {t('langProfile.historyLine24')}
                  </li>
                  <li>
                    <span>{t('langProfile.historyLine25')}</span>
                    {t('langProfile.historyLine26')}
                  </li>
                </ul>
              </div>
              <div className="bg-gray round-30 p-30">
                <ul className="mb-0 fColor-black lh-20">
                  <li>
                    {t('langProfile.historyList101')}
                    {t('langProfile.historyList1')}
                    {t('langProfile.historyList102')}
                    {t('langProfile.historyList2')}
                  </li>
                  <li>
                    {t('langProfile.historyList301')}
                    {t('langProfile.historyList3')}
                    {t('langProfile.historyList4')}
                  </li>
                  <li>
                    {t('langProfile.historyList8')} {t('langProfile.historyList9')}
                    {t('langProfile.historyList10')}
                  </li>
                  <li>
                    {t('langProfile.historyList13')} {t('langProfile.historyList14')}
                    {t('langProfile.historyList15')}
                  </li>
                  <li>
                    {t('langProfile.historyList16')} {t('langProfile.historyList17')}
                  </li>
                  <li>{t('langProfile.historyList5')}</li>
                  <li>
                    {t('langProfile.historyList6')}
                    {t('langProfile.historyList7')}
                  </li>
                </ul>
              </div>
            </FrameCol>
          </div>
        </SectionLayout>
        <section className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall">
          <div className="divimage dzsparallaxer--target w-100" style={{ height: '120%', backgroundImage: 'url(assets/img/custom/lang_profile_bg01.png)', backgroundPosition: 'center center' }}></div>
          <div className="container pt-80 pb-80">
            <FrameRow>
              <FrameCol>
                <h2 className="mb-50">
                  <span>{t('langProfile.reviewHead1')}</span>
                </h2>
              </FrameCol>
              <FrameCol width={5}>
                <div className="service-box3 mt-20">
                  {i18n.language == 'ko' ? (
                    <CustomImgTag src="assets/img/custom/lang_profile_img04.png" alt={t('langProfile.reviewHead2')} />
                  ) : i18n.language == 'ch' ? (
                    <CustomImgTag src="assets/img/custom/lang_profile_img04_cn.png" alt={t('langProfile.reviewHead2')} />
                  ) : (
                    <CustomImgTag src="assets/img/custom/lang_profile_img04_en.png" alt={t('langProfile.reviewHead2')} />
                  )}
                </div>
              </FrameCol>
              <FrameCol width={7}>
                <div className="reviewWrap">
                  <ul>
                    <li className="exp-animation" data-animation="express_b_show" data-delay="400">
                      <p>{t('langProfile.reviewList1')}</p>
                      <span>{t('langProfile.reviewList2')}</span>
                    </li>
                    <li className="exp-animation" data-animation="express_b_show" data-delay="600">
                      <p>{t('langProfile.reviewList3')}</p>
                      <span>{t('langProfile.reviewList4')}</span>
                    </li>
                    <li className="exp-animation" data-animation="express_b_show" data-delay="800">
                      <p>{t('langProfile.reviewList5')}</p>
                      <span>{t('langProfile.reviewList6')}</span>
                    </li>
                    <li className="exp-animation" data-animation="express_b_show" data-delay="1000">
                      <p>{t('langProfile.reviewList7')}</p>
                      <span>{t('langProfile.reviewList8')}</span>
                    </li>
                    <li className="exp-animation" data-animation="express_b_show" data-delay="1200">
                      <p>{t('langProfile.reviewList9')}</p>
                      <span>{t('langProfile.reviewList10')}</span>
                    </li>
                    <li className="exp-animation" data-animation="express_b_show" data-delay="1400">
                      <p>{t('langProfile.reviewList11')}</p>
                      <span>{t('langProfile.reviewList12')}</span>
                    </li>
                    <li className="exp-animation" data-animation="express_b_show" data-delay="1600">
                      <p>{t('langProfile.reviewList13')}</p>
                      <span>{t('langProfile.reviewList14')}</span>
                    </li>
                  </ul>
                </div>
              </FrameCol>
            </FrameRow>
          </div>
        </section>
      </FramePageInner>
    </>
  );
};

export default ProfileComponent;
