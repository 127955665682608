import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sm } from 'util/StyleUtil';
import { styleCursorPointer } from 'util/StyleUtil';

import { FrameCol, FrameContainer, FrameRow } from '../frames';
import FooterMoreEmailModalLayout from './modal/FooterMoreEmailModalLayout';
import FooterMoreInfoModalLayout from './modal/FooterMoreInfoModalLayout';

const FooterMoreInfoLayout = () => {
  const { t } = useTranslation();

  const modalId = 'termsModal';
  const modalTitleName = t('footer.more1');
  const modalEmailId = 'emailModal';
  const modalEmailTitleName = t('footer.more2');
  const [currentTabIdx, setCurrentTabIdx] = useState(0);

  const onClickPolicy = (idx: number) => {
    setCurrentTabIdx(idx);
  };

  return (
    <div className="copyright b-border" style={{ paddingTop: '80px' }}>
      <FrameContainer>
        <FrameRow>
          <FrameCol size={sm} width={8} addClassName="mb-0">
            <ul className="list-inline">
              <li>
                <a data-toggle="modal" data-target={`#${modalId}`} onClick={() => onClickPolicy(0)} style={styleCursorPointer}>
                  {t('footer.more1')}
                </a>
              </li>
              <li>
                <a data-toggle="modal" data-target={`#${modalEmailId}`} onClick={() => onClickPolicy(0)} style={styleCursorPointer}>
                  {t('footer.more2')}
                </a>
              </li>
            </ul>
          </FrameCol>
          {/* 블로그 미운행으로 인한 주석처리 */}
          {/* <FrameCol size={sm} width={4} addClassName="mobile-hide">
            <div className="list-inline text-right">
              <span className="rounded mgt-5">
                <i className="fa fa-bold fs-25"></i>
              </span>
              <CustomATag href="https://e4blog.tistory.com/" target="_blank">
                {t('footer.more3')}
                <i className="glyphicon icon icon-arrow-36 fa-rotate-270"></i>
              </CustomATag>
            </div>
          </FrameCol> */}
        </FrameRow>
      </FrameContainer>
      <FooterMoreInfoModalLayout modalId={modalId} modalTitleName={modalTitleName} currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} />
      <FooterMoreEmailModalLayout modalId={modalEmailId} modalTitleName={modalEmailTitleName} currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} />
    </div>
  );
};

export default FooterMoreInfoLayout;
