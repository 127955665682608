import { selectCommunity } from 'api/board/BoardAPI';
import DetailViewLayout from 'common/view/layouts/detailView/DetailViewLayout';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BoardRVO } from 'types/board';
import { isSuccess } from 'util/ApiResponseUtil';

const defaultCommunityData = {
  brdNm: '',
  brdNo: '',
  postNo: '',
  subject: '',
  imgFile: '',
  regDt: '',
  pstgYmd: '',
  content: '',
  nextPostNo: 0,
  prevPostNo: 0,
  totalCount: 0,
  e4Count: 0,
  noticeCount: 0,
  pageNo: 1,
  home: '',
};

const CommunityViewComponent = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const brdNo: number = parseInt(pathname.split('/').at(2) as string); // community/15/241 => 15
  const postNo: number = parseInt(pathname.split('/').at(3) as string); // community/15/241 => 241
  const [communityData, setCommunityData] = useState<BoardRVO>(defaultCommunityData);
  const getCommunity = async () => {
    await selectCommunity({ postNo: postNo, brdNo: brdNo })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setCommunityData(resData);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCommunity();
  }, [pathname]);

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-0">
        <h1 className="mb-20 mt-50">
          <span>COMMUNITY</span>
        </h1>
        <p className="section-sub-title mb-40">
          {t('community.viewHead1')}
          <br />
          {t('community.viewHead2')}
        </p>
      </FrameCol>
      <DetailViewLayout
        date={communityData.regDt}
        category="COMMUNITY"
        titleClassName="newsTitle"
        title={communityData.subject}
        contentClassName="newsDetail"
        content={communityData.content}
        brdNo={communityData.brdNo}
        prevPostNo={communityData.prevPostNo}
        nextPostNo={communityData.nextPostNo}
        imgDef={{ src: communityData.imgFile, alt: '사진' }}
        linkClassName={communityData.home ? 'moreLink' : ''}
        linkDef={{
          element: [{ text: communityData.home ? t('community.viewLink1') + ' \n\n' : '', linkText: communityData.home ? t('community.viewLink2') : '', link: communityData.home ?? '', target: '_blank' }],
        }}
      />
    </FrameRow>
  );
};

export default CommunityViewComponent;
