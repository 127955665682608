import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const CareerHeadComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol textAlign="text-center" addClassName="mb-0">
        <h1 className="mb-20 mt-50">
          <span>CAREER</span>
        </h1>
        <p className="section-sub-title mb-80">{t('career.head1')}</p>
      </FrameCol>
      <FrameCol addClassName="bg-gray">
        <div className="container pt-50">
          <FrameRow>
            <FrameCol>
              <h2>
                {t('career.head2')}
                <br /> {t('career.head3')}
              </h2>
              <ExpAnimationLayout>
                <FrameRow addClassName="testimonial-two">
                  <FrameCol width={6} addClassName="testimonial-two-col">
                    <div className="testimonial-inner">
                      <div className="feature-box left-icon-v2 exp-animation" data-animation="express_pop" data-delay="0">
                        <i className="fa fa-balance-scale"></i>
                        <div className="feature-box-text">
                          <h3>{t('career.head4')}</h3>
                          <ul>
                            <li>{t('career.head5')}</li>
                            <li>{t('career.head6')}</li>
                            <li>{t('career.head7')}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </FrameCol>
                  <FrameCol width={6} addClassName="testimonial-two-col">
                    <div className="testimonial-inner">
                      <div className="feature-box left-icon-v2 exp-animation" data-animation="express_pop" data-delay="200">
                        <i className="fa fa-smile-o"></i>
                        <div className="feature-box-text">
                          <h3>{t('career.head8')}</h3>
                          <ul>
                            <li>{t('career.head9')}</li>
                            <li>{t('career.head10')}</li>
                            <li>{t('career.head11')}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </FrameCol>
                  <FrameCol width={6} addClassName="testimonial-two-col">
                    <div className="testimonial-inner">
                      <div className="feature-box left-icon-v2 exp-animation" data-animation="express_pop" data-delay="400">
                        <i className="fa fa-mortar-board"></i>
                        <div className="feature-box-text">
                          <h3>{t('career.head12')}</h3>
                          <ul>
                            <li>{t('career.head13')}</li>
                            <li>{t('career.head14')}</li>
                            <li>{t('career.head15')}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </FrameCol>
                  <FrameCol width={6} addClassName="testimonial-two-col">
                    <div className="testimonial-inner">
                      <div className="feature-box left-icon-v2 exp-animation" data-animation="express_pop" data-delay="600">
                        <i className="fa fa-group"></i>
                        <div className="feature-box-text">
                          <h3>{t('career.head16')}</h3>
                          <ul>
                            <li>{t('career.head17')}</li>
                            <li>{t('career.head18')}</li>
                            <li>{t('career.head19')}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </FrameCol>
                </FrameRow>
              </ExpAnimationLayout>
            </FrameCol>
          </FrameRow>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default CareerHeadComponent;
