import { Axios, AxiosRequestConfig, AxiosResponse } from 'axios';
import { PagingVO, ResponseRVO } from 'types/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const doFetch = async <T>(apiClient: Axios, url: string, param?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<ResponseRVO<T>>> => {
  // 제네릭은 응답을 하나만 받는다면 RVO, 여러개를 받는다면 RVO[]
  return apiClient.get(url, { ...config, params: param });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const doPagingFetch = async <T>(apiClient: Axios, url: string, param?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<ResponseRVO<PagingVO<T>[]>>> => {
  //rvo를 []붙히지 않고 제네릭으로 넣어주세요.
  return apiClient.get(url, { ...config, params: param });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const doPost = async <T>(apiClient: Axios, url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<ResponseRVO<T>>> => {
  return apiClient.post(url, data, config);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const doUpdate = async <T>(apiClient: Axios, url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<ResponseRVO<T>>> => {
  return apiClient.put(url, data, config);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const doDelete = async <T>(apiClient: Axios, url: string, param?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<ResponseRVO<T>>> => {
  return apiClient.delete(url, { ...config, data: param });
};
