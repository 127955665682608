import { useMasterSlider, useMasterSliderProps } from 'hooks/common/SliderHook';

interface MasterSliderLayoutProps extends useMasterSliderProps {
  titleAreaClassName?: string;
  noProgressBar?: boolean;
}

const MasterSliderLayout = ({ id, className, sliderOption, arrowOption, bulltesOption, timebarOption, prevBtnRef, nextBtnRef, noProgressBar, customProgressRef, customSlidesLogRef, children }: MasterSliderLayoutProps) => {
  const { slideRef, progressRef } = useMasterSlider({ id, sliderOption, arrowOption, bulltesOption, timebarOption, prevBtnRef, nextBtnRef, customProgressRef, customSlidesLogRef, children });

  return (
    <div id={id} className={className} ref={slideRef}>
      {children}
      {noProgressBar || (
        <div className="progress">
          <div ref={progressRef} className="progress-bar progress-bar-primary">
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterSliderLayout;
