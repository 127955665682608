import FramePageInner from 'common/view/layouts/frames/FramePageInner';
import SectionLayout from 'common/view/layouts/section/SectionLayout';
import { useBodyId } from 'hooks/common/CommonHook';

import CommunityComponent from './CommunityComponent';
import DepartmentsComponent from './DepartmentsComponent';
import MainBannerComponent from './MainBannerComponent';
import NewsComponent from './NewsComponent';
import VideoComponent from './VideoComponent';

const MainPage = () => {
  useBodyId('main');

  return (
    <>
      <section>
        <MainBannerComponent />
      </section>
      <FramePageInner>
        <SectionLayout addSectionClassName="main-color">
          <DepartmentsComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="video-bg">
          <VideoComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="news-color b-bordered">
          <NewsComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="main-color" addContianerClassName="fluid">
          <CommunityComponent />
        </SectionLayout>
        {/* 블로그 미운행으로 인한 주석처리 */}
        {/* <SectionLayout addSectionClassName="tistory b-bordered">
          <TistoryComponent />
        </SectionLayout> */}
      </FramePageInner>
    </>
  );
};

export default MainPage;
