import SectionLayout from 'common/view/layouts/section/SectionLayout';

import CommunityBannerComponent from './CommunityBannerComponent';
import CommunityListPageComponent from './CommunityListPageComponent';

const CommunityPage = () => {
  return (
    <>
      <SectionLayout addSectionClassName="main-color pb-0" addContianerClassName="fluid">
        <CommunityBannerComponent />
      </SectionLayout>
      <CommunityListPageComponent />
    </>
  );
};

export default CommunityPage;
