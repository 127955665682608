import { useCallback, useEffect, useRef } from 'react';
import { AbstractProps } from 'types/common/PropsTypes';

export const useResize = ({ children }: AbstractProps) => {
  const resizeRef = useRef<HTMLDivElement>(null);
  // const [tgGridWrapperRestHeight, setTgGridWrapperRestHeight] = useState<number>(0);
  // console.debug('tgGridWrapperRestHeight', tgGridWrapperRestHeight);

  // restHeight는 뺴도 될 듯?
  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const wrapperElement = resizeRef.current;
      if (!wrapperElement) return;
      // const wrapperStyle = getComputedStyle(wrapperElement); // tg-grid-wrapper의 스타일
      // const wrapperHeight = parseInt(wrapperStyle.height, 10); // tg-grid-wrapper의 높이

      for (const entry of entries) {
        const contentHeight = entry.contentRect.height; // content의 높이
        if (contentHeight > 0) {
          // const restHeight = tgGridWrapperRestHeight ?? wrapperHeight - contentHeight;
          // console.debug('restHeight', restHeight);
          // if (tgGridWrapperRestHeight !== restHeight) {
          //   setTgGridWrapperRestHeight(restHeight);
          // }
          // console.debug('contentHeight + restHeight', contentHeight + restHeight);
          // wrapperElement.style.height = `${contentHeight + restHeight}px`;
          wrapperElement.style.setProperty('height', `${contentHeight}px`, 'important');
        }
      }
    },
    [resizeRef /*, tgGridWrapperRestHeight*/],
  );

  useEffect(() => {
    if (!resizeRef.current) return;

    const resizeObserver = new ResizeObserver(handleResize);
    const tgLayoutGrid = resizeRef.current.querySelector('.tg-layout-grid');

    if (tgLayoutGrid) resizeObserver.observe(tgLayoutGrid);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return { resizeRef };
};
