import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import TgGridLayout from 'common/view/layouts/grid/TgGridLayout';
import styled from 'styled-components';
import { BoardRVO } from 'types/board';

import { selectedTypeEnum } from '../CommunityListPageComponent';

const StyledDiv = styled.div`
  .pracia .tg-item-content-holder .com-type {
    float: right;
    font-weight: 700;
  }
`;
interface CommunityListComponentProps {
  e4Count: number;
  noticeCount: number;
  totalCount: number;
  selectedType: number;
  setSelectedType: React.Dispatch<React.SetStateAction<selectedTypeEnum>>;
  isLoading: boolean;
  communityList: BoardRVO[];
}

const CommunityListComponent = ({ e4Count, noticeCount, totalCount, selectedType, setSelectedType, isLoading, communityList }: CommunityListComponentProps) => {
  return (
    <FrameRow>
      <FrameCol>
        <div className="tg-grid-area-top2 mt-none">
          <div className="tg-filters-holder">
            <div className={`tg-filter tg-show-filter${selectedType === 0 ? ' tg-filter-active' : ''}`} data-filter="*" onClick={() => setSelectedType(0)}>
              <span className="tg-filter-name">
                All
                <span className="tg-filter-count">
                  {totalCount} {/*communityCount.all*/}
                </span>
              </span>
            </div>
            <div className={`tg-filter tg-show-filter${selectedType === 7 ? ' tg-filter-active' : ''}`} data-taxo="portfolio_category" data-filter="*" onClick={() => setSelectedType(7)}>
              <span className="tg-filter-name">
                Notice <span className="tg-filter-count">{noticeCount}</span>
              </span>
            </div>
            <div className={`tg-filter tg-show-filter${selectedType === 15 ? ' tg-filter-active' : ''}`} data-taxo="portfolio_category" data-filter="*" onClick={() => setSelectedType(15)}>
              <span className="tg-filter-name">
                E4.Story
                <span className="tg-filter-count">{e4Count}</span>
              </span>
            </div>
          </div>
        </div>
        <TgGridLayout id="grid-pracia">
          {isLoading || (
            <StyledDiv
              className="tg-grid-holder tg-layout-grid"
              data-name="Pracia"
              data-style="grid"
              data-row="1"
              data-layout="vertical"
              data-rtl=""
              data-fitrows=""
              data-filtercomb=""
              data-filterlogic="AND"
              data-filterload=""
              data-sortbyload="none"
              data-orderload="false"
              data-fullwidth=""
              data-fullheight="null"
              data-gutters="[[320,20],[480,20],[690,20],[1000,20],[1300,20],[9999,20]]"
              data-slider="{'itemNav':'null','swingSpeed':0.1,'cycleBy':'null','cycle':5000,'startAt':1}"
              data-ratio="1.25"
              data-cols="[[320,1],[480,1],[690,2],[1000,3],[1300,3],[9999,3]]"
              data-rows="[[320,200],[480,200],[690,220],[1000,220],[1300,240],[9999,240]]"
              data-animation='{"name":"From Top","visible":"translateY(0)","hidden":"translateY(-100px)"}'
              data-transition="500ms"
              data-ajaxmethod="load_more"
              data-ajaxdelay="100"
              data-preloader="true"
              data-itemdelay="100"
              data-gallery=""
              data-ajax=""
            >
              {communityList.map((ele, idx) => {
                return (
                  <article key={`${idx}`} className="tg-item pracia tg-item-reveal" data-row="1" data-col="1">
                    <div className="tg-item-inner">
                      <div className="tg-item-media-holder tg-light">
                        <div className="tg-item-media-inner">
                          <div
                            className="tg-item-image"
                            style={{ background: `url(${!ele.imgFile && ele.brdNm == 'E4.STORY' ? 'assets/img/community/e4-story.jpg' : !ele.imgFile && ele.brdNm == 'NOTICE' ? 'assets/img/community/notice.jpg' : ele.imgFile})` }}
                          ></div>
                        </div>
                        <div className={`tg-item-overlay random-bg0${idx + 1}`}></div>
                        <div className="tg-center-holder">
                          <div className="tg-center-inner">
                            <CustomATag href={`${ele.brdNo}/${ele.postNo}`} className="tg-link-button" state={{ cate: selectedType, pageNo: ele.pageNo }}>
                              <i className="tg-icon-link"></i>
                            </CustomATag>
                          </div>
                        </div>
                      </div>
                      <div className="tg-item-content-holder image-format">
                        <h2 className="newsTitle">
                          <CustomATag href={`${ele.brdNo}/${ele.postNo}`} state={{ cate: selectedType, pageNo: ele.pageNo }}>
                            {ele.subject}
                          </CustomATag>
                        </h2>
                        <span className="newsDate">{ele.regDt}</span>
                        <span
                          className="com-type"
                          style={{
                            float: 'right',
                            fontWeight: '700',
                          }}
                        >
                          {ele.brdNm}
                        </span>
                      </div>
                    </div>
                  </article>
                );
              })}
            </StyledDiv>
          )}
        </TgGridLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default CommunityListComponent;
