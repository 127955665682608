import SectionLayout from 'common/view/layouts/section/SectionLayout';

import PortfolioBannerComponent from './PortfolioBannerComponent';
import PortfolioListPageComponent from './PortfolioListPageComponent';

const PortfolioPage = () => {
  return (
    <>
      <SectionLayout addSectionClassName="main-color pb-0" addContianerClassName="fluid">
        <PortfolioBannerComponent />
      </SectionLayout>
      <PortfolioListPageComponent />
    </>
  );
};

export default PortfolioPage;
