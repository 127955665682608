import { FrameContainer, FrameSection } from 'common/view/layouts/frames';
import { useLayoutEffect, useState } from 'react';
import { ListAndPageProps } from 'types/common';
import { CommunityListRVO } from 'types/sample/community';
import { defaultPagingListObject } from 'util/DataUtil';

import { SampleCommunityListListComponent, SampleCommunityListPaginationComponent } from './communityListComponents';

// img/community/notice.jpg => 공지사항 이미지 없을 때 기본 으로 나오는 이미지
// img/community/e4-story.jpg => E4스토리 이미지 없을 때 기본 으로 나오는 이미지
const COMMUNITY_LIST = [
  { imageSrc: 'assets/img/community/notice.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/e4-story.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img03.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img04.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img05.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img06.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img07.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img08.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img09.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/notice.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/e4-story.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img03.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img04.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img05.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img06.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img07.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img08.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img09.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/notice.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/e4-story.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img03.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img04.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img05.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img06.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img07.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img08.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img09.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/notice.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/e4-story.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img03.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img04.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img05.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img06.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img07.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img08.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img09.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/notice.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/e4-story.jpg', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img03.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img04.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img05.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img06.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img07.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
  { imageSrc: 'assets/img/community/community_img08.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'Notice' },
  { imageSrc: 'assets/img/community/community_img09.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02', comType: 'E4.Story' },
];
export type selectedTypeEnum = 'All' | 'Notice' | 'E4.Story';
const SampleCommunityListComponent = () => {
  const [communityList, setCommunityList] = useState<ListAndPageProps<CommunityListRVO>>(defaultPagingListObject);
  const [selectedType, setSelectedType] = useState<selectedTypeEnum>('All');
  const [isLoading, setIsLoading] = useState(false); // 임시 로딩처리

  const onSubmit = (pageNo?: number) => {
    setIsLoading(true);
    setTimeout(() => {
      const itemCountPerPage = 9;
      const startIndex = ((pageNo ?? 1) - 1) * itemCountPerPage; // 임시 페이징 처리
      let e4Count = 0;
      let noticeCount = 0;
      COMMUNITY_LIST.forEach((ele) => {
        if (ele.comType === 'Notice') noticeCount++;
        else if (ele.comType === 'E4.Story') e4Count++;
      });
      const res = COMMUNITY_LIST.filter((e) => selectedType === 'All' || selectedType === e.comType).map((ele) => ({ ...ele, e4Count, noticeCount, totalCount: COMMUNITY_LIST.length }));
      const totalCount = res.length;
      const _communityList = res.slice(startIndex, startIndex + 9); // 임시 페이징 처리 + 임시 필터링 처리

      setCommunityList({
        list: _communityList,
        pagination: {
          totalCount,
          currentPageNo: pageNo ?? 1,
          itemCountPerPage,
        },
      });
      setIsLoading(false);
    }, 0); // 임시 로딩처리
  };

  useLayoutEffect(() => {
    onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  return (
    <FrameSection addClassName="b-bordered">
      <FrameContainer>
        <SampleCommunityListListComponent setSelectedType={setSelectedType} isLoading={isLoading} communityList={communityList.list} />
      </FrameContainer>
      <FrameContainer>
        <SampleCommunityListPaginationComponent pagination={communityList.pagination} pagingOnClick={onSubmit} />
      </FrameContainer>
    </FrameSection>
  );
};

export default SampleCommunityListComponent;
