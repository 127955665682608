import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import SlidesJSLayout from 'common/view/layouts/slider/SlidesJSLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stylePre } from 'util/StyleUtil';

const MainBannerComponent = () => {
  const { t, i18n } = useTranslation();
  const [labelName, setLabelName] = useState('E4NET');

  const sliderOption = {
    width: 940,
    height: 528,
    pagination: {
      active: true,
    },
    play: {
      active: true,
      auto: true,
      interval: 3000,
      swap: true,
      pauseOnHover: true,
    },
    callback: {
      complete: (pageNo: number) => {
        const _LabelDom = document.getElementById('dis-subtit' + (pageNo - 1));
        const _LabelName = _LabelDom?.innerText;
        setLabelName(_LabelName ? _LabelName : 'E4NET');
      },
    },
  };

  return (
    <FrameRow>
      <FrameCol addClassName="col-xs-12">
        <SlidesJSLayout wrapperClassname="main-slides" sliderId="Mslides" controllerLabelName={labelName} sliderOption={sliderOption}>
          <div>
            <div className="slideWrap">
              <div className="tngsvc">
                <a href="/lang">
                  {/* 언어서비스 배너 바로가기 링크 */}
                  <div className="slide-01">
                    <div className="discrption">
                      <p className="dis-tit">{t('homeMain.banner0101')}</p>
                      <p className="dis-subtit">
                        {t('homeMain.banner0102')}
                        <br />
                        {t('homeMain.banner0103')}
                        <br />
                        {t('homeMain.banner0104')}
                      </p>
                      <p className="golink">
                        <i className="fa fa-arrow-circle-right fa-3x"></i>
                      </p>
                    </div>
                    <CustomImgTag src="assets/img/slider/slide_tng01.png" alt={t('homeMain.banner0105')} />
                  </div>
                  <div className="bg-video">
                    <div className="discrption">
                      <p className="dis-tit">
                        {t('homeMain.banner0106')}
                        <br />
                        {t('homeMain.banner0107')}
                      </p>
                      <p className="dis-subtit">{t('homeMain.banner0108')}</p>
                      <p className="golink">
                        <i className="fa fa-arrow-circle-right fa-3x"></i>
                      </p>
                    </div>
                    <video className="bg-video__content" autoPlay muted loop>
                      <source src="assets/img/main/e4net_tng_vod.mp4" type="video/mp4" />
                    </video>
                  </div>
                </a>
              </div>
              <div className="itsvc">
                <a href="/it">
                  {/* IT서비스 배너 바로가기 링크 */}
                  <div className="slide-02">
                    <div className="discrption">
                      <p className="dis-tit">{t('homeMain.banner0109')}</p>
                      <p className="dis-subtit">
                        {t('homeMain.banner0110')}
                        <br />
                        {t('homeMain.banner0111')}
                        <br />
                        {t('homeMain.banner0112')}
                      </p>
                      <p className="golink">
                        <i className="fa fa-arrow-circle-right fa-3x"></i>
                      </p>
                    </div>
                    <CustomImgTag src="assets/img/slider/slide_snc01.png" alt={t('homeMain.banner0113')} />
                  </div>
                  <div className="bg-video">
                    <div className="discrption">
                      <p className="dis-tit">
                        {t('homeMain.banner0114')}
                        <br />
                        {t('homeMain.banner0115')}
                      </p>
                      <p className="dis-subtit">{t('homeMain.banner0116')}</p>
                      <p className="golink">
                        <i className="fa fa-arrow-circle-right fa-3x"></i>
                      </p>
                    </div>
                    <video className="bg-video__content" autoPlay muted loop>
                      <source src="assets/img/main/e4net_it_vod.mp4" type="video/mp4" />
                    </video>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* 언어서비스 */}
          <div className="slide-bg1">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner0201')}</label>
                  <p className="dis-tit">
                    {t('homeMain.banner0202')}
                    <br />
                    {t('homeMain.banner0203')}
                    <br />
                    {t('homeMain.banner0204')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit1">
                    {t('homeMain.banner0205')}
                  </p>
                  <CustomATag href="/lang/service#service1" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner0206')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img01.png" className="dis-img" alt={t('homeMain.banner0207')} />
              </div>
            </div>
          </div>
          <div className="slide-bg2">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner0301')}</label>
                  <p className="dis-tit">
                    {t('homeMain.banner0302')}
                    <br />
                    {t('homeMain.banner0303')}
                    <br />
                    {t('homeMain.banner0304')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit2">
                    {t('homeMain.banner0305')}
                  </p>
                  <CustomATag href="/lang/service#service2" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner0306')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img02.png" className="dis-img" alt={t('homeMain.banner0307')} />
              </div>
            </div>
          </div>
          <div className="slide-bg3">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner0401')}</label>
                  <p className="dis-tit">
                    {t('homeMain.banner0402')}
                    <br />
                    {t('homeMain.banner0403')}
                    <br />
                    {t('homeMain.banner0404')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit3">
                    {t('homeMain.banner0405')}
                  </p>
                  <CustomATag href="/lang/service#service4" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner0406')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img03.png" className="dis-img" alt={t('homeMain.banner0407')} />
              </div>
            </div>
          </div>
          <div className="slide-bg4">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner0501')}</label>
                  <p className="dis-tit">
                    {t('homeMain.banner0502')}
                    <br />
                    {t('homeMain.banner05021')}
                  </p>
                  <p className="dis-detail">
                    {t('homeMain.banner0503')}
                    <br />
                    {t('homeMain.banner0504')}
                    <br />
                    {t('homeMain.banner0505')}
                    <br />
                    {t('homeMain.banner0506')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit4">
                    {t('homeMain.banner0507')}
                  </p>
                  <CustomATag href="/lang/profile" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner0508')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                <CustomImgTag src="assets/img/slider/slide_img04.png" className="dis-img" alt={t('homeMain.banner0509')} />
              </div>
            </div>
          </div>
          {/* IT 서비스 */}
          <div className="slide-bg5">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner0601')}</label>
                  <p className="dis-tit" style={stylePre}>
                    {t('homeMain.banner0602')}
                    <br />
                    {t('homeMain.banner0603')}
                    <br />
                    {t('homeMain.banner0604')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit5">
                    {t('homeMain.banner0605')}
                  </p>
                  <CustomATag href="/it/service#service1" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner0606')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                {i18n.language == 'ko' ? (
                  <CustomImgTag src="assets/img/slider/slide_img05.png" className="dis-img" alt={t('homeMain.banner0607')} />
                ) : (
                  <CustomImgTag src="assets/img/slider/slide_img05_en.png" className="dis-img" alt={t('homeMain.banner0607')} />
                )}
              </div>
            </div>
          </div>
          <div className="slide-bg6">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner0701')}</label>
                  <p className="dis-tit" style={stylePre}>
                    {t('homeMain.banner0702')}
                    <br />
                    {t('homeMain.banner0703')}
                    <br />
                    {t('homeMain.banner0704')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit6">
                    {t('homeMain.banner0705')}
                  </p>
                  <CustomATag href="/it/service#service1" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner0706')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                {i18n.language == 'ko' ? (
                  <CustomImgTag src="assets/img/slider/slide_img06.png" className="dis-img" alt={t('homeMain.banner0707')} />
                ) : (
                  <CustomImgTag src="assets/img/slider/slide_img06_en.png" className="dis-img" alt={t('homeMain.banner0707')} />
                )}
              </div>
            </div>
          </div>
          <div className="slide-bg7">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner0801')}</label>
                  <p className="dis-tit">
                    {t('homeMain.banner0802')}
                    <br />
                    {t('homeMain.banner0803')}
                    <br />
                    {t('homeMain.banner0804')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit7">
                    {t('homeMain.banner0805')}
                  </p>
                  <CustomATag href="/it/service#tab01" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner0806')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                {i18n.language == 'ko' ? (
                  <CustomImgTag src="assets/img/slider/slide_img07.png" className="dis-img" alt={t('homeMain.banner0807')} />
                ) : (
                  <CustomImgTag src="assets/img/slider/slide_img07_en.png" className="dis-img" alt={t('homeMain.banner0807')} />
                )}
              </div>
            </div>
          </div>
          <div className="slide-bg8">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner0901')}</label>
                  <p className="dis-tit" style={stylePre}>
                    {t('homeMain.banner0902')}
                    <br />
                    {t('homeMain.banner0903')}
                    <br />
                    {t('homeMain.banner0904')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit8">
                    {t('homeMain.banner0905')}
                  </p>
                  <CustomATag href="/it/service#tab02" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner0906')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                {i18n.language == 'ko' ? (
                  <CustomImgTag src="assets/img/slider/slide_img08.png" className="dis-img" alt={t('homeMain.banner0907')} />
                ) : (
                  <CustomImgTag src="assets/img/slider/slide_img08_en.png" className="dis-img" alt={t('homeMain.banner0907')} />
                )}
              </div>
            </div>
          </div>
          <div className="slide-bg9">
            <div className="slideWrap">
              <div className="container">
                <div className="discrption">
                  <label>{t('homeMain.banner1001')}</label>
                  <p className="dis-tit">
                    {t('homeMain.banner1002')}
                    <br />
                    {t('homeMain.banner1003')}
                    <br />
                    {t('homeMain.banner1004')}
                  </p>
                  <p className="dis-subtit" id="dis-subtit9">
                    {t('homeMain.banner1005')}
                  </p>
                  <CustomATag href="/it/service#tab03" className="btn btn-primary fs-12 btn-rounded">
                    {t('homeMain.banner1006')} <i className="fa fa-arrow-right"></i>
                  </CustomATag>
                </div>
                {i18n.language == 'ko' ? (
                  <CustomImgTag src="assets/img/slider/slide_img09.png" className="dis-img" alt={t('homeMain.banner1007')} />
                ) : (
                  <CustomImgTag src="assets/img/slider/slide_img09_en.png" className="dis-img" alt={t('homeMain.banner1007')} />
                )}
              </div>
            </div>
          </div>
        </SlidesJSLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default MainBannerComponent;
