import React from 'react';
import { FRAME_SIZE, FrameProps } from 'types/common/PropsTypes';

export interface FrameColProps extends FrameProps {
  size?: FRAME_SIZE;
  width?: number;
}

const FrameCol = ({ size, width = 12, addClassName, textAlign, children }: FrameColProps) => {
  return <div className={`col-${size ?? 'md'}-${width} ${addClassName ?? ''} ${textAlign ?? ''}`}>{children}</div>;
};

export default React.memo(FrameCol);
