import { ApiClient } from 'api/RestClient';
import { isSuccess } from 'util/ApiResponseUtil';
import { doFetch } from 'util/AxiosUtil';

export const getCmCode = (param: { deptType: 'lang' | 'it'; grpId: string }) => {
  return doFetch<{ code: string; codeNm: string }[]>(ApiClient, '/api/common/cmCode', param)
    .then((res) => {
      const data = res.data;
      if (res && isSuccess(data.code)) {
        const resData = data.response;
        return resData;
      } else {
        return undefined;
      }
    })
    .catch((err) => alert(err.message));
};
