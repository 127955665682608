import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import { useLayoutEffect, useState } from 'react';
import { CommunityRVO } from 'types/sample/sample1';

const data = [
  {
    img: 'assets/img/community/community_img01.png',
    alt: '뉴스이미지',
    title: "이포넷, '메디아크'와 의료 AI 서비스 및 기술 개발 위한 MOU체결",
    sub: '2023.10.10',
  },
  {
    img: 'assets/img/community/community_img02.png',
    alt: '뉴스이미지',
    title: "이포넷, '메디아크'와 의료 AI 서비스 및 기술 개발 위한 MOU체결",
    sub: '2023.10.10',
  },
  {
    img: 'assets/img/community/community_img03.png',
    alt: '뉴스이미지',
    title: "이포넷, '메디아크'와 의료 AI 서비스 및 기술 개발 위한 MOU체결",
    sub: '2023.10.10',
  },
  {
    img: 'assets/img/community/community_img04.png',
    alt: '뉴스이미지',
    title: "이포넷, '메디아크'와 의료 AI 서비스 및 기술 개발 위한 MOU체결",
    sub: '2023.10.10',
  },
  {
    img: 'assets/img/community/community_img05.png',
    alt: '뉴스이미지',
    title: "이포넷, '메디아크'와 의료 AI 서비스 및 기술 개발 위한 MOU체결",
    sub: '2023.10.10',
  },
  {
    img: 'assets/img/community/community_img06.png',
    alt: '뉴스이미지',
    title: "이포넷, '메디아크'와 의료 AI 서비스 및 기술 개발 위한 MOU체결",
    sub: '2023.10.10',
  },
];

const sliderOption = {
  width: 520,
  height: 580,
  minHeight: 0,
  space: 10,
  start: 1,
  grabCursor: true,
  swipe: true,
  mouse: true,
  keyboard: false,
  layout: 'partialview',
  wheel: false,
  autoplay: true,
  instantStartLayers: false,
  loop: true,
  shuffle: false,
  preload: 0,
  heightLimit: true,
  autoHeight: false,
  smoothHeight: true,
  endPause: false,
  overPause: true,
  fillMode: 'fill',
  centerControls: true,
  startOnAppear: false,
  layersMode: 'center',
  autofillTarget: '',
  hideLayers: false,
  fullscreenMargin: 0,
  speed: 30,
  dir: 'h',
  parallaxMode: 'swipe',
  view: 'fadeWave',
};
const bulletsOption = { autohide: false, align: 'bottom', margin: -30, space: 4 };
const arrowOption = { autohide: true, overVideo: true };
const SampleCommunityComponent = () => {
  /**
   * Data
   */
  const [slidesInfo, setSlidesDef] = useState<CommunityRVO[]>([]);

  /**
   * Func
   */
  const fetch = () => {
    const _slidesInfo = data.map((ele) => {
      return { imgSrc: ele.img, imgAlt: ele.alt, title: ele.title, subTitle: ele.sub };
    });
    setSlidesDef(_slidesInfo);
  };

  useLayoutEffect(() => {
    fetch();
  }, []);

  return (
    <FrameRow>
      <FrameCol textAlign={'text-center'}>
        <h1 className="mb-30 mt-5">
          <span>COMMUNITY</span>
        </h1>
        <p className="section-sub-title mb-40">이포넷에는 다양한 스토리가 있어요</p>
        <CustomATag href="#" className="btn btn-outline-primary btn-rounded fs-14">
          커뮤니티 전체 보기 <i className="glyphicon icon icon-arrow-32"></i>
        </CustomATag>
      </FrameCol>
      <FrameCol addClassName="p-0">
        <MasterSliderLayout sliderOption={sliderOption} arrowOption={arrowOption} bulltesOption={bulletsOption} id="masterslider2" className="master-slider ms-skin-default" titleAreaClassName="ms-info">
          {slidesInfo.map((ele, idx) => {
            return (
              <div className="ms-slide" key={`SampleCommunityComponentMasterSliderLayout${idx}`}>
                <h4 className="com-title">Notice</h4>
                <div className="bgimgWrap" style={{ backgroundImage: `url(${ele.imgSrc})` }}></div>
                <div className="ms-info">
                  <p className="title">
                    <a href="#">{ele.title}</a>
                  </p>
                  <p className="date">{ele.subTitle}</p>
                </div>
              </div>
            );
          })}
        </MasterSliderLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default SampleCommunityComponent;
