import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import { useTranslation } from 'react-i18next';

const sliderOption = {
  width: 520,
  height: 580,
  minHeight: 0,
  space: 10,
  start: 1,
  grabCursor: true,
  swipe: true,
  mouse: true,
  keyboard: false,
  layout: 'partialview',
  wheel: false,
  autoplay: true,
  instantStartLayers: false,
  loop: true,
  shuffle: false,
  preload: 0,
  heightLimit: true,
  autoHeight: false,
  smoothHeight: true,
  endPause: false,
  overPause: true,
  fillMode: 'fill',
  centerControls: true,
  startOnAppear: false,
  layersMode: 'center',
  autofillTarget: '',
  hideLayers: false,
  fullscreenMargin: 0,
  speed: 10,
  dir: 'h',
  parallaxMode: 'swipe',
  view: 'fadeWave',
};

const arrowOption = { autohide: true, overVideo: true };
const bulletsOption = { autohide: true, align: 'bottom', margin: -30, space: 4 };

const PatentComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-0">
        <h3 className="fColor-yellow">{t('about.patent1')}</h3>
      </FrameCol>
      <FrameCol addClassName="text-center">
        <h1 className="mb-30 mt-5">
          <span>Patent</span>
        </h1>
        <p className="section-sub-title mb-40">{t('about.patent2')}</p>
      </FrameCol>
      <FrameCol addClassName="p-0">
        <MasterSliderLayout sliderOption={sliderOption} arrowOption={arrowOption} bulltesOption={bulletsOption} id="masterslider2" className="master-slider ms-skin-default">
          {/* 특허증 슬라이드 */}
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle1')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_001.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title1')}</p>
              <p className="date">{t('about.patent.date1')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle2')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_002.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title2')}</p>
              <p className="date">{t('about.patent.date2')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle3')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_003.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title3')}</p>
              <p className="date">{t('about.patent.date3')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle4')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_004.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title4')}</p>
              <p className="date">{t('about.patent.date4')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle5')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_005.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title5')}</p>
              <p className="date">{t('about.patent.date5')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle6')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_006.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title6')}</p>
              <p className="date">{t('about.patent.date6')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle7')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_007.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title7')}</p>
              <p className="date">{t('about.patent.date7')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle8')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_008.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title8')}</p>
              <p className="date">{t('about.patent.date8')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle9')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_009.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title9')}</p>
              <p className="date">{t('about.patent.date9')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle10')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_010.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title10')}</p>
              <p className="date">{t('about.patent.date10')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle11')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_011.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title11')}</p>
              <p className="date">{t('about.patent.date11')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle12')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_012.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title12')}</p>
              <p className="date">{t('about.patent.date12')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle13')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_013.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title13')}</p>
              <p className="date">{t('about.patent.date13')}</p>
            </div>
          </div>
          <div className="ms-slide">
            <h4 className="com-title">{t('about.patent.comTitle14')}</h4>
            <div className="PatentWrap">
              <CustomImgTag src="assets/img/history/patent_014.png" alt={t('about.patent3')} />
            </div>
            <div className="ms-info">
              <p className="title">{t('about.patent.title14')}</p>
              <p className="date">{t('about.patent.date14')}</p>
            </div>
          </div>
          {/*end 특허증 슬라이드 */}
        </MasterSliderLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default PatentComponent;
