import { selectCommunityList } from 'api/board/BoardAPI';
import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { BoardRVO } from 'types/board';
import { useEffectOnce } from 'usehooks-ts';
import { isSuccess } from 'util/ApiResponseUtil';

// 게시물 리스트
const communityListData = [
  {
    brdNm: '',
    postNo: '',
    subject: '',
    imgFile: '',
    regDt: '',
    pstgYmd: '',
    content: '',
    nextPostNo: 0,
    prevPostNo: 0,
    totalCount: 0,
    e4Count: 0,
    noticeCount: 0,
    pageNo: 1,
    home: '',
  },
];

const sliderOption = {
  width: 520,
  height: 580,
  minHeight: 0,
  space: 10,
  start: 1,
  grabCursor: true,
  swipe: true,
  mouse: true,
  keyboard: false,
  layout: 'partialview',
  wheel: false,
  autoplay: true,
  instantStartLayers: false,
  loop: true,
  shuffle: false,
  preload: 0,
  heightLimit: true,
  autoHeight: false,
  smoothHeight: true,
  endPause: false,
  overPause: true,
  fillMode: 'fill',
  centerControls: true,
  startOnAppear: false,
  layersMode: 'center',
  autofillTarget: '',
  hideLayers: false,
  fullscreenMargin: 0,
  speed: 30,
  dir: 'h',
  parallaxMode: 'swipe',
  view: 'fadeWave',
};

const arrowOption = { autohide: true, overVideo: true };
const bulletsOption = { autohide: false, align: 'bottom', margin: -30, space: 4 };

const CommunityComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [communityList, setCommunityList] = useState<BoardRVO[]>(communityListData);
  const [isLoading, setIsLoading] = useState(false);

  const getCommunityList = async () => {
    const itemCountPerPage = 10;
    await selectCommunityList({ rowsPage: itemCountPerPage })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setCommunityList(resData);
          }
          setIsLoading(true);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffectOnce(() => {
    getCommunityList();
  });

  return (
    <FrameRow>
      <FrameCol addClassName="text-center">
        <h1 className="mb-30 mt-5">
          <span>{t('homeMain.community1')}</span>
        </h1>
        <p className="section-sub-title mb-40">{t('homeMain.community2')}</p>
        <CustomATag href="/community" className="btn btn-outline-primary btn-rounded fs-14">
          {t('homeMain.community3')} <i className="glyphicon icon icon-arrow-32"></i>
        </CustomATag>
      </FrameCol>
      <FrameCol addClassName="p-0">
        <MasterSliderLayout sliderOption={sliderOption} arrowOption={arrowOption} bulltesOption={bulletsOption} id="masterslider2" className="master-slider ms-skin-default">
          {isLoading &&
            communityList.map((ele, idx) => {
              return (
                <div className="ms-slide" key={`${idx}`}>
                  <h4 className="com-title">{ele.brdNm}</h4>
                  <div
                    className="bgimgWrap"
                    style={{ background: `url(${!ele.imgFile && ele.brdNm == 'E4.STORY' ? 'assets/img/community/e4-story.jpg' : !ele.imgFile && ele.brdNm == 'NOTICE' ? 'assets/img/community/notice.jpg' : ele.imgFile})`, cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/community/' + `${ele.brdNo}` + '/' + `${ele.postNo}`, {
                        state: { data: ele },
                      });
                    }}
                  ></div>
                  <div className="ms-info">
                    <p className="title">
                      <Link to={`/community/${ele.brdNo}/${ele.postNo}`} state={{ data: ele }}>
                        {ele.subject}
                      </Link>
                    </p>
                    <p className="date">{ele.regDt}</p>
                  </div>
                </div>
              );
            })}
        </MasterSliderLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default CommunityComponent;
