import GoogleMapComponent, { GoogleMapComponentProps } from 'common/view/components/GoogleMapComponent';
import { useCopyClipBoard } from 'hooks/common/CommonHook';
import { useTranslation } from 'react-i18next';
import { styleCursorPointer } from 'util/StyleUtil';

interface GoogleMapLayoutProps extends GoogleMapComponentProps {
  address: string;
  title?: string;
  postcode: string;
  srcToCopy: string;
}
const GoogleMapLayout = ({ title, address, postcode, googleMapSrc, srcToCopy }: GoogleMapLayoutProps) => {
  const { t } = useTranslation();

  const { onCopy } = useCopyClipBoard(t('googleMap.copy1') + '\n' + t('googleMap.copy2'));

  return (
    <>
      <GoogleMapComponent googleMapSrc={googleMapSrc} />
      <div className="map-address">
        <p className="title">{title}</p>
        <p className="address">
          ({postcode})&nbsp;{address}
        </p>
        <p className="share">
          <a className="btn btn-outline-primary btn-rounded" style={styleCursorPointer} onClick={() => onCopy(srcToCopy)}>
            <span>{t('googleMap.share1')}</span>
            <i className="fa fa-share-alt"></i>
          </a>
        </p>
      </div>
    </>
  );
};

export default GoogleMapLayout;
