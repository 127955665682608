import SectionLayout from 'common/view/layouts/section/SectionLayout';
import { useBodyId } from 'hooks/common/CommonHook';

import BlockChainLabComponent from './BlockChainLabComponent';
import CounterComponent from './CounterComponent';
import DownloadComponent from './DownloadComponent';
import PartnerComponent from './PartnerComponent';
import VisualAreaComponent from './VisualAreaComponent';

const ItMainPage = () => {
  useBodyId('it_main');

  return (
    <>
      <section>
        <VisualAreaComponent />
      </section>
      <SectionLayout addSectionClassName="counter-primary" addContianerClassName="fluid">
        <CounterComponent />
      </SectionLayout>
      <SectionLayout addSectionClassName="it-download bg-gray" addContianerClassName="fluid">
        <DownloadComponent />
      </SectionLayout>
      <SectionLayout addSectionClassName="counter-primary" addContianerClassName="container-fluid">
        <PartnerComponent />
      </SectionLayout>
      <SectionLayout addSectionClassName="counter-primary" addContianerClassName="fluid">
        <BlockChainLabComponent />
      </SectionLayout>
    </>
  );
};

export default ItMainPage;
