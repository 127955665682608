import { TabLayoutCommonProps } from 'types/common/PropsTypes';

import { FrameCol } from '../../frames';
import TabLayout from '../../tab/TabLayout';
import FooterMoreInfoModalImagePolicyLayout from './FooterMoreInfoModalImagePolicyLayout';
import FooterMoreInfoModalPersonalPolicyLayout from './FooterMoreInfoModalPersonalPolicyLayout';
import FooterMoreInfoModalPersonalProtectionLayout from './FooterMoreInfoModalPersonalProtectionLayout';

const FooterMoreInfoModalTabLayout = ({ currentTabIdx, setCurrentTabIdx }: TabLayoutCommonProps) => {
  return (
    <FrameCol size={'lg'} width={12}>
      <TabLayout
        tabLabelDef={['개인정보 처리방침', '영상정보처리기기 운영관리방침', '개인정보보호 내부관리계획']}
        currentTabIdx={currentTabIdx}
        setCurrentTabIdx={setCurrentTabIdx}
        isFromModal
        tabPanelDef={[<FooterMoreInfoModalPersonalPolicyLayout key={0} />, <FooterMoreInfoModalImagePolicyLayout key={1} />, <FooterMoreInfoModalPersonalProtectionLayout key={2} />]}
      />
    </FrameCol>
  );
};

export default FooterMoreInfoModalTabLayout;
