import { useResize } from 'hooks/common/ResizeHook';
import { useScript } from 'hooks/common/ScriptHook';
import { useLayoutEffect } from 'react';
import { CommonProps } from 'types/common/PropsTypes';

const TgGridLayout = ({ id, children }: CommonProps) => {
  const { status, importScript } = useScript();

  const { resizeRef } = useResize({ children });

  useLayoutEffect(() => {
    importScript('js/portfolio');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, children]);

  return (
    status === 'ready' && (
      <div ref={resizeRef} className="tg-grid-wrapper pracia-grid tg-nav-bg full-height mb-50" id={id} data-version="2.5.0">
        <div className="tg-grid-sizer"></div>
        <div className="tg-gutter-sizer"></div>
        {children}
      </div>
    )
  );
};

export default TgGridLayout;
