import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToAnchor = () => {
  const location = useLocation();
  const lastHash = useRef('');

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }
    const target = document.getElementById(lastHash.current);
    if (lastHash.current && target) {
      setTimeout(() => {
        const offset = location.state.offset;
        if (offset) {
          const elementPosition = target?.getBoundingClientRect().top;
          const pageOffset = window.scrollY || document.documentElement.scrollTop;
          const offsetPosition = elementPosition + pageOffset - offset;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        } else {
          target?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        lastHash.current = '';
      }, 100);
    }
  }, [location]);
};

export default useScrollToAnchor;
