import { CustomATag } from 'common/view/components/custom';
import { useRootUrl } from 'hooks/common/CommonHook';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

type MenuList = Array<{ path: string; labelName: string }>;
type HamburgerMenuProps = { menuList: MenuList; handleMenuClick: () => void };
type DMenuProps = { href: string; className: string; /*onClick: () => void;*/ strongText: string };

const menuList = [
  { path: '/', labelName: 'E4NET' },
  { path: '/about', labelName: 'ABOUT' },
  { path: '/news', labelName: 'NEWS' },
  { path: '/community', labelName: 'COMMUNITY' },
  { path: '/contact', labelName: 'CONTACT' },
  { path: '/career', labelName: 'CAREER' },
];

const itMenuList = [
  { path: '/it', labelName: 'IT SERVICE' },
  { path: '/it/service', labelName: 'SERVICE' },
  { path: '/it/portfolio', labelName: 'PORTFOLIO' },
  { path: '/it/contact', labelName: 'CONTACT' },
  { path: '/it/career', labelName: 'CAREER' },
];

const langMenuList = [
  { path: '/lang', labelName: 'LANGUAGE SERVICE' },
  { path: '/lang/profile', labelName: 'PROFILE' },
  { path: '/lang/service', labelName: 'SERVICE' },
  { path: '/lang/portfolio', labelName: 'PORTFOLIO' },
  { path: '/lang/contact', labelName: 'CONTACT' },
  { path: '/lang/career', labelName: 'CAREER' },
];

const HamburgerMenu: FC<HamburgerMenuProps> = ({ menuList, handleMenuClick }) => {
  const { pathname } = useLocation();

  return (
    <ul>
      {menuList.map((ele, idx) => (
        <li key={`hamburger${idx}`} className={pathname.startsWith(ele.path) ? 'active' : ''} onClick={handleMenuClick}>
          <CustomATag href={ele.path}>{ele.labelName}</CustomATag>
        </li>
      ))}
    </ul>
  );
};

const DMenu: FC<DMenuProps> = ({ href, className, /*onClick, */ strongText }) => {
  return (
    <>
      <CustomATag href={href} className={className} /*onClick={onClick} */>
        <strong>{strongText}</strong>
        <span className="arrow"></span>
      </CustomATag>
      &nbsp;
    </>
  );
};

const HeaderNavbarComponent = () => {
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { currentRootUrl: rootUrl } = useRootUrl();

  const handleMenuClick = useCallback(() => setIsMenuOpen((prev) => !prev), [setIsMenuOpen]);

  const renderMenuList = useCallback(() => {
    if (rootUrl === 'home' || rootUrl === 'ir') {
      return <HamburgerMenu menuList={menuList} handleMenuClick={handleMenuClick} />;
    }
    if (rootUrl === 'it') {
      return <HamburgerMenu menuList={itMenuList} handleMenuClick={handleMenuClick} />;
    }
    if (rootUrl === 'lang') {
      return <HamburgerMenu menuList={langMenuList} handleMenuClick={handleMenuClick} />;
    }
    return null;
  }, [rootUrl, handleMenuClick]);

  return (
    <div className="header-nav header-nav-top-line justify-content-end">
      <a href="#" className={`hamburger-button${isMenuOpen ? ' active' : ''}`} onClick={handleMenuClick}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </a>
      <div className={`overlay${isMenuOpen ? ' visible' : ''}`}>
        <nav className="menu">
          {renderMenuList()}
          <div className="dmenu">
            <div className="business">
              <label>{t('header.navbar1')}</label>
              {rootUrl === 'it' || rootUrl === 'lang' || rootUrl === 'ir' ? <DMenu href="/" className="e4svc" /*onClick={handleMenuClick}*/ strongText={t('header.navbar2')} /> : ''}
              {rootUrl === 'home' || rootUrl === 'lang' || rootUrl === 'ir' ? <DMenu href="/it" className="itsvc" /*onClick={handleMenuClick}*/ strongText={t('header.navbar3')} /> : ''}
              {rootUrl === 'home' || rootUrl === 'it' || rootUrl === 'ir' ? <DMenu href="/lang" className="langsvc" /*onClick={handleMenuClick}*/ strongText={t('header.navbar4')} /> : ''}
            </div>
            {/* 블로그 미운행으로 인한 주석처리 */}
            {/* <div className="blog">
              <label>{t('header.navbar5')}</label>
              <CustomATag href="https://e4blog.tistory.com/" target="_blank">
                {t('header.navbar6')} <span className="green">{t('header.navbar7')}</span>
                <span className="arrow rt-45"></span>
              </CustomATag>
            </div> */}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderNavbarComponent;
