import { insertCareer } from 'api/career/CareerAPI';
import { FrameCol } from 'common/view/layouts/frames';
import { ChangeEvent, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CareerFormPVO } from 'types/career/CareerFormPVO';
import { isSuccess } from 'util/ApiResponseUtil';
import { isEmailValid, isNameValid, isTelValid } from 'util/RegexUtil';
import { lg } from 'util/StyleUtil';

type UploadFile = {
  file: File;
};

const defaultFormInfo = {
  brdNo: 9,
  ctgr1Cd: '',
  usrNm: '',
  email: '',
  phone: '',
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  date: new Date().getDate(),
  file: null,
  fileNm: '',
  fileSz: 0,
  vrtNm: '',
};

const CareerModalFormLayout = ({ ctgr1Cd, isOpen, setIsOpen }: { ctgr1Cd: string; isOpen?: boolean; setIsOpen?: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { t } = useTranslation();

  const [formInfo, setFormInfo] = useState<CareerFormPVO>(defaultFormInfo);
  const [careerFile, setCareerFile] = useState<UploadFile | null>(null);
  let ctgrCd = '';
  if (ctgr1Cd === 'home') ctgrCd = '10';
  else if (ctgr1Cd === 'it') ctgrCd = '20';
  else if (ctgr1Cd === 'lang') ctgrCd = '30';

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, type } = e.target;

    if (name in formInfo) {
      if (type === 'file') {
        onFileChange(e as ChangeEvent<HTMLInputElement>);
      } else {
        onInputChange(e);
      }
    }

    setFormInfo((prev) => ({ ...prev, ['ctgr1Cd']: ctgrCd }));
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    let newValue: string | boolean = value;
    if (type === 'checkbox') newValue = (e.target as HTMLInputElement).checked;
    setFormInfo((prev) => ({ ...prev, [name]: newValue }));
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type.includes('wordprocessingml') || file.type.includes('msword')) {
        setFormInfo((prev) => ({ ...prev, [name]: file, fileNm: file.name, fileSz: file.size }));
        setCareerFile({ file: file });
      } else {
        alert(t('career.modalForm1'));
      }
    }
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append('file', careerFile?.file as Blob);
    formData.append('careerFormPVO', new Blob([JSON.stringify(formInfo)], { type: 'application/json' }));

    // 폼 유효성 검사
    if (formInfo.usrNm == '') return alert(t('career.modalForm2'));
    if (!isNameValid(formInfo.usrNm).res) return alert(t('career.modalForm3'));
    if (formInfo.email == '') return alert(t('career.modalForm4'));
    if (!isEmailValid(formInfo.email).res) return alert(t('career.modalForm5'));
    if (formInfo.phone == '') return alert(t('career.modalForm6'));
    if (!isTelValid(formInfo.phone).res) return alert(t('career.modalForm7'));
    if (formInfo.file == null) return alert(t('career.modalForm8'));

    if (confirm(t('career.modalForm9'))) {
      // 폼 전송
      await insertCareer(formData)
        .then((res) => {
          if (isSuccess(res.code)) {
            alert(t('career.modalForm10'));
            setIsOpen?.(false);
            setFormInfo(defaultFormInfo);
            setCareerFile(null);
          } else if (!res.response) {
            alert(t('career.modalForm11'));
          } else {
            alert(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(t('career.modalForm11'));
        });
    }
  };

  useLayoutEffect(() => {
    if (formInfo && !isOpen) {
      setFormInfo(defaultFormInfo);
      setCareerFile(null);
    }
  }, [isOpen]);

  return (
    <FrameCol size={lg}>
      <div className="smart-wrap">
        <div className="smart-forms smart-container">
          <form method="post" id="careerForm" name="careerForm" encType="multipart/form-data">
            <div className="form-body">
              <div className="frm-row">
                <label htmlFor="usrNm" className="field-label colm colm3">
                  {t('career.modalForm12')}
                </label>
                <div className="section colm colm9">
                  <label className="field prepend-icon">
                    <input type="text" name="usrNm" id="usrNm" className="gui-input" placeholder={t('career.modalForm2')} value={formInfo.usrNm} onChange={onChange} maxLength={20} />
                  </label>
                </div>
              </div>
              <div className="frm-row">
                <label htmlFor="email" className="field-label colm colm3">
                  {t('career.modalForm13')}
                </label>
                <div className="section colm colm9">
                  <label className="field prepend-icon">
                    <input type="email" name="email" id="email" className="gui-input" placeholder={t('career.modalForm14')} value={formInfo.email} onChange={onChange} maxLength={30} />
                  </label>
                </div>
              </div>
              <div className="frm-row">
                <label htmlFor="phone" className="field-label colm colm3">
                  {t('career.modalForm15')}
                </label>
                <div className="section colm colm9">
                  <label className="field prepend-icon">
                    <input type="tel" name="phone" id="telephone" className="gui-input" placeholder={t('career.modalForm6')} value={formInfo.phone} onChange={onChange} maxLength={20} />
                  </label>
                  <div className="section mt-10">
                    <span>
                      <i className="fa fa-exclamation-circle"></i> {t('career.modalForm16')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="frm-row">
                <label htmlFor="date" className="field-label colm colm3">
                  {t('career.modalForm17')}
                </label>
                <div className="section colm colm9">
                  <div className="frm-row">
                    <div className="section colm colm4">
                      <label className="field select">
                        <select id="cardyear" name="year" value={formInfo.year} onChange={onChange}>
                          <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                          <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                        </select>
                        <i className="arrow double"></i>
                      </label>
                    </div>
                    <div className="section colm colm4">
                      <label htmlFor="cardmonth" className="field select">
                        <select id="cardmonth" name="month" value={formInfo.month} onChange={onChange}>
                          <option value="01">01 - Jan</option>
                          <option value="02">02 - Feb</option>
                          <option value="03">03 - Mar</option>
                          <option value="04">04 - Apr</option>
                          <option value="05">05 - May</option>
                          <option value="06">06 - Jun</option>
                          <option value="07">07 - Jul</option>
                          <option value="08">08 - Aug</option>
                          <option value="09">09 - Sep</option>
                          <option value="10">10 - Oct</option>
                          <option value="11">11 - Nov</option>
                          <option value="12">12 - Dec</option>
                        </select>
                        <i className="arrow double"></i>
                      </label>
                    </div>
                    <div className="section colm colm4">
                      <label className="field select">
                        <select id="cardday" name="date" value={formInfo.date} onChange={onChange}>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                        <i className="arrow double"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frm-row">
                <label htmlFor="file" className="field-label colm colm3">
                  {t('career.modalForm18')}
                </label>
                <div className="section colm colm9">
                  <label className="field prepend-icon file">
                    <span className="button btn btn-md btn-outline-primary btn-rounded mgt-10 pb-15">
                      {t('career.modalForm19')} <i className="fa fa-upload5"></i>
                    </span>
                    <input type="file" className="gui-file" name="file" id="file" onChange={onChange} />
                    <input type="text" className="gui-input" id="uploader" placeholder={t('career.modalForm20')} value={formInfo.fileNm} readOnly />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-footer">
              <button type="button" className="btn btn-md standard btn-rounded" onClick={onSubmit}>
                {t('career.modalForm21')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </FrameCol>
  );
};

export default CareerModalFormLayout;
