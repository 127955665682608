import { selectITPortfolioList } from 'api/board/BoardAPI';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import SlidesJSLayout from 'common/view/layouts/slider/SlidesJSLayout';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortfolioRVO } from 'types/board';
import { useEffectOnce } from 'usehooks-ts';
import { isSuccess } from 'util/ApiResponseUtil';

// portfolio 배너 리스트
const portfolioBannerListData = [
  {
    categoryIds: '',
    categoryIdsNm: '',
    prjNm: '',
    remarks: '',
    imgFile: '',
    ctrtBgnYmd: '',
    ctrtEndYmd: '',
    clientName: '',
    totalCount: 1,
    _categoryIds: [''],
    _categoryIdsNm: [''],
  },
];

const PortfolioBannerComponent = () => {
  const { t } = useTranslation();

  const customSliderProgressRef = useRef<HTMLElement>(null);
  const customSliderSlidesLogRefRef = useRef<HTMLDivElement>(null);

  const [portfolioBannerList, setPortfolioBannerList] = useState<PortfolioRVO[]>(portfolioBannerListData);
  const [bannerIndex, setBannerIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const sliderOption = {
    width: 590,
    height: 390,
    pagination: false,
    play: {
      active: true,
      auto: true,
      interval: 5000,
      swap: true,
    },
    callback: {
      complete: (pageNo: number) => {
        setBannerIndex(pageNo - 1);
      },
    },
  };

  const getPortfolioBannerList = async () => {
    await selectITPortfolioList({ pageNo: 1, rprsYn: 'Y', category: '' })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            if (resData.length !== 0) {
              setPortfolioBannerList(resData);
              setIsLoading(true);
            } else {
              setPortfolioBannerList(portfolioBannerListData);
            }
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffectOnce(() => {
    getPortfolioBannerList();
  });

  return (
    <>
      <FrameRow>
        <FrameCol addClassName="text-center mb-0">
          <h1 className="mb-20 mt-50">
            <span>PORTFOLIO</span>
          </h1>
          <p className="section-sub-title mb-40">{t('itPortfolio.head1')}</p>
        </FrameCol>
        <FrameCol addClassName="bg-gray py-5">
          <div className="container">
            <FrameRow>
              <FrameCol width={6}>
                {isLoading && (
                  <SlidesJSLayout sliderId="Nslides" wrapperClassname="main-slides" sliderOption={sliderOption} noSlideController customProgressRef={customSliderProgressRef} customSlidesLogRef={customSliderSlidesLogRefRef}>
                    {portfolioBannerList &&
                      portfolioBannerList.map((ele, idx) => {
                        return <div key={`${idx}`} className="newsWrap" style={{ background: `url(${ele.imgFile ? 'https://ipfs.e4net.net/ipfs/' + ele.imgFile : 'assets/img/portfolio/lp_ai.jpg'})` }}></div>;
                      })}
                  </SlidesJSLayout>
                )}
              </FrameCol>
              <FrameCol width={6}>
                <div className="disWrap">
                  <div className="discrption">
                    <h2>{portfolioBannerList[bannerIndex].prjNm}</h2>
                    <p className="news-detail">{portfolioBannerList[bannerIndex].remarks}</p>
                    <p className="news-date">
                      {portfolioBannerList[bannerIndex].ctrtBgnYmd} ~ {portfolioBannerList[bannerIndex].ctrtEndYmd}
                    </p>
                  </div>
                </div>
                <div className="contWarp">
                  <div className="pg-bar">
                    <span ref={customSliderProgressRef} className="progress"></span>
                  </div>
                  <div ref={customSliderSlidesLogRefRef} id="slidesjs-log">
                    <span className="slidesjs-slide-number"></span> / <div className="totalCnt" style={{ display: 'inline' }}></div>
                  </div>
                  <div className="slides-navigation" id="slides-navigation"></div>
                </div>
              </FrameCol>
            </FrameRow>
          </div>
        </FrameCol>
      </FrameRow>
    </>
  );
};

export default PortfolioBannerComponent;
