import { TabLayoutCommonProps } from 'types/common/PropsTypes';

import ModalLayout from '../../modal/ModalLayout';
import FooterMoreInfoModalTabLayout from './FooterMoreInfoModalTabLayout';

export interface FooterMoreInfoModalLayoutProps extends TabLayoutCommonProps {
  modalId: string;
  modalTitleName?: string;
}

const FooterMoreInfoModalLayout = ({ modalId, modalTitleName, currentTabIdx, setCurrentTabIdx }: FooterMoreInfoModalLayoutProps) => {
  return (
    <ModalLayout modalId={modalId} titleName={modalTitleName}>
      <FooterMoreInfoModalTabLayout currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} />
    </ModalLayout>
  );
};

export default FooterMoreInfoModalLayout;
