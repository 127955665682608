import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import { useRootUrl } from 'hooks/common/CommonHook';
import React from 'react';
import { useTranslation } from 'react-i18next';

import HeaderNavbarComponent from './HeaderNavbarComponent';
import HeaderSelectLangComponent from './HeaderSelectLangComponent';

const HeaderLayout = () => {
  const { t } = useTranslation();

  const { currentRootUrl: rootUrl } = useRootUrl();

  return (
    <header id="header" className="header-narrow header-transparent" data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyStartAt': 1, 'stickySetTop': '1'}">
      <div className="header-body">
        <div className="header-container container">
          <div className="header-row">
            <div className="header-column">
              <div className="header-row">
                <div className="header-logo">
                  <CustomATag href="/">
                    <CustomImgTag alt="E4NET" width="106" height="69" src="/assets/img/logo-white.png" />
                  </CustomATag>
                  <CustomATag href={rootUrl === 'it' ? '/it' : rootUrl === 'lang' ? '/lang' : '/'}>
                    <span>{rootUrl === 'it' ? t('header.layout1') : rootUrl === 'lang' ? t('header.layout2') : ''}</span>
                  </CustomATag>
                </div>
              </div>
            </div>

            <div className="header-column justify-content-end">
              <HeaderSelectLangComponent />
              <div className="header-row">
                <HeaderNavbarComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(HeaderLayout);
