import { PaginationComponent } from 'common/view/components';
import React, { useEffect, useMemo, useState } from 'react';
import { paginationProps } from 'types/common/PropsTypes';

export type PaginationLayoutProps = {
  pagination: paginationProps;
  pagingOnClick: (pageNo: number) => void; // setState
  //serachInfo: RequestVO; // request param
};

const PaginationLayout = ({ pagination, pagingOnClick }: PaginationLayoutProps) => {
  const { totalCount, itemCountPerPage = 10, pageCountPerPageArea = 5, currentPageNo } = pagination;

  const [pageNo, setPageNo] = useState(currentPageNo);

  useEffect(() => {
    setPageNo(currentPageNo);
  }, [currentPageNo]);

  const handlePagingClick = (pageNo: number) => {
    setPageNo(pageNo);
    pagingOnClick(pageNo);
  };

  const { totalPageCount, currentPageAreaNo, isHavePrev, isHaveNext } = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / itemCountPerPage); // 총 페이지 개수
    const currentPageAreaNo = Math.ceil(pageNo / pageCountPerPageArea); // 마지막 page area의 페이지 수
    const maxPageAreaNo = Math.ceil(totalCount / (itemCountPerPage * pageCountPerPageArea)); // 현재 내가 있는 pageArea의 번호
    const isHavePrev = currentPageAreaNo > 1; // 이전 page area가 존재하는가
    const isHaveNext = currentPageAreaNo < maxPageAreaNo; // 다음 page area가 존재하는가
    //   const firstOfSeeNo = currentPageNo * pageCountPerPageArea -  + 1; // 현재 페이지에서 첫번째로 보일 인덱스 번호
    //   const lastOfSeeNo = currentPageNo * pageCountPerPageArea pageCountPerPageArea< totalCount ? currentPageNo * pageCountPerPageArea : totalCount; // 현재 페이지에서 마지막로 보일 인덱스 번호
    return { totalPageCount, currentPageAreaNo, isHavePrev, isHaveNext };
  }, [totalCount, itemCountPerPage, pageCountPerPageArea, pageNo]);

  const pageNumbers = useMemo(() => {
    const startPage = (currentPageAreaNo - 1) * pageCountPerPageArea + 1;
    const endPage = Math.min(startPage + pageCountPerPageArea - 1, totalPageCount);
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  }, [currentPageAreaNo, pageCountPerPageArea, totalPageCount]);

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        {isHavePrev && <PaginationComponent labelName="Previous" onClick={() => handlePagingClick((currentPageAreaNo - 1) * pageCountPerPageArea)} isActive={false} />}
        {pageNumbers.map((pageNumber) => (
          <PaginationComponent
            key={pageNumber}
            labelName={pageNumber.toString()}
            onClick={() => {
              handlePagingClick(pageNumber);
            }}
            isActive={pageNo === pageNumber}
          />
        ))}
        {isHaveNext && <PaginationComponent labelName="Next" onClick={() => handlePagingClick(currentPageAreaNo * pageCountPerPageArea + 1)} isActive={false} />}
      </ul>
    </nav>
  );
};

export default React.memo(PaginationLayout); // memoization
