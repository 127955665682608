/************************************************************
 * API RESPONSE CODE
 ************************************************************/
const ARC_SUCCESS_CODE = '0000';

/**
 * response code 정상인지 반환
 * @param code response code
 * @returns 정상여부
 */
const isSuccess = (code: string) => {
  return code === ARC_SUCCESS_CODE;
};

export { isSuccess };
