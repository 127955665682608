import FramePageInner from 'common/view/layouts/frames/FramePageInner';
import SectionLayout from 'common/view/layouts/section/SectionLayout';
import { useBodyId } from 'hooks/common/CommonHook';

import SampleCommunityComponent from './SampleCommunityComponent';
import SampleDepartmentsComponent from './SampleDepartmentsComponent';
import SampleMainComponent from './SampleMainComponent';
import SampleNewsComponent from './SampleNewsComponent';
import SampleTistoryComponent from './SampleTistoryComponent';
import SampleVideoComponent from './SampleVideoComponent';

const SamplePage = () => {
  useBodyId('main');

  return (
    <>
      <section>
        <SampleMainComponent />
      </section>
      <FramePageInner>
        <SectionLayout addSectionClassName="main-color">
          <SampleDepartmentsComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="video-bg">
          <SampleVideoComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="main-color" addContianerClassName="fluid">
          <SampleCommunityComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="news-color b-bordered">
          <SampleNewsComponent />
        </SectionLayout>
        <SectionLayout addSectionClassName="tistory b-bordered">
          <SampleTistoryComponent />
        </SectionLayout>
      </FramePageInner>
    </>
  );
};

export default SamplePage;
