import { TabLayoutCommonProps } from 'types/common/PropsTypes';

import ModalLayout from '../../modal/ModalLayout';
import FooterMoreEmailModalTabLayout from './FooterMoreEmailModalTabLayout';

export interface FooterMoreEmailModalLayoutProps extends TabLayoutCommonProps {
  modalId: string;
  modalTitleName?: string;
}

const FooterMoreEmailModalLayout = ({ modalId, modalTitleName, currentTabIdx, setCurrentTabIdx }: FooterMoreEmailModalLayoutProps) => {
  return (
    <ModalLayout modalId={modalId} titleName={modalTitleName}>
      <FooterMoreEmailModalTabLayout currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} />
    </ModalLayout>
  );
};

export default FooterMoreEmailModalLayout;
