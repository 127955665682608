import FramePageInner from 'common/view/layouts/frames/FramePageInner';
import { useBodyId } from 'hooks/common/CommonHook';
import { Outlet } from 'react-router-dom';

const SampleNewsLayout = () => {
  useBodyId('news');

  return (
    <FramePageInner>
      <Outlet />
    </FramePageInner>
  );
};

export default SampleNewsLayout;
