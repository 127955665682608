import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import { useRootUrl } from 'hooks/common/CommonHook';
import { useScript } from 'hooks/common/ScriptHook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'usehooks-ts';

const QuickMenuLayout = () => {
  const { t } = useTranslation();

  const { importScript } = useScript();

  const { currentRootUrl: rootUrl } = useRootUrl();

  useEffectOnce(() => {
    importScript(['js/theme']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <div className="quickmenu exp-animation" data-animation="top-to-bottom" data-delay="800">
      <div className="learn-more">
        <span className="icon more"></span>
        <span className="button-text">
          <CustomImgTag src="assets/img/icons/icon_phone.png" className="phone mr-2" />
          {t('quickMenu.phone1')}
          <strong>{rootUrl === 'it' ? '02-3465-8501' : rootUrl === 'lang' ? '02-3465-8530' : '02-3465-8500'}</strong>
        </span>
      </div>
      <div className="learn-more">
        <span className="icon more2"></span>
        {rootUrl === 'it' || rootUrl === 'lang' || rootUrl === 'ir' ? (
          <span className="button-text">
            <CustomATag href="/" className="e4sev">
              {t('quickMenu.sev1')}
              <span className="circle">
                <i className="fa fa-angle-right"></i>
              </span>
            </CustomATag>
          </span>
        ) : (
          ''
        )}
        {rootUrl === 'home' || rootUrl === 'lang' || rootUrl === 'ir' ? (
          <span className="button-text">
            <CustomATag href="/it" className="itsev">
              {t('quickMenu.sev2')}
              <span className="circle">
                <i className="fa fa-angle-right"></i>
              </span>
            </CustomATag>
          </span>
        ) : (
          ''
        )}
        {rootUrl === 'home' || rootUrl === 'it' || rootUrl === 'ir' ? (
          <span className="button-text">
            <CustomATag href="/lang" className="langsev">
              {t('quickMenu.sev3')}
              <span className="circle">
                <i className="fa fa-angle-right"></i>
              </span>
            </CustomATag>
          </span>
        ) : (
          ''
        )}
      </div>
      <div className="learn-more2">
        <span className="icon2">
          <CustomImgTag src="assets/img/icons/icon_aitrans.png" />
        </span>
        <CustomATag href="https://aitrans.e4net.net/" target="_blank">
          <span className="icon arrow"></span>
        </CustomATag>
        <span className="button-text">
          <CustomATag href="https://aitrans.e4net.net/" target="_blank">
            {t('quickMenu.link1')}
            <CustomImgTag src="assets/img/icons/image_aitrans.png" className="ml-2" />
          </CustomATag>
        </span>
      </div>
      <div className="learn-more2">
        <span className="icon2">
          <CustomImgTag src="assets/img/icons/icon_bclab.png" />
        </span>
        <CustomATag href="/it/service#tab05">
          <span className="icon arrow"></span>
        </CustomATag>
        <span className="button-text">
          <CustomATag href="/it/service#tab05">
            {t('quickMenu.link2')}
            <CustomImgTag src="assets/img/icons/image_bclab.png" className="ml-2" />
          </CustomATag>
        </span>
      </div>
      <div className="learn-more2 cherry">
        <span className="icon2">
          <CustomImgTag src="assets/img/icons/icon_cherry.png" />
        </span>
        <CustomATag href="https://givecherry.org/" target="_blank">
          <span className="icon arrow"></span>
        </CustomATag>
        <span className="button-text">
          <CustomATag href="https://givecherry.org/" target="_blank">
            {t('quickMenu.link3')}
            <CustomImgTag src="assets/img/icons/image_cherry.png" className="ml-2" />
          </CustomATag>
        </span>
      </div>
    </div>
  );
};

export default React.memo(QuickMenuLayout);
