export const isEmailValid = (value: string) => {
  if (value.length === 0) return { res: true };
  const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/i;
  const res = regex.test(value);
  return { res };
  //   if (res) return { res };
  // else return { res,  message: '이메일 형식만 입력 가능합니다.' }
};

export const isTelValid = (value: string) => {
  const length = value.length;
  if (length === 0) return { res: false };
  const regex = /^\+?([0-9]+-)+?[0-9]+$/i;
  // switch (length) {
  //   case 11:
  //     regex = /^[0-9]{2}-[0-9]{3}-[0-9]{4}$/i;
  //     break;
  //   case 12:
  //     regex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
  //     break;
  //   case 13:
  //     regex = /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/i;
  //     break;
  //   default:
  //     return { res: false /*, message: '전화번호를 올바르게 입력하세요.' */ };
  // }
  const res = regex.test(value);
  return { res };
  //   if (res) return { res };
  // else return { res,  message: '전화번호를 올바르게 입력하세요.' }
};

export const isNameValid = (value: string) => {
  if (value.length === 0) return { res: true };
  const regex = /^[가-힣A-Za-z\s]+$/;
  const res = regex.test(value);
  return { res };
  //   if (res) return { res };
  // else return { res,  message: '올바른 이름을 입력해주세요.' }
};
