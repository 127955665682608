import { usePageMoved } from 'hooks/common/CommonHook';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

interface CustomATagProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  target?: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  state?: any;
  onClick?: () => void;
}
const CustomATag = ({ children, href, className, target, state, onClick }: CustomATagProps) => {
  const { pathname, instantlyToTop } = usePageMoved();

  const handleClickLink = useCallback(() => {
    onClick?.();
    if (pathname === href) instantlyToTop();
  }, [pathname, href, instantlyToTop, onClick]);

  return (
    <Link to={href ?? '#'} state={state} rel={target === '_target' ? 'noreferrer' : ''} target={target ?? ''} className={className} onClick={handleClickLink}>
      {children}
    </Link>
  );
};

export default CustomATag;
