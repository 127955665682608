import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ContactLinkComponent = (props: { rootUrl: string }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <FrameRow>
      <FrameCol textAlign="text-center">
        <h2>{t('contact.link1')}</h2>
        <FrameRow>
          {props.rootUrl === 'it' || props.rootUrl === 'lang' ? (
            <FrameCol width={6}>
              <div className="e4net-contact">
                <p className="title">{t('contact.link2')}</p>
                <button
                  className="btn"
                  onClick={() => {
                    navigate('/contact');
                  }}
                >
                  {t('contact.link3')} <span className="arrow"></span>
                </button>
              </div>
            </FrameCol>
          ) : (
            ''
          )}
          {props.rootUrl === 'home' || props.rootUrl === 'lang' ? (
            <FrameCol width={6}>
              <div className="it-contact">
                <p className="title">{t('contact.link4')}</p>
                <button
                  className="btn"
                  onClick={() => {
                    navigate('/it/contact');
                  }}
                >
                  {t('contact.link5')} <span className="arrow"></span>
                </button>
              </div>
            </FrameCol>
          ) : (
            ''
          )}
          {props.rootUrl === 'home' || props.rootUrl === 'it' ? (
            <FrameCol width={6}>
              <div className="tng-contact">
                <p className="title">{t('contact.link6')}</p>
                <button
                  className="btn"
                  onClick={() => {
                    navigate('/lang/contact');
                  }}
                >
                  {t('contact.link7')} <span className="arrow"></span>
                </button>
              </div>
            </FrameCol>
          ) : (
            ''
          )}
        </FrameRow>
      </FrameCol>
    </FrameRow>
  );
};

export default ContactLinkComponent;
