import { ChangeEvent, useState } from 'react';
import { ContactFormPVO } from 'types/sample/contact/ContactFormPVO';
import { isEmailValid, isNameValid, isTelValid } from 'util/RegexUtil';

const defaultFormInfo = {
  names: '',
  email: '',
  telephone: '',
  comment: '',
  info: false,
  year: '2023',
  month: '12',
  date: '01',
  file: null,
  fileName: '',
};
// state는 밑으로 내리는 것이 좋으므로 form은 컴포넌트로 따로 빼는 것이 좋습니다.
const SampleContactContactFormComponent = () => {
  const [formInfo, setFormInfo] = useState<ContactFormPVO>(defaultFormInfo);
  // TODO 여기에 usecallback하는 것이 과연 맞을 지 고민
  // 유효성 검증 추가
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, type } = e.target;
    if (name in formInfo) {
      if (type === 'file') {
        onFileChange(e as ChangeEvent<HTMLInputElement>);
      } else {
        onInputChange(e);
      }
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    let newValue: string | boolean = value;
    if (type === 'checkbox') newValue = (e.target as HTMLInputElement).checked;
    setFormInfo((prev) => ({ ...prev, [name]: newValue }));
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setFormInfo((prev) => ({ ...prev, [name]: file, fileName: file.name })); // fileName: file.name 만약 파일이 여러개라면 동적으로 받도록 설정
    }
  };
  const onSubmit = () => {
    console.log('formInfo', formInfo);
    if (!isEmailValid(formInfo.email).res) return alert('이메일 형식이 잘못되었습니다.');
    if (!isNameValid(formInfo.names).res) return alert('이름이 잘못되었습니다.');
    if (!isTelValid(formInfo.telephone).res) return alert('전화번호 형식이 잘못되었습니다.');
    console.log('등록되었습니다.');
  };
  return (
    <div className="smart-wrap">
      <div className="smart-forms smart-container">
        <form method="post" id="">
          <div className="form-body">
            <div className="frm-row">
              <label htmlFor="names" className="field-label colm colm2">
                이름
              </label>
              <div className="section colm colm10">
                <label className="field prepend-icon">
                  <input type="text" name="names" id="names" className="gui-input" placeholder="이름을 입력하세요." value={formInfo.names} onChange={onChange} />
                </label>
              </div>
              {/* end section */}
            </div>

            <div className="frm-row">
              <label htmlFor="email" className="field-label colm colm2">
                이메일
              </label>
              <div className="section colm colm10">
                <label className="field prepend-icon">
                  <input type="email" name="email" id="email" className="gui-input" placeholder="이메일 (예:example@doma력n.com)" value={formInfo.email} onChange={onChange} />
                </label>
              </div>
            </div>

            <div className="frm-row">
              <label htmlFor="telephone" className="field-label colm colm2">
                연락처
              </label>
              <div className="section colm colm10">
                <label className="field prepend-icon">
                  <input type="tel" name="telephone" id="telephone" className="gui-input" placeholder="연락처를 입렵하세요." value={formInfo.telephone} onChange={onChange} />
                </label>
                <div className="section mt-10">
                  <span>
                    <i className="fa fa-exclamation-circle"></i> 연락처에&apos;-&apos;를 포함하여 입력하세요.
                  </span>
                </div>
              </div>
            </div>
            <div className="frm-row">
              <label htmlFor="telephone" className="field-label colm colm3">
                입사가능일
              </label>
              {/* body의 id가 career여야 같은 퍼블의 select와 같은 디자인적용되니 참고바라고 여기서는 onChange로직만 보시길 바람 */}
              <div className="section colm colm9">
                <div className="frm-row">
                  <div className="section colm colm4">
                    <label className="field select">
                      <select id="cardyear3" name="year" value={formInfo.year} onChange={onChange}>
                        <option value="2023">2023 년</option>
                        <option value="2024">2024 년</option>
                        <option value="2025">2025 년</option>
                        <option value="2026">2026 년</option>
                      </select>
                      <i className="arrow double"></i>
                    </label>
                  </div>

                  <div className="section colm colm4">
                    <label htmlFor="cardmonth" className="field select">
                      <select id="cardmonth3" name="month" value={formInfo.month} onChange={onChange}>
                        <option value="01">01 - Jan</option>
                        <option value="02">02 - Feb</option>
                        <option value="03">03 - Mar</option>
                        <option value="04">04 - Apr</option>
                        <option value="05">05 - May</option>
                        <option value="06">06 - Jun</option>
                        <option value="07">07 - Jul</option>
                        <option value="08">08 - Aug</option>
                        <option value="09">09 - Sep</option>
                        <option value="10">10 - Oct</option>
                        <option value="11">11 - Nov</option>
                        <option value="12">12 - Dec</option>
                      </select>
                      <i className="arrow double"></i>
                    </label>
                  </div>

                  <div className="section colm colm4">
                    <label className="field select">
                      <select id="cardday3" name="date" value={formInfo.date} onChange={onChange}>
                        <option value="1">1 일</option>
                        <option value="2">2 일</option>
                        <option value="3">3 일</option>
                        <option value="4">4 일</option>
                        <option value="5">5 일</option>
                        <option value="6">6 일</option>
                        <option value="7">7 일</option>
                        <option value="8">8 일</option>
                        <option value="9">9 일</option>
                        <option value="10">10 일</option>
                      </select>
                      <i className="arrow double"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="frm-row">
              <label htmlFor="telephone" className="field-label colm colm3">
                이력서 파일 첨부
              </label>
              <div className="section colm colm9">
                <label className="field prepend-icon file">
                  <span className="button btn btn-md btn-outline-primary btn-rounded mgt-10 pb-15">
                    {' '}
                    파일첨부 <i className="fa fa-upload5"></i>
                  </span>
                  <input type="file" className="gui-file" name="file" id="file" onChange={onChange} />
                  <input type="text" className="gui-input" id="fileName" placeholder="파일을 선택하세요" value={formInfo.fileName} readOnly />
                </label>
              </div>
            </div>

            <div className="frm-row">
              <label htmlFor="telephone" className="field-label colm colm2">
                문의 내용
              </label>
              <div className="section colm colm10">
                <label className="field prepend-icon">
                  <textarea className="gui-textarea" id="comment" name="comment" placeholder="문의하실 내용이나 연락처를 남겨주시면 빠른 시일 내에 연락드리겠습니다." value={formInfo.comment} onChange={onChange}></textarea>
                </label>
              </div>
            </div>

            <div className="section text-center">
              <label className="field option option-yellow">
                <input type="checkbox" name="info" checked={formInfo.info} onChange={onChange} />
                <span className="checkbox"></span>
                개인정보 수집/이용동의{' '}
                <em className="small-text fine-grey">
                  {' '}
                  <a href="" data-toggle="modal" data-target="#termsModal">
                    (약관보기 - 퍼블과 sample/main 확인)
                  </a>{' '}
                </em>
              </label>
            </div>
          </div>
          <div className="form-footer pb-40">
            <button type="button" className="btn btn-primary w40p" onClick={onSubmit}>
              보내기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SampleContactContactFormComponent;
