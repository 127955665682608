import { selectHistoryList } from 'api/board/BoardAPI';
import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryRVO } from 'types/history';
import { useEffectOnce } from 'usehooks-ts';
import { isSuccess } from 'util/ApiResponseUtil';

type ContentDataProps = {
  content: string;
  contentId: number;
};

type TitleDataProps = {
  title: string;
  titleId: number;
};

type DataListProps = {
  contentList: ContentDataProps[];
  titleList: TitleDataProps[];
};

type MonthDataProps = {
  month: string;
  monthId: number;
  dataList: DataListProps;
};

type MonthListProps = {
  monthList: MonthDataProps[];
};

type HistoryListListComponentProps = {
  year: string;
  yearList: MonthListProps;
};

const sliderOption = {
  width: 510,
  height: 460,
  top: 0,
  minHeight: 0,
  space: 30,
  start: 1,
  grabCursor: true,
  swipe: true,
  mouse: true,
  keyboard: false,
  layout: 'partialview',
  wheel: false,
  autoplay: true,
  instantStartLayers: false,
  loop: true,
  shuffle: false,
  preload: 0,
  heightLimit: true,
  autoHeight: false,
  smoothHeight: true,
  endPause: false,
  overPause: true,
  fillMode: 'fill',
  centerControls: true,
  startOnAppear: true,
  layersMode: 'center',
  autofillTarget: '',
  hideLayers: false,
  fullscreenMargin: 0,
  speed: 20,
  dir: 'h',
  parallaxMode: 'swipe',
  view: 'basic',
};

const arrowOption = { autohide: false, soverVideo: true };
const bulletsOption = { autohide: false, align: 'bottom', margin: 10, space: 4 };

const HistoryComponent = () => {
  const { t, i18n } = useTranslation();

  const [hitoryListDataKo, setHitoryListDataKo] = useState<HistoryRVO[]>();
  const [hitoryListDataEn, setHitoryListDataEn] = useState<HistoryRVO[]>();
  const [hitoryListDataCh, setHitoryListDataCh] = useState<HistoryRVO[]>();
  const [historyList, setHistoryList] = useState<HistoryListListComponentProps[]>([]);
  const [isLoading, setIsLoading] = useState(false); // 임시 로딩처리
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  const fnHistoryListKo = async () => {
    const today = new Date();
    await selectHistoryList({ langType: '10', currentYear: today.getFullYear().toString() })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setHitoryListDataKo(resData);
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const fnHistoryListEn = async () => {
    const today = new Date();
    await selectHistoryList({ langType: '20', currentYear: today.getFullYear().toString() })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setHitoryListDataEn(resData);
            setIsLoading(true);
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const fnHistoryListCh = async () => {
    const today = new Date();
    await selectHistoryList({ langType: '30', currentYear: today.getFullYear().toString() })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setHitoryListDataCh(resData);
            setIsLoading(true);
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffectOnce(() => {
    fnHistoryListKo();
    fnHistoryListEn();
    fnHistoryListCh();
  });

  const onloadDataKo = () => {
    let _monthId = 1000;
    let _contentId = 2000;
    let _titleId = 3000;

    const _historyList: HistoryListListComponentProps[] = []; // 최종, year 데이터들이 담김
    let _dataList: DataListProps = { contentList: [], titleList: [] }; // ContentDataProps의 배열, titleList의 배열
    let _monthList: MonthListProps = { monthList: [] }; // MonthDataProps의 배열

    hitoryListDataKo?.forEach((ele) => {
      const _titleData: TitleDataProps = { title: '', titleId: 0 }; // DataListProps를의 titleList에 push 됨
      if (ele.content !== '' && ele.content !== null) {
        const _contentData: ContentDataProps = { content: '', contentId: 0 }; // DataListProps를의 contentList에 push 됨
        _contentData.content = ele.content;
        _contentData.contentId = _contentId++;
        // 반복문 내에 새로 만든 _contentData에 값 지정 후 _dataList의 contentList에 push
        // (content.content.includes('png') || content.content.includes('jpg')) 일 때에만 화면에 보여줄 것이니 여기서 필터링
        if (_contentData.content.includes('png') || _contentData.content.includes('jpg')) _dataList.contentList.push(_contentData);
      }
      _titleData.title = ele.title;
      _titleData.titleId = _titleId++;
      _dataList.titleList.push(_titleData); // 반복문 내에 새로 만든 _titleData 값 지정 후 _dataList의 titleData에 push

      // // ele.nextYearCompare === 'N' => 다음에 오는 데이터랑 지금의 데이터가 연도가 같지 않음! => 원래는 year에다가 push 함
      // // ele.nextMonthCompare === 'N' => 다음에 오는 데이터랑 지금의 데이터가 월이 같지 않음! => monthList에다가 set함
      // // 다만 예외케이스로 2017/12 다음에 데이터가 바로 2016/12인 경우  ===>>> ele.nextYearCompare === 'N' && ele.nextMonthCompare === 'Y'

      if (ele.nextMonthCompare === 'N' || (ele.nextYearCompare === 'N' && ele.nextMonthCompare === 'Y')) {
        const _monthData: MonthDataProps = { dataList: { contentList: [], titleList: [] }, month: '', monthId: _monthId }; // 데이타리스트를 DataListProps를 갖고, 월 정보를 갖고 있다.
        _monthData.month = ele.mm;
        _monthData.monthId = _monthId++;
        _monthData.dataList = _dataList;
        _monthList.monthList.push(_monthData); // 이제 이번달 혹은 이번 년도가 끝나면 _monthData마무리, _dataList세팅
        _dataList = { contentList: [], titleList: [] }; // 필요. 왜냐하면 _monthData.dataList를 새로 넣어주려면 새 거여야 하니깐
      }
      if (ele.nextYearCompare === 'N') {
        _historyList.push({ year: ele.yy, yearList: _monthList });
        _monthList = { monthList: [] };
      }
    });
    setHistoryList(_historyList);
  };

  const onloadDataEn = () => {
    let _monthId = 1000;
    let _contentId = 2000;
    let _titleId = 3000;

    const _historyList: HistoryListListComponentProps[] = []; // 최종, year 데이터들이 담김
    let _dataList: DataListProps = { contentList: [], titleList: [] }; // ContentDataProps의 배열, titleList의 배열
    let _monthList: MonthListProps = { monthList: [] }; // MonthDataProps의 배열

    hitoryListDataEn?.forEach((ele) => {
      const _titleData: TitleDataProps = { title: '', titleId: 0 }; // DataListProps를의 titleList에 push 됨
      if (ele.content !== '' && ele.content !== null) {
        const _contentData: ContentDataProps = { content: '', contentId: 0 }; // DataListProps를의 contentList에 push 됨
        _contentData.content = ele.content;
        _contentData.contentId = _contentId++;
        // 반복문 내에 새로 만든 _contentData에 값 지정 후 _dataList의 contentList에 push
        // (content.content.includes('png') || content.content.includes('jpg')) 일 때에만 화면에 보여줄 것이니 여기서 필터링
        if (_contentData.content.includes('png') || _contentData.content.includes('jpg')) _dataList.contentList.push(_contentData);
      }
      _titleData.title = ele.title;
      _titleData.titleId = _titleId++;
      _dataList.titleList.push(_titleData); // 반복문 내에 새로 만든 _titleData 값 지정 후 _dataList의 titleData에 push

      // // ele.nextYearCompare === 'N' => 다음에 오는 데이터랑 지금의 데이터가 연도가 같지 않음! => 원래는 year에다가 push 함
      // // ele.nextMonthCompare === 'N' => 다음에 오는 데이터랑 지금의 데이터가 월이 같지 않음! => monthList에다가 set함
      // // 다만 예외케이스로 2017/12 다음에 데이터가 바로 2016/12인 경우  ===>>> ele.nextYearCompare === 'N' && ele.nextMonthCompare === 'Y'

      if (ele.nextMonthCompare === 'N' || (ele.nextYearCompare === 'N' && ele.nextMonthCompare === 'Y')) {
        const _monthData: MonthDataProps = { dataList: { contentList: [], titleList: [] }, month: '', monthId: _monthId }; // 데이타리스트를 DataListProps를 갖고, 월 정보를 갖고 있다.
        _monthData.month = ele.mm;
        _monthData.monthId = _monthId++;
        _monthData.dataList = _dataList;
        _monthList.monthList.push(_monthData); // 이제 이번달 혹은 이번 년도가 끝나면 _monthData마무리, _dataList세팅
        _dataList = { contentList: [], titleList: [] }; // 필요. 왜냐하면 _monthData.dataList를 새로 넣어주려면 새 거여야 하니깐
      }
      if (ele.nextYearCompare === 'N') {
        _historyList.push({ year: ele.yy, yearList: _monthList });
        _monthList = { monthList: [] };
      }
    });
    setHistoryList(_historyList);
  };

  const onloadDataCh = () => {
    let _monthId = 1000;
    let _contentId = 2000;
    let _titleId = 3000;

    const _historyList: HistoryListListComponentProps[] = []; // 최종, year 데이터들이 담김
    let _dataList: DataListProps = { contentList: [], titleList: [] }; // ContentDataProps의 배열, titleList의 배열
    let _monthList: MonthListProps = { monthList: [] }; // MonthDataProps의 배열

    hitoryListDataCh?.forEach((ele) => {
      const _titleData: TitleDataProps = { title: '', titleId: 0 }; // DataListProps를의 titleList에 push 됨
      if (ele.content !== '' && ele.content !== null) {
        const _contentData: ContentDataProps = { content: '', contentId: 0 }; // DataListProps를의 contentList에 push 됨
        _contentData.content = ele.content;
        _contentData.contentId = _contentId++;
        // 반복문 내에 새로 만든 _contentData에 값 지정 후 _dataList의 contentList에 push
        // (content.content.includes('png') || content.content.includes('jpg')) 일 때에만 화면에 보여줄 것이니 여기서 필터링
        if (_contentData.content.includes('png') || _contentData.content.includes('jpg')) _dataList.contentList.push(_contentData);
      }
      _titleData.title = ele.title;
      _titleData.titleId = _titleId++;
      _dataList.titleList.push(_titleData); // 반복문 내에 새로 만든 _titleData 값 지정 후 _dataList의 titleData에 push

      // // ele.nextYearCompare === 'N' => 다음에 오는 데이터랑 지금의 데이터가 연도가 같지 않음! => 원래는 year에다가 push 함
      // // ele.nextMonthCompare === 'N' => 다음에 오는 데이터랑 지금의 데이터가 월이 같지 않음! => monthList에다가 set함
      // // 다만 예외케이스로 2017/12 다음에 데이터가 바로 2016/12인 경우  ===>>> ele.nextYearCompare === 'N' && ele.nextMonthCompare === 'Y'

      if (ele.nextMonthCompare === 'N' || (ele.nextYearCompare === 'N' && ele.nextMonthCompare === 'Y')) {
        const _monthData: MonthDataProps = { dataList: { contentList: [], titleList: [] }, month: '', monthId: _monthId }; // 데이타리스트를 DataListProps를 갖고, 월 정보를 갖고 있다.
        _monthData.month = ele.mm;
        _monthData.monthId = _monthId++;
        _monthData.dataList = _dataList;
        _monthList.monthList.push(_monthData); // 이제 이번달 혹은 이번 년도가 끝나면 _monthData마무리, _dataList세팅
        _dataList = { contentList: [], titleList: [] }; // 필요. 왜냐하면 _monthData.dataList를 새로 넣어주려면 새 거여야 하니깐
      }
      if (ele.nextYearCompare === 'N') {
        _historyList.push({ year: ele.yy, yearList: _monthList });
        _monthList = { monthList: [] };
      }
    });
    setHistoryList(_historyList);
  };

  useEffect(() => {
    if (isLoading && i18n.language == 'ko') {
      onloadDataKo();
    } else if (isLoading && i18n.language == 'ch') {
      onloadDataCh();
    } else if (isLoading && i18n.language == 'en') {
      onloadDataEn();
    }
  }, [i18n.language, isLoading]);

  console.debug('isLoading', isLoading);

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-0">
        <h3 className="fColor-yellow">{t('about.history1')}</h3>
      </FrameCol>
      <FrameCol width={6} addClassName="text-right mb-20 mob-text-center">
        <h2 className="mt-0">
          <span className="fColor-999">{t('about.history2')}</span> {t('about.history3')}
        </h2>
        <p className="section-sub-title">
          {t('about.history4')}
          <br />
          {t('about.history5')}
        </p>
      </FrameCol>
      <FrameCol width={6} addClassName="mb-20 mob-text-center">
        <h2 className="mt-0">
          <span className="fColor-999">{t('about.history6')}</span> {t('about.history7')}
        </h2>
        <p className="section-sub-title">
          {t('about.history8')}
          <br />
          {t('about.history9')}
        </p>
      </FrameCol>
      <FrameCol>
        <FrameRow>
          <FrameCol width={2} addClassName="mob-text-center mobile-hide">
            <div className="newsCont">
              {/* 웹브라우저에서만 보임 */}
              <ul>
                <li ref={prevBtnRef} id="newsPrevButton" title="이전">
                  <span className="ion-ios-arrow-left fa-4x"></span>
                </li>
                <li ref={nextBtnRef} id="newsNextButton" title="다음">
                  <span className="ion-ios-arrow-right fa-4x"></span>
                </li>
              </ul>
            </div>
          </FrameCol>
          <FrameCol width={10} addClassName="newsWrap">
            <div className="ms-filters-template">
              <MasterSliderLayout
                sliderOption={sliderOption}
                arrowOption={arrowOption}
                bulltesOption={bulletsOption}
                noProgressBar={false}
                prevBtnRef={prevBtnRef}
                nextBtnRef={nextBtnRef}
                className="master-slider ms-skin-default"
                id="historyslider"
              >
                {historyList?.map((item, idx) => {
                  return (
                    <div key={`${item.year}${idx}`} className="ms-slide">
                      <div className="years">{item.year}</div>
                      <div className="historyBox">
                        <dl>
                          {item.yearList.monthList.map((element) => {
                            return (
                              <React.Fragment key={element.monthId}>
                                <dt>{element.month}</dt>
                                {element.dataList.contentList.length ? (
                                  <dd>
                                    {element.dataList.contentList.map((content) => {
                                      // if (content.content.includes('png') || content.content.includes('jpg')) {
                                      return <CustomImgTag key={content.contentId} src={content.content ?? ''} />;
                                      // }
                                    })}
                                  </dd>
                                ) : null}
                                {element.dataList.titleList.map((title) => {
                                  return <dd key={title.titleId}>{title.title}</dd>;
                                })}
                              </React.Fragment>
                            );
                          })}
                        </dl>
                      </div>
                    </div>
                  );
                })}
              </MasterSliderLayout>
            </div>
          </FrameCol>
        </FrameRow>
      </FrameCol>
    </FrameRow>
  );
};
export default HistoryComponent;
