import { selectNewsList } from 'api/board/BoardAPI';
import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { BoardRVO } from 'types/board';
import { useEffectOnce } from 'usehooks-ts';
import { isSuccess } from 'util/ApiResponseUtil';
import { dateFormat } from 'util/FormatUtil';

// 게시물 리스트
const newsListData: BoardRVO[] = [
  {
    brdNm: '',
    postNo: '',
    subject: '',
    imgFile: '',
    regDt: '',
    pstgYmd: '',
    content: '',
    nextPostNo: 0,
    prevPostNo: 0,
    totalCount: 0,
    e4Count: 0,
    noticeCount: 0,
    pageNo: 1,
    home: '',
  },
];

const sliderOption = {
  width: 480,
  height: 500,
  top: 0,
  minHeight: 0,
  space: 30,
  start: 1,
  grabCursor: true,
  swipe: true,
  mouse: true,
  keyboard: false,
  layout: 'partialview',
  wheel: false,
  autoplay: false,
  instantStartLayers: false,
  loop: true,
  shuffle: false,
  preload: 0,
  heightLimit: true,
  autoHeight: false,
  smoothHeight: true,
  endPause: false,
  overPause: true,
  fillMode: 'fill',
  centerControls: true,
  startOnAppear: false,
  layersMode: 'center',
  autofillTarget: '',
  hideLayers: false,
  fullscreenMargin: 0,
  speed: 30,
  dir: 'h',
  parallaxMode: 'swipe',
  view: 'basic',
  filters: {
    grayscale: 1,
    contrast: 1.5,
  },
};

const arrowOption = { autohide: false, soverVideo: true };
const bulletsOption = { autohide: false, align: 'bottom', margin: 10, space: 4 };

const NewsComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false); // 임시 로딩처리
  const [newsList, setNewsList] = useState<BoardRVO[]>(newsListData);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  const getNewsList = async (pageNo?: number) => {
    const itemCountPerPage = 10;

    await selectNewsList({ pageNo: pageNo, rowsPage: itemCountPerPage, mostYn: 'N' })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setNewsList(resData);
          }
          setIsLoading(true);
        } else {
          setNewsList(newsListData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffectOnce(() => {
    getNewsList();
  });

  return (
    <FrameRow>
      <FrameCol width={4} addClassName="mob-text-center">
        <h1 className="mb-30 mt-5">
          <span>{t('homeMain.news1')}</span>
        </h1>
        <p className="section-sub-title mb-40">{t('homeMain.news2')}</p>
        <CustomATag href="/news" className="btn btn-outline-primary btn-rounded fs-14">
          {t('homeMain.news3')} <i className="glyphicon icon icon-arrow-32"></i>
        </CustomATag>
        <div className="newsCont">
          {/* 웹브라우저에서만 보임*/}
          <ul>
            <li ref={prevBtnRef} id="newsPrevButton" title={t('homeMain.news4')}>
              <span className="ion-ios-arrow-left fa-4x"></span>
            </li>
            <li ref={nextBtnRef} id="newsNextButton" title={t('homeMain.news5')}>
              <span className="ion-ios-arrow-right fa-4x"></span>
            </li>
          </ul>
        </div>
      </FrameCol>
      <FrameCol width={8} addClassName="newsWrap">
        <div className="ms-filters-template">
          <MasterSliderLayout sliderOption={sliderOption} arrowOption={arrowOption} bulltesOption={bulletsOption} prevBtnRef={prevBtnRef} nextBtnRef={nextBtnRef} className="master-slider ms-skin-default" id="masterslider">
            {isLoading &&
              newsList.map((ele, idx) => {
                return (
                  <div className="ms-slide" key={`${idx}`}>
                    <div
                      className="bgimgWrap"
                      style={{ background: `url(${ele.imgFile ? ele.imgFile : 'assets/img/news/news.jpg'})`, cursor: 'pointer' }}
                      onClick={() => {
                        navigate('/news/' + `${ele.postNo}`, {
                          state: { data: ele },
                        });
                      }}
                    ></div>
                    <div className="newsTitle">
                      <p className="title">
                        <Link to={`/news/${ele.postNo}`} state={{ data: ele }}>
                          {ele.subject}
                        </Link>
                      </p>
                      <p className="date">{dateFormat(ele.pstgYmd)}</p>
                    </div>
                  </div>
                );
              })}
          </MasterSliderLayout>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default NewsComponent;
