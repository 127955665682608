import { VideoPlayer } from 'common/view/components';
import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';
import { stylePre } from 'util/StyleUtil';

const VideoComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol width={4}>
        <h1>{t('homeMain.video1')}</h1>
        <h2>{t('homeMain.video2')}</h2>
        <p className="subtitle" style={stylePre}>
          {t('homeMain.video3')}
          <br />
          {t('homeMain.video4')}
        </p>
        <p>{t('homeMain.video5')}</p>
        <CustomATag href="https://www.ytn.co.kr/replay/view.php?idx=239&key=202303071139519819" target="_blank" className="btn btn-primary fs-14 btn-rounded">
          {t('homeMain.video6')} <i className="glyphicon icon icon-arrow-32"></i>
        </CustomATag>
      </FrameCol>
      <FrameCol width={8}>
        <VideoPlayer thumbNailSrc={'assets/img/custom/ytn_news.png'} thumbNailAlt={'Photo Title'} videoSrc={'https://www.youtube.com/embed/uU5Sb7hMWxk'} videoTitle={'Embedded Video'} />
      </FrameCol>
    </FrameRow>
  );
};

export default VideoComponent;
