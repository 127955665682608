import { useCallback, useEffect, useState } from 'react';

type ScriptStatus = 'idle' | 'loading' | 'ready' | 'error';

export const useScript = (basePath = 'assets/') => {
  const [status, setStatus] = useState<ScriptStatus>('idle');

  const loadScript = useCallback(
    (src: string) => {
      const existingScript = document.querySelector(`script[src="${src}"]`);

      if (existingScript) {
        const existingStatus = existingScript.getAttribute('data-status');

        document.body.removeChild(existingScript);
        if (existingStatus) {
          // setStatus(existingStatus as ScriptStatus);
          // return existingScript as HTMLScriptElement;
        }
      }

      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');

      script.addEventListener('load', () => {
        script.setAttribute('data-status', 'ready');
        setStatus('ready');
      });
      script.addEventListener('error', () => {
        script.setAttribute('data-status', 'error');
        setStatus('error');
      });

      document.body.appendChild(script);
    },
    [setStatus],
  );

  const importScript = useCallback(
    (jsSrc: string | string[]) => {
      const scripts = Array.isArray(jsSrc) ? jsSrc.map((js) => `${basePath}${js}.js`) : [`${basePath}${jsSrc}.js`];
      scripts.forEach(loadScript);
    },
    [loadScript, basePath],
  );

  useEffect(() => {
    return () => {
      const scriptElements = document.querySelectorAll('script[data-status="loading"], script[data-status="ready"], script[data-status="error"]');
      scriptElements.forEach((script) => {
        document.body.removeChild(script);
      });
    };
  }, []);

  return { status, importScript };
};
