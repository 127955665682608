import { CustomImgTag } from 'common/view/components/custom';
import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const DownloadComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol>
        <ExpAnimationLayout>
          <FrameContainer>
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="mb-30">
                  <span>{t('itMain.download1')}</span>
                </h1>
                <p className="section-sub-title mb-30">{t('itMain.download2')}</p>
              </div>
            </div>

            <div className="row mt-50">
              <div className="col-md-4 col-sm-6">
                <div className="anim-perspective">
                  <div className="feature-box-core v2 alternate-color exp-animation" data-animation="express_b_show" data-delay="400">
                    <h2>
                      {t('itMain.download3')}
                      <br />
                      {t('itMain.download4')}
                      <br />
                      <br />
                    </h2>
                    <CustomImgTag className="feature-box-core-icon" src="assets/img/custom/it_down01.png" />
                    <div className="feature-box-core-detail">
                      <h3 className="feature-box-core-title">{t('itMain.download5')}</h3>
                      <p className="feature-box-core-text">{t('itMain.download6')}</p>
                    </div>
                    <div className="btn-down">
                      <h4 className="feature-box-sub-title">{t('itMain.download7')}</h4>
                      <a href="./assets/download/lmps.pdf" className="btn btn-primary fs-14 btn-rounded w100p" download>
                        {t('itMain.download8')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="anim-perspective">
                  <div className="feature-box-core v2 alternate-color exp-animation" data-animation="express_b_show" data-delay="800">
                    <h2>
                      {t('itMain.download9')}
                      <br />
                      {t('itMain.download10')}
                      <br />
                      <br />
                    </h2>
                    <CustomImgTag className="feature-box-core-icon" src="assets/img/custom/it_down02.png" />
                    <div className="feature-box-core-detail">
                      <h3 className="feature-box-core-title">{t('itMain.download11')}</h3>
                      <p className="feature-box-core-text">{t('itMain.download12')}</p>
                    </div>
                    <div className="btn-down">
                      <h4 className="feature-box-sub-title">{t('itMain.download13')}</h4>
                      <a href="./assets/download/ppms.pdf" className="btn btn-primary fs-14 btn-rounded w100p" download>
                        {t('itMain.download14')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="anim-perspective">
                  <div className="feature-box-core v2 alternate-color exp-animation" data-animation="express_b_show" data-delay="1200">
                    <h2>
                      {t('itMain.download15')}
                      <br />
                      {t('itMain.download16')}
                      <br />
                      {t('itMain.download17')}
                    </h2>
                    <CustomImgTag className="feature-box-core-icon" src="assets/img/custom/it_down03.png" />
                    <div className="feature-box-core-detail">
                      <h3 className="feature-box-core-title">{t('itMain.download18')}</h3>
                      <p className="feature-box-core-text">{t('itMain.download19')}</p>
                    </div>
                    <div className="btn-down">
                      <h4 className="feature-box-sub-title">{t('itMain.download20')}</h4>
                      <a href="./assets/download/owlog.pdf" className="btn btn-primary fs-14 btn-rounded w100p" download>
                        {t('itMain.download21')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FrameContainer>
        </ExpAnimationLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default DownloadComponent;
