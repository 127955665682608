import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';

import SampleContactContactFormComponent from './sampleContactContactComponents/SampleContactContactFormComponent';

const SampleContactFormComponent = () => {
  return (
    <FrameRow>
      <FrameCol addClassName="bg-gray py-5">
        <FrameContainer>
          <FrameRow>
            <FrameCol width={3}>
              <div className="contact-area">
                <h3>
                  경영전략실
                  <br />
                  1:1 문의하기
                </h3>
                <p>채용 및 행정, 회사 일반에 관한 문의는 경영전략실에 문의하세요.</p>
                <ul className="contactWrap">
                  <li>
                    <div className="contact">
                      <div className="icon">
                        <i className="fa fa-mail"></i>
                      </div>
                      <div className="adress">
                        <p>Email</p>
                        <p>
                          <a href="mailto:e4net@e4net.net">e4net@e4net.net</a>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="contact">
                      <div className="icon">
                        <i className="fa fa-volume-control-phone"></i>
                      </div>
                      <div className="adress">
                        <p>Phone</p>
                        <p>
                          <a href="tel:02-3465-8500">02-3465-8500</a>
                        </p>
                        <p className="mt-5">Global</p>
                        <p>
                          <a href="tel:02-3465-8500">+82 2 3465 8500</a>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="contact">
                      <div className="icon">
                        <i className="fa fa-printer"></i>
                      </div>
                      <div className="adress">
                        <p>Fax</p>
                        <p>
                          <a href="tel:02-3465-8501">02-3465-8501</a>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </FrameCol>
            <FrameCol width={9}>
              <SampleContactContactFormComponent />
            </FrameCol>
          </FrameRow>
        </FrameContainer>
      </FrameCol>
    </FrameRow>
  );
};

export default SampleContactFormComponent;
