import React, { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useEffectOnce } from 'usehooks-ts';
import { checkDevice } from 'util/CommonUtil';

import { CustomImgTag } from './custom';

interface VideoPlayerProps {
  thumbNailSrc: string;
  thumbNailAlt: string;
  videoSrc: string;
  videoTitle?: string;
}
const VideoPlayer = ({ thumbNailSrc, thumbNailAlt, videoSrc, videoTitle }: VideoPlayerProps) => {
  const [open, setOpen] = useState(false);
  const root = useRef<HTMLElement>();

  useEffectOnce(() => {
    root.current = document.createElement('div');
    root.current.id = 'video-portal';
    document.body.insertBefore(root.current, document.getElementById('root'));

    return () => {
      if (root.current) {
        document.body.removeChild(root.current);
      }
    };
  });

  const calSize = useCallback(() => {
    const { isIOS } = checkDevice();
    const zoomLevel = document.documentElement.clientWidth / window.innerWidth;
    const height = Math.floor(window.innerHeight * zoomLevel);
    return {
      height: isIOS ? window.innerHeight ?? document.documentElement.clientHeight : height,
      totalHeight: document.documentElement.scrollHeight,
      top: window.pageYOffset ?? document.documentElement.scrollTop,
    };
  }, []);

  const sizeObj = calSize();

  return (
    <div className="video-wrap br-none mt-40" data-cursor-overlay="2" data-cursor-round="5" data-cursor-shadow="20">
      <a
        // className="video-wrap-link magnific-iframe"
        className="video-wrap-link"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <CustomImgTag className="video-wrap-image" src={thumbNailSrc} alt={thumbNailAlt ?? ''} />
        <span className="video-overlay">
          <span className="video-wrap-icon"></span>
        </span>
      </a>
      {open &&
        root.current &&
        createPortal(
          <>
            <div className="mfp-bg mfp-fade mfp-ready" style={{ height: `${sizeObj.totalHeight}px`, position: 'absolute' }}></div>
            <div
              className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
              tabIndex={-1}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                const target = e.target as HTMLElement;
                if (target.classList.value !== 'mfp-iframe') setOpen(false);
              }}
              style={{ top: `${sizeObj.top}px`, position: 'absolute', height: `${sizeObj.height}px` }}
            >
              <div className="mfp-container mfp-iframe-holder">
                <div className="mfp-content">
                  <div className="mfp-iframe-scaler">
                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setOpen(false)}>
                      ×
                    </button>
                    <iframe
                      className="mfp-iframe"
                      src={videoSrc} // Replace with your video's URL or embed code
                      title={videoTitle ?? ''}
                      frameBorder="0" // Optional: set frameborder to 0 to remove the border
                      allowFullScreen // Optional: enable fullscreen mode
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </>,
          root.current,
        )}
    </div>
  );
};

export default React.memo(VideoPlayer);
