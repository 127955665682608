import { selectBlogList } from 'api/lang/main/BlogAPI';
import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlogRVO } from 'types/lang/main/BlogRVO';
import { useEffectOnce } from 'usehooks-ts';
import { isSuccess } from 'util/ApiResponseUtil';
import { dateFormat } from 'util/FormatUtil';

// 블로그 리스트
const blogListData: BlogRVO[] = [
  {
    title: '',
    date: '',
    link: '',
    imgFile: '',
  },
];

const sliderOption = {
  width: 480,
  height: 500,
  top: 0,
  minHeight: 0,
  space: 30,
  start: 1,
  grabCursor: true,
  swipe: true,
  mouse: true,
  keyboard: false,
  layout: 'partialview',
  wheel: false,
  autoplay: false,
  instantStartLayers: false,
  loop: true,
  shuffle: false,
  preload: 0,
  heightLimit: true,
  autoHeight: false,
  smoothHeight: true,
  endPause: false,
  overPause: true,
  fillMode: 'fill',
  centerControls: true,
  startOnAppear: false,
  layersMode: 'center',
  autofillTarget: '',
  hideLayers: false,
  fullscreenMargin: 0,
  speed: 30,
  dir: 'h',
  parallaxMode: 'swipe',
  view: 'basic',
  filters: {
    grayscale: 1,
    contrast: 1.5,
  },
};

const arrowOption = { autohide: false, soverVideo: true };
const bulletsOption = { autohide: false, align: 'bottom', margin: 10, space: 4 };

const BlogComponent = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false); // 임시 로딩처리
  const [blogList, setBlogList] = useState<BlogRVO[]>(blogListData);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  const getRss = async () => {
    await selectBlogList()
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setBlogList(resData);
          }
          setIsLoading(true);
        } else {
          setBlogList(blogListData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffectOnce(() => {
    getRss();
  });

  return (
    <FrameRow>
      <FrameCol width={4} addClassName="mob-text-center">
        <h1 className="mb-30 mt-5">
          <span>
            {t('langMain.blog1')}
            {t('langMain.blog2') != '' ? <br /> : ''}
            {t('langMain.blog2')}
            {t('langMain.blog3') != '' ? <br /> : ''}
            <span className="fw-4">
              {t('langMain.blog3')}
              {t('langMain.blog4') != '' ? <br /> : ''}
              {t('langMain.blog4')}
            </span>
          </span>
        </h1>
        <CustomATag href="https://blog.naver.com/PostList.naver?blogId=e4nettng" target="_blank" className="btn btn-outline-primary btn-rounded fs-14">
          {t('langMain.blog5')}
          <i className="glyphicon icon icon-arrow-32"></i>
        </CustomATag>
        <div className="newsCont">
          {/* 웹브라우저에서만 보임*/}
          <ul>
            <li ref={prevBtnRef} id="newsPrevButton" title={t('langMain.blog6')}>
              <span className="ion-ios-arrow-left fa-4x"></span>
            </li>
            <li ref={nextBtnRef} id="newsNextButton" title={t('langMain.blog7')}>
              <span className="ion-ios-arrow-right fa-4x"></span>
            </li>
          </ul>
        </div>
      </FrameCol>
      <FrameCol width={8} addClassName="newsWrap">
        <div className="ms-filters-template">
          <MasterSliderLayout sliderOption={sliderOption} arrowOption={arrowOption} bulltesOption={bulletsOption} prevBtnRef={prevBtnRef} nextBtnRef={nextBtnRef} className="master-slider ms-skin-default" id="masterslider">
            {isLoading &&
              blogList.map((ele, idx) => {
                return (
                  <div className="ms-slide" key={`${idx}`} style={{ background: `url(/assets/img/blog/b.png)`, backgroundPosition: `-140px -60px`, backgroundSize: `cover` }}>
                    <div
                      className="bgimgWrap"
                      style={{ background: `url(${ele.imgFile ? ele.imgFile : '/assets/img/blog/${idx}.png'})`, cursor: 'pointer', height: `70%` }}
                      onClick={() => {
                        window.open(`${ele.link}`);
                      }}
                    ></div>
                    <div className="newsTitle">
                      <p className="title">
                        <CustomATag href={`${ele.link}`} target="_blank">
                          {ele.title}
                        </CustomATag>
                      </p>
                      <p className="date">{dateFormat(ele.date)}</p>
                    </div>
                  </div>
                );
              })}
          </MasterSliderLayout>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default BlogComponent;
