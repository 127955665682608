import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import FlowBannerLayout from 'common/view/layouts/slider/FlowBannerLayout';

import ProfileComponent from './ProfileComponent';

const ProfilePage = () => {
  return (
    <ExpAnimationLayout>
      <FlowBannerLayout>
        <ProfileComponent />
      </FlowBannerLayout>
    </ExpAnimationLayout>
  );
};

export default ProfilePage;
