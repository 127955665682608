import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useEffectOnce } from 'usehooks-ts';

const BackToTopButton = () => {
  const [isShow, setIsShow] = useState(false);

  useEffectOnce(() => {
    const handleBackToTopRef = () => {
      if (window.scrollY > 150) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    };
    window.addEventListener('scroll', handleBackToTopRef);

    return () => {
      window.removeEventListener('scroll', handleBackToTopRef);
    };
  });

  const handleScrollToTop = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const $ = window.$;
    // eslint-disable-next-line
    $('body, html').animate({ scrollTop: 0 }, 800, (x: never, t: any, b: any, c: any, d: any) => {
      return c * ((t = t / d - 1) * t * t + 1) + b;
    });
  }, []);

  return createPortal(
    <a data-scroll className={`lift-off js-lift-off lift-off_${isShow ? 'show' : 'hide'}`} href="#" onClick={handleScrollToTop}>
      <i className="fa fa-angle-up"></i>
    </a>,
    document.body,
  );
};

export default React.memo(BackToTopButton);
