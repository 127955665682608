import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import FlowBannerLayout from 'common/view/layouts/slider/FlowBannerLayout';
import { useTranslation } from 'react-i18next';

const PartnerComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol>
        <FlowBannerLayout>
          <section className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall">
            <div className="divimage dzsparallaxer--target w-100" style={{ height: `120%`, backgroundImage: `url(assets/img/custom/it_partner_bg.jpg)`, backgroundPosition: `center center` }}></div>
            <div className="container-fluid partnerWrap">
              <FrameContainer>
                <FrameRow>
                  <div className="col-md-8 mob-text-center">
                    <h1>
                      {t('itMain.partner1')}
                      <br />
                      <span className="fw-4">{t('itMain.partner2')}</span>
                    </h1>
                  </div>
                  <div className="col-md-4 z-index-1">
                    <div className="flow_ctrl_box">
                      <a href="#none" id="banner_left" className="back">
                        <span className="blind">{t('itMain.partner3')}</span>
                      </a>
                      <a href="#" onClick={(e) => e.preventDefault()} id="banner_play" className="play">
                        <span className="">{t('itMain.partner4')}</span>
                      </a>
                      <a href="#" onClick={(e) => e.preventDefault()} id="banner_pause" className="stop">
                        <span className="">{t('itMain.partner5')}</span>
                      </a>
                      <a href="#none" id="banner_right" className="next">
                        <span className="blind">{t('itMain.partner6')}</span>
                      </a>
                    </div>
                  </div>
                </FrameRow>
              </FrameContainer>
              <div className="footer_banner_zone z-index-1">
                <div className="flow_banner_box">
                  <div id="banner" className="flow_bann_area">
                    <ul className="a1">
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_01.png" alt={t('itMain.partnerlist1')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_02.png" alt={t('itMain.partnerlist2')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_03.png" alt={t('itMain.partnerlist3')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_04.png" alt={t('itMain.partnerlist4')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_05.png" alt={t('itMain.partnerlist5')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_06.png" alt={t('itMain.partnerlist6')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_09.png" alt={t('itMain.partnerlist7')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_10.png" alt={t('itMain.partnerlist8')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_11.png" alt={t('itMain.partnerlist9')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_13.png" alt={t('itMain.partnerlist10')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_16.png" alt={t('itMain.partnerlist11')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_12.png" alt={t('itMain.partnerlist12')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_14.png" alt={t('itMain.partnerlist13')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_15.png" alt={t('itMain.partnerlist14')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_22.png" alt={t('itMain.partnerlist15')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_37.png" alt={t('itMain.partnerlist16')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_23.png" alt={t('itMain.partnerlist17')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_24.png" alt={t('itMain.partnerlist18')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_07.png" alt={t('itMain.partnerlist19')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_19.png" alt={t('itMain.partnerlist20')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_08.png" alt={t('itMain.partnerlist21')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_20.png" alt={t('itMain.partnerlist22')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_25.png" alt={t('itMain.partnerlist23')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_38.png" alt={t('itMain.partnerlist24')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_33.png" alt={t('itMain.partnerlist25')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_35.png" alt={t('itMain.partnerlist26')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_34.png" alt={t('itMain.partnerlist27')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_36.png" alt={t('itMain.partnerlist28')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_39.png" alt={t('itMain.partnerlist29')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_40.png" alt={t('itMain.partnerlist30')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_41.png" alt={t('itMain.partnerlist31')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_42.png" alt={t('itMain.partnerlist32')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_27.png" alt={t('itMain.partnerlist33')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_28.png" alt={t('itMain.partnerlist34')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_43.png" alt={t('itMain.partnerlist35')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_29.png" alt={t('itMain.partnerlist36')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_52.png" alt={t('itMain.partnerlist37')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_30.png" alt={t('itMain.partnerlist38')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_31.png" alt={t('itMain.partnerlist39')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_32.png" alt={t('itMain.partnerlist40')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_26.png" alt={t('itMain.partnerlist41')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_45.png" alt={t('itMain.partnerlist42')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_46.png" alt={t('itMain.partnerlist43')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_47.png" alt={t('itMain.partnerlist44')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_48.png" alt={t('itMain.partnerlist45')} />
                      </li>
                      <li>
                        <CustomImgTag src="assets/img/clients/it_logo_49.png" alt={t('itMain.partnerlist46')} />
                      </li>
                      {/* 
                <!--<li>
                    <CustomImgTag src="assets/img/clients/it_logo_18.png" alt="교보생명" />
                    </li>
                    <li>
                    <CustomImgTag src="assets/img/clients/it_logo_17.png" alt="삼성생명" />
                    </li>
                    <li>
                    <CustomImgTag src="assets/img/clients/it_logo_21.png" alt="현대증권" />
                    </li>
                    <li>
                    <CustomImgTag src="assets/img/clients/it_logo_44.png" alt="한국정보회진흥원" />
                    </li>
                    <li>
                    <CustomImgTag src="assets/img/clients/it_logo_50.png" alt="대한축구협회" />
                    </li>
                    <li>
                    <CustomImgTag src="assets/img/clients/it_logo_51.png" alt="국방과학기술원" />
                    </li>--> 
                */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <CustomATag href="/it/portfolio" className="btn btn-primary fs-14 btn-rounded">
                    {t('itMain.partner7')} <i className="glyphicon icon icon-arrow-32 ml-5"></i>
                  </CustomATag>
                </div>
              </div>
            </div>
          </section>
        </FlowBannerLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default PartnerComponent;
