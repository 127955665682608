import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useLayoutEffect, useState } from 'react';
import { TistoryRVO } from 'types/sample/sample1';

const data = [
  { className: 'photo1', dataDelay: 400, imgSrc: 'assets/img/blog/b01.png', alt: '블로그이미지' },
  { className: 'photo2', dataDelay: 800, imgSrc: 'assets/img/blog/b02.png', alt: '블로그이미지' },
  { className: 'photo3', dataDelay: 1200, imgSrc: 'assets/img/blog/b03.png', alt: '블로그이미지' },
];

const SampleTistoryComponent = () => {
  const [imgContents, setImgContents] = useState<TistoryRVO[]>([]);

  const fetch = () => {
    const _imgContents = data.map((ele, idx) => ({ className: ele.className ?? '', dataDelay: ele.dataDelay, src: ele.imgSrc, alt: `${ele.alt}${idx + 1}` }));
    setImgContents(_imgContents);
  };

  useLayoutEffect(() => fetch(), []);

  return (
    <FrameRow>
      <FrameCol width={6} addClassName="pt-100 mob-text-center">
        <h2>이포넷 언어서비스 E4NET LOCALIZATION</h2>
        <h1>World IT Show 2023 참가</h1>
        <p>
          지난 4월 19일부터 21일까지 코엑스에서 열린 World IT Show 2023에 참가한 이야기를 해드리고자 합니다. 이번 World IT Show 2023에서 우리 언어서비스사업부 마케팅팀은 번역 관리 툴 업체인 Phrase와 함께 합동 부스를 마련하여 참가했습니다.
          지난 4월 19일부터 21일까지 코엑스에서 열린 World IT Show 2023에 참가한 이야기를 해드리고자 합니다.
        </p>
        <p className="subtitle">E4NET 블로그에서 나머지 이야기를 들어보세요</p>
        <CustomATag href="https://www.ytn.co.kr/replay/view.php?idx=239&amp;key=202303071139519819" target="_blank" className="btn btn-primary fs-14 btn-rounded">
          이포넷<span> Blog</span> 보기 <i className="glyphicon icon icon-arrow-36 fa-rotate-270"></i>
        </CustomATag>
      </FrameCol>
      <FrameCol width={6}>
        <ExpAnimationLayout>
          <div className="photo-wrap">
            {imgContents.map((ele, idx) => {
              return (
                <div key={`SampleTistoryComponentimgContents${idx}`} className={`${ele.className ?? ''} exp-animation`} data-animation="express_appear" data-delay={ele.dataDelay}>
                  <div>
                    <CustomImgTag src={ele.src} alt={ele.alt} />
                  </div>
                </div>
              );
            })}
          </div>
        </ExpAnimationLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default SampleTistoryComponent;
