import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import { useTranslation } from 'react-i18next';

const sliderOption = {
  width: 1140,
  height: 370,
  minHeight: 0,
  space: 0,
  start: 1,
  grabCursor: true,
  swipe: true,
  mouse: false,
  keyboard: true,
  layout: 'fullwidth',
  wheel: false,
  autoplay: true,
  instantStartLayers: false,
  loop: true,
  shuffle: false,
  preload: 0,
  heightLimit: true,
  autoHeight: false,
  smoothHeight: true,
  endPause: false,
  overPause: true,
  fillMode: 'fill',
  centerControls: false,
  startOnAppear: false,
  layersMode: 'center',
  autofillTarget: '',
  hideLayers: false,
  fullscreenMargin: 0,
  speed: 80,
  dir: 'h',
  parallaxMode: 'mouse:y-only',
  view: 'basic',
};

const arrowOption = { autohide: true, overVideo: true };
const bulletsOption = { autohide: false, overVideo: true, dir: 'h', align: 'bottom', space: 6, margin: -25 };
const timebarOption = { autohide: false, overVideo: true, align: 'bottom', color: '#3d3d3d', width: 2 };

const AboutHeaderComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-0">
        <h1 className="mb-20 mt-50">
          <span>ABOUT</span>
        </h1>
        <p className="section-sub-title mb-40">{t('about.head1')}</p>
      </FrameCol>
      <FrameCol addClassName="p-0">
        <div id="P_masterslider" className="master-slider-parent ms-parent-id-38">
          <MasterSliderLayout
            sliderOption={sliderOption}
            arrowOption={arrowOption}
            bulltesOption={bulletsOption}
            timebarOption={timebarOption}
            noProgressBar={true}
            id="subintroslider"
            className="master-slider ms-skin-default"
            titleAreaClassName="ms-info"
          >
            <div className="ms-slide" data-delay="5" data-fill-mode="fill">
              <CustomImgTag src="assets/img/slider/it_visual01.jpg" alt="" data-src="assets/img/slider/it_visual01.jpg" />
              <div
                className="ms-layer it-v-txt-01"
                data-effect="t(true,n,150,n,6,n,n,n,n,n,n,3,n,n,n)"
                data-duration="1000"
                data-delay="100"
                data-ease="easeOutQuad"
                data-offset-x="160"
                data-offset-y="70"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner1')}
              </div>
              <div
                className="ms-layer it-v-txt-02 mobile-hide"
                data-effect="t(true,n,150,n,6,n,n,n,n,n,n,3,n,n,n)"
                data-duration="1200"
                data-delay="400"
                data-ease="easeOutQuad"
                data-offset-x="0"
                data-offset-y="170"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner2')}
              </div>
              <div
                className="ms-layer it-v-txt-03"
                data-effect="t(true,n,150,n,6,n,n,n,n,n,n,3,n,n,n)"
                data-duration="1200"
                data-delay="700"
                data-ease="easeOutQuad"
                data-offset-x="160"
                data-offset-y="110"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner3')}
              </div>
              <div
                className="ms-layer it-v-txt-03"
                data-effect="t(true,n,115,n,4,n,n,n,n,n,n,2,n,n,n)"
                data-duration="1225"
                data-delay="1100"
                data-ease="easeOutQuad"
                data-offset-x="160"
                data-offset-y="155"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner4')}
              </div>
              <div
                className="ms-layer it-v-txt-03"
                data-effect="t(true,n,115,n,4,n,n,n,n,n,n,2,n,n,n)"
                data-duration="1150"
                data-delay="1400"
                data-ease="easeOutQuad"
                data-offset-x="160"
                data-offset-y="200"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner5')}
              </div>
              <div
                className="ms-layer it-v-txt-02 mobile-hide"
                data-effect="t(true,n,150,n,6,n,n,n,n,n,n,3,n,n,n)"
                data-duration="1200"
                data-delay="1700"
                data-ease="easeOutQuad"
                data-offset-x="550"
                data-offset-y="170"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner6')}
              </div>
            </div>
            <div className="ms-slide" data-delay="5" data-fill-mode="fill">
              <CustomImgTag src="assets/img/slider/it_visual02.jpg" alt="" data-src="assets/img/slider/it_visual02.jpg" />
              <div
                className="ms-layer it-v-txt-03"
                data-effect="t(true,n,150,n,6,n,n,n,n,n,n,3,n,n,n)"
                data-duration="1200"
                data-delay="100"
                data-ease="easeOutQuad"
                data-offset-x="680"
                data-offset-y="110"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner7')}
              </div>
              <div
                className="ms-layer it-v-txt-03"
                data-effect="t(true,n,115,n,4,n,n,n,n,n,n,2,n,n,n)"
                data-duration="1225"
                data-delay="400"
                data-ease="easeOutQuad"
                data-offset-x="680"
                data-offset-y="155"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner8')}
              </div>
              <div
                style={{ fontSize: '1.125rem' }}
                className="ms-layer it-v-txt-02 mobile-hide"
                data-effect="t(true,n,115,n,4,n,n,n,n,n,n,2,n,n,n)"
                data-duration="1150"
                data-delay="700"
                data-ease="easeOutQuad"
                data-offset-x="680"
                data-offset-y="200"
                data-origin="tl"
                data-position="normal"
                data-masked="true"
              >
                {t('about.banner9')}
                <br />
                {t('about.banner10')}
              </div>
            </div>
          </MasterSliderLayout>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default AboutHeaderComponent;
