import React from 'react';

interface ModalHeaderLayoutProps {
  closeRef?: React.RefObject<HTMLButtonElement>;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  titleName?: string;
}

const ModalHeaderLayout = ({ closeRef, setIsOpen, titleName }: ModalHeaderLayoutProps) => {
  return (
    <div className="modal-header">
      <h4 className="modal-title" id="largeModalLabel">
        {titleName}
      </h4>
      <button ref={closeRef} type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => setIsOpen?.(false)}>
        ×
      </button>
    </div>
  );
};

export default React.memo(ModalHeaderLayout);
