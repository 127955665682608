import { AbstractProps } from 'types/common/PropsTypes';

interface TabPanelLayoutProps extends AbstractProps {
  idx: number;
}

const TabPanelLayout = ({ idx, children }: TabPanelLayoutProps) => {
  return (
    <div className="tab-pane active" id={`tab_justified-${idx}`} role="tabpanel">
      {children}
    </div>
  );
};

export default TabPanelLayout;
