import SectionLayout from 'common/view/layouts/section/SectionLayout';
import { useRootUrl } from 'hooks/common/CommonHook';
import IrTabComponent from 'views/ir/common/page/IrTabComponent';

import ContactGoogleMapComponent from './ContactGoogleMapComponent';
import ContactHeadComponent from './ContactHeadComponent';
import ContactHeadIrComponent from './ContactHeadIrComponent';
import ContactInfoComponent from './ContactInfoComponent';
import ContactLinkComponent from './ContactLinkComponent';

const ContactPage = () => {
  const { currentRootUrl: rootUrl } = useRootUrl();

  return (
    <>
      <SectionLayout addSectionClassName="main-color pb-0" addContianerClassName="container-fluid">
        {rootUrl === 'ir' ? (
          <>
            <IrTabComponent />
            <ContactHeadIrComponent />
          </>
        ) : (
          <ContactHeadComponent />
        )}
      </SectionLayout>
      <SectionLayout addSectionClassName="col-md-12 bg-gray py-5">
        <ContactInfoComponent rootUrl={rootUrl} />
      </SectionLayout>
      {rootUrl === 'ir' ? (
        ''
      ) : (
        <SectionLayout>
          <ContactLinkComponent rootUrl={rootUrl} />
        </SectionLayout>
      )}
      <SectionLayout>
        <ContactGoogleMapComponent />
      </SectionLayout>
    </>
  );
};

export default ContactPage;
