import { sanitize } from 'dompurify';

const FooterMoreEmailModalEmailCollectionRefusalLayout = () => {
  return (
    <>
      <h3 className="mb-10">이메일 무단수집 거부</h3>
      <pre
        dangerouslySetInnerHTML={{
          __html:
            sanitize(`당사의 대표메일 및 당사의 직원에게 무차별적으로 보내지는 타사의 메일을 차단하기 위해, 본 웹사이트에 게시된 이메일 주소가 전자우편수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시 정보통신망 법에 의해 형사 처벌됨을 유념하시기 바랍니다.

ο 본 방침은 2016년 7월 1일부터 시행됩니다.

<b>정보통신망 법 제 50조의 2 (전자우편주소의 무단 수집행위 등 금지)</b>
    1. 누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니 된다.
    2. 누구든지 제1항의 규정을 위반하여 수집된 전자우편 주소를 판매/유통하여서는 아니 된다.
    3. 누구든지 제1항 및 제2항의 규정에 의하여 수집, 판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는 아니 된다.`),
        }}
      />
    </>
  );
};

export default FooterMoreEmailModalEmailCollectionRefusalLayout;
