import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

const NotFoundLayout = () => {
  return (
    <>
      <Helmet>
        <title>PAGENOTFOUND</title>
        <meta property="og:title" content="이포넷" />
        <meta property="og:description" content="PAGENOTFOUND" />
      </Helmet>
      <Outlet />
    </>
  );
};

export default NotFoundLayout;
