import { findStatsLang } from 'api/lang/main/StatsLangAPI';
import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsLangRVO } from 'types/lang/main/StatsLangRVO';

const defaultStatsLangInfo = {
  progressWord: '',
  lastYearWord: '',
  company: '',
  freelancer: '',
};

const CounterComponent = () => {
  const { t } = useTranslation();

  const [pj, setPj] = useState<StatsLangRVO>(defaultStatsLangInfo);

  const fetchCounter = async () => {
    await findStatsLang()
      .then((res) => {
        if (!res) return;
        setPj(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCounter();
  }, []);

  return (
    <FrameRow>
      <FrameCol>
        <div className="row counterWrap">
          <div className="col-md-6 counters total-counter">
            <div className="counter">
              <h2>{t('langMain.counter1')}</h2>
              <strong className="counter-number" data-to={pj.progressWord}>
                {pj.progressWord}
              </strong>
            </div>
          </div>
          <div className="col-md-6 counters it-counter">
            <div className="row">
              <div className="col-md-10">
                <div className="counter">
                  <span className="counter-icon">
                    <CustomImgTag src="assets/img/custom/lang_counter01.png" alt={t('langMain.counter2')} />
                  </span>
                  <span className="text">{t('langMain.counter2')}</span>
                  <strong className="counter-number" data-to={pj.lastYearWord}>
                    {pj.lastYearWord}
                  </strong>
                </div>
              </div>

              <div className="col-md-5">
                <div className="counter">
                  <span className="counter-icon">
                    <CustomImgTag src="assets/img/custom/lang_counter02.png" alt={t('langMain.counter3')} />
                  </span>
                  <span className="text">{t('langMain.counter3')}</span>
                  <strong className="counter-number" data-to={pj.company}>
                    {pj.company}
                  </strong>
                </div>
              </div>

              <div className="col-md-5">
                <div className="counter">
                  <span className="counter-icon">
                    <CustomImgTag src="assets/img/custom/lang_counter03.png" alt={t('langMain.counter4')} />
                  </span>
                  <span className="text">{t('langMain.counter4')}</span>
                  <strong className="counter-number" data-to={pj.freelancer}>
                    {pj.freelancer}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default CounterComponent;
