import SectionLayout from 'common/view/layouts/section/SectionLayout';

import Sample2HeadlineComponent from './Sample2HeadlineComponent';
import Sample2NewsListComponent from './Sample2NewsListComponent';

const Sample2Page = () => {
  return (
    <>
      <SectionLayout addSectionClassName="main-color pb-0" addContianerClassName="fluid">
        <Sample2HeadlineComponent />
      </SectionLayout>
      <Sample2NewsListComponent />
    </>
  );
};

export default Sample2Page;
