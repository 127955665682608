import SampleCommunityListComponent from './SampleCommunityListComponent';

const SampleCommunityPage = () => {
  return (
    <>
      <SampleCommunityListComponent />
    </>
  );
};

export default SampleCommunityPage;
