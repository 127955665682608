import { CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const CIComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="text-center mb-20">
        <h3 className="fColor-yellow">{t('about.ci1')}</h3>
        <h2>
          {t('about.ci2')}
          <br />
          {t('about.ci3')}
        </h2>
        <p className="section-sub-title">{t('about.ci4')}</p>
      </FrameCol>
      <FrameCol addClassName="ci-box exp-animation" data-animation="express_b_show" data-delay="400">
        <FrameRow addClassName="bordered bg-white">
          <FrameCol addClassName="b-bordered text-center py-4">
            <CustomImgTag src="assets/img/custom/E4NET-CI.png" alt={t('about.ci5')} />
          </FrameCol>
          <FrameCol addClassName="section-sub-title text-center pt-3">
            <a href="./assets/download/E4.-CI.zip" download>
              {t('about.ci6')}
              <i className="ml-2 fa fa-download5"></i>
            </a>
          </FrameCol>
        </FrameRow>
        <p className="pull-right fColor-999 mt-2">{t('about.ci7')}</p>
      </FrameCol>
      <FrameCol addClassName="ci-box mt-40">
        <FrameRow>
          <FrameCol width={3} addClassName="col-sm-3 col-md-border">
            <h3 className="fColor-red">{t('about.ci8')}</h3>
            <p>{t('about.ci9')}</p>
          </FrameCol>
          <FrameCol width={3} addClassName="col-sm-3 pl-30 pr-30 col-md-border">
            <h3>{t('about.ci10')}</h3>
            <p>{t('about.ci11')}</p>
          </FrameCol>
          <FrameCol width={3} addClassName="col-sm-3 pl-30 pr-30 col-md-border">
            <h3>{t('about.ci12')}</h3>
            <p>{t('about.ci13')}</p>
          </FrameCol>
          <FrameCol width={3} addClassName="col-sm-3">
            <h3>{t('about.ci14')}</h3>
            <p>{t('about.ci15')}</p>
          </FrameCol>
        </FrameRow>
      </FrameCol>
    </FrameRow>
  );
};

export default CIComponent;
