import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
import { handleElementScrollToTop } from 'util/UiUtil';

/**
 * body 태그의 아이디를 변경한다.
 *
 * @param id 변경할 아이디
 */
export const useBodyId = (id: string): void => {
  useIsomorphicLayoutEffect(() => {
    // BODY아이디 추가
    document.body.id = id;

    return () => {
      // BODY아이디 제거
      document.body.id = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

type onCopyFn = (text: string) => Promise<boolean>;
export const useCopyClipBoard = (copySucceedMsg?: string) => {
  // const [isCopy, setIsCopy] = useState<boolean>(false);

  const { t } = useTranslation();

  // 복사한 것에 대한 이벤트를 오로지 여기서만 줄것이라면 state를 만들 필요가 없음
  const onCopy: onCopyFn = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      // setIsCopy(true);
      alert(copySucceedMsg ?? t('hooks.useCopyClipBoard1'));

      return true;
    } catch (error) {
      console.error(error);
      // setIsCopy(false);

      return false;
    }
  };

  // useEffect(() => {
  //   if (isCopy) {
  //     alert(copySucceedMsg ?? '복사되었습니다.');
  //     setIsCopy(false);
  //   }
  // }, [isCopy]);

  return { /*isCopy,*/ onCopy };
};

export const usePageMoved = () => {
  const { pathname } = useLocation();

  const instantlyToTop = useCallback(() => {
    handleElementScrollToTop(document.documentElement);
  }, []);
  return { pathname, instantlyToTop };
};

export const useRootUrl = () => {
  const { pathname } = useLocation();

  const getRootUrl = useCallback(() => {
    const firstUrl = pathname.split('/')[1];
    switch (firstUrl) {
      case 'it':
        return 'it';
      case 'lang':
        return 'lang';
      case 'ir':
        return 'ir';
      default:
        return 'home';
    }
  }, [pathname]);

  return { currentRootUrl: getRootUrl() };
};

export const useSecondUrl = () => {
  const { pathname } = useLocation();

  const getSecondUrl = useCallback(() => {
    const secondUrl = pathname.split('/')[2];
    switch (secondUrl) {
      default:
        return secondUrl;
    }
  }, [pathname]);

  return { currentSecondUrl: getSecondUrl() };
};
