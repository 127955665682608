import { selectLangPortfolioCount, selectLangPortfolioList } from 'api/board/BoardAPI';
import FrameContainer from 'common/view/layouts/frames/FrameContainer';
import FrameSection from 'common/view/layouts/frames/FrameSection';
import { useState } from 'react';
import { PortfolioRVO } from 'types/board';
import { ListAndPageProps } from 'types/common';
import { useEffectOnce } from 'usehooks-ts';
import { isSuccess } from 'util/ApiResponseUtil';
import { defaultPagingListObject } from 'util/DataUtil';

import { PortfolioListComponent, PortfolioListPaginationComponent } from './portfolioListComponents';

const PortfolioListPageComponent = () => {
  const [portfolioList, setPortfolioList] = useState<ListAndPageProps<PortfolioRVO>>(defaultPagingListObject);
  const [portfolioCount, setPortfolioCount] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNone, setIsNone] = useState<boolean>(false);
  const [searchInputData, setSearchInputData] = useState({ searchInput: '', backupSearchInput: '', isFirstSearch: false });

  const getPortfolioCount = async () => {
    await selectLangPortfolioCount({
      searchValue: searchInputData.backupSearchInput,
      rprsYn: 'N',
    })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setPortfolioCount(resData);
          } else {
            setPortfolioCount([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPortfolioList = async (pageNo?: number, category?: string) => {
    setIsLoading(true);
    const itemCountPerPage = 9;
    await selectLangPortfolioList({
      pageNo: pageNo,
      rowsPage: itemCountPerPage,
      searchValue: searchInputData.backupSearchInput,
      rprsYn: 'N',
      category: category,
    })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            if (resData.length !== 0) {
              resData.map((ele, idx) => {
                const _categoryIds = ele.categoryIds.split(',');
                const _categoryIdsNm = ele.categoryIdsNm.split(',');
                resData[idx]._categoryIds = _categoryIds;
                resData[idx]._categoryIdsNm = _categoryIdsNm;
              });
              setPortfolioList({
                list: resData,
                pagination: {
                  totalCount: resData[0].totalCount,
                  currentPageNo: pageNo ?? 1,
                  itemCountPerPage,
                },
              });
              setIsNone(false);
            } else {
              setPortfolioList(defaultPagingListObject);
              setIsNone(true);
            }
          }
          setSearchInputData({
            ...searchInputData,
            //검색조건을 지우고 탭 클릭시 이전 검색값 유지
            searchInput: searchInputData.backupSearchInput,
            isFirstSearch: false,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffectOnce(() => {
    getPortfolioCount();
  });

  return (
    <FrameSection addClassName="b-bordered">
      <FrameContainer>
        <PortfolioListComponent
          portfolioCount={portfolioCount}
          getPortfolioCount={getPortfolioCount}
          getPortfolioList={getPortfolioList}
          searchInputData={searchInputData}
          setSearchInputData={setSearchInputData}
          portfolioList={portfolioList.list}
          isLoading={isLoading}
          isNone={isNone}
        />
      </FrameContainer>
      <FrameContainer>
        <PortfolioListPaginationComponent pagination={portfolioList.pagination} pagingOnClick={getPortfolioList} />
      </FrameContainer>
    </FrameSection>
  );
};

export default PortfolioListPageComponent;
