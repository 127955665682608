import { CustomImgTag } from 'common/view/components/custom';
import { useRootUrl } from 'hooks/common/CommonHook';
import { useTranslation } from 'react-i18next';

import { FrameCol, FrameContainer, FrameRow } from '../frames';

const FooterCopyrightLayout = () => {
  const { t } = useTranslation();
  const { currentRootUrl: rootUrl } = useRootUrl();

  return (
    <div className="copyright pt-4 pb-60">
      {rootUrl === 'lang' ? (
        <FrameContainer>
          <FrameRow>
            <FrameCol width={6}>
              <ul className="list-inline2">
                <li>
                  {t('footer.copyright1')}
                  <span className="ml-2">{t('footer.copyright2')}</span>
                </li>
                <li>{t('footer.copyright3')}</li>
                <li className="mt-3">{t('footer.copyright4')}</li>
              </ul>
            </FrameCol>
            <FrameCol width={4}>
              <FrameRow addClassName="isoWrap">
                <FrameCol addClassName="mb-0">
                  <h4>{t('footer.copyright5')}</h4>
                </FrameCol>
                <FrameCol width={6}>
                  <ul>
                    <li className="align-space">
                      <CustomImgTag src="assets/img/icons/iso-01.png" width="45px" alt={t('footer.copyright5')} />
                      <strong className="ml-2">{t('footer.copyright6')}</strong>
                    </li>
                    <li>
                      <CustomImgTag src="assets/img/icons/iso-01.png" width="45px" alt={t('footer.copyright5')} />
                      <strong className="ml-2">{t('footer.copyright7')}</strong>
                    </li>
                  </ul>
                </FrameCol>
                <FrameCol width={6}>
                  <ul>
                    <li>
                      <CustomImgTag src="assets/img/icons/iso-02.png" width="45px" alt={t('footer.copyright5')} />
                      <strong className="ml-2">{t('footer.copyright8')}</strong>
                    </li>
                    <li>
                      <CustomImgTag src="assets/img/icons/iso-02.png" width="45px" alt={t('footer.copyright5')} />
                      <strong className="ml-2">{t('footer.copyright9')}</strong>
                    </li>
                  </ul>
                </FrameCol>
              </FrameRow>
            </FrameCol>
            <FrameCol width={2}>
              <FrameRow addClassName="isoWrap">
                <FrameCol addClassName="mb-0">
                  <h4>{t('footer.copyright10')}</h4>
                </FrameCol>
                <FrameCol width={6}>
                  <CustomImgTag src="assets/img/custom/gala.png" className="w75" alt={t('footer.copyright11')} />
                </FrameCol>
                <FrameCol width={6}>
                  <CustomImgTag src="assets/img/custom/top3001.png" className="w60" alt={t('footer.copyright12')} />
                </FrameCol>
              </FrameRow>
            </FrameCol>
          </FrameRow>
        </FrameContainer>
      ) : (
        <FrameContainer>
          <FrameRow>
            <FrameCol width={8}>
              <ul className="list-inline2">
                <li>
                  {t('footer.copyright1')}
                  <span className="ml-2">{t('footer.copyright2')}</span>
                </li>
                <li>{t('footer.copyright3')}</li>
                <li className="mt-3">{t('footer.copyright4')}</li>
              </ul>
            </FrameCol>
            <FrameCol width={4}>
              <FrameRow addClassName="isoWrap">
                <FrameCol addClassName="mb-0">
                  <h4>{t('footer.copyright5')}</h4>
                </FrameCol>
                <FrameCol width={6}>
                  <ul>
                    <li className="align-space">
                      <CustomImgTag src="assets/img/icons/iso-01.png" width="45px" alt={t('footer.copyright5')} />
                      <strong className="ml-2">{t('footer.copyright6')}</strong>
                    </li>
                    <li>
                      <CustomImgTag src="assets/img/icons/iso-01.png" width="45px" alt={t('footer.copyright5')} />
                      <strong className="ml-2">{t('footer.copyright7')}</strong>
                    </li>
                  </ul>
                </FrameCol>
                <FrameCol width={6}>
                  <ul>
                    <li>
                      <CustomImgTag src="assets/img/icons/iso-02.png" width="45px" alt={t('footer.copyright5')} />
                      <strong className="ml-2">{t('footer.copyright8')}</strong>
                    </li>
                    <li>
                      <CustomImgTag src="assets/img/icons/iso-02.png" width="45px" alt={t('footer.copyright5')} />
                      <strong className="ml-2">{t('footer.copyright9')}</strong>
                    </li>
                  </ul>
                </FrameCol>
              </FrameRow>
            </FrameCol>
          </FrameRow>
        </FrameContainer>
      )}
    </div>
  );
};

export default FooterCopyrightLayout;
