import FramePageInner from 'common/view/layouts/frames/FramePageInner';
import { useBodyId, useRootUrl } from 'hooks/common/CommonHook';
import { Outlet } from 'react-router-dom';

const ContactLayout = () => {
  const { currentRootUrl: rootUrl } = useRootUrl();

  let bodyId = '';
  if (rootUrl === 'it') bodyId = 'it_contact';
  else if (rootUrl === 'lang') bodyId = 'lang_contact';
  else if (rootUrl === 'home') bodyId = 'contact';
  else if (rootUrl === 'ir') bodyId = 'irinfo';
  useBodyId(bodyId);

  return (
    <FramePageInner>
      <Outlet />
    </FramePageInner>
  );
};

export default ContactLayout;
