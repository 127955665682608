import RootLayout from 'common/view/layouts/default/RootLayout';
import { NotFoundLayout, NotFoundPage } from 'common/view/layouts/error';
import { createBrowserRouter } from 'react-router-dom';
import { CareerLayout, CareerPage } from 'views/common/children/career';
import { ContactLayout, ContactPage } from 'views/common/children/contact';
import { ItPortfolioLayout, ItPortfolioPage } from 'views/it/children/portfolio';
import { ItServiceLayout, ItServicePage } from 'views/it/children/service';
import { ItMainLayout, ItMainPage } from 'views/it/main';
import { LangPortfolioLayout, LangPortfolioPage } from 'views/lang/children/portfolio';
import { ProfileLayout, ProfilePage } from 'views/lang/children/profile';
import { LangServiceLayout, LangServicePage } from 'views/lang/children/service';
import { LangMainLayout, LangMainPage } from 'views/lang/main';
import { AboutLayout, AboutPage } from 'views/main/children/about';
import { CommunityLayout, CommunityPage } from 'views/main/children/community';
import { CommunityViewPage } from 'views/main/children/community/children/community';
import { NewsLayout, NewsPage } from 'views/main/children/news';
import { NewsViewPage } from 'views/main/children/news/children/news';
import { MainLayout, MainPage } from 'views/main/main';
import { SampleLayout, SamplePage } from 'views/sample/sample';
import { SampleCommunityLayout, SampleCommunityPage } from 'views/sample/sample/children/community';
import { SampleContactLayout } from 'views/sample/sample/children/contact';
import SampleContactPage from 'views/sample/sample/children/contact/page/SampleContactPage';
import { SampleNewsLayout, SampleNewsPage } from 'views/sample/sample/children/news';
import SampleNewsDetailPage from 'views/sample/sample/children/news/children/newsDetail/page/SampleNewsDetailPage';

const DefaultBrowserRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <MainPage />,
          },
          {
            path: 'about',
            element: <AboutLayout />,
            children: [
              {
                index: true,
                element: <AboutPage />,
              },
            ],
          },
          {
            path: 'news',
            element: <NewsLayout />,
            children: [
              {
                index: true,
                element: <NewsPage />,
              },
              {
                path: ':idx',
                element: <NewsViewPage />,
              },
            ],
          },
          {
            path: 'community',
            element: <CommunityLayout />,
            children: [
              {
                index: true,
                element: <CommunityPage />,
              },
              {
                path: ':idx/:idx',
                element: <CommunityViewPage />,
              },
            ],
          },
          {
            path: 'contact',
            element: <ContactLayout />,
            children: [
              {
                index: true,
                element: <ContactPage />,
              },
            ],
          },
          {
            path: 'career',
            element: <CareerLayout />,
            children: [
              {
                index: true,
                element: <CareerPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'it',
        element: <ItMainLayout />,
        children: [
          {
            index: true,
            element: <ItMainPage />,
          },
          {
            path: 'portfolio',
            element: <ItPortfolioLayout />,
            children: [
              {
                index: true,
                element: <ItPortfolioPage />,
              },
            ],
          },
          {
            path: 'service',
            element: <ItServiceLayout />,
            children: [
              {
                index: true,
                element: <ItServicePage />,
              },
            ],
          },
          {
            path: 'contact',
            element: <ContactLayout />,
            children: [
              {
                index: true,
                element: <ContactPage />,
              },
            ],
          },
          {
            path: 'career',
            element: <CareerLayout />,
            children: [
              {
                index: true,
                element: <CareerPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'lang',
        element: <LangMainLayout />,
        children: [
          {
            index: true,
            element: <LangMainPage />,
          },
          {
            path: 'profile',
            element: <ProfileLayout />,
            children: [
              {
                index: true,
                element: <ProfilePage />,
              },
            ],
          },
          {
            path: 'service',
            element: <LangServiceLayout />,
            children: [
              {
                index: true,
                element: <LangServicePage />,
              },
            ],
          },
          {
            path: 'portfolio',
            element: <LangPortfolioLayout />,
            children: [
              {
                index: true,
                element: <LangPortfolioPage />,
              },
            ],
          },
          {
            path: 'contact',
            element: <ContactLayout />,
            children: [
              {
                index: true,
                element: <ContactPage />,
              },
            ],
          },
          {
            path: 'career',
            element: <CareerLayout />,
            children: [
              {
                index: true,
                element: <CareerPage />,
              },
            ],
          },
        ],
      },
      {
        /*
        path: 'ir',
        element: <IrCorporateLayout />,
        children: [
          {
            index: true,
            element: <IrCorporatePage />,
          },
          {
            path: 'corporate',
            element: <IrCorporateLayout />,
            children: [
              {
                index: true,
                element: <IrCorporatePage />,
              },
            ],
          },
          {
            path: 'financial',
            element: <IrFinancialLayout />,
            children: [
              {
                index: true,
                element: <IrFinancialPage />,
              },
            ],
          },
          {
            path: 'policy',
            element: <IrPolicyLayout />,
            children: [
              {
                index: true,
                element: <IrPolicyPage />,
              },
            ],
          },
          {
            path: 'news',
            element: <IrNewsLayout />,
            children: [
              {
                index: true,
                element: <IrNewsPage />,
              },
              {
                path: ':id',
                element: <IrNewsDetailPage />,
              },
            ],
          },
          {
            path: 'contact',
            element: <ContactLayout />,
            children: [
              {
                index: true,
                element: <ContactPage />,
              },
            ],
          },
        ],
      */
      },
      {
        path: 'sample',
        element: <SampleLayout />,
        children: [
          {
            index: true,
            element: <SamplePage />,
          },
          {
            path: 'news',
            element: <SampleNewsLayout />,
            children: [
              {
                index: true,
                element: <SampleNewsPage />,
              },
              {
                path: ':id',
                element: <SampleNewsDetailPage />,
              },
            ],
          },
          {
            path: 'community',
            element: <SampleCommunityLayout />,
            children: [
              {
                index: true,
                element: <SampleCommunityPage />,
              },
            ],
          },
          {
            path: 'contact',
            element: <SampleContactLayout />,
            children: [
              {
                index: true,
                element: <SampleContactPage />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundLayout />,
        children: [
          {
            index: true,
            element: <NotFoundPage />,
          },
        ],
      },
    ],
  },
]);

export default DefaultBrowserRouter;
