import SectionLayout from 'common/view/layouts/section/SectionLayout';

import CommunityViewComponent from './CommunityViewComponent';

const CommunityViewPage = () => {
  return (
    <SectionLayout addSectionClassName="b-bordered">
      <CommunityViewComponent />
    </SectionLayout>
  );
};

export default CommunityViewPage;
