import SectionLayout from 'common/view/layouts/section/SectionLayout';

import SampleContactFormComponent from './SampleContactContactFormComponent';
import SampleContactGoogleMapComponent from './SampleContactGoogleMapComponent';

const SampleContactPage = () => {
  return (
    <>
      <SectionLayout addSectionClassName="main-color pb-0">
        <SampleContactFormComponent />
      </SectionLayout>
      <SectionLayout>
        <SampleContactGoogleMapComponent />
      </SectionLayout>
    </>
  );
};

export default SampleContactPage;
