import { CustomATag } from 'common/view/components/custom';
import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const VisualAreaComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="col-xs-12">
        <ExpAnimationLayout>
          <section className="section-primar visual-area">
            <FrameContainer>
              <FrameRow>
                <FrameCol width={12}>
                  <h1>
                    <span>{t('itMain.visual1')}</span>
                    <br />
                    <span className="fw-4">{t('itMain.visual2')}</span>
                    <span className="pull-right">
                      <CustomATag href="/it/service" className="btn btn-primary fs-14 btn-rounded">
                        {t('itMain.visual3')}
                        <i className="glyphicon icon icon-arrow-32 ml-5"></i>
                      </CustomATag>
                    </span>
                  </h1>
                </FrameCol>
                <FrameCol width={4}>
                  <div className="visual-box-core v2 alternate-color exp-animation" data-animation="express_image_appear" data-delay="400">
                    <h2>{t('itMain.visual4')}</h2>
                    <div className="visual-box-core-detail">
                      <p className="visual-box-core-text">
                        {t('itMain.visual5')}
                        <br />
                        {t('itMain.visual6')}
                      </p>
                    </div>
                    <div className="visual-icon-box">
                      <div>
                        <i className="fa fa-bank"></i>
                        <span>{t('itMain.visual7')}</span>
                      </div>
                      <div>
                        <i className="fa fa-briefcase"></i>
                        <span>{t('itMain.visual8')}</span>
                      </div>
                    </div>
                  </div>
                </FrameCol>
                <FrameCol width={4}>
                  <div className="visual-box-core v2 alternate-color exp-animation" data-animation="express_image_appear" data-delay="800">
                    <h2>{t('itMain.visual9')}</h2>
                    <div className="visual-box-core-detail">
                      <p className="visual-box-core-text">
                        {t('itMain.visual10')}
                        <br />
                        {t('itMain.visual11')}
                      </p>
                    </div>
                    <div className="visual-icon-box">
                      <div>
                        <i className="fa fa-credit-card"></i>
                        <span>{t('itMain.visual12')}</span>
                      </div>
                      <div>
                        <i className="fa fa-ticket"></i>
                        <span>
                          {t('itMain.visual13')}
                          <br />
                          {t('itMain.visual14')}
                        </span>
                      </div>
                    </div>
                  </div>
                </FrameCol>
                <FrameCol width={4}>
                  <div className="visual-box-core v2 alternate-color exp-animation" data-animation="express_image_appear" data-delay="1200">
                    <h2>{t('itMain.visual15')}</h2>
                    <div className="visual-box-core-detail"></div>
                    <div className="visual-icon-box2">
                      <div>
                        <i className="fa fa-terminal"></i>
                        <span>{t('itMain.visual16')}</span>
                      </div>
                      <div>
                        <i className="fa fa-bullseye"></i>
                        <span>{t('itMain.visual17')}</span>
                      </div>
                      <div>
                        <i className="fa fa-btc"></i>
                        <span>{t('itMain.visual18')}</span>
                      </div>
                    </div>
                  </div>
                </FrameCol>
              </FrameRow>
            </FrameContainer>
            <div className="visual-bg">
              <video className="bg-video__content" autoPlay muted loop>
                <source src="assets/img/custom/it_main.mp4" type="video/mp4" />
              </video>
            </div>
          </section>
        </ExpAnimationLayout>
      </FrameCol>
    </FrameRow>
  );
};

export default VisualAreaComponent;
