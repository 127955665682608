import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const ContactHeadIrComponent = () => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      <FrameCol textAlign="text-center" addClassName="mb-0">
        <h2 className="mb-20 mt-70">
          <span>IR CONTACT</span>
        </h2>
        <p className="section-sub-title mb-40">
          {t('contact.head2')}
          <br />
          {t('contact.head3')}
        </p>
      </FrameCol>
    </FrameRow>
  );
};

export default ContactHeadIrComponent;
