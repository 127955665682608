import { AbstractProps, TEXT_ALIGN } from 'types/common/PropsTypes';

import FrameContainer from '../frames/FrameContainer';
import FrameSection from '../frames/FrameSection';

interface SectionLayoutProps extends AbstractProps {
  addSectionClassName?: string;
  sectionTextAlign?: TEXT_ALIGN;
  addContianerClassName?: string;
}

const SectionLayout = ({ addSectionClassName, sectionTextAlign, addContianerClassName, children }: SectionLayoutProps) => {
  return (
    <FrameSection addClassName={addSectionClassName} textAlign={sectionTextAlign}>
      <FrameContainer addClassName={addContianerClassName}>{children}</FrameContainer>
    </FrameSection>
  );
};

export default SectionLayout;
