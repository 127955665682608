import { FrameContainer, FrameSection } from 'common/view/layouts/frames';
import { useRef, useState } from 'react';
import { ListAndPageProps } from 'types/common';
import { NewListRVO } from 'types/sample/sample2';
import { useEffectOnce } from 'usehooks-ts';
import { defaultPagingListObject } from 'util/DataUtil';

import { Sample2NewsListListComponent, Sample2NewsListPaginationComponent } from './sample2NewsListComponents';

const NEWS_LIST = [
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img7.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img8.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img8.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img7.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img6.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img5.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img4.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img3.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img2.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img1.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
  { imageSrc: 'assets/img/news/news_img9.png', title: '이포넷, "메디아크"와 의료 AI 서비스 및 기술 개발 위한 MOU 체결 딜라이트 닷넷', subTitle: '2023.04.02' },
];
const Sample2NewsListComponent = () => {
  const searchInputRef = useRef<HTMLInputElement>(null); // 인풋이 여러개일때는 state, 한 두개만 있ㅇ르 때에는 useRef
  const [newsList, setNewList] = useState<ListAndPageProps<NewListRVO>>(defaultPagingListObject);
  const [isLoading, setIsLoading] = useState(false); // 임시 로딩처리
  const onSubmit = (pageNo?: number) => {
    // const value = searchInputRef.current?.value;
    setIsLoading(true);
    setTimeout(() => {
      const itemCountPerPage = 9;
      const startIndex = ((pageNo ?? 1) - 1) * itemCountPerPage; // 임시 페이징 처리
      const res = NEWS_LIST; // 임시 페이징 처리
      const totalCount = res.length;
      const _newsList = res.slice(startIndex, startIndex + 9);
      setNewList({
        list: _newsList,
        pagination: {
          totalCount,
          currentPageNo: pageNo ?? 1,
          itemCountPerPage,
        },
      });
      setIsLoading(false);
    }, 500); // 임시 로딩처리
  };

  useEffectOnce(() => {
    onSubmit();
  });

  return (
    <FrameSection addClassName="b-bordered">
      <FrameContainer>
        <Sample2NewsListListComponent searchInputRef={searchInputRef} isLoading={isLoading} onSubmit={onSubmit} newsList={newsList.list} totalCount={newsList.pagination.totalCount} />
      </FrameContainer>
      <FrameContainer>
        <Sample2NewsListPaginationComponent pagination={newsList.pagination} pagingOnClick={onSubmit} />
      </FrameContainer>
    </FrameSection>
  );
};

export default Sample2NewsListComponent;
