import { getCmCode } from 'api/common/CommonAPI';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import TgGridLayout from 'common/view/layouts/grid/TgGridLayout';
import { useEffect, useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PortfolioRVO } from 'types/board';
import { CmCodeRVO } from 'types/common';
import NonePage from 'views/common/children/common/NonePage';

/**
 * PortfolioListComponent 프로퍼티즈
 */
interface PortfolioListComponentProps {
  portfolioCount: string[];
  getPortfolioList: (pageNo: number, categoryIds: string) => void;
  getPortfolioCount: (SearchValue: string) => void;
  searchInputData: {
    isFirstSearch: boolean;
    searchInput: string;
    backupSearchInput: string;
  };
  setSearchInputData: Dispatch<SetStateAction<{ searchInput: string; backupSearchInput: string; isFirstSearch: boolean }>>;
  portfolioList: PortfolioRVO[];
  isLoading: boolean;
  isNone: boolean;
}

const defaultCheckedList: string[] = [];

const StyledDiv = styled.div`
  .pracia .tg-item-content-holder .com-type {
    float: right;
    font-weight: 700;
  }
`;

const PortfolioListComponent = ({ portfolioCount, getPortfolioList, getPortfolioCount, searchInputData, setSearchInputData, portfolioList, isLoading, isNone }: PortfolioListComponentProps) => {
  const { t } = useTranslation();

  /******************************************
   * Data
   *******************************************/
  const [checkedList, setCheckedList] = useState<string[]>(defaultCheckedList);
  const [categoryList, setCategoryList] = useState<CmCodeRVO[]>([]);
  const isAllChecked = checkedList.length === 0;
  /******************************************
   * Func
   *******************************************/
  const fetch = async () => {
    const res = await getCmCode({ deptType: 'lang', grpId: 'HOME_CTGR' });
    if (res) {
      setCategoryList(res);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    getPortfolioList(1, checkedList.join(','));
  }, [checkedList]);

  useEffect(() => {
    if (searchInputData.isFirstSearch) getPortfolioList(1, checkedList.join(',')), getPortfolioCount(searchInputData.backupSearchInput);
  }, [searchInputData.backupSearchInput, searchInputData.isFirstSearch]);
  const handleOnKeyPress = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const onClick = (ele: CmCodeRVO, isChecked: boolean) => {
    if (isChecked) {
      const _checkedList = checkedList.filter((e) => e !== ele.code);
      setCheckedList(_checkedList);
      console.log('1');
    } else {
      console.log('2');
      setCheckedList([...checkedList, ele.code]);
    }
  };

  const onClickAll = () => {
    setCheckedList([]);
  };

  const controlSearch = () => {
    setSearchInputData({
      ...searchInputData,
      backupSearchInput: searchInputData.searchInput,
      isFirstSearch: true,
    });
  };

  const resetSearch = () => {
    onClickAll();
    setSearchInputData({
      searchInput: '',
      backupSearchInput: '',
      isFirstSearch: true,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputData({
      ...searchInputData,
      searchInput: event.target.value,
      isFirstSearch: false,
    });
  };

  return (
    <>
      <FrameRow>
        <FrameCol addClassName="text-center">
          <div className="searchWrap mb-30">
            <form id="newsSearch" method="POST">
              <div className="input-group">
                <input className="form-control" placeholder={t('langPortfolio.list1')} name="newsSearch" id="Search" type="text" onChange={handleChange} onKeyDown={handleOnKeyPress} value={searchInputData.searchInput} />
                <span className="input-group-btn">
                  <button className="btn btn-outline-white mr-20" type="button" onClick={() => controlSearch()}>
                    <i className="glyphicon glyphicon-search"></i>
                  </button>
                  <button className="btn btn-outline-white" type="button" onClick={() => resetSearch()}>
                    {t('langPortfolio.list2')}
                  </button>
                </span>
              </div>
            </form>
          </div>
        </FrameCol>
      </FrameRow>
      <FrameRow>
        <FrameCol>
          {/* 프로젝트 타입 선택 */}
          <div className="filter-wrap">
            <div className="smart-forms">
              <form method="post" action="" id="form-ui">
                <div className="form-body">
                  <div className="section">
                    <div className="option-group field">
                      <div className="smart-option-group text-center">
                        <label htmlFor="rate1" className="option" onClick={onClickAll}>
                          <input type="checkbox" id="rate" name="rate" />
                          <span className={`tg-filter tg-show-filter${isAllChecked ? ' tg-filter-active' : ''}`} data-filter="*">
                            <span className="tg-filter-name">
                              <i className="fa fa-check mr-5 mr-5"></i>A11 <span className="tg-filter-count">{portfolioCount[0]}</span>
                            </span>
                          </span>
                        </label>
                        {categoryList.map((ele, idx) => {
                          const isEleChecked = checkedList.find((e) => e === ele.code) !== undefined;

                          return (
                            <label key={`${idx}`} htmlFor="rate1" className="option" onClick={() => onClick(ele, isEleChecked)}>
                              <input type="checkbox" id={`${idx}`} name="rate" />
                              <span className={`tg-filter tg-show-filter${isEleChecked ? ' tg-filter-active' : ''}`}>
                                <span className="tg-filter-name">
                                  <i className="fa fa-check mr-5"></i>
                                  {ele.codeNm} <span className="tg-filter-count">{portfolioCount[idx + 1]}</span>
                                </span>
                              </span>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <TgGridLayout id="grid-pracia">
            {isLoading || (
              <StyledDiv
                className="tg-grid-holder tg-layout-grid"
                data-name="Pracia"
                data-style="grid"
                data-row="1"
                data-layout="vertical"
                data-rtl=""
                data-fitrows=""
                data-filtercomb=""
                data-filterlogic="AND"
                data-filterload=""
                data-sortbyload="none"
                data-orderload="false"
                data-fullwidth=""
                data-fullheight="null"
                data-gutters="[[320,20],[480,20],[690,20],[1000,20],[1300,20],[9999,20]]"
                data-slider="{'itemNav':'null','swingSpeed':0.1,'cycleBy':'null','cycle':5000,'startAt':1}"
                data-ratio="1.25"
                data-cols="[[320,1],[480,1],[690,2],[1000,3],[1300,3],[9999,3]]"
                data-rows="[[320,200],[480,200],[690,220],[1000,220],[1300,240],[9999,240]]"
                data-animation='{"name":"From Top","visible":"translateY(0)","hidden":"translateY(-100px)"}'
                data-transition="500ms"
                data-ajaxmethod="load_more"
                data-ajaxdelay="100"
                data-preloader="true"
                data-itemdelay="100"
                data-gallery=""
                data-ajax=""
              >
                {portfolioList &&
                  portfolioList.map((ele, idx) => {
                    return (
                      <article key={`${idx}`} className="tg-item camberra tg-item-reveal" data-date="1443202134" data-title={ele.categoryIdsNm[0]} data-none="0" data-row="1" data-col="1">
                        <div className="tg-item-inner">
                          <div className="tg-item-media-holder tg-light">
                            <div className="tg-item-media-inner">
                              <div className="tg-item-image" style={{ background: `url(${ele.imgFile ? ele.imgFile : 'assets/img/portfolio/lp_ai.jpg'})` }}></div>
                            </div>
                            <div className="tg-item-content">
                              <div className={`tg-item-overlay random-bg0${idx + 1}`}></div>
                              <div className="tg-center-holder">
                                <div className="tg-center-inner2">
                                  <h2 className="title">{ele.prjNm}</h2>
                                  <p className="date">
                                    {ele.ctrtBgnYmd} ~ {ele.ctrtEndYmd}
                                  </p>
                                  <p className="port-type">
                                    {(() => {
                                      switch (ele._categoryIds[0]) {
                                        case 'pc01':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc02':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc03':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc04':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc05':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc06':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc07':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc08':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc09':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc10':
                                          return <i className="fa fa-language"></i>;
                                        case 'pc11':
                                          return <i className="fa fa-language"></i>;
                                        default:
                                          <i className="fa fa-language"></i>;
                                      }
                                    })()}
                                    {ele._categoryIdsNm[0]}
                                    <span>{ele.clientName}</span>
                                  </p>
                                  <p className="discrption">{ele.remarks}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    );
                  })}
              </StyledDiv>
            )}
          </TgGridLayout>
        </FrameCol>
      </FrameRow>
      {isNone == true ? <NonePage /> : null}
    </>
  );
};

export default PortfolioListComponent;
