import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FrameCol } from '../frames';
import { FrameColProps } from '../frames/FrameCol';

/**
 * DetailViewNavBar 프로퍼티즈
 */

type DetailViewNavBarProps = FrameColProps & { brdNo: string; prevPostNo: number; nextPostNo: number };

const DetailViewNavBar = ({ size: colSize, width: colWidth, brdNo, prevPostNo, nextPostNo }: DetailViewNavBarProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const moveToPage = useCallback(
    (upcomingPostNo: number | null) => {
      if (upcomingPostNo === null) return;
      if (brdNo === '') {
        navigate(`../${upcomingPostNo}`, { state: { ...state, pageNo: state.pageNo ?? 1 } });
      } else {
        navigate(`../${brdNo}/${upcomingPostNo}`, { state: { ...state, pageNo: state.pageNo ?? 1 } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, state, brdNo],
  );

  const handlePageNavigation = useCallback(
    (direction: 'prev' | 'next') => {
      moveToPage(direction === 'next' ? nextPostNo : prevPostNo);
    },
    [moveToPage, nextPostNo, prevPostNo],
  );

  const backToList = useCallback(() => {
    navigate('../', { state: { ...state, pageNo: state.pageNo ?? 1 } });
  }, [navigate, state]);

  const isPrevDisabled = prevPostNo === 0;
  const isNextDisabled = nextPostNo === 0;

  return (
    <FrameCol size={colSize} width={colWidth} addClassName="pt-40" textAlign="text-center">
      <button type="button" className="btn btn-default btn-rounded" onClick={() => handlePageNavigation('prev')} disabled={isPrevDisabled}>
        Previous
      </button>
      <button type="button" className="btn btn-primary btn-rounded" onClick={backToList}>
        List
      </button>
      <button type="button" className="btn btn-default btn-rounded" onClick={() => handlePageNavigation('next')} disabled={isNextDisabled}>
        Next
      </button>
    </FrameCol>
  );
};

export default React.memo(DetailViewNavBar);
// 커뮤니티, 뉴스 등 화면 당 한번만 렌더링 되므로 메모이제이션을 하는게 과연 이득일까 싶은 의문
// 나중가서 탭화면에서 안쓰이면 메모이제이션 안하는 걸로 하겠음
