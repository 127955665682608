import ExpAnimationLayout from 'common/view/layouts/expAnimation/ExpAnimationLayout';
import SectionLayout from 'common/view/layouts/section/SectionLayout';
import FlowBannerLayout from 'common/view/layouts/slider/FlowBannerLayout';
import { useEffect } from 'react';

import ServiceTabComponent from './ServiceTabComponent';
import ServiceTabDetailComponent from './ServiceTabDetailComponent';

const ServiceLayout = () => {
  const pathname = window.location.href;
  const urlParse = pathname.split('#');
  const anchor = urlParse[1];

  useEffect(() => {
    if (anchor) {
      const anchorEl = document.getElementById(String(anchor));
      if (anchorEl) {
        setTimeout(() => {
          anchorEl.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      }
    }
  }, []);

  return (
    <ExpAnimationLayout>
      <FlowBannerLayout>
        <SectionLayout addSectionClassName="main-color pb-0" addContianerClassName="fluid">
          <ServiceTabComponent />
        </SectionLayout>
        <ServiceTabDetailComponent />
      </FlowBannerLayout>
    </ExpAnimationLayout>
  );
};

export default ServiceLayout;
