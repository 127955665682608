import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import MasterSliderLayout from 'common/view/layouts/slider/MasterSliderLayout';
import { useLayoutEffect, useRef, useState } from 'react';
import { NewsRVO } from 'types/sample/sample1';

const data = [
  {
    img: 'assets/img/news/news_img1.png',
    alt: '뉴스이미지',
    title: "블록체인 기부플렛폼 '체리', 누적 후원 금액 80억원 돌파 블록체인 기부플렛폼 '체리', 누적 후원 금액 80억원 돌파",
    sub: '2023.10.11',
  },
  {
    img: 'assets/img/news/news_img2.png',
    alt: '뉴스이미지',
    title: "블록체인 기부플렛폼 '체리', 누적 후원 금액 80억원 돌파",
    sub: '2023.04.03',
  },
  {
    img: 'assets/img/news/news_img3.png',
    alt: '뉴스이미지',
    title: "블록체인 기부플렛폼 '체리', 누적 후원 금액 80억원 돌파",
    sub: '2023.04.03',
  },
  {
    img: 'assets/img/news/news_img4.png',
    alt: '뉴스이미지',
    title: "블록체인 기부플렛폼 '체리', 누적 후원 금액 80억원 돌파",
    sub: '2023.04.03',
  },
  {
    img: 'assets/img/news/news_img5.png',
    alt: '뉴스이미지',
    title: "블록체인 기부플렛폼 '체리', 누적 후원 금액 80억원 돌파",
    sub: '2023.04.03',
  },
  {
    img: 'assets/img/news/news_img6.png',
    alt: '뉴스이미지',
    title: "블록체인 기부플렛폼 '체리', 누적 후원 금액 80억원 돌파",
    sub: '2023.04.03',
  },
  {
    img: 'assets/img/news/news_img7.png',
    alt: '뉴스이미지',
    title: "블록체인 기부플렛폼 '체리', 누적 후원 금액 80억원 돌파",
    sub: '2023.04.03',
  },
];

const sliderOption = {
  width: 480,
  height: 500,
  top: 0,
  layout: 'partialview',
  space: 30,
  view: 'basic',
  loop: true,
  autoplay: true,
  speed: 30,
  //   filters: { // 흑백 필터
  //     grayscale: 1,
  //     contrast: 1.5
  //   }
};
const bulletsOption = { autohide: false, align: 'bottom', margin: 10, space: 4 };
const SampleNewsComponent = () => {
  const [slidesInfo, setSlidesDef] = useState<NewsRVO[]>([]);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  const fetch = () => {
    const _slidesInfo = data.map((ele) => {
      return { imgSrc: ele.img, imgAlt: ele.alt, href: '#', title: ele.title, subTitle: ele.sub };
    });
    setSlidesDef(_slidesInfo);
  };

  useLayoutEffect(() => {
    fetch();
  }, []);
  return (
    <FrameRow>
      <FrameCol width={4} addClassName={'mob-text-center'}>
        <h1 className="mb-30 mt-5">
          <span>NEWS</span>
        </h1>
        <p className="section-sub-title mb-40">새로운 소식으로 기분도 UP!</p>
        <CustomATag href="#" className="btn btn-outline-primary btn-rounded fs-14">
          보도자료 전체 보기 <i className="glyphicon icon icon-arrow-32"></i>
        </CustomATag>

        {/* 웹브라우저에서만 보임 */}
        <div className="newsCont">
          <ul>
            <li ref={prevBtnRef} title="이전">
              <span className="ion-ios-arrow-left fa-4x"></span>
            </li>
            <li ref={nextBtnRef} title="다음">
              <span className="ion-ios-arrow-right fa-4x"></span>
            </li>
          </ul>
        </div>
      </FrameCol>
      <FrameCol width={8} addClassName="newsWrap">
        <div className="ms-filters-template">
          <MasterSliderLayout bulltesOption={bulletsOption} sliderOption={sliderOption} prevBtnRef={prevBtnRef} nextBtnRef={nextBtnRef} id="masterslider" className="master-slider ms-skin-default">
            {slidesInfo.map((ele, idx) => {
              return (
                <div className="ms-slide" key={`SampleNewsComponentMasterSliderLayout${idx}`}>
                  <div className="bgimgWrap" style={{ backgroundImage: `url(${ele.imgSrc})` }}></div>
                  <div className="newsTitle">
                    <p className="title">
                      <a href="#">{ele.title}</a>
                    </p>
                    <p className="date">{ele.subTitle}</p>
                  </div>
                </div>
              );
            })}
          </MasterSliderLayout>
        </div>
      </FrameCol>
    </FrameRow>
  );
};

export default SampleNewsComponent;
