import { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import ModalComponent, { ModalComponentProps } from './ModalComponent';

const ModalLayout = (props: ModalComponentProps) => {
  const root = useRef<HTMLElement>();
  const [isInitialized, setIsInitialized] = useState(false);

  useLayoutEffect(() => {
    let modalPortal = document.querySelector('div#modal-portal') as HTMLElement;
    if (!modalPortal) {
      modalPortal = document.createElement('div');
      modalPortal.id = 'modal-portal';
      document.body.insertBefore(modalPortal, document.getElementById('root'));
    }
    root.current = modalPortal;
    setIsInitialized(true);

    return () => {
      if (root.current) {
        // const modalNode = root.current.querySelector(`div#${props.modalId}`);
        // console.log('modalNode', modalNode);
        // if (modalNode) root.current.removeChild(modalNode);
        // console.log('root.current.childElementCount', root.current.childElementCount);
        if (!root.current.childElementCount) document.body.removeChild(root.current);
      }
    };
  }, []);

  return isInitialized && root.current && createPortal(<ModalComponent {...props}>{props.children}</ModalComponent>, root.current);
};

export default ModalLayout;
