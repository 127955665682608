import { CustomATag } from 'common/view/components/custom';
import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import TgGridLayout from 'common/view/layouts/grid/TgGridLayout';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BoardRVO } from 'types/board';
import { dateFormat } from 'util/FormatUtil';

const StyledDiv = styled.div`
  .pracia .tg-item-content-holder .com-type {
    float: right;
    font-weight: 700;
  }
`;
interface NewsListComponentProps {
  searchInputData: { searchInput: string; backupSearchInput: string };
  setSearchInputData: Dispatch<SetStateAction<{ searchInput: string; backupSearchInput: string; isFirstSearch: boolean }>>;
  newsList: BoardRVO[];
  isLoading: boolean;
  totalCount: number;
}

const handleOnKeyPress = (e: { key: string; preventDefault: () => void }) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

const NewsListComponent = ({ searchInputData, setSearchInputData, newsList, isLoading, totalCount }: NewsListComponentProps) => {
  const { t } = useTranslation();

  const controlSearch = () => {
    setSearchInputData({
      ...searchInputData,
      backupSearchInput: searchInputData.searchInput,
      isFirstSearch: true,
    });
  };

  const resetSearch = () => {
    setSearchInputData({
      searchInput: '',
      backupSearchInput: '',
      isFirstSearch: true,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputData({
      ...searchInputData,
      searchInput: event.target.value,
      isFirstSearch: false,
    });
  };

  return (
    <>
      <FrameRow>
        <FrameCol addClassName="text-center">
          <div className="searchWrap">
            <form id="newsSearch" method="POST">
              <div className="input-group">
                <input className="form-control" placeholder={t('news.search1')} name="newsSearch" id="Search" type="text" onChange={handleChange} onKeyDown={handleOnKeyPress} value={searchInputData.searchInput} />
                <span className="input-group-btn">
                  <button className="btn btn-outline-white mr-20" type="button" onClick={() => controlSearch()}>
                    <i className="glyphicon glyphicon-search"></i>
                  </button>
                  <button className="btn btn-outline-white" type="button" onClick={() => resetSearch()}>
                    {t('news.search2')}
                  </button>
                </span>
              </div>
            </form>
          </div>
        </FrameCol>
      </FrameRow>
      <FrameRow>
        <FrameCol>
          <p className="total-num">
            <span>{totalCount ?? 0}</span> {t('news.search3')}
          </p>
          <TgGridLayout id="grid-pracia">
            {isLoading || (
              <StyledDiv
                className="tg-grid-holder tg-layout-grid"
                data-name="Pracia"
                data-style="grid"
                data-row="1"
                data-layout="vertical"
                data-rtl=""
                data-fitrows=""
                data-filtercomb=""
                data-filterlogic="AND"
                data-filterload=""
                data-sortbyload="none"
                data-orderload="false"
                data-fullwidth=""
                data-fullheight="null"
                data-gutters="[[320,20],[480,20],[690,20],[1000,20],[1300,20],[9999,20]]"
                data-slider="{'itemNav':'null','swingSpeed':0.1,'cycleBy':'null','cycle':5000,'startAt':1}"
                data-ratio="1.25"
                data-cols="[[320,1],[480,1],[690,2],[1000,3],[1300,3],[9999,3]]"
                data-rows="[[320,200],[480,200],[690,220],[1000,220],[1300,240],[9999,240]]"
                data-animation='{"name":"From Top","visible":"translateY(0)","hidden":"translateY(-100px)"}'
                data-transition="500ms"
                data-ajaxmethod="load_more"
                data-ajaxdelay="100"
                data-preloader="true"
                data-itemdelay="100"
                data-gallery=""
                data-ajax=""
              >
                {newsList.map((ele, idx) => {
                  return (
                    <article key={`${idx}`} className="tg-item pracia tg-item-reveal" data-row="1" data-col="1">
                      <div className="tg-item-inner">
                        <div className="tg-item-media-holder tg-light">
                          <div className="tg-item-media-inner">
                            <div className="tg-item-image" style={{ background: `url(${ele.imgFile ? ele.imgFile : 'assets/img/news/news.jpg'})` }}></div>
                          </div>
                          <div className={`tg-item-overlay random-bg0${idx + 1}`}></div>
                          <div className="tg-center-holder">
                            <div className="tg-center-inner">
                              <CustomATag href={`${ele.postNo}`} className="tg-link-button" state={{ pageNo: ele.pageNo }}>
                                <i className="tg-icon-link"></i>
                              </CustomATag>
                            </div>
                          </div>
                        </div>
                        <div className="tg-item-content-holder image-format">
                          <h2 className="newsTitle">
                            <CustomATag href={`${ele.postNo}`} state={{ pageNo: ele.pageNo }}>
                              {ele.subject}
                            </CustomATag>
                          </h2>
                          <span className="newsDate">{dateFormat(ele.pstgYmd)}</span>
                        </div>
                      </div>
                    </article>
                  );
                })}
              </StyledDiv>
            )}
          </TgGridLayout>
        </FrameCol>
      </FrameRow>
    </>
  );
};

export default NewsListComponent;
