import { ApiClient } from 'api/RestClient';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useEffect } from 'react';

export const useAxiosInterceptors = () => {
  /**
   * 공통 에러 핸들러
   */
  // const errorHandler = useCallback((error: AxiosError) => {
  //   console.debug(error);
  //   const errorStatus = error.response?.status;
  //   console.debug('errorStatus', errorStatus);
  //   if (errorStatus === 504) return alert('서버통신 실패하였습니다.');
  //   // else
  //   if (!errorStatus || errorStatus === 404) return alert('잘못된 요청입니다.');
  //   else return Promise.reject(error);
  // }, []);

  /**
   * 기본요청 인터셉터\
   * 요청 성공 직전 호출
   *
   * @param config
   * @returns
   */
  const defaultRequestHandler = (config: InternalAxiosRequestConfig) => {
    console.debug(`Request ${config.baseURL}${config.url}`);
    return config;
  };

  /**
   * 기본요청에러 인터셉터\
   * 요청 에러 직전 호출
   *
   * @param error
   * @returns
   */
  const defaultRequestErrorHandler = (error: AxiosError) => {
    console.debug(`Request Error ${error.response?.status}${error.response?.statusText}`);
    return Promise.reject(error);
    // return errorHandler(error);
  };

  /**
   * 기본응답 인터셉터\
   * http status가 200인 경우 응답 성공 직전 호출 .then() 으로 이어짐
   *
   * @param response
   * @returns
   */
  const defaultResponseHandler = async (response: AxiosResponse) => {
    console.debug(`Response ${response.config.baseURL}${response.config.url}`);
    return response;
  };

  /**
   * 기본응답에러 인터셉터\
   * http status가 200이 아닌 경우 응답 에러 직전 호출 .catch() 으로 이어짐
   *
   * @param error
   * @returns
   */
  const defaultResponseErrorHandler = (error: AxiosError) => {
    console.debug(`Response Error ${error.response?.status}${error.response?.statusText}`);
    return Promise.reject(error);
    // return errorHandler(error);
  };
  useEffect(() => {
    const requestInterceptor = ApiClient.interceptors.request.use(defaultRequestHandler, defaultRequestErrorHandler);
    const responseInterceptor = ApiClient.interceptors.response.use(defaultResponseHandler, defaultResponseErrorHandler);
    console.debug('ADD AxiosInterceptors');

    return () => {
      console.debug('REMOVE AxiosInterceptors');
      ApiClient.interceptors.request.eject(requestInterceptor);
      ApiClient.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};
