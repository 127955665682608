import { selectNewsList } from 'api/board/BoardAPI';
import FrameContainer from 'common/view/layouts/frames/FrameContainer';
import FrameSection from 'common/view/layouts/frames/FrameSection';
import { useEffect, useState } from 'react';
import { BoardRVO } from 'types/board';
import { ListAndPageProps } from 'types/common';
import { useEffectOnce } from 'usehooks-ts';
import { isSuccess } from 'util/ApiResponseUtil';
import { defaultPagingListObject } from 'util/DataUtil';

import { NewsListComponent, NewsListPaginationComponent } from './newsListComponents';

// 페이징처리시 기존 검색어 유지를 위한 처리
// type SearchInputProps = {
//   searchInput: string;
//   backupSearchInput: string;
// };

// export const searchInputData: SearchInputProps = {
//   searchInput: '',
//   backupSearchInput: '',
// };

// 뉴스 페이지 세션 키
// const sessionPageNoKey = 'NewsPageNo';
// 뉴스 검색어 세션 키
// const sessionSearchKey = 'NewsSearch';

const NewsListPageComponent = () => {
  const [newsList, setNewsList] = useState<ListAndPageProps<BoardRVO>>(defaultPagingListObject);
  const [isLoading, setIsLoading] = useState(false); // 임시 로딩처리
  const [searchInputData, setSearchInputData] = useState({ searchInput: '', backupSearchInput: '', isFirstSearch: false });
  // 뒤로가기 시 전화면 staate값(pageNo등...) 필요
  // const { state } = useLocation();
  // const searchInputRef = useRef<HTMLInputElement>(null); // 인풋이 여러개일때는 state, 한 두개만 있ㅇ르 때에는 useRef

  const getNewsList = async (pageNo?: number) => {
    const itemCountPerPage = 9;
    setIsLoading(true);
    await selectNewsList({ pageNo: pageNo, rowsPage: itemCountPerPage, mostYn: 'N', searchValue: searchInputData.backupSearchInput })
      .then((res) => {
        if (isSuccess(res.code)) {
          const resData = res.response;
          if (resData) {
            setNewsList({
              list: resData,
              pagination: {
                totalCount: resData[0].totalCount,
                currentPageNo: pageNo ?? 1,
                itemCountPerPage,
              },
            });
            // 새로고침 시 세션값 필요
            // sessionStorage.setItem(sessionPageNoKey, String(pageNo ?? 1));
            // console.log('searchValue', searchValue);
            // console.log(' ?? sessionStorage.getItem(NewsSearch)', sessionStorage.getItem('NewsSearch'));
            // sessionStorage.setItem(sessionSearchKey, searchValue ?? searchInputData.backupSearchInput);
          }
          setSearchInputData({
            ...searchInputData,
            searchInput: searchInputData.backupSearchInput,
            isFirstSearch: false,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffectOnce(() => {
    getNewsList();
  });

  // useEffect(() => {
  //   return () => {
  //     sessionStorage.removeItem(sessionPageNoKey);
  //     // sessionStorage.removeItem(sessionSearchKey);
  //   };
  // }, []);
  // console.log('::::: setSearchInputData resData : ', searchInputData.backupSearchInput);

  // useEffect(() => {
  //   const sessionSearch = sessionStorage.getItem(sessionSearchKey);
  //   console.log('::::: sessionSearch : ', sessionSearch);
  //   const sessionPageNo = sessionStorage.getItem(sessionPageNoKey);

  //   if (sessionPageNo !== null) {
  //     if (sessionSearch !== null) {
  //       getNewsList(parseInt(sessionPageNo), sessionSearch);
  //     }
  //     getNewsList(parseInt(sessionPageNo));
  //   } else if (state !== null) {
  //     if (sessionSearch !== null) {
  //       getNewsList(parseInt(state.pageNo), sessionSearch);
  //     }
  //     getNewsList(parseInt(state.pageNo));
  //   } else if (state === null) {
  //     getNewsList();
  //   }
  // }, [state]);

  useEffect(() => {
    if (searchInputData.isFirstSearch) getNewsList();
  }, [searchInputData.backupSearchInput, searchInputData.isFirstSearch]);

  return (
    <FrameSection addClassName="b-bordered">
      <FrameContainer>
        <NewsListComponent searchInputData={searchInputData} setSearchInputData={setSearchInputData} newsList={newsList.list} isLoading={isLoading} totalCount={newsList.pagination.totalCount} />
      </FrameContainer>
      <FrameContainer>
        <NewsListPaginationComponent pagination={newsList.pagination} pagingOnClick={getNewsList} />
      </FrameContainer>
    </FrameSection>
  );
};

export default NewsListPageComponent;
