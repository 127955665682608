/**
 * 스타일 관련 클래스 공통 상수 모음
 */

import { CSSProperties } from 'react';

// 사이즈
const md = 'md';
const lg = 'lg';
const sm = 'sm';
const xl = 'xl';

// 스타일 object
const stylePre = { whiteSpace: 'pre-line' } as CSSProperties;
const styleCursorPointer = { cursor: 'pointer' } as CSSProperties;

/* 자주 쓰이는 스타일클래스 */
// 아이콘
const arrow36 = 'arrow-36';
const arrow32 = 'arrow-32';

// 컬러테마
const primary = 'primary';
const outlinePrimary = 'outline-primary';

export { arrow32, arrow36, lg, md, outlinePrimary, primary, sm, styleCursorPointer, stylePre, xl };
