import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const ItMainLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('views.layout1')}</title>
        <meta property="og:title" content="이포넷" />
        <meta property="og:description" content="기술로 세계를 섬기는 기업" />
        <meta
          property="keyword"
          content="e4net, 이포넷, 이수정, Globalization, Localization, Translation, Asian Localization, Asian Translation, Korean Localization, Korean Translation, English to Korean, Korean to English, Localization for Asian market, Localization for Korean market, 한글화, 현지화, 번역, 기계 번역, IT, IT Service, IT Outsourcing, IT Consulting, 자동 번역, 자동번역, 기계번역, Machine Translation, 한국어 번역, 영한, 한영, 일한, 컨설팅, SI, EA, EAMS, 기술번역, 특허번역, 다국어 웹사이트 번역, 의학번역, 메디컬번역, Life Science, 헬스케어, 의료기기, 전문번역"
        />
      </Helmet>
      <Outlet />
    </>
  );
};

export default ItMainLayout;
