import ModalLayout from 'common/view/layouts/modal/ModalLayout';
import { useTranslation } from 'react-i18next';

import CareerModalFormLayout from './CareerModalFormLayout';

export interface CareerModalLayoutProps {
  modalId: string;
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  modalTitleName: string;
  stat: string;
}

const CareerModalLayout = ({ isOpen, setIsOpen, modalId, modalTitleName, stat }: CareerModalLayoutProps) => {
  const { t } = useTranslation();

  return (
    <ModalLayout isOpen={isOpen} setIsOpen={setIsOpen} modalId={modalId} titleName={modalTitleName} footerButtonDef={[{ colorTheme: 'outline-secondary', dontDismiss: false, labelName: t('career.modalLayout1'), size: 'md' }]}>
      <CareerModalFormLayout isOpen={isOpen} setIsOpen={setIsOpen} ctgr1Cd={stat} />
    </ModalLayout>
  );
};

export default CareerModalLayout;
