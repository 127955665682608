import React, { useEffect, useRef } from 'react';
import { TabLayoutCommonProps } from 'types/common/PropsTypes';
import { handleElementScrollToTop } from 'util/UiUtil';

import TabPanelLayout from './TabPanelLayout';

interface TabLayoutProps extends TabLayoutCommonProps {
  tabLabelDef: string[];
  tabPanelDef: React.ReactNode[];
  isFromModal?: boolean;
}

const TabLayout = ({ tabLabelDef, tabPanelDef, isFromModal, currentTabIdx, setCurrentTabIdx }: TabLayoutProps) => {
  const tabContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tabContentRef.current) handleElementScrollToTop(tabContentRef.current);
  }, [currentTabIdx]);

  return (
    <div className={`${isFromModal ? 'modal-tab-wrap' : 'tab-wrap'} line-tab`}>
      <ul className="nav nav-tabs" role="tablist">
        {tabLabelDef.map((ele, idx) => (
          <li className="nav-item" key={`tabLabelDef${idx}`}>
            <a className={`nav-link ${idx === currentTabIdx && 'active'}`} data-toggle="tab" href={`#tab_justified-${idx}`} role="tab" onClick={() => setCurrentTabIdx(idx)}>
              <span>{ele}</span>{' '}
            </a>
          </li>
        ))}
      </ul>
      <div ref={tabContentRef} className="tab-content hi600" style={{ overflow: 'auto' }}>
        {tabPanelDef.map(
          (e, idx) =>
            idx === currentTabIdx && (
              <TabPanelLayout key={`TabPanelLayout${idx}`} idx={idx}>
                {e}
              </TabPanelLayout>
            ),
        )}
      </div>
    </div>
  );
};

export default React.memo(TabLayout);
