import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

import ContactFormComponent from './ContactFormComponent';

const ContactInfoComponent = (props: { rootUrl: string }) => {
  const { t } = useTranslation();

  return (
    <FrameRow>
      {props.rootUrl === 'home' ? (
        <FrameCol width={3}>
          <div className="contact-area">
            <h3>
              {t('contact.info1')}
              <br />
              {t('contact.info2')}
            </h3>
            <p>{t('contact.info3')}</p>
            <ul className="contactWrap">
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-mail"></i>
                  </div>
                  <div className="adress">
                    <p>Email</p>
                    <p>
                      <a href="mailto:e4-mng@e4net.net">e4-mng@e4net.net</a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-volume-control-phone"></i>
                  </div>
                  <div className="adress">
                    <p>Phone</p>
                    <p>
                      <a href="tel:02-3465-8500">02-3465-8500</a>
                    </p>
                    <p className="mt-5">Global</p>
                    <p>
                      <a href="tel:82-2-3465-8500">+82 2 3465 8500</a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-printer"></i>
                  </div>
                  <div className="adress">
                    <p>Fax</p>
                    <p>
                      <a href="tel:02-3465-8501">02-3465-8501</a>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </FrameCol>
      ) : (
        ''
      )}

      {props.rootUrl === 'it' ? (
        <FrameCol width={3}>
          <div className="contact-area">
            <h3>
              {t('contact.info4')}
              <br />
              {t('contact.info5')}
            </h3>
            <p>{t('contact.info6')}</p>
            <ul className="contactWrap">
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-mail"></i>
                  </div>
                  <div className="adress">
                    <p>Email</p>
                    <p>
                      <a href="mailto:it-mkt@e4net.net">it-mkt@e4net.net</a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-volume-control-phone"></i>
                  </div>
                  <div className="adress">
                    <p>Phone</p>
                    <p>
                      <a href="tel:02-3465-8581">02-3465-8581</a>
                    </p>
                    <p className="mt-5">Global</p>
                    <p>
                      <a href="tel:82-2-3465-8581">+82 2 3465 8581</a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-printer"></i>
                  </div>
                  <div className="adress">
                    <p>Fax</p>
                    <p>
                      <a href="tel:02-3465-8501">02-3465-8501</a>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </FrameCol>
      ) : (
        ''
      )}

      {props.rootUrl === 'lang' ? (
        <FrameCol width={3}>
          <div className="contact-area">
            <h3>
              {t('contact.info7')}
              <br />
              {t('contact.info8')}
            </h3>
            <p>{t('contact.info9')}</p>
            <ul className="contactWrap">
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-mail"></i>
                  </div>
                  <div className="adress">
                    <p>Email</p>
                    <p>
                      <a href="mailto:e4mkt@e4net.net">e4mkt@e4net.net</a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-volume-control-phone"></i>
                  </div>
                  <div className="adress">
                    <p>Phone</p>
                    <p>
                      <a href="tel:02-3465-8530">02-3465-8530</a>
                    </p>
                    <p className="mt-5">Global</p>
                    <p>
                      <a href="tel:82-2-3465-8530">+82 2 3465 8530</a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-printer"></i>
                  </div>
                  <div className="adress">
                    <p>Fax</p>
                    <p>
                      <a href="tel:02-3465-8501">02-3465-8501</a>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </FrameCol>
      ) : (
        ''
      )}

      {props.rootUrl === 'ir' ? (
        <FrameCol width={3}>
          <div className="contact-area">
            <h3>{t('contact.info10')}</h3>
            <p>{t('contact.info11')}</p>
            <ul className="contactWrap">
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-mail"></i>
                  </div>
                  <div className="adress">
                    <p>Email</p>
                    <p>
                      <a href="mailto:e4mkt@e4net.net">e4mkt@e4net.net</a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-volume-control-phone"></i>
                  </div>
                  <div className="adress">
                    <p>Phone</p>
                    <p>
                      <a href="tel:02-3465-8500">02-3465-8500</a>
                    </p>
                    <p className="mt-5">Global</p>
                    <p>
                      <a href="tel:82-2-3465-8500">+82 2 3465 8500</a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact">
                  <div className="icon">
                    <i className="fa fa-printer"></i>
                  </div>
                  <div className="adress">
                    <p>Fax</p>
                    <p>
                      <a href="tel:02-3465-8501">02-3465-8501</a>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </FrameCol>
      ) : (
        ''
      )}

      <FrameCol width={9}>
        <ContactFormComponent categoryValue={props.rootUrl} />
      </FrameCol>
    </FrameRow>
  );
};

export default ContactInfoComponent;
