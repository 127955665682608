import { EN } from 'common/constants/Constants';
import { CustomATag } from 'common/view/components/custom';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { stylePre } from 'util/StyleUtil';

import { FrameCol, FrameContainer, FrameRow } from '../frames';

const FooterMainLayout = () => {
  const { t } = useTranslation();
  const englishLanguageStyleAdjustment = (el: HTMLHeadingElement | null) => {
    if (!el) return;
    if (i18n.language === EN) {
      el.style.setProperty('font-size', '0.85rem', 'important');
      return;
    }
    el.style.removeProperty('font-size');
  };

  return (
    <div className="main">
      <FrameContainer>
        <FrameRow>
          <FrameCol width={4}>
            <section>
              <h2>
                {t('footer.main1')}
                <br />
                {t('footer.main2')}
              </h2>
              <p style={stylePre}>{t('footer.main3')}</p>
            </section>
          </FrameCol>
          <FrameCol width={4}>
            <section>
              <h3>{t('footer.main4')}</h3>
              <p className="discrption">
                {t('footer.main5')}
                <br />
                {t('footer.main6')}
              </p>
              <p className="discrption">{t('footer.main7')}</p>
              <div className="question">
                <p>
                  <CustomATag href="/it/contact">
                    {t('footer.main8')}
                    <i className="fa fa-arrow-circle-right ml-2"></i>
                  </CustomATag>
                </p>
              </div>
            </section>
          </FrameCol>
          <FrameCol width={4}>
            <section>
              <h3>{t('footer.main9')}</h3>
              <p className="discrption">
                {t('footer.main10')}
                <br />
                {t('footer.main11')}
              </p>
              <p className="discrption">{t('footer.main12')}</p>
              <div className="question">
                <p>
                  <span>
                    <CustomATag href="/lang/contact">
                      {t('footer.main13')}
                      <i className="fa fa-arrow-circle-right ml-2"></i>
                    </CustomATag>
                  </span>
                  <CustomATag href="https://www.e4lang.com/app/quota/immediateView.do" target="_blank">
                    <span className="ml-3 e4langci">
                      {t('footer.main14')}
                      <i className="fa fa-arrow-circle-right ml-2"></i>
                    </span>
                  </CustomATag>
                </p>
              </div>
            </section>
          </FrameCol>
          <FrameCol>
            <section>
              <ul className="contactWrap">
                <li>
                  <h3 ref={englishLanguageStyleAdjustment}>
                    {t('footer.main15')}
                    <br />
                    {t('footer.main16')}
                  </h3>
                </li>
                <li>
                  <div className="contact">
                    <div className="icon">
                      <i className="fa fa-mail"></i>
                    </div>
                    <div className="adress">
                      <p>Email</p>
                      <p>
                        <a href="mailto:e4-mng@e4net.net">e4-mng@e4net.net</a>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact">
                    <div className="icon">
                      <i className="fa fa-volume-control-phone"></i>
                    </div>
                    <div className="adress">
                      <p>Phone</p>
                      <p>
                        <a href="tel:02-3465-8500">02-3465-8500</a>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact">
                    <div className="icon">
                      <i className="fa fa-printer"></i>
                    </div>
                    <div className="adress">
                      <p>Fax</p>
                      <p>
                        <a href="tel:02-3465-8501">02-3465-8501</a>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </section>
          </FrameCol>
        </FrameRow>
      </FrameContainer>
    </div>
  );
};

export default FooterMainLayout;
