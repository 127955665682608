import BackToTopButton from 'common/view/components/BackToTopButton';
import { useAxiosInterceptors } from 'hooks/common/AxiosHook';
import { usePageMoved } from 'hooks/common/CommonHook';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import HeaderLayout from '../header/HeaderLayout';
import QuickMenuLayout from '../quickMenu/QuickMenuLayout';
import PageLayout from './PageLayout';

const RootLayout = () => {
  useAxiosInterceptors();
  const { pathname, instantlyToTop } = usePageMoved();
  useEffect(() => {
    instantlyToTop();
  }, [pathname]);

  return (
    <div className="wrapper">
      <HeaderLayout />
      <PageLayout>
        <Outlet />
      </PageLayout>
      <QuickMenuLayout />
      <BackToTopButton />
    </div>
  );
};

export default RootLayout;
