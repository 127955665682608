export const checkDevice = () => {
  const appVersion = navigator.appVersion;
  const isIOS = /iphone|ipad|ipod/gi.test(appVersion);
  const isAndroid = /android/gi.test(appVersion);
  return { isIOS, isAndroid };
};
// export const throttle = <T extends (...args: any[]) => void>(func: T, limit: number): ((...args: Parameters<T>) => void) => {
//   let inThrottle: boolean;
//   return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
//     const context = this;
//     if (!inThrottle) {
//       func.apply(context, args);
//       inThrottle = true;
//       setTimeout(() => (inThrottle = false), limit);
//     }
//   } as (...args: Parameters<T>) => void;
// };
