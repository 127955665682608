import { CH, EN } from 'common/constants/Constants';

/**
 * 날짜 변환 ex) 20220101 => 2022-01-01
 * @param value{string} 변환할 값
 * @param format{string} 변환할 포맷 (ex: yyyy-MM-dd or yyyy.MM.dd or MM/yy)
 * @param isWithDay{bool} 요일도 함께 표시되는 지 여부
 * @returns{string} 변환된 값
 */
export const dateFormat = (value = '', format = 'yyyy-MM-dd', isWithDay = false) => {
  const len = value?.length;
  if (!len) return '';
  else if (len !== 6 && len !== 8) return value;

  const year = value.substring(0, 4);
  const month = value.substring(4, 6);
  const date = value.length === 8 ? value.substring(6, 8) : null;

  let result = format
    .toLowerCase()
    .replace('yyyy', year)
    .replace('yy', year.slice(-2))
    .replace('mm', month)
    .replace(date ? 'dd' : '$', date ?? '');

  if (isWithDay && date) {
    result += ` ${_getDayName(year, month, date)}`;
  }

  return result;
};

const _getDayName = (year: string, month: string, date: string) => {
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  const resDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(date));
  return days[resDate.getDay()];
};

/**
 * 천단위 콤마 ex) 1000 => 1,000
 * @param num{string} 변환할 값
 * @returns{string} 변환된 값
 */
export const addComma = (num: string) => {
  const result = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return result;
};

/**
 * 천단위 콤마 && 백만원 미만 자리 비노출 ex) 1000 => 1,000 && 10000000 => 10,000,000 => 10
 * @param num{number} 변환할 값
 * @returns{string} 변환된 값
 */
export const addCommaMillion = (num: number) => {
  const result = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (num < 1000000) {
    const lang = sessionStorage.getItem('langCd');
    let message = '백만원 미만';
    switch (lang) {
      case EN:
        message = 'Less than 1 million won';
        break;
      case CH:
        message = '100万韩元以下';
        break;
    }
    return message;
  } else {
    return result.slice(0, -8);
  }
};

/**
 * 천단위, 백만단위 인스타그램처럼 표기 ex) 천 1000 => 1K, 백만 1000000 => 1M
 * @param num{string} 변환할 값
 * @returns{string} 변환된 값
 */
export const addInsta = (num: string) => {
  const inputNumber = Number(num) < 0 ? false : Number(num);
  const unitWords = ['', 'K', 'M'];
  const splitUnit = 1000;
  const splitCount = unitWords.length;
  const resultArray = [];
  let resultString = '';

  for (let i = 0; i < splitCount; i++) {
    let unitResult = (Number(inputNumber) % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    if (resultArray.length > 1) {
      resultString = String(addComma(String(resultArray[i]))) + unitWords[i];
    }
    if (resultArray.length == 1) {
      resultString = addComma(num);
    }
  }

  return resultString;
};
