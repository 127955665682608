import { useLocation } from 'react-router-dom';

const NotFoundPage = () => {
  const location = useLocation();

  return (
    <div>
      <span>
        Page Not Found
        <br />
        <br />
      </span>
      <span>pathname: {location.pathname}</span>
    </div>
  );
};

export default NotFoundPage;
