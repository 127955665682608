import { CustomATag, CustomImgTag } from 'common/view/components/custom';
import { FrameCol, FrameContainer, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const BlockChainLabComponent = () => {
  const { t, i18n } = useTranslation();

  return (
    <FrameRow>
      <FrameCol addClassName="blockchainlab-bg">
        <FrameContainer>
          <FrameRow>
            <div className="col-md-5 mob-text-center">
              <h1>
                {t('itMain.blockchain1')}
                <br />
                {t('itMain.blockchain2')}
                <br />
                <span className="fw-4">{t('itMain.blockchain3')}</span>
              </h1>
              <p>
                <CustomImgTag src="assets/img/icons/image-bclab.png" alt={t('itMain.blockchain4')} className="w70p" />
              </p>
              <p>
                {t('itMain.blockchain5')}
                <br />
                {t('itMain.blockchain6')}
              </p>
              <h2>{t('itMain.blockchain7')}</h2>
              <CustomATag href="/it/service#tab05" className="btn btn-outline-primary btn-rounded fs-14 mt-30">
                {t('itMain.blockchain8')}
                <i className="glyphicon icon icon-arrow-32 ml-5"></i>
              </CustomATag>
            </div>
            <div className="col-md-7">
              {i18n.language == 'ko' ? (
                <CustomImgTag className="blockchainlab-img" src="assets/img/custom/blockchainlab.png" alt="블록체인랩" />
              ) : i18n.language == 'ch' ? (
                <CustomImgTag className="blockchainlab-img" src="assets/img/custom/blockchainlab_cn.png" alt="블록체인랩" />
              ) : (
                <CustomImgTag className="blockchainlab-img" src="assets/img/custom/blockchainlab_en.png" alt="블록체인랩" />
              )}
            </div>
          </FrameRow>
        </FrameContainer>
      </FrameCol>
    </FrameRow>
  );
};

export default BlockChainLabComponent;
